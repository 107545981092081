<template>
  <div class="result-categories">
    <p class="mb-3">دسته بندی</p>

    <div class="resultCategoryContent">
      <div class="category-all-products">
        <font-awesome-icon
          icon="fa-chevron-down"
          :class="[
            'category-result-i-down',
            { 'inactive-chevron-down': activeCategory != '' },
            { 'active-chevron-down': activeCategory == '' },
          ]"
        />
        <span
          :style="[
            activeCategory == '' ? { color: `${$mainColor} !important` } : {},
          ]"
          @click="allCategoriesAction"
        >
          همه کالاها
        </span>
      </div>

      <div
        id="resultCategories"
        :class="[
          'product-route-detail-btn1',
          { showCollapseResultCategoryContent: showMoreResultCategories },
        ]"
      >
        <a
          v-if="'productCategories' in $route.query"
          @click="showSpecificCategory(category)"
        >
          <font-awesome-icon
            :class="[
              'category-result-i-down',
              { 'inactive-chevron-down': activeCategory != parentId },
              { 'active-chevron-down': activeCategory == parentId },
            ]"
            icon="fa-chevron-down"
            class="category-result-i-down"
          />
          <span
            :style="[
              activeCategory == parentId
                ? { color: `${$mainColor} !important` }
                : {},
            ]"
          >
            {{ parentTitle }}
          </span>
        </a>

        <a
          v-show="categories.length > 0"
          v-for="(category, index) in categories"
          :key="index"
          :style="[
            'productCategories' in $route.query ? { marginRight: '30px' } : {},
          ]"
          @click="showSpecificCategory(category)"
        >
          <font-awesome-icon
            icon="fa-chevron-down"
            class="category-result-i-down"
          />
          <span>
            {{ "title" in category ? category.title : "" }}
          </span>
        </a>
      </div>
    </div>

    <button
      @click="showMoreResultCategories = !showMoreResultCategories"
      class="result-categories-showMore-btn mt-3 mb-3"
    >
      <div
        class="d-flex justify-content-center align-items-center"
        v-if="!showMoreResultCategories"
      >
        <span>بیشتر</span>

        <font-awesome-icon icon="fa-chevron-down" />
      </div>
      <div class="d-flex justify-content-center align-items-center" v-else>
        <span>کمتر</span>

        <font-awesome-icon icon="fa-chevron-up" />
      </div>
    </button>
  </div>
</template>

<script>
import ProductCategory from "./ProductCategory.vue";

export default {
  name: "ProductCategory",

  components: {
    ProductCategory: ProductCategory,
  },

  props: ["categories"],

  data() {
    return {
      showMoreResultCategories: true,
      activeCategory: "",
    };
  },

  methods: {
    // method for show all categories
    allCategoriesAction() {
      let query = Object.assign({}, this.$route.query);
      delete query.productCategories;

      this.$router.replace({ query });

      // reload page
      location.href = this.$router.resolve({
        name: this.$route.path == "/" ? "store" : "search",
        query: this.$router.query,
      }).href;
    },

    //      method for show specific category
    showSpecificCategory(category) {
      // dispatch show specific categories
      this.$store.dispatch("showCategoriesDispatch", {
        category_id: category.id,
      });

      // reload page
      location.href = this.$router.resolve({
        name: "search",
        query: {
          productCategories: JSON.stringify([`${category.id}`]),
        },
      }).href;
    },
  },

  computed: {
    parentId() {
      if (this.$store.getters.getShowCatgories) {
        return this.$store.getters.getShowCatgories.id;
      } else {
        return "";
      }
    },
    parentTitle() {
      if (this.$store.getters.getShowCatgories) {
        return this.$store.getters.getShowCatgories.title;
      } else {
        return "";
      }
    },
  },

  created() {
    let status = "productCategories" in this.$route.query;

    if (!status) {
      this.activeCategory = "";
    } else {
      let productCategories = JSON.parse(this.$route.query.productCategories);

      this.activeCategory = productCategories[0];
    }
  },
};
</script>

<style src="../../assets/css/store/productsList.css" scoped></style>
