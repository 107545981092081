<template>
  <div style="width: 100%">
    <div class="marketing">
      <div class="marketing-content">
        <template v-for="(market, mIndex) in getStoreMarketing">
          <div v-if="mIndex < 3" class="marketing-section" :key="mIndex">
            <img :src="market.media_link" alt="مشاوره تخصصی" />
            <h5 class="title">{{ market.title }}</h5>
            <p class="description mt-3 mb-2">
              {{ market.description }}
            </p>
          </div>
          <!-- <div v-if="mIndex != (getStoreMarketing.length - 1)" class="flash-right">
                        <img class="img-fluid flash-img" src="../../assets/images/pictures/blue-flash-store.svg"
                            alt="" />
                    </div> -->
        </template>
      </div>
    </div>

    <!-- mobile size section -->
    <div class="mobileMarketing">
      <div
        v-show="mmIndex < 3"
        v-for="(market, mmIndex) in getStoreMarketing"
        class="professionalConsultation"
        :key="mmIndex"
      >
        <img :src="market.media_link" alt="" />
        <p>{{ market.title }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Marketing",

  data() {
    return {};
  },

  computed: {
    getStoreMarketing() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_marketing.length > 0
      ) {
        return this.$store.getters.getStoreInfo.store_marketing;
      }
    },
  },

  created() {
    this.$store.dispatch("storeInfoDispatch");
  },
};
</script>

<style src="../../assets/css/store/marketing.css" scoped></style>
