<template>
  <div class="download-app-cotainer">
    <div class="app-box">
      <div class="app-header">
        <img src="../../assets/images/icons/mobile-fav-icon.svg" />
        <h3>ویتسل</h3>
      </div>

      <div class="app-title">
        <h3>تو موبایل راحت تره!</h3>
        <p>برای دسترسی بهتر در گوشی موبایل اپلیکیشن ویتسل را نصب کنید</p>
      </div>

      <div class="bazar-and-apk-download">
        <!-- bazar box -->
        <div class="bazar-box">
          <div class="bazar-top">
            <img
              class="bazar-top-img"
              src="../../assets/images/pictures/bazar-logo.png"
              alt=""
            />

            <div class="bazar-top-left">
              <span>
                دریافت از
                <img src="../../assets/images/icons/bazar-persian.svg" alt="" />
              </span>

              <div class="andriod-and-size">
                <span>
                  <img src="../../assets/images/icons/android.svg" alt="" />
                  APK.
                </span>

                <p>35 Mb</p>
              </div>
            </div>
          </div>
          <button @click="getFromBazar()" class="bazar-submit">
            دریافت از
            <img
              :style="{ marginRight: '8px' }"
              src="../../assets/images/icons/bazar-white.svg"
            />
            <img
              :style="{ marginRight: '24px' }"
              src="../../assets/images/icons/min-basket.svg"
            />
          </button>
          <div class="bazar-bottom"></div>
        </div>

        <!-- download box -->
        <div :style="{ border: '1px solid #81A9C9' }" class="bazar-box">
          <div class="bazar-top">
            <img
              class="bazar-top-img"
              src="../../assets/images/pictures/download-app.png"
              alt=""
            />

            <div class="bazar-top-left">
              <h3>
                دانلود مستقیم
              </h3>

              <div class="andriod-and-size">
                <span>
                  <img src="../../assets/images/icons/android.svg" alt="" />
                  APK.
                </span>

                <p>35 Mb</p>
              </div>
            </div>
          </div>

          <a
            href="https://vitsell.ir/dist/vitsellapp.apk"
            target="_blank"
            download="vitesllapp.apk"
            class="download-submit"
          >
            <img
              :style="{ marginLeft: '8px' }"
              src="../../assets/images/icons/download-icon.svg"
            />
            دانلود مستقیم اپ ویتسل
          </a>

          <div class="bazar-bottom"></div>
        </div>
      </div>

      <div class="tab-navigator-box"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      fetching: false,
      error: false
    };
  },

  methods: {
    // method for get app from bazar
    getFromBazar() {
      window.open("https://cafebazaar.ir/app/com.vistaapp", "_blank");
    }
  }
};
</script>

<style src="../../assets/css/mobileSize/downloadApp.css" scoped></style>
