<template>
  <div id="review" class="review">
    <div
      id="review-route-title"
      :class="[
        'review-route-title',
        { reviewScrollClass: reviewTitleScrolled },
      ]"
    >
      <div class="review-title">
        <span>نقد و بررسی</span>
        <font-awesome-icon
          id="chevron-arrow"
          @click="showMoreAndLessReview"
          icon="fa-chevron-down"
        />
      </div>

      <div class="review-route">
        <div
          v-for="(circle, cIndex) in getProductReviews"
          :style="{
            width:
              cIndex != getProductReviews.length - 1
                ? 100 / (getProductReviews.length - 1) - 1 + '%'
                : '5%',
          }"
          :key="cIndex"
          class="review-route-circle"
        >
          <div @click="showProductReviewEvent(cIndex)" class="route-circle">
            <img
              src="../../assets/images/icons/preview-route-img-tike.svg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="route-item-title">
        <a
          @click.prevent="showProductReviewEvent(tIndex)"
          v-for="({ title }, tIndex) in getProductReviews"
          :key="tIndex"
        >
          {{ title.length > 15 ? title.slice(0, 40) + "..." : title }}
        </a>
      </div>
    </div>

    <div id="review-content" class="review-content">
      <div
        v-for="(review, index) in getProductReviews"
        :key="index"
        class="review-content"
      >
        <p :id="`review${index}`" class="review-content-title">
          {{ review.title }}
        </p>

        <div class="review-content-description-img">
          <span>
            {{ review.description }}
          </span>
          <img :src="review.media.media_link" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Review",

  data() {
    return {
      reviewTitleScrolled: false,
      showReview: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1400) {
        this.reviewTitleScrolled = true;
      } else {
        this.reviewTitleScrolled = false;
      }
    });
  },

  methods: {
    // method for toolbar show content of product reviews
    showProductReviewEvent(index) {
      // const reviewContent = document.getElementById('review-content');
      const introduction = document.getElementById(`review${index}`);

      let introductionOffset = introduction.offsetTop;

      window.scroll(0, introductionOffset - 110);
    },

    showMoreAndLessReview() {
      const reviewContent = document.getElementById("review-content");
      const review = document.getElementById("review");
      const chevronArrow = document.getElementById("chevron-arrow");

      if (this.showReview === false) {
        this.showReview = true;
        reviewContent.style.maxHeight = "0px";
        reviewContent.style.transition = ".3s ease-in-out max-height";
        review.style.paddingBottom = "0px";
        chevronArrow.style.transform = "rotate(0deg)";
      } else {
        this.showReview = false;
        reviewContent.style.maxHeight = "10000px";
        reviewContent.style.transition = ".3s ease-in-out max-height";
        review.style.paddingBottom = "50px";
        chevronArrow.style.transform = "rotate(180deg)";
      }
    },
  },

  computed: {
    getProductReviews() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.product_reviews.length > 0
      ) {
        return this.$store.getters.getShowProductInfo.product_reviews;
      }
    },
  },
};
</script>

<style src="../../assets/css/product/review.css" scoped></style>
