<template>
  <div id="user-account" class="UserAccount">
    <!-- mobile header -->
    <app-header id="app-header-mobile"></app-header>

    <!--  avatar for Desktop  -->
    <div
      v-if="showAvatarsBox"
      @click.self="showAvatarsBox = false"
      class="background-avatar"
    >
      <div class="user-account-avatar">
        <h4>نمایه خود را انتخاب کنید</h4>

        <div class="profile-avatars">
          <div
            v-for="(avatarImg, index) in avatarImages"
            v-bind:key="index"
            :class="[
              'single-profile-avatar',
              { 'single-profile-avatar-margin-right': index > 0 },
              { 'single-profile-avatar-margin-top': index > 4 },
            ]"
          >
            <img
              @click="selectedAvatar(avatarImg.src)"
              :src="avatarImg.src"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <!--  user Account details for Desktop  -->
    <div class="userAccount-details">
      <div class="userAccount-slider">
        <div class="userAccount-title">
          <div ref="" class="userAccount-avatar">
            <svg
              v-if="profileImg == ''"
              @click="showAvatarsBox = true"
              id="Iconly_Bold_Profile"
              width="80%"
              height="80%"
              data-name="Iconly/Bold/Profile"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g id="Profile" transform="translate(4 2)">
                <path
                  id="Profile-2"
                  data-name="Profile"
                  d="M0,16.575c0-2.722,3.686-3.4,8-3.4,4.339,0,8,.7,8,3.424S12.315,20,8,20C3.662,20,0,19.3,0,16.575ZM2.706,5.291A5.294,5.294,0,1,1,8,10.583,5.274,5.274,0,0,1,2.706,5.291Z"
                  transform="translate(0 0)"
                  fill="#200e32"
                />
              </g>
            </svg>

            <img
              v-else
              @click="showAvatarsBox = true"
              :src="profileImg"
              alt=""
            />

            <!--                        <input type="file" @change="onFileSelected">-->
          </div>

          <div class="name-phoneNumber">
            <p>{{ userInfo.full_name }}</p>

            <span>{{ userInfo.phone_number }}</span>
          </div>
        </div>

        <hr class="userAccount-hr" />

        <!--  links for show Content    -->

        <div class="slider-content">
          <div
            @click="userAccount"
            :class="['slider-user-account', { activeClass: activeUserAccount }]"
          >
            <img
              v-if="!activeUserAccount"
              class="img-fluid"
              src="../../assets/images/icons/slider-user-account.svg"
              alt=""
            />

            <img
              v-else
              class="img-fluid"
              src="../../assets/images/icons/active-user-account.svg"
              alt=""
            />

            <span :class="{ activeClass: activeUserAccount }">حساب کاربری</span>
          </div>

          <div
            @click="followOrders"
            :class="['follow-orders', { activeClass: activeFollowOrders }]"
          >
            <img
              v-if="!activeFollowOrders"
              src="../../assets/images/icons/slider-follow-orders-icon.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/icons/active-follow-orders.svg"
              alt=""
            />
            <span :class="{ activeClass: activeFollowOrders }"
              >پیگیری سفارشات</span
            >
          </div>

          <div
            @click="myAddress"
            :class="['my-address', { activeClass: activeMyAddress }]"
          >
            <img
              v-if="!activeMyAddress"
              src="../../assets/images/icons/slider-my-address.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/icons/active-my-addresses.svg"
              alt=""
            />
            <span :class="{ activeClass: activeMyAddress }">آدرس‌های من</span>
          </div>

          <div
            @click="sliderComments"
            :class="['slider-comments', { activeClass: activeSliderComments }]"
          >
            <img
              v-if="!activeSliderComments"
              src="../../assets/images/icons/slider-comments-icon.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/icons/active-comments-icon.svg"
              alt=""
            />
            <span :class="{ activeClass: activeSliderComments }">نظرات</span>
          </div>

          <div
            @click="sliderMessages"
            :class="['slider-messages', { activeClass: activeSliderMessages }]"
          >
            <img
              v-if="!activeSliderMessages"
              src="../../assets/images/icons/slider-messages-icon.svg"
              alt=""
            />
            <img
              v-else
              src="../../assets/images/icons/active-slider-messages.svg"
              alt=""
            />
            <span :class="{ activeClass: activeSliderMessages }">پیغام ها</span>
          </div>

          <!-- TODO Returned orders -->

          <div
            @click="sliderProductsReturned"
            :class="[
              'slider-products-returned',
              { activeClass: activeSliderProductsReturned },
            ]"
          >
            <img
              v-if="!activeSliderProductsReturned"
              src="../../assets/images/icons/slider-product-returned-icon.svg"
              alt=""
            />

            <img
              v-else
              src="../../assets/images/icons/active-slider-product-returned.svg"
              alt=""
            />

            <span :class="{ activeClass: activeSliderProductsReturned }"
              >محصولات مرجوعی</span
            >
          </div>
        </div>

        <div class="slider-get-News">
          <span>دریافت خبرنامه</span>

          <label
            for="checkBoxGetNews"
            @click="checkBoxGetNews('checkBoxGetNews')"
            :class="{ activeCheckBox: activeCheckBoxGetNews }"
            class="get-news-checkBox-parent"
          >
            <div
              :class="[
                'checkBox-button',
                { activeCheckBoxCircle: activeCheckBoxGetNews },
              ]"
            ></div>
            <input
              id="checkBoxGetNews"
              :checked="userInfo.subscribe_news_status"
              name="get-news"
              style="display: none"
              type="checkbox"
            />
          </label>
        </div>

        <hr class="checkBoxGetNews-hr" />

        <div class="slider-user-account-exit">
          <img
            @click.prevent="logOut"
            class="img-fluid"
            src="../../assets/images/icons/slider-user-accoount-exit-icon.svg"
            alt=""
          />
          <span @click.prevent="logOut">خروج</span>
        </div>
      </div>

      <div class="userAccount-content">
        <!-- routes -->

        <!-- address route -->
        <slot></slot>

        <!-- router view in nested route -->
        <!-- <router-view
          :showUserAccountContentMobile="showUserAccountContentMobile"
        >
        </router-view> -->
      </div>
    </div>

    <!--  Slider For Mobile  -->
    <div id="user-account-slider-mobile" class="user-account-slider-mobile">
      <div class="user-account-slider-mobile-avatar">
        <div class="user-account-slider-mobile-avatar-image">
          <img
            @click="showAvatarsBoxMobile"
            :src="
              profileImg == ''
                ? require('../../assets/images/icons/avatar/1.png')
                : profileImg
            "
            alt=""
          />

          <h4>{{ userInfo.full_name }}</h4>
          <p>{{ userInfo.phone_number }}</p>
        </div>

        <div class="background-avatar-mobile pb-0">
          <img
            src="../../assets/images/pictures/background-avatar-mobile2.svg"
            alt=""
          />
        </div>

        <div class="user-account-slider-mobile-orders-info">
          <div class="mobile-orders-info">
            <p>سفارشات</p>
            <h4>{{ countResult != null ? countResult.all : 0 }}</h4>
          </div>

          <div class="mobile-order-info-line"></div>

          <div class="mobile-delivered-info">
            <p>تحویل شده</p>
            <h4>{{ countResult != null ? countResult.completed : 0 }}</h4>
          </div>

          <div class="mobile-order-info-line"></div>

          <div class="mobile-not-delivered-info">
            <p>تحویل نشده</p>
            <h4>{{ countResult != null ? countResult.processing : 0 }}</h4>
          </div>

          <div class="mobile-order-info-line"></div>

          <div class="mobile-Canceled-info">
            <p>لغو شده</p>
            <h4>{{ countResult != null ? countResult.canceled : 0 }}</h4>
          </div>
        </div>
      </div>

      <div class="user-account-slider-mobile-content">
        <div
          @click="showUserAccountContentMobile"
          class="mobile-user-account-link"
        >
          <div class="mobile-user-account-link-right">
            <img src="../../assets/images/icons/user-account-icon.svg" alt="" />
            <p>حساب کاربری</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div @click.prevent="goToOrdersFollow" class="mobile-track-orders-link">
          <div class="mobile-user-account-link-right">
            <img
              src="../../assets/images/icons/track-orders-mobile-icon.svg"
              alt=""
            />
            <p>پیگیری سفارشات</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div @click.prevent="goToMyAddresses" class="mobile-my-addresses-link">
          <div class="mobile-user-account-link-right">
            <img
              src="../../assets/images/icons/my-addresses-mobile-icon.svg"
              alt=""
            />
            <p>آدرس‌های من</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div @click="goToCommentsPageMobile" class="mobile-comments-link">
          <div class="mobile-user-account-link-right">
            <img
              src="../../assets/images/icons/comments-mobile-icon.svg"
              alt=""
            />
            <p>نظرات</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div @click="goToMessagesPageMobile" class="mobile-messages-link">
          <div class="mobile-user-account-link-right">
            <img
              src="../../assets/images/icons/messages-mobile-icon.svg"
              alt=""
            />
            <p>پیغام ها</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div
          @click="goToReturnedProductsPageMobile"
          class="mobile-messages-link"
        >
          <div class="mobile-user-account-link-right">
            <img
              src="../../assets/images/icons/active-slider-product-returned.svg"
              alt=""
            />
            <p>محصولات مرجوعی</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>

        <div class="mobile-exit-link">
          <div @click.prevent="logOut" class="mobile-user-account-link-right">
            <img src="../../assets/images/icons/exit-mobile-icon.svg" alt="" />
            <p>خروج</p>
          </div>

          <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="" />
        </div>
      </div>

      <div class="get-newsLetter-mobile">
        <p>دریافت خبرنامه</p>

        <label
          @click="checkBoxGetNews('checkBoxGetNewsMobile')"
          for="checkBoxGetNewsMobile"
          :class="[
            'checkBox-get-newsLetter-mobile',
            { activeCheckBoxGetNewsMobile: activeCheckBoxGetNews },
          ]"
        >
          <input
            style="display: none"
            type="checkbox"
            id="checkBoxGetNewsMobile"
          />

          <div
            :class="[
              'checkBox-get-newsLetter-mobile-circle',
              { activeCheckBoxGetNewsMobileCircle: activeCheckBoxGetNews },
            ]"
          ></div>
        </label>
      </div>

      <div
        id="avatarModalBackgroundMobile"
        @click="closeAvatarModalClick"
        class="user-account-slider-mobile-background"
      ></div>

      <div
        @touchstart.self="closeAvatarModalStart"
        @touchmove.self="closeAvatarModalMove"
        @touchend.self="closeAvatarModalEnd"
        id="avatarsBoxContentMobile"
        :style="{ bottom: avatarMobileBottom }"
        class="select-new-avatar-image-content-for-mobile"
      >
        <div class="line-mobile-select-avatar"></div>
        <p>انتخاب نمایه</p>

        <div
          id="avatarImagesMobile"
          class="select-avatar-images-content-mobile"
        >
          <img
            @click="selectAvatarImgMobile(index)"
            v-for="(avatar, index) in avatarImages"
            :src="avatar.src"
            :key="index"
            alt=""
          />
        </div>

        <button
          @click="submitAvatarImgMobile"
          :disabled="selectedAvatarImgMobile == ''"
          :class="[
            'confirmation-set-avatar-img-mobile',
            { activeSubmitAvatarImgMobile: selectedAvatarImgMobile != '' },
          ]"
        >
          تایید
        </button>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "UserAccount",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      activeCheckBoxGetNews: false,
      activeUserAccount: false,
      activeFollowOrders: false,
      activeMyAddress: false,
      activeSliderComments: false,
      activeSliderMessages: false,
      activeSliderProductsReturned: false,

      /* avatar data */
      showAvatarsBox: false,
      // selectedImg: null,
      avatarImages: [
        { src: require("../../assets/images/icons/avatar/15.png") },
        { src: require("../../assets/images/icons/avatar/20.png") },
        { src: require("../../assets/images/icons/avatar/10.png") },
        { src: require("../../assets/images/icons/avatar/11.png") },
        { src: require("../../assets/images/icons/avatar/4.png") },
        { src: require("../../assets/images/icons/avatar/24.png") },
        { src: require("../../assets/images/icons/avatar/30.png") },
        { src: require("../../assets/images/icons/avatar/28.png") },
        { src: require("../../assets/images/icons/avatar/29.png") },
        { src: require("../../assets/images/icons/avatar/27.png") },
      ],

      //    avatar mobile data
      startingY: null,
      avatarMobileBottom: null,
      changeClientTouch: null,
      screenWidth: null,
      selectedAvatarImgMobile: "",
      submittedAvatarImgMobile: "",
    };
  },

  methods: {
    //  method for logout user
    logOut() {
      this.$store
        .dispatch("logOutDispatch", {
          token: this.token,
        })
        .then((res) => {
          if (res.status) {
            //  remove token
            this.$store.dispatch("setTokenDispatch", "");

            //  redirect user
            let resolved = this.$router.resolve({
              name: "store",
            });

            location.href = resolved.href;
          }
        });
    },

    /* method for select image for avatar */

    selectedAvatar(src) {
      console.log(src);
      this.$store.dispatch("profileImageDispatch", src);
      // this.selectedImg = src;
      this.showAvatarsBox = false;
    },

    checkBoxGetNews(checkBoxInput) {
      console.log(checkBoxInput);

      const checkBoxGetNews = document.getElementById(checkBoxInput);

      if (checkBoxGetNews.checked === false) {
        this.activeCheckBoxGetNews = true;
        checkBoxGetNews.checked = true;
      } else {
        this.activeCheckBoxGetNews = false;
        checkBoxGetNews.checked = false;
      }

      this.$store
        .dispatch("updateUserDispatch", {
          token: this.token,
          subscribe_news_status: this.activeCheckBoxGetNews,
        })
        .then((res) => {
          //  success alert
          if (res.status) {
            //  dispatch for get userInfo
            this.$store.dispatch("userDispatch", { token: this.token });
          }
        });
    },

    userAccount() {
      this.activeFollowOrders = false;
      this.activeMyAddress = false;
      this.activeSliderComments = false;
      this.activeSliderMessages = false;
      this.activeSliderProductsReturned = false;

      /**/

      this.activeUserAccount = true;

      //  redirect
      let resolved = this.$router.resolve({
        name: "userAccount",
      });
      location.href = resolved.href;
    },

    followOrders() {
      this.activeUserAccount = false;
      this.activeMyAddress = false;
      this.activeSliderComments = false;
      this.activeSliderMessages = false;
      this.activeSliderProductsReturned = false;

      /**/

      this.activeFollowOrders = true;

      //  redirect
      let resolved = this.$router.resolve({
        name: "orders",
      });

      location.href = resolved.href;
    },

    myAddress() {
      this.activeUserAccount = false;
      this.activeFollowOrders = false;
      this.activeSliderComments = false;
      this.activeSliderMessages = false;
      this.activeSliderProductsReturned = false;

      /**/
      this.activeMyAddress = true;
      //  redirect
      let resolved = this.$router.resolve({
        name: "addresses",
      });

      location.href = resolved.href;
    },

    sliderComments() {
      this.activeUserAccount = false;
      this.activeFollowOrders = false;
      this.activeMyAddress = false;
      this.activeSliderMessages = false;
      this.activeSliderProductsReturned = false;

      /**/

      this.activeSliderComments = true;
      //  redirect
      let resolved = this.$router.resolve({
        name: "comments",
      });

      location.href = resolved.href;
    },

    sliderMessages() {
      this.activeUserAccount = false;
      this.activeFollowOrders = false;
      this.activeMyAddress = false;
      this.activeSliderComments = false;
      this.activeSliderProductsReturned = false;

      /**/

      this.activeSliderMessages = true;
      //  redirect
      let resolved = this.$router.resolve({
        name: "messages",
      });

      location.href = resolved.href;
    },

    sliderProductsReturned() {
      this.activeUserAccount = false;
      this.activeFollowOrders = false;
      this.activeMyAddress = false;
      this.activeSliderComments = false;
      this.activeSliderMessages = false;

      /**/

      this.activeSliderProductsReturned = true;

      //  redirect
      let resolved = this.$router.resolve({
        name: "returnedOrder",
      });

      location.href = resolved.href;
    },

    /* mobile methods */
    showAvatarsBoxMobile() {
      /* background avatar modal mobile */
      const background = document.getElementById("avatarModalBackgroundMobile");
      background.style.height = "100%";

      document.body.style.overflow = "hidden !important";

      /* get content box avatar mobile */
      const avatarMobile = document.getElementById("avatarsBoxContentMobile");
      const heightDevice = window.screen.height;
      // const heightAvatarDevice = (heightDevice * 66) / 100;

      // console.log(avatarMobile, heightAvatarDevice);

      avatarMobile.style.maxHeight = heightDevice + "px";
      this.avatarMobileBottom = "0px";

      /* get avatar images  */

      // const avatarImagesMobile = document.getElementById('avatarImagesMobile');

      // avatarImagesMobile.style.height = (heightAvatarDevice * 50) / 100 + 'px';

      /* get header for hide when avatar modal mobile show */

      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";
      }

      /* empty avatar mobile src */
      this.selectedAvatarImgMobile = "";

      /* remove border in any avatar img mobile */

      let images = document.querySelectorAll(
        ".select-avatar-images-content-mobile img"
      );

      images.forEach((img) => {
        img.style.border = "none";
      });
    },

    /* close avatar modal */

    closeAvatarModalStart(ev) {
      const avatarContentBoxMobile = document.getElementById(
        "avatarsBoxContentMobile"
      );
      this.startingY = ev.touches[0].clientY;

      console.log(avatarContentBoxMobile);
    },

    closeAvatarModalMove(ev) {
      let touch = ev.touches[0];
      let change = this.startingY - touch.clientY;
      this.changeClientTouch = change;

      if (change < 0) {
        this.avatarMobileBottom = change + "px !important";
      }

      console.log(touch, change, change);
    },

    closeAvatarModalEnd() {
      const header = document.getElementById("app-header-mobile");

      if (
        this.changeClientTouch < 0 &&
        this.changeClientTouch <= -80 &&
        this.screenWidth < 426
      ) {
        this.avatarMobileBottom = "-5000px";
        document.body.style.overflow = "scroll !important";
        /* background avatar modal mobile */
        const background = document.getElementById(
          "avatarModalBackgroundMobile"
        );

        background.style.height = "0%";
        this.changeClientTouch = null;

        /* get header for hide when avatar modal mobile show */
        header.style.display = "flex";
      }
    },

    closeAvatarModalClick() {
      this.avatarMobileBottom = "-5000px";
      document.body.style.overflow = "scroll";

      /* background avatar modal mobile */
      const background = document.getElementById("avatarModalBackgroundMobile");
      background.style.height = "0%";

      /* get header for hide when avatar modal mobile show */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "flex";
      }
    },

    selectAvatarImgMobile(index) {
      const images = document.querySelectorAll(
        ".select-avatar-images-content-mobile img"
      );

      images.forEach((i, j) => {
        i.style.border = "none";

        if (index == j) {
          console.log(i.src);
          i.style.border = "3px solid #3FBDCC";
          this.selectedAvatarImgMobile = i.src;
        }
      });
    },

    submitAvatarImgMobile() {
      this.avatarMobileBottom = "-5000px";
      document.body.style.overflow = "scroll";
      /* background avatar modal mobile */
      const background = document.getElementById("avatarModalBackgroundMobile");

      background.style.height = "0%";

      /* get header for hide when avatar modal mobile show */

      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "flex";
      }

      /* final submitted */
      this.submittedAvatarImgMobile = this.selectedAvatarImgMobile;

      this.$store.dispatch(
        "profileImageDispatch",
        this.submittedAvatarImgMobile
      );
    },

    /* show user account content mobile */

    showUserAccountContentMobile() {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      /* get user account content */
      const userAccountContent = document.getElementById(
        "user-account-content"
      );

      if (this.screenWidth < 426) {
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
        userAccountContent.classList.remove("hideContent");
      }
    },

    /* go to orders follow page method */
    goToOrdersFollow() {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      if (this.screenWidth < 426) {
        let resolved = this.$router.resolve({
          name: "orders",
        });

        location.href = resolved.href;
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
      }
    },

    /* got to my addresses page method */
    goToMyAddresses() {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      if (this.screenWidth < 426) {
        let resolved = this.$router.resolve({
          name: "addresses",
        });

        location.href = resolved.href;
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
      }
    },

    /* go to messages page mobile */

    goToMessagesPageMobile() {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      if (this.screenWidth < 426) {
        let resolved = this.$router.resolve({
          name: "messages",
        });

        location.href = resolved.href;
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
      }
    },

    /* go to comments page mobile */
    goToCommentsPageMobile() {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      if (this.screenWidth < 426) {
        let resolved = this.$router.resolve({
          name: "comments",
        });

        location.href = resolved.href;
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
      }
    },

    /* got to returned products page mobile */

    goToReturnedProductsPageMobile() {
      /* get orders returned mobile */
      const ordersReturnedMobile = document.getElementById(
        "orders-returned-mobile"
      );

      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      if (this.screenWidth < 426) {
        let resolved = this.$router.resolve({
          name: "returnedOrder",
        });

        location.href = resolved.href;
        header.style.display = "none";
        userAccountSliderMobile.style.display = "none";
        ordersReturnedMobile.style.display = "flex";
      }
    },
  },

  mounted() {
    // method for sidebar of mobile responsive
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }

    if (
      this.$route.path == "/profile/" ||
      this.$route.path == "/profile" ||
      this.$route.path == "/profile/password/edit" ||
      this.$route.path == "/profile/password/requestActiveCode" ||
      this.$route.path == "/profile/password/activeCode" ||
      this.$route.path == "/profile/newPassword"
    ) {
      this.activeUserAccount = true;
      this.$forceUpdate();
    } else if (
      this.$route.path == "/profile/address" ||
      this.$route.path == "/profile/address/"
    ) {
      this.activeMyAddress = true;
      this.$forceUpdate();
    } else if (
      this.$route.path == "/profile/comment" ||
      this.$route.path == "/profile/comment/"
    ) {
      this.activeSliderComments = true;
      this.$forceUpdate();
    } else if (
      this.$route.path == "/profile/message" ||
      this.$route.path == "/profile/message/"
    ) {
      this.activeSliderMessages = true;
      this.$forceUpdate();
    } else if (
      this.$route.path == "/profile/order" ||
      this.$route.path == "/profile/order/"
    ) {
      this.activeFollowOrders = true;
      this.$forceUpdate();
    } else if (
      this.$route.path == "/profile/return-order" ||
      this.$route.path == "/profile/return-order/"
    ) {
      this.activeSliderProductsReturned = true;
    }
  },

  computed: {
    // computed for get show suggestion app download
    getShowAppSuggestionProfile() {
      console.log(this.$store.getters.getShowAppSuggestionProfile);
      return this.$store.getters.getShowAppSuggestionProfile;
    },

    // method for get token
    token() {
      return this.$store.getters.getToken;
    },

    //  method for get user info
    userInfo() {
      if (this.$store.getters.getUserInfo != null) {
        this.activeCheckBoxGetNews =
          this.$store.getters.getUserInfo.subscribe_news_status;
        return this.$store.getters.getUserInfo;
      } else {
        return {
          full_name: "",
        };
      }
    },

    // method for get order count result
    countResult() {
      if (this.$store.getters.getUserOrdes != null) {
        return this.$store.getters.getUserOrdes.count_result;
      } else {
        return null;
      }
    },

    //  method for get profile image
    profileImg() {
      return this.$store.getters.getProfileImage;
    },
  },

  created() {
    // method for sidebar of mobile responsive
    /* if (window != undefined) {
      this.screenWidth = window.screen.width;
    } */

    //  get user logged info
    if (this.token != "") {
      this.$store.dispatch("userDispatch", { token: this.token });
    }

    // get orders count info
    this.$store.dispatch("userOrdersDispatch", {
      token: this.token,
      order_status: "",
    });
  },
};
</script>

<style src="../../assets/css/userAccount/userAccount.css" scoped></style>
