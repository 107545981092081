<template>
  <div class="col-md-12 banner-brand p-0">
    <img
      @click="openBannerLink(getStoreBanner.banner_link)"
      :style="[
        getStoreBanner != null && getStoreBanner.banner_link != null
          ? { cursor: 'pointer' }
          : { cursor: 'auto' },
      ]"
      class="p-0"
      :src="getStoreBanner != null ? getStoreBanner.media_link : ''"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "BannerBrand",

  data() {
    return {};
  },

  computed: {
    getStoreBanner() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_banners.length > 0
      ) {
        return this.$store.getters.getStoreInfo.store_banners.find(
          (item) => item.banner_type == 3
        );
      } else {
        return null;
      }
    },
  },

  methods: {
    openBannerLink(banner_link) {
      if (banner_link != null) {
        window.open("http://" + banner_link, "_blank");
      }
    },
  },
};
</script>

<style scoped>
.banner-brand {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 210px;
  width: 100%;
}

.banner-brand img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  margin-top: 50px;
}

@media only screen and (max-width: 426px) {
  .banner-brand {
    display: none;
  }
}
</style>