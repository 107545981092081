<template>
  <div id="app" class="app-container container-fluid p-0">
    <!-- alerts section -->
    <Success></Success>
    <Warning></Warning>
    <Error></Error>

    <!--  TODO loader -->
    <!-- <Loader></Loader> -->

    <!-- background for modal or dropdown section -->
    <span
      @click="closeBackground"
      v-if="showBackground"
      class="background-box"
    ></span>

    <!-- TODO download mobile app suggestion -->
    <download-app v-if="getShowAppSuggestion" />

    <!-- content of app  -->
    <router-view v-if="!getShowAppSuggestion"></router-view>
  </div>
</template>

<script>
import Success from "./components/alert/Success.vue";
import Warning from "./components/alert/Warning.vue";
import Error from "./components/alert/Error.vue";
// import Loader from "./components/loader/Loader";
import DownloadApp from "./components/mobileSize/DownloadApp.vue";

export default {
  name: "App",

  data() {
    return {};
  },
  components: {
    Success,
    Warning,
    Error,
    // Loader,
    DownloadApp
  },

  computed: {
    showBackground() {
      return this.$store.getters.getShowBackground;
    },

    // TODO download mobile app suggestion
    getShowAppSuggestion() {
      console.log(this.$store.getters.getShowAppSuggestion);
      return this.$store.getters.getShowAppSuggestion;
    }
  },

  methods: {
    closeBackground() {
      this.$store.dispatch("showBackgroundDispatch", false);
    }
  },

  mounted() {
    // TODO download mobile app suggestion
    // check device size
    /*  
    if (window.innerWidth < 1000 && this.$route.name != "privacy") {
      this.$store.dispatch("setShowAppSuggestionDispatch", true);
    }
    */
    // TODO conditions for remove loader
    /*
    if (
      this.$route.name == "product" ||
      this.$route.name == "store" ||
      this.$route.name == "search"
    ) {
      //  event listener for remove loader
      addEventListener("DOMContentLoaded", event => {
        setTimeout(() => {
          this.$store.dispatch("showLoaderDispatch", false);
        }, 2000);
      });
    } else {
      //  event listener for remove loader
      addEventListener("load", event => {
        this.$store.dispatch("showLoaderDispatch", false);
      });
    }
    */
  }
  /*watch: {
      $route() {
          window.scrollTo(0, 0);
      }
  }*/
};
</script>

<style scoped>
.app-container {
  position: relative;
  width: 100%;
}

.background-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #11b67b; */
  z-index: 21;
  cursor: pointer;
}
</style>
