<template>
  <div class="discount-slider">
    <div class="discount-slider-content">
      <div class="discount-slider-title p-0">
        <p v-if="$route.path == '/'" class="title-right">پیشنهاد شگفت انگیز</p>
        <p v-else-if="$route.path.includes('/product')" class="title-right">
          محصولات مرتبط
        </p>
        <p class="title-left">مشاهده همه</p>
      </div>

      <!--            <hr class="product-discount-hr">-->

      <div class="slider p-0 m-0">
        <button @click="prev" class="nextBnt">
          <font-awesome-icon icon="fa-chevron-right" />
          <!-- <i class="fa fa-chevron-right" aria-hidden="true"></i> -->
        </button>

        <button @click="next" class="prevBtn">
          <font-awesome-icon icon="fa-chevron-left" />
          <!-- <i class="fa fa-chevron-left" aria-hidden="true"></i> -->
        </button>

        <Slick v-if="getProducts != null" ref="slick" :options="slickOptions">
          <!-- old product box -->
          <div
            v-for="(product, index) in getProducts"
            @click="openPrductHaveDiscount(product)"
            :key="index"
            class="product-div"
          >
            <div class="product-div-top">
              <img :src="product.productGallery" alt="" />
            </div>

            <div class="product-div-bottom">
              <div class="product-about">
                <router-link to="" tag="a">
                  <h3>
                    {{
                      product.product_name.length > 22
                        ? product.product_name.slice(0, 22) + "..."
                        : product.product_name
                    }}
                  </h3>
                  <span>
                    {{
                      product.product_introduction.length > 25
                        ? product.product_introduction.slice(0, 25) + "..."
                        : product.product_introduction
                    }}
                  </span>
                </router-link>
              </div>
              <div class="product-price">
                <!-- <span class="initial-price">{{ product.product_price }}</span> -->
                <div class="price">
                  <p>{{ numberFormat(product.product_price) }}</p>

                  <span>تومان</span>
                </div>
              </div>
              
              <div class="star-and-comment">
                <div class="comments">
                  <img
                    src="../../assets/images/icons/new-product-comment.svg"
                    alt=""
                  />
                  <span>{{ product.productComment_count }} +</span>
                </div>

                <div v-if="product.star_average != null" class="stars">
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(product.star_average, 4)"
                    class="text-warning pr1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(product.star_average, 3)"
                    class="text-warning pr1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(product.star_average, 2)"
                    class="text-warning pr-1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(product.star_average, 1)"
                    class="text-warning pr-1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(product.star_average, 0)"
                    class="text-warning pr-1"
                  />
                </div>

                <div v-else class="empty-starts">
                  <img src="../../assets/images/icons/empty-star.svg" alt="" />
                  <p>محصول جدید</p>
                </div>
              </div>
            </div>

            <!--<div class="news">
                <img class="img-fluid" src="../../assets/images/icons/report.svg" alt="">
                <p>7 عدد در انبار باقیست</p>
            </div>-->
          </div>
        </Slick>
      </div>
    </div>
  </div>
</template>

<script>
let imgSuggestionProduct1 = require("../../assets/images/pictures/shampo.png");
let imgSuggestionProduct2 = require("../../assets/images/pictures/product-img.png");
import Slick from "vue-slick";
import { numberFormat } from "../../assets/helper/helper";

export default {
  name: "DiscountSlider",
  components: {
    Slick
  },

  data() {
    return {
      slickOptions: {
        slidesToShow: 5,
        slidesToScroll: 1,
        rtl: true,
        dots: false,
        infinite: true,
        autoplay: false,
        arrows: false,
        // Any other options that can be got from plugin documentation

        responsive: [
          {
            breakpoint: 1440,

            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      },

      allSuggestionProduct: [
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct2,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct2,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct2,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct2,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct2,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        },
        {
          srcImg: imgSuggestionProduct1,
          desProduct: "پاک کننده ارایش چشم تک فاز پریم",
          subDesProduct: "مدل نیلوفری",
          price: "134,0000",
          countComment: "35+",
          rateStar: null
        }
      ]
    };
  },

  methods: {
    numberFormat,
    next() {
      this.$refs.slick.next();
    },

    prev() {
      this.$refs.slick.prev();
    },

    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    },

    // method for dispatch get product api
    asyncData({ store }) {
      if (this.$route.path == "/") {
        store.dispatch("discountSliderDispatch");
      } else if (this.$route.path.includes("/product")) {
        store.dispatch("relatedSliderDispatch", this.$route.params.id);
      }
    },

    //      font awesome computed for product stars
    fontAwesomeComputed(starAverage, index) {
      if (index < starAverage && starAverage < index + 1) {
        return "fa-solid fa-star-half-stroke";
      } else if (index < starAverage) {
        return "fa fa-star";
      } else {
        return "fa-regular fa-star";
      }
    },

    // method fo open new tab (product have discount)
    openPrductHaveDiscount(product) {
      console.log(product.url);
      let routeData = this.$router.resolve({
        name: "product",
        params: {
          id: product.product_id,
          url: product.url
        }
      });
      window.open(routeData.href, "_blank");
    }
  },

  computed: {
    // method for calculate div box
    /* calcWidthBox() {
      const divBoxWidth = document.body.clientWidth;

      if (divBoxWidth >= 1440) {
        return 100 / 5;
      } else if (1200 <= divBoxWidth && divBoxWidth < 1440) {
        return 100 / 4;
      } else if (divBoxWidth < 1200) {
        return 100 / 3;
      }
    },
 */
    // get products for discount slider
    getProducts() {
      if (this.$route.path == "/") {
        if (this.$store.getters.getDiscountSlider != null) {
          return this.$store.getters.getDiscountSlider;
        }
      } else if (this.$route.path.includes("/product")) {
        if (this.$store.getters.getRelatedSlider != null) {
          return this.$store.getters.getRelatedSlider;
        }
      }
    }
  },

  created() {
    if (this.$route.path == "/") {
      this.$store.dispatch("discountSliderDispatch");
    } else if (this.$route.path.includes("/product")) {
      this.$store.dispatch("relatedSliderDispatch", {
        product_id: this.$route.params.id
      });
    }
  }
};
</script>

<style src="../../assets/css/store/discountSlider.css" scoped></style>
