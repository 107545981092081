import Vue from "vue";
import Vuex from "vuex";
import states from "./states";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import createPersistedState from "./persistedState";

Vue.use(Vuex);

const plugins = [];

plugins.push(createPersistedState);

export function createStore() {
  return new Vuex.Store({
    plugins: plugins,
    state: states,
    getters,
    mutations,
    actions,
  });
}
