var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demo-background",style:([
    _vm.getShowDemoModal().show && _vm.getShowDemoModal().count > 0
      ? { opacity: '1', zIndex: '100' }
      : { opacity: '0', zIndex: '-1' }
  ])},[_c('div',{staticClass:"demo-box",style:([
      _vm.getShowDemoModal
        ? { opacity: '1', zIndex: '101' }
        : { opacity: '0', zIndex: '-1' }
    ])},[_vm._m(0),_c('p',[_vm._v("برای مشاهده فروشگاه لطفا شماره تلفن خود را تایید کنید")]),_c('div',{staticClass:"phoneNumber-box"},[_c('h3',[_vm._v("شماره موبایل")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.demoPhoneNumber),expression:"demoPhoneNumber"}],class:{
          'error-border': _vm.submitDemoUserError.demoPhoneNumber.length > 0
        },attrs:{"placeholder":"*۰۹۱۲۳۴۵۶۷۸۹","type":"number","name":"","id":""},domProps:{"value":(_vm.demoPhoneNumber)},on:{"input":[function($event){if($event.target.composing)return;_vm.demoPhoneNumber=$event.target.value},function($event){_vm.submitDemoUserError.demoPhoneNumber = []}]}}),_vm._l((_vm.submitDemoUserError.demoPhoneNumber),function(err,index){return (_vm.submitDemoUserError.demoPhoneNumber.length > 0)?_c('span',{key:index,staticClass:"error-text"},[_vm._v(_vm._s(err))]):_vm._e()})],2),_c('button',{staticClass:"demo-submit",on:{"click":function($event){return _vm.submitDemoUser()}}},[_vm._v(" ورود به فروشگاه ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"demo-box-header"},[_c('img',{attrs:{"src":require("../../assets/images/icons/goodbye-icon.svg"),"alt":""}}),_c('h3',[_vm._v("به ویتسل خوش آمدید")])])
}]

export { render, staticRenderFns }