<template>
  <div class="points-and-comments-users">
    <!-- <router-view @toggleCommentShowForAddNew="showAndHideCommentsForAddNew = $event"
            @toggleCommentShow="showAndHideCommentsForPointShow = $event">
        </router-view> -->

    <add-new-point
      v-if="token != ''"
      @toggleCommentShowForAddNew="showAndHideCommentsForAddNew = $event"
    />
    <points-show
      v-else
      @toggleCommentShow="showAndHideCommentsForPointShow = $event"
    />

    <comment-users
      id="product-comments"
      v-bind:showAndHideCommentsForPointShow="showAndHideCommentsForPointShow"
      v-bind:showAndHideCommentsForAddNew="showAndHideCommentsForAddNew"
    ></comment-users>
  </div>
</template>

<script>
import CommentUsers from "./CommentUsers";
import PointsShow from "./PointsShow.vue";
import AddNewPoint from "./AddNewPoint.vue";

export default {
  name: "PointsAndCommentsUsers",

  data() {
    return {
      showAndHideCommentsForPointShow: false,
      showAndHideCommentsForAddNew: false,
    };
  },

  computed: {
    token() {
      return this.$store.getters.getToken;
    },
  },

  watch: {
    $route() {
      if (this.showAndHideCommentsForPointShow === true) {
        this.showAndHideCommentsForAddNew = false;
        this.showAndHideCommentsForPointShow = false;
      } else if (this.showAndHideCommentsForAddNew === true) {
        this.showAndHideCommentsForAddNew = false;
        this.showAndHideCommentsForPointShow = false;
      }
    },
  },

  components: {
    "comment-users": CommentUsers,
    "points-show": PointsShow,
    "add-new-point": AddNewPoint,
  },
};
</script>

<style src="../../assets/css/product/pointsAndComments.css" scoped></style>
