import { render, staticRenderFns } from "./CommentUsers.vue?vue&type=template&id=37de864e&scoped=true&"
import script from "./CommentUsers.vue?vue&type=script&lang=js&"
export * from "./CommentUsers.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/product/commentUsers.css?vue&type=style&index=0&id=37de864e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37de864e",
  null
  
)

export default component.exports