import { render, staticRenderFns } from "./Marketing.vue?vue&type=template&id=1a2557c4&scoped=true&"
import script from "./Marketing.vue?vue&type=script&lang=js&"
export * from "./Marketing.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/store/marketing.css?vue&type=style&index=0&id=1a2557c4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a2557c4",
  null
  
)

export default component.exports