// router.js
import Vue from "vue";
import Router from "vue-router";
import Store from "../components/store/Store";
import AboutUs from "../components/others/AboutUs";
import ContactToUs from "../components/others/ContactToUs";
import TermsOfUs from "../components/others/TermsOfUs";
import Privacy from "../components/others/Privacy";
import TermsOfReturn from "../components/others/TermsOfReturn";
import Product from "../components/product/Product";
import PointShow from "../components/product/PointsShow";
import AddNewPoint from "../components/product/AddNewPoint";
import Questions from "../components/others/Questions";
import Category from "../components/category/Category";
import Register from "../components/register/Register.vue";
import CartContent from "../components/cart/CartContent";
import DeliveryInformation from "../components/cart/DeliveryInformation";
import PaymentInformation from "../components/cart/PaymentInformation";
import Addresses from "../components/userAccount/Addresses";
import Comments from "../components/userAccount/Comments";
import Messages from "../components/userAccount/Messages";
import UserAccountContent from "../components/userAccount/UserAccountContent";
import UserAccount from "../components/userAccount/UserAccount";
import OrdersFollow from "../components/userAccount/OrdersFollow";
import OrdersReturned from "../components/userAccount/OrdersReturned";
import CreateReturnedOrder from "../components/userAccount/CreateReturnedOrder";
import NotFound from "../components/others/NotFound";
import PaymentVerify from '../components/cart/PaymentVerify'
import { isJsonValid } from "../assets/helper/helper";

// const token = localStorage.getItem("access_token");
export default [
  // 404 page
  { path: "/404", component: NotFound },
  { path: "*", component: NotFound },
  { path: "/", name: "store", component: Store },
  { path: "/about-us", name: "aboutUs", component: AboutUs },
  { path: "/contact-us", name: "contactToUs", component: ContactToUs },
  { path: "/privacy", name: "privacy", component: Privacy },
  { path: "/terms-us", name: "termsOfUs", component: TermsOfUs },
  { path: "/return-policy", name: "returnPolicy", component: TermsOfReturn },
  {
    path: "/product/vsp-:id/:url",
    name: "product",
    component: Product,
  },
  {
    path: "/search",
    name: "search",
    beforeEnter: (to, from, next) => {
      if ("productCategories" in to.query) {
        if (isJsonValid(to.query.productCategories)) {
          return next();
        } else {
          return next("/404");
        }
      } else {
        return next();
      }
    },
    component: Category,
  },

  { path: "/questions", name: "questions", component: Questions },

  { path: "/users/login", name: "login", component: Register },

  // cart section routes
  { path: "/cart", name: "CartContent", component: CartContent },

  {
    path: "/cart/delivery-info",
    name: "deliveryInformation",
    component: DeliveryInformation,
  },

  {
    path: "/cart/checkout",
    name: "paymentInformation",
    component: PaymentInformation,
  },
  {
    path: "/cart/payment-verify",
    name: "paymentVerify",
    component: PaymentVerify,
  },

  // profile routes
  { path: "/profile", name: "userAccount", component: UserAccountContent },
  { path: "/profile/address", name: "addresses", component: Addresses },
  { path: "/profile/comment", name: "comments", component: Comments },
  { path: "/profile/message", name: "messages", component: Messages },
  { path: "/profile/order", name: "orders", component: OrdersFollow },
  {
    path: "/profile/return-order",
    name: "returnedOrder",
    component: OrdersReturned,
  },

  {
    path: "/profile/create-return-order/item-:id",
    name: "createReturnOrder",
    component: CreateReturnedOrder,
  },
];
