<template>
  <div class="pass-info-container">
    <!-- first layout (step 1) -->
    <div v-show="stepActive == 1" class="security-info-parent">
      <div class="security-info">
        <div ref="securityInfo" id="security-info"></div>

        <div class="personal-info-title">
          <span>اطلاعات امنیتی</span>
          <!--  v-if="screenWidth > 426" -->
          <img
            @click="securityInfo"
            src="../../assets/images/icons/edit-inputs-icon.svg"
            alt=""
          />
        </div>

        <div class="personal-info-details">
          <div class="personal-info-details-title">
            <p>رمز عبور</p>
          </div>

          <div class="personal-info-details-content">
            <p class="personal-info-password">
              &#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;
            </p>
          </div>
        </div>

        <!-- mobile section -->
        <!--  <div v-if="screenWidth < 426" class="edit-personal-info-mobile">
                    <hr>

                    <button @click="securityInfo">
                        <img src="../../assets/images/icons/edit-user-account-info-mobile.svg" alt="">
                        ویرایش
                    </button>
                </div> -->
      </div>

      <!--  security info mobile  -->
      <!-- <button @click="securityInfo" class="security-info-mobile">
                تغییر رمز عبور
            </button> -->
    </div>

    <!-- edit security info section (step 2) -->
    <div v-show="stepActive == 2" class="edit-security-info">
      <form class="edit-security-info-form col-12 p-0">
        <div class="edit-security-info-div">
          <div class="edit-security-current-password p-0">
            <label for="current-password">رمز عبور فعلی</label>

            <div
              :class="[
                'edit-security-current-password-input',
                { borderActive: currentPasswordInputActive },
              ]"
            >
              <span @click="forgetPassword">فرامش کردید ؟</span>

              <input
                v-model="currentPass"
                @focus="currentPasswordInputActive = true"
                @blur="currentPasswordInputActive = false"
                id="current-password"
                type="text"
              />
            </div>
          </div>

          <div class="edit-security-new-password p-0">
            <label for="new-password">رمز عبور جدید</label>

            <div
              :class="[
                'edit-security-new-password-input',
                { borderActive: newPasswordInputActive },
              ]"
            >
              <img
                @click="
                  () => {
                    typePassword == 'text'
                      ? (typePassword = 'password')
                      : (typePassword = 'text');
                  }
                "
                @touchstart="typePassword = 'text'"
                @touchend="typePassword = 'password'"
                src="../../assets/images/icons/hideAndShowInput.svg"
                alt=""
              />
              <input
                @keydown.enter="submitWithPrevPass"
                v-model="newPass"
                @focus="newPasswordInputActive = true"
                @blur="newPasswordInputActive = false"
                :type="typePassword"
                id="new-password"
              />
            </div>
          </div>
        </div>

        <div class="edit-security-info-buttons">
          <button @click.prevent="cancelEditSecurityInfo">لغو</button>
          <button @click.prevent="submitWithPrevPass">ثبت ویرایش</button>
        </div>
      </form>
    </div>

    <!-- request for active code step 3 -->
    <div v-show="stepActive == 3" class="requestActiveCode">
      <p>.جهت تغییر رمز ابتدا درخواست کد تایید بدهید</p>
      <div class="mobile-number-img">
        <!-- TODO mobile number of user -->
        <span>{{ userInfo.phone_number }}</span>
        <img src="../../assets/images/icons/phone-icon.svg" alt="" />
      </div>

      <div class="request-active-code-buttons">
        <button @click.prevent="cancelRequestActiveCode">برگشت</button>
        <button @click.prevent="requestActiveCode">درخواست کد تایید</button>
      </div>
    </div>

    <!-- enter verification code section step 4 -->
    <div v-show="stepActive == 4" class="active-code-for-new-pass">
      <p>کد 5 رقمی ارسال شده را وارد کنید .</p>

      <form action="#" class="verification-code-form">
        <div :style="{ marginBottom: '' }" class="verification-code-inputs">
          <input
            :class="{
              activeClassInputs:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
            dir="ltr"
            id="fifth-char"
            type="text"
            v-model="verificationCode.fifthChar"
            maxlength="1"
            @keyup="checkFifthInput('fifth-char')"
          />

          <input
            :class="{
              activeClassInputs:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
            dir="ltr"
            id="fourth-char"
            type="text"
            v-model="verificationCode.fourthChar"
            maxlength="1"
            @keyup="checkFourthInput('fourth-char')"
          />

          <input
            :class="{
              activeClassInputs:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
            dir="ltr"
            id="third-char"
            type="text"
            v-model="verificationCode.thirdChar"
            maxlength="1"
            @keyup="checkThirdInput('third-char')"
          />

          <input
            :class="{
              activeClassInputs:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
            dir="ltr"
            id="second-char"
            type="text"
            v-model="verificationCode.secondChar"
            maxlength="1"
            @keyup="checkSecondInput('second-char')"
          />

          <input
            :class="{
              activeClassInputs:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
            dir="ltr"
            id="first-char"
            type="text"
            v-model="verificationCode.firstChar"
            maxlength="1"
            @keyup="checkFirstInput"
          />
        </div>

        <!-- <span class="message-for-send-code"
          >امکان ارسال مجدد کد بعد از 100 ثانیه</span
        > -->

        <div class="active-code-form-buttons">
          <button @click.prevent="cancelActiveCode">برگشت</button>

          <button
            @click.prevent="activeCodeForNewPass"
            :disabled="
              !(firstChar && secondChar && thirdChar && fourthChar && fifthChar)
            "
            :class="{
              activeButton:
                firstChar && secondChar && thirdChar && fourthChar && fifthChar,
            }"
          >
            ادامه
          </button>
        </div>
      </form>
    </div>

    <!-- changes password last step (step 5) -->
    <div v-show="stepActive == 5" class="new-password">
      <form class="new-password-form">
        <div class="new-password-div col-12 p-0">
          <label for="new-password-div">رمز عبور جدید</label>

          <div
            :class="[
              'new-password-input-div',
              { borderActive: newPasswordActive },
            ]"
          >
            <img
              @click="
                () => {
                  showPass == 'text'
                    ? (showPass = 'password')
                    : (showPass = 'text');
                }
              "
              @touchstart="showPass = 'text'"
              @touchend="showPass = 'password'"
              src="../../assets/images/icons/hideAndShowInput.svg"
              alt=""
            />
            <input
              @keydown.enter="submitWithVerifyCode"
              v-model="newPass"
              @focus="newPasswordActive = true"
              @blur="newPasswordActive = false"
              id="new-password-div"
              :type="showPass"
              name="newPassword"
            />
          </div>
        </div>

        <div class="new-password-buttons">
          <button @click.prevent="cancelNewPassword">برگشت</button>
          <button @click.prevent="submitWithVerifyCode">ثبت ویرایش</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordInfo",

  data() {
    return {
      screenWidth: null,
      stepActive: 1,

      // editsecurityInfo varables
      typePassword: "password",
      currentPasswordInputActive: false,
      newPasswordInputActive: false,

      // enter verification code (step 4) variables
      verificationCode: {
        firstChar: "",
        secondChar: "",
        thirdChar: "",
        fourthChar: "",
        fifthChar: "",
      },

      // submit password step 5
      showPass: "password",
      newPasswordActive: false,

      // inputs value
      newPass: "",
      currentPass: "",
      finalVerifyCode: "",
    };
  },

  methods: {
    // step 1 methods
    securityInfo() {
      this.stepActive = 2;
      this.currentPass = "";
      this.newPass = "";
      this.inActiveHideDivs();
    },

    // editSecurityInfo methods (step 2 methods)
    forgetPassword() {
      this.stepActive = 3;

      const personalInfo = document.getElementById("personal-info");
      const selectedAddressInfo = document.getElementById(
        "selected-address-info"
      );
      const bankAccountInfo = document.getElementById("bank-account-info");
      // const securityInfo = document.getElementById('security-info');

      personalInfo.style.display = "block";
      selectedAddressInfo.style.display = "block";
      bankAccountInfo.style.display = "block";
    },

    cancelEditSecurityInfo() {
      this.stepActive = 1;

      this.activeHideDivs();

      this.newPass = "";
      this.currentPass = "";
    },

    // method for change pass with prev pass
    submitWithPrevPass() {
      let payload = {
        currentPass: this.currentPass,
        newPass: this.newPass,
        token: this.token,
      };

      // axios for change pass with current pass
      this.$store
        .dispatch("changePassWithCurrentDispatch", payload)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.stepActive = 1;

            this.activeHideDivs();
            this.newPass = "";
            this.currentPass = "";
          } else {
            this.$store.dispatch("errorAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    // method for change pas with verify code
    submitWithVerifyCode() {
      let payload = {
        verify_code: this.finalVerifyCode,
        newPass: this.newPass,
        token: this.token,
        phone_number: this.userInfo.phone_number,
      };

      // axios for change pass with current pass
      this.$store
        .dispatch("changePassWithVerifyCodeDispatch", payload)
        .then((res) => {
          if (res.status) {
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.stepActive = 1;

            this.activeHideDivs();
            this.newPass = "";
            this.currentPass = "";
            this.finalVerifyCode = "";
            this.verificationCode = {
              firstChar: "",
              secondChar: "",
              thirdChar: "",
              fourthChar: "",
              fifthChar: "",
            };
          } else {
            this.$store.dispatch("errorAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    // step 3 methods
    // methods for request active code step one
    cancelRequestActiveCode() {
      this.stepActive = 2;
    },

    requestActiveCode() {
      // axios request for verification code
      this.$store
        .dispatch("getVerifyCodeForChangePassDispatch", { token: this.token })
        .then((res) => {
          if (res.status) {
            this.stepActive = 4;
          } else {
            this.$store.dispatch("errorAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    // enter verification code (step 4) methods
    checkFirstInput() {
      const firstInput = document.getElementById("first-char");
      const secondInput = document.getElementById("second-char");
      firstInput.value = firstInput.value.replace(/\D+/g, "");

      if (firstInput.value.length > 0) {
        this.verificationCode.firstChar = firstInput.value;

        console.log(this.verificationCode.firstChar);
        secondInput.focus();
      }
    },

    checkSecondInput() {
      const secondInput = document.getElementById("second-char");
      const thirdInput = document.getElementById("third-char");
      const firstInput = document.getElementById("first-char");

      secondInput.value = secondInput.value.replace(/\D+/g, "");

      if (secondInput.value.length > 0) {
        this.verificationCode.secondChar = secondInput.value;

        thirdInput.focus();
      } else if (secondInput.value.length == 0) {
        firstInput.focus();
      }
    },

    checkThirdInput() {
      const thirdInput = document.getElementById("third-char");
      const fourthInput = document.getElementById("fourth-char");
      const secondInput = document.getElementById("second-char");

      thirdInput.value = thirdInput.value.replace(/\D+/g, "");
      if (thirdInput.value.length > 0) {
        this.verificationCode.thirdChar = thirdInput.value;

        fourthInput.focus();
      } else if (thirdInput.value.length == 0) {
        secondInput.focus();
      }
    },

    checkFourthInput() {
      const fourthInput = document.getElementById("fourth-char");
      const fifthInput = document.getElementById("fifth-char");
      const thirdInput = document.getElementById("third-char");

      fourthInput.value = fourthInput.value.replace(/\D+/g, "");
      if (fourthInput.value.length > 0) {
        this.verificationCode.fourthChar = fourthInput.value;
        fifthInput.focus();
      } else if (fourthInput.value.length == 0) {
        thirdInput.focus();
      }
    },

    checkFifthInput() {
      const fifthInput = document.getElementById("fifth-char");
      const fourthInput = document.getElementById("fourth-char");

      fifthInput.value = fifthInput.value.replace(/\D+/g, "");

      this.verificationCode.fifthChar = fifthInput.value;

      if (fifthInput.value.length == 0) {
        fourthInput.focus();
      }
    },

    cancelActiveCode() {
      this.stepActive = 2;
      this.newPass = "";
      this.currentPass = "";
      this.finalVerifyCode = "";
      this.verificationCode = {
        firstChar: "",
        secondChar: "",
        thirdChar: "",
        fourthChar: "",
        fifthChar: "",
      };
    },

    activeCodeForNewPass() {
      // send code for verification
      this.finalVerifyCode = `${this.verificationCode.firstChar}${this.verificationCode.secondChar}${this.verificationCode.thirdChar}${this.verificationCode.fourthChar}${this.verificationCode.fifthChar}`;
      this.stepActive = 5;
      this.newPass = "";

      console.log(this.finalVerifyCode);
    },

    // methods fr submit new pass (last step , step 5)
    cancelNewPassword() {
      this.stepActive = 4;
      //   this.activeHideDivs();
    },

    // method for active hide divs
    activeHideDivs() {
      const personalInfo = document.getElementById("personal-info");
      const selectedAddressInfo = document.getElementById(
        "selected-address-info"
      );
      const bankAccountInfo = document.getElementById("bank-account-info");
      // const securityInfo = document.getElementById('security-info');

      personalInfo.style.display = "none";
      selectedAddressInfo.style.display = "none";
      bankAccountInfo.style.display = "none";
    },
    inActiveHideDivs() {
      const personalInfo = document.getElementById("personal-info");
      const selectedAddressInfo = document.getElementById(
        "selected-address-info"
      );
      const bankAccountInfo = document.getElementById("bank-account-info");
      // const securityInfo = document.getElementById('security-info');

      personalInfo.style.display = "block";
      selectedAddressInfo.style.display = "block";
      bankAccountInfo.style.display = "block";
    },
  },

  computed: {
    firstChar() {
      return this.verificationCode.firstChar;
    },

    secondChar() {
      return this.verificationCode.secondChar;
    },

    thirdChar() {
      return this.verificationCode.thirdChar;
    },

    fourthChar() {
      return this.verificationCode.fourthChar;
    },

    fifthChar() {
      return this.verificationCode.fifthChar;
    },

    //  method for get token
    token() {
      return this.$store.getters.getToken;
    },

    // method for get user info
    //  method for get user info
    userInfo() {
      if (this.$store.getters.getUserInfo != null) {
        return this.$store.getters.getUserInfo;
      } else {
        return {
          full_name: "",
          gender: 1,
          birth_date: "",
          national_code: "",
          phone_number: "",
          email: "",
        };
      }
    },
  },

  mounted() {
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
  },
};
</script>

<style src="../../assets/css/userAccount/passwordInfo.css" scoped></style>
