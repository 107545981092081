<template>
  <div class="app-cart">
    <app-header id="app-header-mobile"></app-header>

    <div class="app-cart-details">
      <div class="w-100">
        <!--  delivery information in Web  -->
        <div class="delivery-information">
          <div class="cart-route">
            <div class="cart-img-div">
              <div class="cart-img">
                <img
                  class="img-fluid"
                  src="../../assets/images/pictures/cart-img-icon.svg"
                  alt=""
                />
                <p>سبد خرید</p>
              </div>

              <div class="cart-to-delivery-route-line"></div>

              <div class="delivery-img">
                <img
                  src="../../assets/images/pictures/active-delivery-information-icon.svg"
                  alt=""
                />
                <p>اطلاعات ارسال</p>
              </div>

              <div class="delivery-to-paymentInfo-route-line"></div>

              <div class="payment-information">
                <img
                  src="../../assets/images/pictures/payment-information-icon2.svg"
                  alt=""
                />
                <p>اطلاعات پرداخت</p>
              </div>
            </div>
          </div>

          <div
            :ref="'cartErrors' + index"
            v-for="(error, index) in cartDeliveryErrors"
            v-bind:key="index"
            class="cart-delivery-info-errors"
          >
            <span>{{ error }}</span>
          </div>

          <div class="selected-address-title">
            <h4>انتخاب ادرس تحویل سفارش</h4>

            <div @click.prevent="addNewAddress" class="add-new-address">
              <img
                src="../../assets/images/icons/add-new-address-icon.svg"
                alt=""
              />
              <p>افزودن آدرس جدید</p>
            </div>
          </div>

          <!-- add new address section -->

          <div v-show="showAddNewAddress" class="cart-add-new-address-box">
            <div class="cart-add-new-address-title">
              <label for="add-title">عنوان</label>
              <input v-model="newAddress.title" type="text" id="add-title" />
            </div>

            <div class="cart-add-new-address-postalCode">
              <label for="add-postalCode">کد پستی</label>
              <input
                @input="newAddressError.post_code = []"
                :class="{
                  'error-border': newAddressError.post_code.length > 0,
                }"
                v-model="newAddress.post_code"
                type="text"
                id="add-postalCode"
              />
              <span
                v-if="newAddressError.post_code.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.post_code"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="cart-add-new-address-constTelephone">
              <label for="add-constTelephone">شماره تلفن ثابت</label>
              <input
                @input="newAddressError.phone_number = []"
                :class="{
                  'error-border': newAddressError.phone_number.length > 0,
                }"
                v-model="newAddress.phone_number"
                type="number"
                id="add-constTelephone"
              />
              <span
                v-if="newAddressError.phone_number.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.phone_number"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="cart-add-new-address-mobileNumber">
              <label for="add-constTelephone">شماره موبایل</label>
              <input
                @input="newAddressError.mobile_number = []"
                :class="{
                  'error-border': newAddressError.mobile_number.length > 0,
                }"
                v-model="newAddress.mobile_number"
                type="number"
                id="add-constTelephone"
              />
              <span
                v-if="newAddressError.mobile_number.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.mobile_number"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="cart-add-new-address-state">
              <label for="add-state">استان</label>

              <div
                :class="[
                  'cart-add-new-address-state-div',
                  { 'error-border': newAddressError.province_id.length > 0 },
                ]"
              >
                <!-- @click.prevent="showProvinces" -->
                <select
                  @input="newAddressError.province_id = []"
                  v-model="newAddress.province_id"
                  name="add-state"
                  id="add-state"
                >
                  <option
                    v-if="provinces.length > 0"
                    v-for="(state, pi) in provinces"
                    :key="pi"
                    :value="state.id"
                  >
                    {{ state.province }}
                  </option>
                  <!-- <option v-else>خطا در شبکه</option> -->
                </select>
              </div>
              <span
                v-if="newAddressError.province_id.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.province_id"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div style="margin-left: 0" class="cart-add-new-address-state">
              <label for="add-state">شهر</label>

              <div
                :class="[
                  'cart-add-new-address-state-div',
                  { 'error-border': newAddressError.city_id.length > 0 },
                ]"
              >
                <select
                  @input="newAddressError.city_id = []"
                  @click.prevent="showCities"
                  v-model="newAddress.city_id"
                  name="add-city"
                  id="add-state"
                >
                  <option
                    v-if="cities.length > 0"
                    v-for="(city, ci) in cities"
                    :key="ci"
                    :value="city.id"
                  >
                    {{ city.province }}
                  </option>
                  <option v-else>خطا در شبکه</option>
                </select>
              </div>

              <span
                v-if="newAddressError.city_id.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.city_id"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="cart-add-new-address-address">
              <label for="add-address">جزئیات آدرس</label>
              <textarea
                @input="newAddressError.address_details = []"
                :class="{
                  'error-border': newAddressError.address_details.length > 0,
                }"
                id="add-address"
                v-model="newAddress.address_details"
                name="add-detail-address"
              ></textarea>

              <span
                v-if="newAddressError.address_details.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.address_details"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="cart-add-new-address-buttons">
              <button
                @click="hideAddNewAddress"
                class="add-new-address-canceled"
              >
                لغو
              </button>
              <button
                @click.prevent="submitNewAddress"
                class="add-new-address-submitted"
              >
                ثبت آدرس
              </button>
            </div>
          </div>

          <!-- all addresses section -->
          <div class="my-addresses">
            <div
              v-for="(singleAddress, index) in addresses"
              v-bind:key="index"
              :class="[
                'singleAddress-and-editAddress',
                { singleAddressMarginRight: index % 2 == 1 },
                { singleAddressMarginTop: addresses.length > 2 },
              ]"
            >
              <label
                v-if="!editSelectedAddress[index]"
                :for="'radioAddress' + index"
                :class="[
                  'single-address',
                  { singleAddress: checkedAddress == singleAddress.id },
                  { deActiveHover: hideSelectedAddress[index] },
                ]"
              >
                <div
                  v-show="hideSelectedAddress[index]"
                  class="hide-single-address"
                ></div>

                <input
                  :disabled="hideSelectedAddress[index] == true"
                  style="display: none"
                  :value="singleAddress.id"
                  v-model="checkedAddress"
                  type="radio"
                  :name="'radioAddress' + index"
                  :id="'radioAddress' + index"
                />

                <div class="single-address-top">
                  <div class="single-address-top-right">
                    <span
                      :class="[
                        {
                          selectedAddressChecked:
                            checkedAddress == singleAddress.id,
                        },
                        'selected-address-input',
                      ]"
                    ></span>
                    <span>{{ singleAddress.title }}</span>
                  </div>

                  <div class="single-address-edit-delete">
                    <img
                      class="edit-img-noneActive"
                      v-if="checkedAddress != singleAddress.id"
                      src="../../assets/images/icons/selected-address-for-cart-edit.svg"
                      alt=""
                    />

                    <img
                      class="edit-img-active"
                      @click="editCartSelectedAddress(index, singleAddress)"
                      v-else
                      src="../../assets/images/icons/active-edit-selected-address-icon.svg"
                      alt=""
                    />

                    <img
                      class="delete-img-noneActive"
                      v-if="checkedAddress != singleAddress.id"
                      src="../../assets/images/icons/selected-address-for-cart-delete.svg"
                      alt=""
                    />

                    <img
                      class="delete-img-active"
                      @click="deleteCartSelectedAddress(singleAddress)"
                      v-else
                      src="../../assets/images/icons/active-selected-address-delete.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div class="address-content">
                  <ul class="address-content-ul">
                    <li class="address-content-li">
                      {{ singleAddress.province }}
                    </li>
                    <li class="address-content-li">
                      {{ singleAddress.city.province }}
                    </li>
                    <li>
                      {{
                        checkedAddress == singleAddress.id
                          ? singleAddress.address_details
                          : singleAddress.address_details.slice(0, 70) + "..."
                      }}
                    </li>
                  </ul>
                </div>

                <hr class="address-content-hr" />

                <div class="single-address-bottom">
                  <div class="single-address-bottom-title">
                    <div class="postal-code">
                      <img
                        src="../../assets/images/icons/postal-code-cart-icon.svg"
                        alt=""
                      />
                      <span>کد پستی</span>
                    </div>

                    <div class="const-telephone">
                      <img
                        src="../../assets/images/icons/const-telephon-icon.svg"
                        alt=""
                      />
                      <span>شماره موبایل</span>
                    </div>
                  </div>

                  <div class="single-address-bottom-content">
                    <p>{{ singleAddress.post_code }}</p>
                    <p>{{ singleAddress.mobile_number }}</p>
                  </div>
                </div>
              </label>

              <div v-else class="edit-selected-address">
                <div class="edit-selected-address-title-state">
                  <div
                    style="margin-left: 2%"
                    class="edit-selected-address-title"
                  >
                    <label for="title"> عنوان </label>
                    <input
                      v-model="editSelectedAddressInputValue.title"
                      type="text"
                      id="title"
                    />
                  </div>

                  <div class="edit-selected-address-postalCode">
                    <label for="postalCode">کد پستی</label>
                    <input
                      @input="updateAddressError.post_code = []"
                      :class="{
                        'error-border': updateAddressError.post_code.length > 0,
                      }"
                      v-model="editSelectedAddressInputValue.post_code"
                      type="text"
                      id="postalCode"
                    />

                    <span
                      v-if="updateAddressError.post_code.length > 0"
                      class="error-text"
                      v-for="(err, index) in updateAddressError.post_code"
                      :key="index"
                      >{{ err }}</span
                    >
                  </div>
                </div>

                <div class="edit-selected-address-postalCode-city">
                  <div
                    style="margin-left: 2%"
                    class="edit-selected-address-state"
                  >
                    <label for="state"> استان </label>

                    <div
                      :class="[
                        'edit-selected-address-state-div',
                        {
                          'error-border':
                            updateAddressError.province_id.length > 0,
                        },
                      ]"
                    >
                      <select
                        @input="updateAddressError.province_id = []"
                        v-model="editSelectedAddressInputValue.province_id"
                        class="edit-selected-address-state-select"
                        name="state"
                        id="state"
                      >
                        <option
                          v-if="provinces.length > 0"
                          v-for="(state, pi) in provinces"
                          :key="pi"
                          :selected="
                            editSelectedAddressInputValue.province_id ==
                            state.id
                          "
                          :value="state.id"
                        >
                          {{ state.province }}
                        </option>
                      </select>

                      <span
                        v-if="updateAddressError.province_id.length > 0"
                        class="error-text"
                        v-for="(err, index) in updateAddressError.province_id"
                        :key="index"
                      >
                        {{ err }}
                      </span>
                    </div>
                  </div>

                  <div class="edit-selected-address-state">
                    <label for="city"> شهر </label>

                    <div
                      :class="[
                        'edit-selected-address-state-div',
                        {
                          'error-border': updateAddressError.city_id.length > 0,
                        },
                      ]"
                    >
                      <select
                        @input="updateAddressError.city_id = []"
                        @click.prevent="showEditCities"
                        v-model="editSelectedAddressInputValue.city_id"
                        value=""
                        class="edit-selected-address-state-select"
                        name="state"
                        id="city"
                      >
                        <option
                          v-if="cities.length > 0"
                          v-for="(city, ci) in cities"
                          :key="ci"
                          :selected="
                            editSelectedAddressInputValue.city_id == city.id
                          "
                          :value="city.id"
                        >
                          {{ city.province }}
                        </option>
                        <option v-else>خطا در شبکه</option>
                      </select>

                      <span
                        v-if="updateAddressError.city_id.length > 0"
                        class="error-text"
                        v-for="(err, index) in updateAddressError.city_id"
                        :key="index"
                        >{{ err }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="edit-selected-address-constTelephone-address">
                  <div
                    style="margin-left: 2%"
                    class="edit-selected-address-constTelephone"
                  >
                    <label for="constTelephone"> شماره تلفن ثابت</label>
                    <input
                      @input="updateAddressError.phone_number = []"
                      :class="{
                        'error-border':
                          updateAddressError.phone_number.length > 0,
                      }"
                      v-model="editSelectedAddressInputValue.phone_number"
                      type="text"
                      id="constTelephone"
                    />

                    <span
                      v-if="updateAddressError.phone_number.length > 0"
                      class="error-text"
                      v-for="(err, index) in updateAddressError.phone_number"
                      :key="index"
                      >{{ err }}</span
                    >
                  </div>

                  <div class="edit-selected-address-constTelephone">
                    <label for="constTelephone">شماره موبایل</label>
                    <input
                      @input="updateAddressError.mobile_number = []"
                      :class="{
                        'error-border':
                          updateAddressError.mobile_number.length > 0,
                      }"
                      v-model="editSelectedAddressInputValue.mobile_number"
                      type="text"
                      id="constTelephone"
                    />

                    <span
                      v-if="updateAddressError.mobile_number.length > 0"
                      class="error-text"
                      v-for="(err, index) in updateAddressError.mobile_number"
                      :key="index"
                    >
                      {{ err }}
                    </span>
                  </div>
                </div>

                <div class="edit-selected-address-constTelephone-address">
                  <div class="edit-selected-address-address">
                    <label for="address">جزئیات آدرس</label>

                    <textarea
                      @input="updateAddressError.address_details = []"
                      :class="{
                        'error-border':
                          updateAddressError.address_details.length > 0,
                      }"
                      v-model="editSelectedAddressInputValue.address_details"
                      name=""
                      id="address"
                    ></textarea>

                    <span
                      v-if="updateAddressError.address_details.length > 0"
                      class="error-text"
                      v-for="(err, index) in updateAddressError.address_details"
                      :key="index"
                      >{{ err }}</span
                    >
                  </div>
                </div>

                <div class="edit-selected-address-buttons">
                  <button
                    @click="cancelEditSelectedAddress(index)"
                    class="cancel-edit-selected-address"
                  >
                    لغو
                  </button>
                  <button
                    @click.prevent="submitUpdateAddress(singleAddress, index)"
                    class="submit-edit-selected-address"
                  >
                    ثبت ویرایش
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- delivery type section -->
          <div v-show="checkedAddress != null" class="selected-delivery-type">
            <div class="selected-delivery-title">
              <h4>انتخاب نحوه ارسال برای</h4>
              <span>( آدرس انتخاب شده)</span>
            </div>

            <div class="selected-delivery-content-parent">
              <div
                v-for="(delivery, index) in storeDeliveryTypes"
                :key="index"
                @click="selectDeliveryType(delivery, index)"
                :class="[
                  'selected-delivery-content',
                  {
                    activeSelectedDeliveryContent:
                      selectedDeliveryType == delivery.storeDeliveryType_id,
                  },
                ]"
              >
                <div class="selected-delivery-content-top">
                  <div class="selected-delivery-content-top-right">
                    <label
                      @click="selectDeliveryType(delivery, index)"
                      :class="[
                        'delivery-type-checkBox',
                        {
                          selectedDeliveryTypeCheckBox:
                            selectedDeliveryType ==
                            delivery.storeDeliveryType_id,
                        },
                      ]"
                      :for="'delivery-type' + index"
                    >
                      <input
                        v-model="selectedDeliveryType"
                        :value="delivery.storeDeliveryType_id"
                        :ref="'deliveryType' + index"
                        type="radio"
                        style="display: none"
                        :id="'delivery-type' + index"
                      />
                    </label>

                    <!-- <img v-if="selectedDeliveryType != delivery.storeDeliveryType_id"
                                            :src="iconComputed(delivery, false)" alt="">
                                        
                                        <img v-if="selectedDeliveryType == delivery.storeDeliveryType_id"
                                            :src="iconComputed(delivery, true)" alt=""> -->

                    <!-- bike -->
                    <img
                      v-show="delivery.deliveryType_title == 'پیک موتور'"
                      v-if="
                        selectedDeliveryType == delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/bikeSelected.svg"
                    />
                    <img
                      v-show="delivery.deliveryType_title == 'پیک موتور'"
                      v-if="
                        selectedDeliveryType != delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/bikeDefault.svg"
                    />

                    <!-- truck -->
                    <img
                      v-show="
                        delivery.deliveryType_title == 'کامیونت' ||
                        delivery.deliveryType_title == 'کامیون'
                      "
                      v-if="
                        selectedDeliveryType == delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/truckSelected.svg"
                    />
                    <img
                      v-show="
                        delivery.deliveryType_title == 'کامیونت' ||
                        delivery.deliveryType_title == 'کامیون'
                      "
                      v-if="
                        selectedDeliveryType != delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/truckDefault.svg"
                    />

                    <!-- pickUp -->
                    <img
                      v-show="delivery.deliveryType_title == 'وانت'"
                      v-if="
                        selectedDeliveryType == delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/pickupSelected.svg"
                    />
                    <img
                      v-show="delivery.deliveryType_title == 'وانت'"
                      v-if="
                        selectedDeliveryType != delivery.storeDeliveryType_id
                      "
                      src="../../assets/images/icons/pickupDefault.svg"
                    />

                    <p class="normal-post-desc">
                      {{ delivery.deliveryType_title }}
                    </p>
                  </div>

                  <p class="selected-delivery-content-top-left">
                    هزینه ارسال : {{ numberFormat(delivery.price) }}
                  </p>
                </div>

                <div class="selected-delivery-content-bottom">
                  <!-- TODO description -->
                  <span>تا 6 روز کاری - متغییر بدلیل ترافیک کاری فروشگاه</span>
                </div>
              </div>
            </div>
          </div>

          <div class="description-and-confirmation-btn">
            <span
              >برای حفظ محیط زیست از نسخه الکترونیکی فاکتور در پروفایل خود به
              جای چاپ کاغذی می توانید استفاده کنید</span
            >

            <button @click="cartDeliverySubmitted" class="confirmationBtn">
              تایید اطلاعات و ادامه
            </button>
          </div>
        </div>

        <!--  delivery information in Mobile section  -->

        <div class="delivery-information-mobile">
          <!-- cart route mobile -->
          <div class="cart-route-mobile">
            <div class="cart-route-mobile-content">
              <div class="cart-route-mobile-img">
                <img
                  src="../../assets/images/icons/image-route-cart-mobile.svg"
                  alt=""
                />
              </div>
              <p>سبد خرید</p>
            </div>

            <div class="route-mobile-line1"></div>

            <div class="cart-route-mobile-delivery-information">
              <div class="deliveryInformation-route-mobile-img">
                <img
                  src="../../assets/images/icons/cart-delivery-information-mobile-img.svg"
                  alt=""
                />
              </div>

              <p>اطلاعات ارسال</p>
            </div>

            <div class="route-mobile-line2"></div>

            <div class="cart-route-mobile-payment">
              <div class="payment-route-mobile-img">
                <!-- payment icon mobile -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.837"
                  height="28.604"
                  viewBox="0 0 31.837 28.604"
                >
                  <g
                    id="payment-route-mobile-img"
                    transform="translate(-36.229 -124.983)"
                  >
                    <path
                      id="Stroke_1"
                      data-name="Stroke 1"
                      d="M10.685,8.255H4.267A4.2,4.2,0,0,1,0,4.128,4.2,4.2,0,0,1,4.267,0h6.417"
                      transform="translate(56.631 134.956)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_3"
                      data-name="Stroke 3"
                      d="M.794.456H.3"
                      transform="translate(60.83 138.533)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_5"
                      data-name="Stroke 5"
                      d="M8.318,0h13.7a8.187,8.187,0,0,1,8.318,8.048V19.056A8.187,8.187,0,0,1,22.019,27.1H8.318A8.187,8.187,0,0,1,0,19.056V8.048A8.187,8.187,0,0,1,8.318,0Z"
                      transform="translate(36.979 125.733)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_7"
                      data-name="Stroke 7"
                      d="M0,.456H8.558"
                      transform="translate(44.168 132.238)"
                      fill="#4990e2"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
              </div>

              <p>پرداخت</p>
            </div>
          </div>

          <!-- Select & Add & Remove My Address -->

          <div class="my-address-mobile">
            <div class="my-address-mobile-title">
              <p>آدرس</p>

              <div
                @click.prevent="goToAddresses"
                class="add-new-address-mobile"
              >
                <img
                  src="../../assets/images/icons/add-new-address-mobile.svg"
                  alt=""
                />
                <p>افزودن آدرس</p>
              </div>
            </div>

            <div class="my-address-mobile-select">
              <label
                :for="'myAddress' + i"
                v-for="(addressMobile, i) in addresses"
                :key="i"
                :class="[
                  'my-address-mobile-select-box',
                  { marginRightMobile: i > 0 },
                  { marginLeftMobile: i == addresses.length - 1 },
                  {
                    myAddressMobileBorderActive:
                      checkedAddress == addressMobile.id,
                  },
                ]"
              >
                <input
                  style="display: none"
                  type="radio"
                  :value="addressMobile.id"
                  v-model="checkedAddress"
                  :id="'myAddress' + i"
                />

                <div class="my-address-mobile-select-box-title">
                  <div class="my-address-mobile-select-box-title-right">
                    <div
                      :class="[
                        'checkBox-my-address-mobile',
                        {
                          checkBoxMyAddressMobile:
                            checkedAddress == addressMobile.id,
                        },
                      ]"
                    ></div>
                    <p>{{ addressMobile.title }}</p>
                  </div>

                  <div class="my-address-mobile-edit-delete">
                    <img
                      v-if="checkedAddress != addressMobile.id"
                      src="../../assets/images/icons/product-edit-mobile-icon-inactive.svg"
                      alt=""
                    />
                    <img
                      v-else
                      @click.prevent="goToAddresses"
                      src="../../assets/images/icons/product-edit-mobile-icon.svg"
                      alt=""
                    />
                    <img
                      v-if="checkedAddress != addressMobile.id"
                      src="../../assets/images/icons/product-delete-mobile-icon-inactive.svg"
                      alt=""
                    />
                    <img
                      v-else
                      @click="deleteCartSelectedAddress(addressMobile)"
                      src="../../assets/images/icons/product-delete-mobile-icon.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div class="my-address-mobile-select-box-content">
                  <div class="my-address-mobile-select-box-content-right">
                    <p>کد پستی</p>
                    <p>شماره موبایل</p>
                    <p>آدرس</p>
                  </div>
                  <div class="my-address-mobile-select-box-line"></div>

                  <div class="my-address-mobile-select-box-content-left">
                    <p>{{ addressMobile.post_code }}</p>
                    <p>{{ addressMobile.mobile_number }}</p>

                    <p>
                      {{
                        addressMobile.province +
                        "-" +
                        addressMobile.city.province +
                        "-" +
                        (checkedAddress == addressMobile.id
                          ? addressMobile.address_details
                          : addressMobile.address_details.slice(0, 30) + "...")
                      }}
                    </p>
                  </div>
                </div>
              </label>
            </div>
          </div>

          <!-- cart sending method -->
          <div
            v-show="checkedAddress != null"
            class="cart-sending-method-mobile"
          >
            <div class="cart-sending-method-mobile-title">
              <p>شیوه ارسال</p>
            </div>

            <div class="cart-sending-method-mobile-content">
              <label
                :for="'delivery-type' + index"
                v-for="(delivery, index) in storeDeliveryTypes"
                :key="index"
                @click="selectDeliveryType(delivery, index)"
                :class="[
                  'cart-sending-method-mobile-content-box',
                  {
                    cartSendingMethodContentBoxActive:
                      selectedDeliveryType == delivery.storeDeliveryType_id,
                  },
                ]"
              >
                <input
                  v-model="selectedDeliveryType"
                  :value="delivery.storeDeliveryType_id"
                  :ref="'deliveryType' + index"
                  type="radio"
                  style="display: none"
                  :id="'delivery-type' + index"
                />

                <div
                  :class="[
                    'checkBox-my-address-mobile',
                    {
                      checkBoxMyAddressMobile:
                        selectedDeliveryType == delivery.storeDeliveryType_id,
                    },
                  ]"
                ></div>

                <!-- bike -->
                <img
                  v-show="delivery.deliveryType_title == 'پیک موتور'"
                  v-if="selectedDeliveryType == delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/bikeSelected.svg"
                />
                <img
                  v-show="delivery.deliveryType_title == 'پیک موتور'"
                  v-if="selectedDeliveryType != delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/bikeDefault.svg"
                />

                <!-- truck -->
                <img
                  v-show="
                    delivery.deliveryType_title == 'کامیونت' ||
                    delivery.deliveryType_title == 'کامیون'
                  "
                  v-if="selectedDeliveryType == delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/truckSelected.svg"
                />
                <img
                  v-show="
                    delivery.deliveryType_title == 'کامیونت' ||
                    delivery.deliveryType_title == 'کامیون'
                  "
                  v-if="selectedDeliveryType != delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/truckDefault.svg"
                />

                <!-- pickUp -->
                <img
                  v-show="delivery.deliveryType_title == 'وانت'"
                  v-if="selectedDeliveryType == delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/pickupSelected.svg"
                />
                <img
                  v-show="delivery.deliveryType_title == 'وانت'"
                  v-if="selectedDeliveryType != delivery.storeDeliveryType_id"
                  src="../../assets/images/icons/pickupDefault.svg"
                />

                <div class="cart-sending-method-mobile-description">
                  <p>{{ delivery.deliveryType_title }}</p>
                  <span>تا 6 روز کاری - متغییر بدلیل ترافیک کاری فروشگاه</span>

                  <span class="d-flex justify-content-start align-items-center">
                    هزینه ارسال :
                    <p>{{ numberFormat(delivery.price) }}</p>
                  </span>
                </div>
              </label>
            </div>
          </div>

          <!-- delivery information mobile description -->

          <div class="cart-product-description">
            <p>
              برای
              <span>حفظ محیط زیست</span>
              از نسخه الکترونیکی فاکتور در پروفایل خود به جای چاپ کاغذی می
              توانید استفاده کنید
            </p>
          </div>

          <!-- marketing mobile -->

          <div class="cart-marketing-mobile">
            <div class="guaranteed-delivery-of-goods">
              <img
                src="../../assets/images/icons/mobile-marketing-saddarsad.svg"
                alt=""
              />
              <div class="guaranteed-delivery-of-goods-title">
                <p>تضمین</p>
                <p>تحویل کالا</p>
              </div>
            </div>

            <div class="professional-consultation">
              <img
                src="../../assets/images/icons/mobile-marketing-moshavere.svg"
                alt=""
              />
              <div class="professional-consultation-title">
                <p>مشاوره</p>
                <p>تخصصی</p>
              </div>
            </div>

            <div class="delivery-express">
              <img
                src="../../assets/images/icons/mobile-marketing-express.svg"
                alt=""
              />
              <div class="delivery-express-title">
                <p>تحویل</p>
                <p>اکسپرس</p>
              </div>
            </div>
          </div>

          <div class="delivery-information-submit-and-continuation">
            <!--  -->
            <button
              :disabled="selectedDeliveryType == null && checkedAddress == null"
              @click="deliverySubmitAndContinueMobile"
              :class="[
                'submit-and-continuation',
                {
                  activeSubmitAndContinuation:
                    selectedDeliveryType != null && checkedAddress != null,
                },
              ]"
            >
              ثبت و ادامه
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { numberFormat } from "../../assets/helper/helper";

export default {
  name: "DeliveryInformation",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      editSelectedAddress: [],
      hideSelectedAddress: [],
      cartDeliveryErrors: [],

      selectedDeliveryType: null,

      showAddNewAddress: false,

      checkedAddress: null,

      newAddress: {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      },

      newAddressError: {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      },

      updateAddressError: {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      },

      editSelectedAddressInputValue: {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      },

      allAddresses: [
        {
          addressTitle: "آدرس من",
          addressContent: [
            "تهران",
            "تهران",
            "خیابان ولیعصر. کوچه عبده،پلاک 12",
          ],
          postalCode: "2564859756",
          constTelephone: "02125648596",
        },

        {
          addressTitle: "آدرس من",
          addressContent: [
            "تهران",
            "تهران",
            "خیابان ولیعصر. کوچه عبده،پلاک 12",
          ],
          postalCode: "2564859756",
          constTelephone: "02125648596",
        },

        {
          addressTitle: "آدرس من",
          addressContent: [
            "تهران",
            "تهران",
            "خیابان ولیعصر. کوچه عبده،پلاک 12",
          ],
          postalCode: "2564859756",
          constTelephone: "02125648596",
        },

        {
          addressTitle: "آدرس من",
          addressContent: [
            "تهران",
            "تهران",
            "خیابان ولیعصر. کوچه عبده،پلاک 12",
          ],
          postalCode: "2564859756",
          constTelephone: "02125648596",
        },
      ],
    };
  },

  computed: {
    //  method for get token
    token() {
      if (this.$store.getters.getToken != "") {
        return this.$store.getters.getToken;
      }
    },

    //  method for get user addresses
    addresses() {
      if (this.$store.getters.getUserAddresses != null) {
        this.$store.getters.getUserAddresses.map((item, index) => {
          this.editSelectedAddress.push(false);
          this.hideSelectedAddress.push(false);
        });

        return this.$store.getters.getUserAddresses;
      }
    },

    //  method for get provinces
    provinces() {
      if (this.$store.getters.getProvinces != null) {
        return this.$store.getters.getProvinces;
      }
    },
    //  method for get cities
    cities() {
      if (this.$store.getters.getCities != null) {
        return this.$store.getters.getCities.cities;
      }
    },

    //  method for get storeDeliveryTypes
    storeDeliveryTypes() {
      if (this.$store.getters.getStoreDeliveryTypes != null) {
        console.log(this.$store.getters.getStoreDeliveryTypes);
        return this.$store.getters.getStoreDeliveryTypes;
      }
    },
  },

  methods: {
    numberFormat,
    // method for go to address page
    goToAddresses() {
      let resolved = this.$router.resolve({
        name: "addresses",
      });

      location.href = resolved.href;
    },

    /* delete selected Address methods */
    deleteCartSelectedAddress(item) {
      this.$store
        .dispatch("deleteAddressDispatch", {
          token: this.token,
          address_id: item.id,
        })
        .then((res) => {
          if (res.status) {
            //  action for get all addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });
            //  action for show success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });
          } else {
            //  action for show warning alert
            this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    //  edit selected address
    editCartSelectedAddress(index, item) {
      //  get all cities
      this.$store.dispatch("citiesDispatch", {
        province_id: item.city.parent_id,
      });

      this.editSelectedAddress[index] = true;

      for (let i = 0; i < this.hideSelectedAddress.length; i++) {
        if (index != i) {
          this.hideSelectedAddress[i] = true;
        }
      }

      this.$store
        .dispatch("editAddressDispatch", {
          token: this.token,
          address_id: item.id,
        })
        .then((res) => {
          if (res.status) {
            this.editSelectedAddressInputValue = {
              title: res.data.title,
              city_id: res.data.city.id,
              province_id: res.data.province_id,
              post_code: res.data.post_code,
              phone_number: res.data.phone_number,
              mobile_number: res.data.mobile_number,
              address_details: res.data.address_details,
              default: 0,
            };
          }
          console.log(this.editSelectedAddressInputValue);
        });

      this.$forceUpdate();
    },

    //  cancle edit selected address
    cancelEditSelectedAddress(index) {
      this.editSelectedAddress[index] = false;

      for (let i = 0; i < this.hideSelectedAddress.length; i++) {
        if (index != i) {
          console.log(i);
          this.hideSelectedAddress[i] = false;
        }
      }
      this.$forceUpdate();
    },

    //  method for update address
    submitUpdateAddress(item, index) {
      //  add token
      this.editSelectedAddressInputValue.token = this.token;

      //  address_id
      this.editSelectedAddressInputValue.address_id = item.id;

      // update address dispatch
      this.$store
        .dispatch("updateAddressDispatch", this.editSelectedAddressInputValue)
        .then((res) => {
          if (res.status) {
            // refresh addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            //  cancle edit mode
            this.editSelectedAddress[index] = false;

            for (let i = 0; i < this.hideSelectedAddress.length; i++) {
              if (index != i) {
                this.hideSelectedAddress[i] = false;
              }
            }
            this.$forceUpdate();

            //  empty update state
            this.editSelectedAddressInputValue = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.province_id =
                    res.message.province_id)
                : this.updateAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.address_details =
                    res.message.address_details)
                : this.updateAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.mobile_number =
                    res.message.mobile_number)
                : this.updateAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.phone_number =
                    res.message.phone_number)
                : this.updateAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.post_code = res.message.post_code)
                : this.updateAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.city_id = res.message.city_id)
                : this.updateAddressError.city_id.push(res.message);
            }
          }
        });
    },

    /* selected delivery type methods */

    selectDeliveryType(delivery, index) {
      this.selectedDeliveryType = delivery.storeDeliveryType_id;

      //  dispatch for change delivery address of cart
      this.$store.dispatch("changeCartDispatch", {
        token: this.token,
        deliveryAddress_id: this.checkedAddress,
        storeDeliveryType_id: delivery.storeDeliveryType_id,
        factureAddress_id: "",
      });
    },

    addNewAddress() {
      this.showAddNewAddress = true;

      for (let i = 0; i < this.hideSelectedAddress.length; i++) {
        this.hideSelectedAddress[i] = true;
      }
    },

    //  method for hide add new address section
    hideAddNewAddress() {
      this.showAddNewAddress = false;

      for (let i = 0; i < this.hideSelectedAddress.length; i++) {
        this.hideSelectedAddress[i] = false;
      }

      this.newAddress = {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      };

      this.newAddressError = {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      };
    },

    //  method for submit new address
    submitNewAddress() {
      //  add token
      this.newAddress.token = this.token;
      //  dispatch for new address
      this.$store
        .dispatch("newAddressDispatch", this.newAddress)
        .then((res) => {
          if (res.status) {
            //  action for get all addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });

            //  action for show success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            // hide form
            this.showAddNewAddress = false;

            for (let i = 0; i < this.hideSelectedAddress.length; i++) {
              this.hideSelectedAddress[i] = false;
            }

            this.newAddress = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.province_id = res.message.province_id)
                : this.newAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.address_details =
                    res.message.address_details)
                : this.newAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.mobile_number =
                    res.message.mobile_number)
                : this.newAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.phone_number = res.message.phone_number)
                : this.newAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.post_code = res.message.post_code)
                : this.newAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.city_id = res.message.city_id)
                : this.newAddressError.city_id.push(res.message);
            }
          }
        });
    },

    cartDeliverySubmitted() {
      this.cartDeliveryErrors = [];

      if (this.checkedAddress === null) {
        this.cartDeliveryErrors.push("ابتدا آدرس ارسال را انتخاب نمایید .");

        window.scroll({
          top: 50,
          left: 0,
          behavior: "smooth",
        });
      } else if (this.selectedDeliveryType === null) {
        this.cartDeliveryErrors.push("ابتدا نحوه ارسال را انتخاب نمایید .");
        window.scroll({
          top: 50,
          left: 0,
          behavior: "smooth",
        });
      } else {
        let resolved = this.$router.resolve({
          name: "paymentInformation",
        });

        location.href = resolved.href;
      }
    },

    /* delivery Submit And Continue Mobile */

    deliverySubmitAndContinueMobile() {
      if (this.selectedDeliveryType != null && this.checkedAddress != null) {
        let resolved = this.$router.resolve({
          name: "paymentInformation",
        });

        location.href = resolved.href;
      }
    },

    //  method for show provinces of select box
    showProvinces() {
      // this.$store.dispatch("provincesDispatch")
    },
    //  method for show cities of select box
    showCities() {
      this.$store.dispatch("citiesDispatch", {
        province_id: this.newAddress.province_id,
      });
    },
    showEditCities() {
      this.$store.dispatch("citiesDispatch", {
        province_id: this.editSelectedAddressInputValue.province_id,
      });
    },

    //  method for set api in ssr
    asyncData({ store, router }) {
      store.dispatch("userAddressesDispatch", { token: this.token });
    },
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  action for get all addresses
    this.$store.dispatch("userAddressesDispatch", { token: this.token });

    this.$store.dispatch("provincesDispatch");
  },

  watch: {
    checkedAddress() {
      //  dispatch for change delivery address of cart
      this.$store
        .dispatch("changeCartDispatch", {
          token: this.token,
          deliveryAddress_id: this.checkedAddress,
          storeDeliveryType_id: "",
          factureAddress_id: "",
        })
        .then((res) => {
          if (res.status) {
            //  dispatch for get all store delveryTypes
            this.$store.dispatch("storeDeliveryTypesDispatch", {
              token: this.token,
              order_id: res.data.id,
            });
          }
        });
    },
  },

  mounted() {
    /*  for (let i = 0; i < this.addresses.length; i++) {
      this.editSelectedAddress.push(false);
      this.hideSelectedAddress.push(false);
    } */
  },
};
</script>

<style src="../../assets/css/cart/deliveryInformation.css" scoped></style>
