<template>
  <div class="product-details">
    <!--  product details content for Desktop  -->
    <div id="product-details" class="product-details-content">
      <div class="product-content-top">
        <div class="product-details-top-right">
          <p>{{ getProductName != null ? getProductName.title : "" }}</p>
          <p>{{ getProductName != null ? getProductName.latin_title : "" }}</p>
        </div>

        <div class="product-details-top-left">
          <div class="product-stars">
            <font-awesome-icon :icon="fontAwesomeComputed(4)" />
            <font-awesome-icon :icon="fontAwesomeComputed(3)" />
            <font-awesome-icon :icon="fontAwesomeComputed(2)" />
            <font-awesome-icon :icon="fontAwesomeComputed(1)" />
            <font-awesome-icon :icon="fontAwesomeComputed(0)" />
          </div>

          <!-- <a href="#product-comments" id="product-comments-a">-->
          <div @click="scrollToComments" class="product-comment-btn">
            <img
              src="../../assets/images/icons/product-comment-icon.svg"
              alt=""
            />
            <span>مشاهده نظرات</span>
          </div>
          <!--</a>-->
        </div>
      </div>

      <hr class="product-content-hr" />

      <div class="product-content-details">
        <div class="product-content-details-right">
          <div class="another-img">
            <img
              v-for="(image, index) in getProductImages"
              v-show="index < 5"
              :key="index"
              @click="showPreviewProductImages"
              :src="image.media.media_link"
              alt=""
            />
          </div>

          <div @click="showPreviewProductImages" class="main-img">
            <img :src="getMainProductImage.media.media_link" alt="" />
          </div>

          <div class="like-share-preview">
            <!--                        <i id="like-product" @click="likeProduct" class="fas fa-heart"></i>-->
            <!--                    <img  src="../../assets/images/icons/like-icon.svg" alt="">-->
            <img
              @click="showPreviewProductImages"
              src="../../assets/images/icons/preview-icon.svg"
              alt=""
            />
            <img
              @click="showSocialMediaIcons"
              src="../../assets/images/icons/share-icon-2.svg"
              alt=""
            />

            <div id="all-social-media" class="all-social-media">
              <img
                src="../../assets/images/icons/product-telegram-icon.svg"
                alt=""
              />
              <img
                src="../../assets/images/icons/product-instagram-icon.svg"
                alt=""
              />
              <img
                src="../../assets/images/icons/product-whatsapp-icon.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          @click.self="closePreviewProductImages"
          id="background-modal"
          class="backgroundColorModalPreviewProductImages"
        >
          <div id="preview-product-images" class="preview-product-images">
            <div class="product-images-content">
              <img
                class="product-images-content-nextBtn"
                @click="previousImg"
                src="../../assets/images/icons/nextBtnProductPreviewImg.svg"
                alt=""
              />

              <div class="show-product-img">
                <img
                  :src="getProductImages[showProductImgIndex].media.media_link"
                  alt=""
                />
              </div>

              <img
                class="product-images-content-prevBtn"
                @click="nextImg"
                src="../../assets/images/icons/previosBtnProductPreviewImg.svg"
                alt=""
              />
            </div>

            <!-- product images detail -->
            <div class="product-images-details">
              <div
                @click="changeProductImg(index)"
                id="product-img-item"
                v-for="(productImg, index) in getProductImages"
                v-bind:key="index"
                :style="[
                  index != 0 ? { marginRight: '30px' } : { marginRight: '0px' },
                ]"
                :class="[
                  'single-img-details',
                  { borderProductImg: showProductImgIndex === index },
                  { notBorderProductImgIndex: showProductImgIndex != index },
                ]"
              >
                <img :src="productImg.media.media_link" alt="" />
              </div>

              <!--
                                                <div class="single-img-details">
                                                    <img src="../../assets/images/pictures/product-img.png" alt="">
                                                </div>


                                                <div class="single-img-details">
                                                    <img src="../../assets/images/pictures/wolf.jpg" alt="">
                                                </div>
                            -->
            </div>
          </div>
        </div>

        <div class="vertical-line-product-details"></div>

        <div ref="productCenter" class="product-content-details-center">
          <div class="product-content-details-title">
            <div class="title-country-made">
              <span>ساخت کشور :</span>
              <span>{{ getProductCountryAndFirstCategory.country }}</span>
            </div>

            <div class="title-category">
              <span>دسته بندی :</span>
              <span>{{ getProductCountryAndFirstCategory.category }}</span>
            </div>
          </div>

          <div class="product-content-details-title mt-5">
            <div class="title-country-made">
              <span>فروشنده :</span>
              <span v-if="activeSeller[0].price_type == 'owner'">{{
                getStoreName
              }}</span>
              <span v-else-if="activeSeller[0].price_type == 'seller'">{{
                activeSeller[0].seller.seller_storename
              }}</span>
            </div>
          </div>

          <!-- TODO Seller -->
          <div
            :style="[
              getProductPrices[0].key != null
                ? { opacity: '1' }
                : { opacity: '0' },
            ]"
            class="product-content-details-colors"
          >
            <span>انتخاب {{ getProductPrices[0].key }} :</span>

            <!-- TODO Check selectVariableProp -->
            <div
              v-for="(price, index) in getProductPrices"
              :key="index"
              @click="selectVariableProp(index)"
              id="red"
              :style="[
                getProductPrices[index].key == 'رنگ' ||
                getProductPrices[index].key == 'color'
                  ? { padding: '10px' }
                  : { padding: '10px 20px' },
                getProductPrices.length > 4 ? { marginBottom: '10px' } : {},
              ]"
              :class="[
                { 'red-active': price.id == selectedVariable.id },
                'red',
              ]"
            >
              <div
                v-show="
                  getProductPrices[index].key == 'رنگ' ||
                  getProductPrices[index].key == 'color'
                "
                :style="{ backgroundColor: price.alternative_value }"
                class="red-show-color"
              ></div>

              <span>{{ price.main_value }}</span>

              <input
                v-model="selectedVariable.id"
                name="color"
                :checked="selectedVariable.id == price.id"
                id="red-input"
                :value="price.id"
                type="radio"
              />
            </div>
          </div>

          <div
            :style="[
              getProductPrices[0].key != null
                ? { marginTop: '50px' }
                : { marginTop: '0px' },
            ]"
            class="main-property"
          >
            <span>ویژگی های کلیدی :</span>

            <ul id="main-property-list-ul" class="main-property-list-ul">
              <li
                v-for="(prop, pIndex) in getIsKeyProps"
                :key="pIndex"
                class="main-property-list-li"
              >
                {{ prop.key }} : {{ prop.value }}
              </li>
            </ul>

            <div
              v-show="getIsKeyProps.length > 2"
              :style="{ marginTop: '10px' }"
              v-if="!this.showMoreList"
              @click="showMoreProperty"
              class="more-property"
            >
              <img src="../../assets/images/icons/plus-more-icon.svg" alt="" />
              <span>بیشتر</span>
            </div>
            <div
              v-show="getIsKeyProps.length > 2"
              v-else
              @click="showMoreProperty"
              class="more-property"
            >
              <img src="../../assets/images/icons/fewer-less-icon.svg" alt="" />
              <span>بستن</span>
            </div>
          </div>

          <div
            :style="[
              getIsKeyProps.length > 2
                ? getPriceAndDiscountComputed.discount != 0
                  ? { marginTop: '0px' }
                  : { marginTop: '30px' }
                : { marginTop: '60px' },
            ]"
            class="add-to-card-and-price"
          >
            <button
              @click="addToCart"
              v-if="!activeSeller[0].isIn_cart"
              class="add-to-card"
            >
              <img
                src="../../assets/images/icons/product-details-card.svg"
                alt=""
              />
              <span>افزودن به سبد خرید</span>
            </button>

            <button @click="removeFromCart" v-else class="remove-from-cart">
              <img src="../../assets/images/icons/cart-delete.svg" alt="" />
              <span>حذف از سبد خرید</span>
            </button>

            <div class="price">
              <div
                v-if="activeSeller[0].seller_discount != 0"
                class="price-discount p-0"
              >
                <span>{{
                  numberFormat(getPriceAndDiscountComputed.price)
                }}</span>
                <div class="price-discount-percent p-0">
                  <span>% {{ getPriceAndDiscountComputed.discount }}</span>
                </div>
              </div>

              <div class="final-price">
                <span>{{
                  numberFormat(
                    selectedVariable.price - selectedVariable.discount
                  )
                }}</span>
                <span>تومان</span>
              </div>
            </div>
          </div>
        </div>

        <div class="vertical-line-product-details"></div>

        <div ref="productLeft" class="product-content-details-left">
          <div class="product-content-details-left-top">
            <span>معرفی محصول :</span>

            <span
              id="about-product"
              :class="['about-product', { aboutProductScroll: activeScroll }]"
            >
              {{ getProductIntroduction }}
            </span>

            <div
              @click="showMoreAboutProduct"
              v-if="!showMoreProductDescription"
              class="more-property mt-2"
            >
              <img src="../../assets/images/icons/plus-more-icon.svg" alt="" />
              <span>بیشتر</span>
            </div>

            <div
              @click="showMoreAboutProduct"
              v-else
              class="more-property mt-2"
            >
              <img src="../../assets/images/icons/fewer-less-icon.svg" alt="" />
              <span>بستن</span>
            </div>
          </div>

          <div id="left-bottom" class="product-content-details-left-bottom">
            <div class="guaranteeDeliveryProduct">
              <img
                src="../../assets/images/icons/GuaranteeDeliveryProduct.svg"
                alt=""
              />
              <span>تضمین تحویل کالا</span>
            </div>
            <div class="professional-consulting">
              <img
                src="../../assets/images/icons/profesionalConsulting.svg"
                alt=""
              />
              <span>مشاوره تخصصی</span>
            </div>
            <div class="express-delivery">
              <img src="../../assets/images/icons/expressDelivery.svg" alt="" />
              <span>تحویل اکسپرس</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- product details content for Mobile size section  -->
    <div v-show="!showAllComments" class="product-details-content-mobile">
      <!-- category of product -->
      <!-- <div class="route-category-mobile">
        <span>فروشگاه اینلای</span>
        <i class="fa fa-chevron-left"></i>

        <span>آرایشی، بهداشتی</span>
        <i class="fa fa-chevron-left"></i>

        <span>همه محصولات</span>
        <i class="fa fa-chevron-left"></i>

        <span> همه محصولات</span>
        <i class="fa fa-chevron-left"></i>
      </div> -->

      <div class="product-details-mobile-box-item">
        <div class="product-details-social-media-and-stars">
          <img
            src="../../assets/images/icons/social-media-mobile-icon.svg"
            alt=""
          />
          <div class="product-details-item-stars">
            <font-awesome-icon :icon="fontAwesomeComputed(4)" />
            <font-awesome-icon :icon="fontAwesomeComputed(3)" />
            <font-awesome-icon :icon="fontAwesomeComputed(2)" />
            <font-awesome-icon :icon="fontAwesomeComputed(1)" />
            <font-awesome-icon :icon="fontAwesomeComputed(0)" />
          </div>
        </div>

        <div class="product-details-carousel">
          <!-- vue bootstrap -->

          <b-carousel
            class="carousel slide"
            v-model="activeSlide"
            :interval="4000"
            indicators
            img-width="200"
            background="#fff"
            img-height="188"
            id="carouselExampleIndicators"
          >
            <b-carousel-slide
              v-for="(img, index) in getProductImages"
              :key="index"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100"
                  width="200"
                  height="188"
                  :src="img.media.media_link"
                  alt=""
                />
              </template>
            </b-carousel-slide>
          </b-carousel>

          <!-- pure bootstrap -->
          <!-- <div 
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <div class="carousel-inner">
              <div
                v-for="(img, index) in getProductImages"
                :key="index"
                :class="
                  index == activeSlide
                    ? 'carousel-item active'
                    : 'carousel-item'
                "
              >
                <img class="img-carousel1" :src="img.media.media_link" alt="" /> -->
          <!-- <div
                  :style="{ backgroundImage: `url(${img.media.media_link})` }"
                  :class="`img-carousel${index}`"
                >
                </div> -->
          <!-- </div>
            </div>

            <ol class="carousel-indicators">
              <li
                @click="activeSlide = i"
                v-for="(img, i) in getProductImages"
                :key="i"
                data-target="#carouselExampleIndicators"
                :data-slide-to="activeSlide"
                :class="i == activeSlide ? 'active' : ''"
              ></li>
            </ol>
          </div> -->
        </div>

        <!-- product title and latin_title mobile responsive -->
        <div class="product-details-mobile-description">
          <h4>
            {{ getProductName != null ? getProductName.title : "" }}
          </h4>
          <h4>
            {{ getProductName != null ? getProductName.latin_title : "" }}
          </h4>
        </div>

        <div class="product-details-mobile-country-category-price">
          <div class="product-details-country-price-discount">
            <div class="product-details-country">
              <p>ساخت کشور :</p>
              <p>{{ getProductCountryAndFirstCategory.country }}</p>
            </div>

            <div class="product-details-price">
              <span>{{ numberFormat(getPriceAndDiscountComputed.price) }}</span>
              <div class="product-details-discount">
                <span>% {{ getPriceAndDiscountComputed.discount }}</span>
              </div>
            </div>
          </div>

          <div class="product-details-category-mobile-final-price">
            <div class="product-details-category">
              <p>دسته بندی :</p>
              <p>{{ getProductCountryAndFirstCategory.category }}</p>
            </div>

            <div class="product-details-final-price">
              <h4>
                {{
                  numberFormat(
                    selectedVariable.price - selectedVariable.discount
                  )
                }}
              </h4>
              <span>تومان</span>
            </div>
          </div>
        </div>

        <hr
          :style="[
            getProductPrices[0].key != null
              ? { display: 'flex' }
              : { display: 'none' },
          ]"
          class="product-details-mobile-hr1"
        />

        <!-- color property section in mobile responsive -->
        <div
          :style="[
            getProductPrices[0].key != null
              ? { display: 'flex' }
              : { display: 'none' },
          ]"
          class="product-details-selected-color-mobile"
        >
          <h4>{{ getProductPrices[0].key }} :</h4>

          <div class="product-details-selected-color-mobile-content">
            <label
              @click.prevent="selectVariableProp(index)"
              :for="'colorBox' + index"
              v-for="(item, index) in getProductPrices"
              :key="index"
              :class="[
                'product-details-color-box-mobile',
                { selectedBox: selectedVariable.id == item.id },
              ]"
            >
              <div
                v-if="
                  getProductPrices[0].key == 'color' ||
                  getProductPrices[0].key == 'رنگ'
                "
                class="color-box"
                :style="{ backgroundColor: item.alternative_value }"
              ></div>

              <span>{{ item.main_value }}</span>

              <input
                style="display: none"
                v-model="selectedVariable.id"
                :checked="item.id == selectedVariable.id"
                :value="item.id"
                type="radio"
                :id="'colorBox' + index"
              />
            </label>
          </div>
        </div>

        <hr class="product-details-mobile-hr2" />

        <!-- marketing in mobile size -->
        <div class="product-details-marketing-mobile">
          <div
            v-for="(marketing, mIndex) in getStoreMarketing"
            :key="mIndex"
            class="marketing-mobile-right"
          >
            <img :src="marketing.media_link" alt="" />
            <div class="marketing-title">
              <p>{{ marketing.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- fix add to cart cuttons in mobile -->
    <div v-show="!showAllComments" class="add-to-card-and-consulting">
      <button
        v-if="!activeSeller[0].isIn_cart"
        @click="addToCart"
        class="add-to-card-btn"
      >
        افزودن به سبد خرید
      </button>
      <button @click="removeFromCart" v-else class="remove-from-cart-btn">
        حذف از سبد خرید
      </button>
      <!-- <button class="consulting-btn">مشاوره</button> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { numberFormat } from "../../assets/helper/helper";

export default {
  name: "ProductDetails",
  components: {},

  props: ["showAllComments"],

  data() {
    return {
      activeSlide: 0,
      showMoreList: false,
      showMoreProductDescription: false,
      selectColor: "",
      showModalProductImage: false,
      activeScroll: false,

      // showProductImg: productImg1,
      showProductImgIndex: 0,
      showBorder: false,
      showSocialMedia: false,

      colorBox: [
        {
          color: "#C80020",
          colorName: "زرشکی",
        },

        {
          color: "#F952B1",
          colorName: "صورتی",
        },
        {
          color: "#FF001D",
          colorName: "قرمز",
        },
        {
          color: "#3FBDCC",
          colorName: "فیروزه ای",
        },
        {
          color: "#4990E2",
          colorName: "آبی",
        },
      ],

      colorValue: "",

      // selected Variable Property for productPrice
      selectedVariable: {
        id: "",
        discount: 0,
        price: 0,
      },

      // state for active selller in product price
      /* activeSeller: {
        price_type: "owner",
        productPrice_id: "",
        seller_discount: "",
        seller_price: "",
      }, */

      // state for computed height of productLeft
      // productLeftHeight: null
    };
  },

  methods: {
    numberFormat,
    /* scroll to comments */
    scrollToComments() {
      const productComments = document.getElementById("product-comments");

      let offsetTop = productComments.offsetTop;
      console.log(productComments.offsetTop);

      window.scrollTo(0, offsetTop - 100);
    },

    /* TODO  colors inputs selected  */
    selectVariableProp(index) {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.product_prices.length > 0
      ) {
        this.selectedVariable =
          this.$store.getters.getShowProductInfo.product_prices[index];

        // dispatch active selected variable id

        let currentSellers = this.getSellersInfo.filter(
          (item) =>
            item.productPrice_id ==
            this.$store.getters.getShowProductInfo.product_prices[index].id
        );

        console.log(currentSellers);
        // active seller for change productPrice changes
        this.$store.dispatch(
          "activeSelectedVariableIdDispatch",
          currentSellers
        );

        this.$forceUpdate();
      }
    },

    showMoreProperty() {
      const propertyList = document.getElementById("main-property-list-ul");
      const verticalLine = document.getElementsByClassName(
        "vertical-line-product-details"
      );

      if (this.showMoreList === false) {
        propertyList.style.height = "auto";
        this.showMoreList = true;
        this.$refs.productLeft.style.height =
          this.$refs.productCenter.clientHeight - 9 + "px";

        for (let i = 0; i < verticalLine.length; i++) {
          verticalLine[i].style.height =
            this.$refs.productCenter.clientHeight + "px";
        }
      } else {
        propertyList.style.height = "70px";
        this.showMoreList = false;
        this.$refs.productLeft.style.height =
          this.$refs.productCenter.clientHeight - 9 + "px";

        for (let i = 0; i < verticalLine.length; i++) {
          verticalLine[i].style.height =
            this.$refs.productCenter.clientHeight + "px";
        }
      }
    },

    showMoreAboutProduct() {
      const aboutProduct = document.getElementById("about-product");

      if (this.showMoreProductDescription == false) {
        aboutProduct.style.height = "250px";
        this.showMoreProductDescription = true;
        this.activeScroll = true;
      } else {
        aboutProduct.style.height = "100px";
        this.showMoreProductDescription = false;
        aboutProduct.scrollTo(0, 0);
        this.activeScroll = false;
      }
    },

    /*  show preview product Images */
    showPreviewProductImages() {
      const previewProductImages = document.getElementById(
        "preview-product-images"
      );
      const backgroundModal = document.getElementById("background-modal");

      //  scroll to top of page for top (absolute) and marginTop
      // window.scroll(0, 0);

      previewProductImages.style.opacity = "1";
      backgroundModal.style.opacity = "1";
      previewProductImages.style.visibility = "visible";
      backgroundModal.style.visibility = "visible";
      document.body.style.overflow = "hidden";
    },

    closePreviewProductImages() {
      const previewProductImages = document.getElementById(
        "preview-product-images"
      );
      const backgroundModal = document.getElementById("background-modal");

      previewProductImages.style.opacity = 0;
      backgroundModal.style.opacity = 0;
      previewProductImages.style.visibility = "hidden";
      backgroundModal.style.visibility = "hidden";
      document.body.style.overflow = "auto";
    },

    nextImg() {
      if (this.showProductImgIndex === this.getProductImages.length - 1) {
        this.showProductImgIndex = 0;
      } else {
        this.showProductImgIndex++;
      }
      // console.log(this.showProductImgIndex)
    },

    previousImg() {
      if (this.showProductImgIndex === 0) {
        this.showProductImgIndex = this.getProductImages.length - 1;
      } else {
        this.showProductImgIndex--;
      }
      // console.log(this.showProductImgIndex)
    },

    changeProductImg(index) {
      this.showProductImgIndex = index;
    },

    /*  like product method */

    likeProduct() {
      const heart = document.getElementById("like-product");

      heart.style.color = "#FF364D";
    },

    /*  show Social Media method  */
    showSocialMediaIcons() {
      const allSocialMedia = document.getElementById("all-social-media");

      if (this.showSocialMedia === false) {
        allSocialMedia.style.opacity = "1";
        allSocialMedia.style.visibility = "visible";

        this.showSocialMedia = true;
      } else {
        allSocialMedia.style.opacity = "0";
        allSocialMedia.style.visibility = "hidden";

        this.showSocialMedia = false;
      }
    },

    // computed fontawesome star average
    fontAwesomeComputed(index) {
      if (
        index < this.getProductStarAverage &&
        this.getProductStarAverage < index + 1
      ) {
        return "fa-solid fa-star-half-stroke";
      } else if (index < this.getProductStarAverage) {
        return "fa fa-star";
      } else {
        return "fa-regular fa-star";
      }
    },

    // TODO  (seller) method for add to cart product
    addToCart() {
      if (this.$store.state.token == "") {
        // alert for login
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "ابتدا وارد حساب کاربری خود شوید .",
        });
      } else {
        //  add to cart
        this.$store
          .dispatch("addToCartDispatch", {
            token: this.$store.getters.getToken,
            productPrice_id: this.selectedVariable.id,
            product_count: 1,
            seller_id: this.activeSeller[0].seller_id,
          })
          .then((res) => {
            if (res.status) {
              // success alert
              this.selectedVariable.isIn_cart = true;

              let activeSellerChanges = this.activeSeller;

              activeSellerChanges.forEach((seller, index) => {
                if (this.activeSeller.length > 0 && index == 0) {
                  seller.isIn_cart = true;
                }
              });

              this.activeSeller[0].isIn_cart = true;
              this.$forceUpdate();
              this.$store.dispatch(
                "activeSelectedVariableIdDispatch",
                activeSellerChanges
              );
              this.$store.dispatch("alertDispatch", {
                show: true,
                text: res.message,
              });
            } else {
              // error alert
              this.$store.dispatch("errorAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          });
      }
    },

    //  method for remove product from cart
    // TODO  (seller)  delete order
    removeFromCart() {
      if (this.$store.state.token == "") {
        // alert for login
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "ابتدا وارد حساب کاربری خود شوید .",
        });
      } else {
        //  add to cart
        this.$store
          .dispatch("removeProductFromCart", {
            token: this.$store.getters.getToken,
            productPrice_id: this.selectedVariable.id,
            seller_id:
              this.activeSeller[0].seller_id == null
                ? ""
                : this.activeSeller[0].seller_id,
          })
          .then((res) => {
            if (res.status) {
              // success alert
              this.selectedVariable.isIn_cart = false;

              let activeSellerChanges = this.activeSeller;

              activeSellerChanges.forEach((seller, index) => {
                if (this.activeSeller.length > 0 && index == 0) {
                  seller.isIn_cart = false;
                }
              });

              this.activeSeller[0].isIn_cart = false;
              this.$forceUpdate();

              this.$store.dispatch(
                "activeSelectedVariableIdDispatch",
                activeSellerChanges
              );

              this.$store.dispatch("alertDispatch", {
                show: true,
                text: res.message,
              });
            } else {
              // error alert
              this.$store.dispatch("errorAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          });
      }
    },
  },

  computed: {
    activeSeller() {
      if (
        this.$store.getters.getActiveSelectedVariable_id != null &&
        this.$store.getters.getActiveSelectedVariable_id.length > 0
      ) {
        console.log(this.$store.getters.getActiveSelectedVariable_id);
        return this.$store.getters.getActiveSelectedVariable_id;
      }
    },
    // get all sellers
    getSellersInfo() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getProductSellers != null
      ) {
        return this.$store.getters.getProductSellers;
      }
    },

    getStoreMarketing() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_marketing.length > 0
      ) {
        return this.$store.getters.getStoreInfo.store_marketing;
      }
    },

    getStoreName() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.selectedVariable.available_count > 0
      ) {
        return this.$store.getters.getStoreInfo.store_name;
      }
    },
    //      method for get product name from vuex
    getProductName() {
      if (this.$store.getters.getShowProductInfo != null) {
        return {
          title: this.$store.getters.getShowProductInfo.title,
          latin_title: this.$store.getters.getShowProductInfo.latin_title,
        };
      } else {
        return {
          title: "",
          latin_title: "",
        };
      }
    },

    //      method for get product introduction from vuex
    getProductIntroduction() {
      if (this.$store.getters.getShowProductInfo != null) {
        return this.$store.getters.getShowProductInfo.product_introduction;
      }
    },

    //      method for get star average from vuex
    getProductStarAverage() {
      if (this.$store.getters.getShowProductInfo != null) {
        return this.$store.getters.getShowProductInfo.start_average;
      }
    },

    //      method for get product country and first category from vuex
    getProductCountryAndFirstCategory() {
      if (this.$store.getters.getShowProductInfo != null) {
        return {
          country: this.$store.getters.getShowProductInfo.country,
          category:
            this.$store.getters.getShowProductInfo.product_categories.length > 0
              ? this.$store.getters.getShowProductInfo.product_categories[0]
                  .title
              : "",
        };
      } else {
        return {
          country: "",
          category: "",
        };
      }
    },

    // TODO For get first current seller item in single box
    //      method for get productPrice items from vuex
    getProductPrices() {
      if (this.$store.getters.getShowProductInfo != null) {
        this.selectedVariable =
          this.$store.getters.getShowProductInfo.product_prices[0];
        return this.$store.getters.getShowProductInfo.product_prices;
      } else {
        return [{ key: "", price: 0, discount: 0 }];
      }
    },

    //  method for get product isKeyProps from vuex
    getIsKeyProps() {
      if (this.$store.getters.getShowProductInfo != null) {
        return this.$store.getters.getShowProductInfo.isKey_properties;
      } else {
        return [];
      }
    },

    //  method for get product (price && discount) from vuex
    getPriceAndDiscountComputed() {
      if (this.$store.getters.getShowProductInfo != null) {
        let discount = 0;
        // check discount have value or not
        if (
          this.activeSeller[0].seller_discount != null ||
          this.activeSeller[0].seller_discount != 0
        ) {
          discount = Math.ceil(
            (this.activeSeller[0].seller_discount /
              this.activeSeller[0].seller_price) *
              100
          );
        }
        return {
          price: this.activeSeller[0].seller_price,
          discount,
        };
      } else {
        return {
          price: 0,
          discount: 0,
        };
      }
    },

    //      method for get product images from vuex
    getProductImages() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.product_galleries.length > 0
      ) {
        return this.$store.getters.getShowProductInfo.product_galleries;
      } else {
        return [{ media: { media_link: "" } }];
      }
    },

    // method for get main product Image
    getMainProductImage() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.product_galleries.length > 0
      ) {
        return this.$store.getters.getShowProductInfo.product_galleries.find(
          (item) => item.is_main == 1
        );
      } else {
        return {
          media: { media_link: "" },
        };
      }
    },
  },

  created() {
    // setTimeout(() => {
    //     if (this.$store.getters.getShowProductInfo != null && ('product_prices' in this.$store.getters.getShowProductInfo) &&
    //         this.$store.getters.getShowProductInfo.product_prices.length > 0) {
    //         this.selectedVariable = this.$store.getters.getShowProductInfo.product_prices[0];
    //         this.$forceUpdate();
    //     }
    //  computed height of productLeft
    // if (typeof window != undefined) {
    //     this.$refs.productLeft.style.height = (this.$refs.productCenter.clientHeight + 30) + 'px';
    // }
    // }, 3000);
  },

  mounted() {
    setTimeout(() => {
      let currentSellers = this.getSellersInfo.filter(
        (item) =>
          item.productPrice_id ==
          this.$store.getters.getShowProductInfo.product_prices[0].id
      );

      this.$store.dispatch("activeSelectedVariableIdDispatch", currentSellers);
      // TODO new comment
      // this.activeSeller = this.$store.getters.getActiveSelectedVariable_id[0];
    }, 3000);
  },
};
</script>

<style src="../../assets/css/product/productDetails.css" scoped></style>
