import {
  httpAddresses,
  httpAddToCart,
  httpAllProductComments,
  httpAllProducts,
  httpAllQuestions,
  httpCartItems,
  httpChangeCart,
  httpChangePassWithCurrent,
  httpChangePassWithVerifyCode,
  httpCities,
  httpColorProp,
  httpCreateReturnOrder,
  httpDeleteAddress,
  httpDestroyComment,
  httpDiscountSlider,
  httpEditAddress,
  httpFqQuestions,
  httpGetAllReturnedOrders,
  httpGetVerifyCodeForChangePass,
  httpHeaderCategories,
  httpLoginPass,
  httpLogOut,
  httpNewAddress,
  httpOrderPaymentGateway,
  httpOrderPaymentVerify,
  httpPhoneNumber,
  httpPostComment,
  httpPostMessage,
  httpProductComments,
  httpProvinces,
  httpRelatedSlider,
  httpRemoveFromCart,
  httpRemoveProductFromCart,
  httpShowCategories,
  httpShowOrder,
  httpShowProductInfo,
  httpShowReturnOrder,
  httpStoreAboutUsInfo,
  httpStoreContactUs,
  httpStoreDeliveryTypes,
  httpStoreInfo,
  httpSubmitDemoUser,
  httpUpdateAddress,
  httpUpdateCount,
  httpUpdateUser,
  httpUser,
  httpUserComments,
  httpUserMessages,
  httpUserOrders,
  httpVerificationCode,
} from "./api";

const actions = {
  // action for set show filter mobile
  setShowFilterMobileDispatch({ commit }, payload) {
    commit("setShowFilterMobile", payload);
  },
  // action for set access token
  setTokenDispatch({ commit }, payload) {
    commit("setToken", payload);
  },

  activeSelectedVariableIdDispatch({ commit }, payload) {
    commit("setActiveSelectedvariable_id", payload);
  },

  setShowDemoModalDispatch({ commit }, payload) {
    commit("setShowDemoModal", payload);
  },

  // action for set show download suggestion
  setShowAppSuggestionDispatch({ commit }, payload) {
    commit("setShowAppSuggestion", payload);
  },

  profileImageDispatch({ commit }, payload) {
    commit("setProfileImage", payload);
  },

  //  action for set show background for dropDown or modal
  showBackgroundDispatch({ commit }, payload) {
    commit("setShowBackground", payload);
  },

  // action for show alerts
  alertDispatch({ commit }, payload) {
    commit("setAlert", payload);
  },

  // action for show warning alert
  warningAlertDispatch({ commit }, payload) {
    commit("setWarningAlert", payload);
  },

  // action for show error alert
  errorAlertDispatch({ commit }, payload) {
    commit("setErrorAlert", payload);
  },
  // action for header categories
  headerCategoriesDispatch({ commit }) {
    return httpHeaderCategories()
      .then((res) => {
        commit("setHeaderCategories", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //   action for store info
  storeInfoDispatch({ commit }) {
    // return api resolve
    return httpStoreInfo()
      .then((res) => {
        commit("setStoreInfo", res);

        // TODO set show loader
        // commit("setShowLoader", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //   action for store aboutUs info
  storeAboutUsDispatch({ commit }) {
    return httpStoreAboutUsInfo()
      .then((res) => {
        commit("setStoreAboutUs", res);

        // TODO set show Loader
        // commit("setShowLoader", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action fr store contactus info
  storeContactUsDispatch({ commit }) {
    return httpStoreContactUs()
      .then((res) => {
        //  TODO set show loader
        // commit("setShowLoader", false);
        commit("setStoreContactUs", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for show single product info
  showProductInfoDispatch({ commit }, payload) {
    return httpShowProductInfo(payload)
      .then((res) => {
        commit("setShowProductInfo", res.data);
        commit("setRatingTypes", res.rating_types);
        commit("setProductSellers", res.sellers);
        //  TODO remove loader
        // commit("setShowLoader", false);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get all (paginated) prductComments of specific product
  productCommentsDispatch({ commit }, payload) {
    return httpProductComments(payload)
      .then((res) => {
        commit("setProductComments", res.data);
        commit("setProductCommentsPagination", {
          meta: res.meta,
          links: res.links,
        });
        commit("setProductCommentsRates", res.productCommentsRates);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  // action for get all prductComments of specific product
  allProductCommentsDispatch({ commit }, payload) {
    return httpAllProductComments(payload)
      .then((res) => {
        commit("setAllProductComments", res.data);
        // commit("setProductCommentsPagination", {
        //   meta: res.meta,
        //   links: res.links
        // });
        commit("setAllProductCommentsRates", res.productCommentsRates);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get all products with filters
  allProductsDispatch({ commit }, payload) {
    return httpAllProducts(payload)
      .then((res) => {
        commit("setAllProducts", res.data);

        //  TODO remove loader
        // commit("setShowLoader", false);

        // commit pagination
        commit("setAllProductsPagination", {
          meta: res.meta,
          links: res.links,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get color prop and all values of store
  colorPropDispatch({ commit }) {
    return httpColorProp()
      .then((res) => {
        commit("setColorProp", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get products of discounts slider
  discountSliderDispatch({ commit }) {
    return httpDiscountSlider()
      .then((res) => {
        commit("setDiscountSlider", res);
      })

      .catch((e) => {
        console.log(e);
      });
  },

  // action for get products of related slider
  relatedSliderDispatch({ commit }, payload) {
    return httpRelatedSlider(payload)
      .then((res) => {
        commit("setRelatedSlider", res);
      })

      .catch((e) => {
        console.log(e);
      });
  },

  // action for get fq questions in store main page
  fqQuestionsDispatch({ commit }) {
    return httpFqQuestions()
      .then((res) => {
        commit("setFqQuestions", res);
      })

      .catch((e) => {
        console.log(e);
      });
  },

  // action for get all questions
  allQuestionsDispatch({ commit }) {
    return httpAllQuestions()
      .then((res) => {
        commit("setAllQuestions", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get specific category
  showCategoriesDispatch({ commit }, payload) {
    return httpShowCategories(payload.category_id)
      .then((res) => {
        commit("setShowCategories", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for post phone number in login
  phoneNumberDispatch({ commit }, payload) {
    return httpPhoneNumber(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for post verification code in login
  verificationCodeDispatch({ commit }, payload) {
    return httpVerificationCode(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for post password in login
  loginPassDispatch({ commit }, payload) {
    return httpLoginPass(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for post new productComment by users
  postCommentDispatch({ commit }, payload) {
    return httpPostComment(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for post new message by users
  postMessageDispatch({ commit }, payload) {
    return httpPostMessage(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get user logged in info
  userDispatch({ commit }, payload) {
    return httpUser(payload)
      .then((res) => {
        commit("setUserInfo", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for add product to cart
  addToCartDispatch({ commit }, payload) {
    return httpAddToCart(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for remove product from cart in show product page
  removeProductFromCart({ commit }, payload) {
    return httpRemoveProductFromCart(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for remove product from cart in cart page
  removeFromCartDispatch({ commit }, payload) {
    return httpRemoveFromCart(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get all cart items
  cartItemsDispatch({ commit }, payload) {
    return httpCartItems(payload)
      .then((res) => {
        commit("setCartItems", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for update count of products into cart
  updateCountDispatch({ commit }, payload) {
    return httpUpdateCount(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get all addresses of user
  userAddressesDispatch({ commit }, payload) {
    return httpAddresses(payload)
      .then((res) => {
        commit("setUserAddresses", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for get all province of iran
  provincesDispatch({ commit }, payload) {
    return httpProvinces()
      .then((res) => {
        commit("setProvinces", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get all cities of specific province
  citiesDispatch({ commit }, payload) {
    return httpCities(payload)
      .then((res) => {
        commit("setCities", res.data);
      })
      .catch((e) => console.log(e));
  },

  //  action for add new address of user
  newAddressDispatch({ commit }, payload) {
    return httpNewAddress(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for delete address of user
  deleteAddressDispatch({ commit }, payload) {
    return httpDeleteAddress(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get edit selected address info
  editAddressDispatch({ commit }, payload) {
    return httpEditAddress(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for update selected address
  updateAddressDispatch({ commit }, payload) {
    return httpUpdateAddress(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for change cart info(deliver address , store deliveryType)
  changeCartDispatch({ commit }, payload) {
    return httpChangeCart(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get storeDeliveryTypes of cart
  storeDeliveryTypesDispatch({ commit }, payload) {
    return httpStoreDeliveryTypes(payload)
      .then((res) => {
        commit("setStoreDeliveryTypes", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get userMessages of prfile
  userMessagesDispatch({ commit }, payload) {
    return httpUserMessages(payload)
      .then((res) => {
        commit("setUserMessages", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //  action for get userComments of profile
  userCommentsDispatch({ commit }, payload) {
    return httpUserComments(payload)
      .then((res) => {
        commit("setUserComments", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // action for delete userComment in user profile
  destroyCommentDispatch({ commit }, payload) {
    return httpDestroyComment(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // actin for update user info in profile
  updateUserDispatch({ commit }, payload) {
    return httpUpdateUser(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  userOrdersDispatch({ commit }, payload) {
    return httpUserOrders(payload)
      .then((res) => {
        commit("setUserOrders", res);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  showOrderDispatch({ commit }, payload) {
    return httpShowOrder(payload)
      .then((res) => {
        commit("setShowOrder", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  logOutDispatch({ commit }, payload) {
    return httpLogOut(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  showLoaderDispatch({ commit }, payload) {
    commit("setShowLoader", payload);
  },

  changePassWithCurrentDispatch({ commit }, payload) {
    console.log(payload);
    return httpChangePassWithCurrent(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getVerifyCodeForChangePassDispatch({ commit }, payload) {
    return httpGetVerifyCodeForChangePass(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  changePassWithVerifyCodeDispatch({ commit }, payload) {
    return httpChangePassWithVerifyCode(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  getAllReturnedOrdersDispatch({ commit }, payload) {
    return httpGetAllReturnedOrders(payload)
      .then((res) => {
        commit("setReturnedOrders", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  showReturnOrderDispatch({ commit }, payload) {
    return httpShowReturnOrder(payload)
      .then((res) => {
        commit("setShowReturnOrder", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  createReturnOrderDispatch({ commit }, payload) {
    return httpCreateReturnOrder(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  submitDemoUserDispatch({ commit }, payload) {
    return httpSubmitDemoUser(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //*** dispatch for checkout step one (gateway)
  checkoutGatewayDispatch({ commit }, payload) {
    return httpOrderPaymentGateway(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  //*** dispatch for checkout step one (verify orderPayment)
  checkoutVerifyDispatch({ commit }, payload) {
    return httpOrderPaymentVerify(payload)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export default actions;
