<template>
    <div class="general-specifications">
        <p class="general-specifications-title">مشخصات کلی</p>
        <hr class="general-specifications-hr">
        <div class="general-specifications-properties">


            <div v-for="(prop, propIndex) in getGeneralSpecification" :key="propIndex" class="single-property">
                <div class="property-title">
                    <span>{{ prop.key }}</span>
                </div>
                <div class="property-value">
                    <span>
                        {{ prop.value }}
                    </span>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    name: "GeneralSpecifications",


    data() {
        return {}
    },


    computed: {
        // method for get is not key properties
        getGeneralSpecification() {
            if (this.$store.getters.getShowProductInfo != null
                && this.$store.getters.getShowProductInfo.isNotKey_properties.length > 0) {
                return this.$store.getters.getShowProductInfo.isNotKey_properties
            }
        }
    }
}
</script>

<style src="../../assets/css/product/generalSpecifications.css" scoped>
</style>