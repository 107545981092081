<template>
  <!--  -->
  <div
    :class="[
      'alert-box',
      {
        activeAlert: alertInfo.show == true,
        deActiveAlert: alertInfo.show == false
      }
    ]"
  >
    <img src="../../assets/images/icons/error-alert2.svg" alt="" />

    <p>
      {{ alertInfo.text }}
    </p>

    <!-- <p>{{ alertInfo.text }}</p> -->
  </div>
</template>
<script>
export default {
  name: "Error",
  data() {
    return {};
  },
  computed: {
    alertInfo() {
      if (this.$store.getters.getErrorAlert != null) {
        return this.$store.getters.getErrorAlert;
      }
    }
  },

  watch: {
    alertInfo() {
      if (this.alertInfo.show) {
        setTimeout(() => {
          this.$store.dispatch("errorAlertDispatch", { show: false, text: "" });
        }, 3000);
      }
    }
  }
};
</script>
<style src="../../assets/css/alert/error.css" scoped></style>
