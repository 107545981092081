<template>
  <!-- v-show="showFilterMobile" -->
  <div class="filters-mobile">
    <div class="filters-mobile-header">
      <span>فیلتر‌ها</span>
      <img
        @click="backToStoreMobile"
        src="../../assets/images/icons/back-arrow-mobile.svg"
        alt=""
      />
    </div>
    <div class="filters-mobile-content">
      <div class="filter-mobile-available-products">
        <div class="filter-mobile-available-products-content">
          <p>فقط محصولات موجود</p>

          <label
            for="available-products"
            :class="[
              'available-products-switchCase-back',
              { activeBackClass: availableStatus },
            ]"
          >
            <input
              @click.prevent="activeAvailableProductsFilter"
              v-model="availableStatus"
              :checked="availableStatus"
              style="display: none"
              type="checkbox"
              id="available-products"
            />

            <div
              :class="[
                'available-products-switchCase-circle',
                { activeCircleClass: availableStatus },
              ]"
            ></div>
          </label>
        </div>

        <!-- <div class="filter-mobile-fast-send-products">
          <p>فقط محصولات ارسال سریع</p>

          <label
            for="fastSend-products"
            @click="activeFastSendProductsFilter"
            :class="[
              'available-products-switchCase-back',
              { activeBackClass: activeFastSendProductsClass }
            ]"
          >
            <input
              style="display: none"
              type="checkbox"
              id="fastSend-products"
            />

            <div
              :class="[
                'available-products-switchCase-circle',
                { activeCircleClass: activeFastSendProductsClass }
              ]"
            ></div>
          </label>
        </div> -->
      </div>

      <div
        :class="[
          'advanced-search',
          { backGroundAdvanceSearch: advanceSearchOpenBox },
        ]"
      >
        <div
          @click="advanceSearchOpenBox = !advanceSearchOpenBox"
          class="advanced-search-header"
        >
          <h4>جستجوی پیشرفته</h4>
          <font-awesome-icon
            icon="fa-chevron-down"
            :class="[
              'fa',
              'fa-chevron-down',
              { advanceSearchOpenBoxImg: advanceSearchOpenBox },
            ]"
          ></font-awesome-icon>
        </div>

        <div
          :class="[
            'search-box-mobile',
            { advanceSearchOpenBoxClass: advanceSearchOpenBox },
          ]"
        >
          <div class="search-box-mobile-content mb-4">
            <input
              v-model="search"
              type="text"
              class="search-box-input"
              placeholder="لطفا نام محصول مورد نظر را بنویسید"
            />

            <img
              src="../../assets/images/icons/search-icon-for-mobile.svg"
              alt=""
            />
          </div>

          <button @click.prevent="submitSearch" class="search-submit w-100 btn">
            <img
              src="../../assets/images/icons/actions-filter-icon-mobile.svg"
              alt=""
            />
            <span>اعمال فیلتر</span>
          </button>
        </div>
      </div>

      <div
        :class="[
          'result-category',
          { resultCategoryBorder: showResultCategoryBox },
        ]"
      >
        <div @click="showResultCategoryMain" class="result-category-header">
          <h4>دسته بندی نتایج</h4>

          <font-awesome-icon
            icon="fa-chevron-down"
            :class="[
              'fa',
              'fa-chevron-down',
              { resultCategoryArrowClass: showResultCategoryBox },
            ]"
          ></font-awesome-icon>
        </div>

        <div
          :class="[
            'result-category-content',
            { resultCategoryShowContent: showResultCategoryBox },
          ]"
        >
          <div
            @click="allCategoriesAction"
            class="result-category-content-title"
          >
            <font-awesome-icon
              :class="['fa', 'fa-chevron-down', 'pb-1']"
              icon="fa-chevron-down"
            />

            <h4>همه کالاها</h4>
          </div>

          <!-- parent category -->
          <div
            :style="[
              'productCategories' in $route.query
                ? { marginRight: '15px' }
                : {},
            ]"
            v-if="'productCategories' in $route.query"
            class="result-category-content-title"
            @click="showSpecificCategory(category)"
          >
            <font-awesome-icon
              :class="[
                'category-result-i-down',
                'pb-1',
                { 'inactive-chevron-down': activeCategory != parentId },
                { 'active-chevron-down': activeCategory == parentId },
              ]"
              icon="fa-chevron-down"
              class="category-result-i-down"
            />
            <h4
              :style="[
                activeCategory == parentId
                  ? { color: '#4990e2 !important' }
                  : {},
              ]"
            >
              {{ parentTitle }}
            </h4>
          </div>

          <!-- childes category -->
          <div
            :style="[
              'productCategories' in $route.query
                ? { marginRight: '30px' }
                : {},
            ]"
            v-for="(category, index) in getCategories"
            :key="index"
            @click="showSpecificCategory(category)"
            class="result-category-content-title"
          >
            <font-awesome-icon
              :class="['fa', 'fa-chevron-down', 'pb-1']"
              icon="fa-chevron-down"
            />

            <h4>{{ "title" in category ? category.title : "" }}</h4>
          </div>
        </div>
      </div>

      <div
        :class="[
          'price-range',
          'mb-4',
          { resultCategoryBorder: showPriceRangeMobile },
        ]"
      >
        <div
          @click="showPriceRangeMobile = !showPriceRangeMobile"
          class="price-range-title"
        >
          <h4>محدوده قیمت</h4>
          <font-awesome-icon
            icon="fa-chevron-down"
            :class="[
              'fa',
              'fa-chevron-down',
              { advanceSearchOpenBoxImg: showPriceRangeMobile },
            ]"
          ></font-awesome-icon>
        </div>

        <div
          :class="[
            'price-range-content',
            { showPriceRangeContentClass: showPriceRangeMobile },
          ]"
        >
          <p>قیمت به تومان است</p>

          <vue-slider
            :tooltip="'none'"
            :process-style="{ backgroundColor: $mainColor }"
            v-bind="{ max, direction }"
            :enable-cross="false"
            :hide-label="true"
            v-model="Price"
          >
            <template v-slot:dot>
              <div class="bigCircle">
                <div class="smallCircle"></div>
              </div>
            </template>
          </vue-slider>

          <div class="price-details mb-3">
            <div class="price-details-right">
              <span>از</span>
              <span>{{ Price[0] }}</span>
            </div>
            <div class="price-details-left">
              <span>تا</span>
              <span>{{ Price[1] }}</span>
            </div>
          </div>

          <button
            @click.prevent="setPriceFilter"
            :style="{ width: '90%' }"
            class="btn search-submit"
          >
            <img
              src="../../assets/images/icons/actions-filter-icon-mobile.svg"
              alt=""
            />
            <span>اعمال فیلتر</span>
          </button>
        </div>
      </div>

      <!-- color filter for mobile -->
      <!--  <div
        :class="[
          'color-determination-mobile',
          { resultCategoryBorder: colorDeterminationShowBox }
        ]"
      >
        <div
          @click="colorDeterminationShowBox = !colorDeterminationShowBox"
          class="color-determination-mobile-title"
        >
          <h4>تعیین رنگ</h4>

          <i
            :class="[
              'fa',
              'fa-chevron-down',
              { colorDeterminationArrow: colorDeterminationShowBox }
            ]"
          ></i>
        </div>

        <div
          :class="[
            'color-determination-mobile-content',
            { colorDeterminationMobileContentClass: colorDeterminationShowBox }
          ]"
        >
          <label
            for="color-determination-mobile-black"
            class="color-determination-mobile-content-item"
          >
            <div class="color-content-item-right">
              <div class="color-box black"></div>
              <p>مشکی</p>
            </div>

            <div class="color-content-item-left">
              <div class="border-circle">
                <div
                  :class="{ 'color-circle': colorDetermination == 'black' }"
                ></div>
              </div>

              <input
                v-model="colorDetermination"
                value="black"
                id="color-determination-mobile-black"
                style="display: none"
                type="radio"
              />
            </div>
          </label>

          <label
            for="color-determination-mobile-orange"
            class="color-determination-mobile-content-item"
          >
            <div class="color-content-item-right">
              <div class="color-box orange"></div>
              <p>نارنجی</p>
            </div>

            <div class="color-content-item-left">
              <div class="border-circle">
                <div
                  :class="{ 'color-circle': colorDetermination == 'orange' }"
                ></div>
              </div>

              <input
                v-model="colorDetermination"
                value="orange"
                id="color-determination-mobile-orange"
                style="display: none"
                type="radio"
              />
            </div>
          </label>

          <label
            for="color-determination-mobile-blue"
            class="color-determination-mobile-content-item"
          >
            <div class="color-content-item-right">
              <div class="color-box blue"></div>
              <p>آبی</p>
            </div>

            <div class="color-content-item-left">
              <div class="border-circle">
                <div
                  :class="{ 'color-circle': colorDetermination == 'blue' }"
                ></div>
              </div>

              <input
                v-model="colorDetermination"
                value="blue"
                id="color-determination-mobile-blue"
                style="display: none"
                type="radio"
              />
            </div>
          </label>

          <label
            for="color-determination-mobile-turquoise"
            class="color-determination-mobile-content-item"
          >
            <div class="color-content-item-right">
              <div class="color-box turquoise"></div>
              <p>فیروزه ای</p>
            </div>

            <div class="color-content-item-left">
              <div class="border-circle">
                <div
                  :class="{ 'color-circle': colorDetermination == 'turquoise' }"
                ></div>
              </div>

              <input
                v-model="colorDetermination"
                value="turquoise"
                id="color-determination-mobile-turquoise"
                style="display: none"
                type="radio"
              />
            </div>
          </label>
        </div>
      </div> -->

      <!-- size filter in mobile  -->
      <!--  <div
        :class="[
          'size-determination-mobile',
          { resultCategoryBorder: sizeDeterminationShowBox }
        ]"
      >
        <div
          @click="sizeDeterminationShowBox = !sizeDeterminationShowBox"
          class="size-determination-mobile-title"
        >
          <h4>تعیین سایز</h4>
          <i
            :class="[
              'fa',
              'fa-chevron-down',
              { sizeDeterminationArrow: sizeDeterminationShowBox }
            ]"
          ></i>
        </div>

        <div
          :class="[
            'size-determination-mobile-content',
            { sizeDeterminationShowContent: sizeDeterminationShowBox }
          ]"
        >
          <label for="size-L" class="size-determination-mobile-item">
            <div class="size-box">L</div>

            <div class="border-circle-radio">
              <div :class="{ 'fill-circle-radio': sizeValue == 'L' }"></div>
            </div>

            <input
              style="display: none"
              v-model="sizeValue"
              value="L"
              id="size-L"
              type="radio"
            />
          </label>

          <label for="size-M" class="size-determination-mobile-item">
            <div class="size-box">M</div>

            <div class="border-circle-radio">
              <div :class="{ 'fill-circle-radio': sizeValue == 'M' }"></div>
            </div>

            <input
              style="display: none"
              v-model="sizeValue"
              value="M"
              id="size-M"
              type="radio"
            />
          </label>

          <label for="size-XL" class="size-determination-mobile-item">
            <div class="size-box">XL</div>

            <div class="border-circle-radio">
              <div :class="{ 'fill-circle-radio': sizeValue == 'XL' }"></div>
            </div>

            <input
              style="display: none"
              v-model="sizeValue"
              value="XL"
              id="size-XL"
              type="radio"
            />
          </label>

          <label for="size-XXL" class="size-determination-mobile-item">
            <div class="size-box">XXL</div>

            <div class="border-circle-radio">
              <div :class="{ 'fill-circle-radio': sizeValue == 'XXL' }"></div>
            </div>

            <input
              style="display: none"
              v-model="sizeValue"
              value="XXL"
              id="size-XXL"
              type="radio"
            />
          </label>
        </div>
      </div> -->
    </div>

    <!-- for new version removed -->
    <!-- <button class="actions-filters-button">
      <img
        src="../../assets/images/icons/actions-filter-icon-mobile.svg"
        alt=""
      />
      <span>اعمال فیلتر</span>
    </button> -->
  </div>
</template>

<script>
// import VueSlider from "vue-slider-component";
// import "vue-slider-component/theme/default.css";

export default {
  name: "FiltersMobile",

  components: {},

  data() {
    return {
      // filters for all products
      mostSaleProducts:
        "mostSaleProducts" in this.$route.query
          ? this.$route.query.mostSaleProducts
          : 0,

      newestProducts:
        "newestProducts" in this.$route.query
          ? this.$route.query.newestProducts
          : 0,
      cheapestProducts:
        "cheapestProducts" in this.$route.query
          ? this.$route.query.cheapestProducts
          : 0,
      mostExpensiveProducts:
        "mostExpensiveProducts" in this.$route.query
          ? this.$route.query.mostExpensiveProducts
          : 0,

      search: "q" in this.$route.query ? this.$route.query.q : "",

      availableStatus:
        "availableStatus" in this.$route.query
          ? this.$route.query.availableStatus
          : 0,

      minPrice:
        "minPrice" in this.$route.query ? this.$route.query.minPrice : "",
      maxPrice:
        "maxPrice" in this.$route.query ? this.$route.query.maxPrice : "",

      productCategories:
        "productCategories" in this.$route.query
          ? JSON.parse(this.$route.query.productCategories)
          : [],

      propertyKey:
        "propertyKey" in this.$route.query ? this.$route.query.propertyKey : "",

      propertyValues:
        "propertyValues" in this.$route.query
          ? JSON.parse(this.$route.query.propertyValues)
          : [],

      // available products checkbox
      activeAvailableProductsClass: false,
      activeFastSendProductsClass: false,

      advanceSearchOpenBox: false,
      showResultCategoryBox: false,
      showResultCategoryItem1: false,
      showResultCategoryItem2: false,
      showPriceRangeMobile: false,
      colorDetermination: "",
      colorDeterminationShowBox: false,

      sizeValue: "",
      sizeDeterminationShowBox: false,

      /*  Vue Slider Data  */
      Price: [0, 60000000],
      max: 60000000,
      direction: "rtl",

      //  state for get current category
      activeCategory: "",
    };
  },

  methods: {
    showResultCategoryMain() {
      this.showResultCategoryBox = !this.showResultCategoryBox;
    },

    backToStoreMobile() {
      this.showFilterMobile = false;
      this.$store.dispatch(
        "setShowFilterMobileDispatch",
        this.showFilterMobile
      );
    },

    // ? method for change available status filter
    activeAvailableProductsFilter() {
      // check route is main page or search
      if (this.$route.path == "/") {
        location.href = `/search?availableStatus=${1}`;
      } else if (this.$route.path.includes("/search")) {
        if (this.availableStatus == 0) {
          this.availableStatus = 1;
          this.$forceUpdate();

          this.$router.push({
            query: Object.assign({}, this.$route.query, {
              availableStatus: this.availableStatus,
            }),
          });

          //  dispatch get all products for available products
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });

          // back to search page
          this.backToStoreMobile();
        } else {
          this.availableStatus = 0;
          this.$forceUpdate();

          let query = Object.assign({}, this.$route.query);
          delete query.availableStatus;

          this.$router.replace({ query });

          //   dispatch get all products for available products
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });

          // back to search page
          this.backToStoreMobile();
        }
      }
    },

    // ? method for submit search filter
    submitSearch() {
      // check route is main page or search
      if (this.$route.path == "/") {
        location.href = `/search?q=${this.search}`;
      } else if (this.$route.path.includes("/search")) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { q: this.search }),
        });

        // dispatch get all products with search filter
        this.$store.dispatch("allProductsDispatch", {
          page: 1,
          mostSaleProducts: this.mostSaleProducts,
          search: this.search,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          availableStatus: this.availableStatus,
          productCategories: JSON.stringify(this.productCategories),
          newestProducts: this.newestProducts,
          cheapestProducts: this.cheapestProducts,
          mostExpensiveProducts: this.mostExpensiveProducts,
          propertyKey: this.propertyKey,
          propertyValues: JSON.stringify(this.propertyValues),
        });

        // back to search page
        this.backToStoreMobile();
      }
    },

    // ? method for price filter
    setPriceFilter() {
      // check route is store main page or search page
      if (this.$route.path == "/") {
        //  change route to search page and set querty params for min and max price
        location.href = `/search?minPrice=${this.Price[0]}&maxPrice=${this.Price[1]}`;
      } else if (this.$route.path.includes("/search")) {
        // set value price of vue-slider for filter
        this.minPrice = this.Price[0];
        this.maxPrice = this.Price[1];

        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
          }),
        });

        // dispatch for get all products with price filter
        this.$store.dispatch("allProductsDispatch", {
          page: 1,
          mostSaleProducts: this.mostSaleProducts,
          search: this.search,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          availableStatus: this.availableStatus,
          productCategories: JSON.stringify(this.productCategories),
          newestProducts: this.newestProducts,
          cheapestProducts: this.cheapestProducts,
          mostExpensiveProducts: this.mostExpensiveProducts,
          propertyKey: this.propertyKey,
          propertyValues: JSON.stringify(this.propertyValues),
        });

        // back to search page
        this.backToStoreMobile();
      }
    },

    // method for show all categories
    allCategoriesAction() {
      let query = Object.assign({}, this.$route.query);
      delete query.productCategories;

      this.$router.replace({ query });

      // reload page
      location.href = this.$router.resolve({
        name: this.$route.path == "/" ? "store" : "search",
        query: this.$router.query,
      }).href;
    },

    // method for show specific category
    showSpecificCategory(category) {
      // dispatch show specific categories
      this.$store.dispatch("showCategoriesDispatch", {
        category_id: category.id,
      });

      // reload page
      location.href = this.$router.resolve({
        name: "search",
        query: {
          productCategories: JSON.stringify([`${category.id}`]),
        },
      }).href;
    },

    /* activeFastSendProductsFilter() {
      //    get checkBox Element
      const fastSendProducts = document.getElementById("fastSend-products");

      if (fastSendProducts.checked) {
        this.activeFastSendProductsClass = true;
      } else {
        this.activeFastSendProductsClass = false;
      }
    } */
  },

  computed: {
    // ...mapState(["showFilterMobile"]),

    // method for get all categories
    getCategories() {
      if ("productCategories" in this.$route.query) {
        // return specific categoreis with childes

        if (
          this.$store.getters.getShowCatgories != null &&
          "title" in this.$store.getters.getShowCatgories
        ) {
          return this.$store.getters.getShowCatgories
            .all_product_childes_categories_store;
        }
      } else {
        // return all categories
        if (
          this.$store.getters.getHeaderCategories != null &&
          this.$store.getters.getHeaderCategories.length > 0
        ) {
          return this.$store.getters.getHeaderCategories;
        }
      }
    },

    parentId() {
      if (this.$store.getters.getShowCatgories) {
        return this.$store.getters.getShowCatgories.id;
      } else {
        return "";
      }
    },
    parentTitle() {
      if (this.$store.getters.getShowCatgories) {
        return this.$store.getters.getShowCatgories.title;
      } else {
        return "";
      }
    },
  },

  created() {
    let status = "productCategories" in this.$route.query;

    if (!status) {
      this.activeCategory = "";
    } else {
      let productCategories = JSON.parse(this.$route.query.productCategories);

      this.activeCategory = productCategories[0];
    }
  },
};
</script>

<style src="../../assets/css/store/filtersMobile.css" scoped></style>
