<template>
  <user-account>
    <div
      id="user-account-content"
      :class="['user-account-content', { hideContent: screenWidth < 426 }]"
    >
      <!--  back to slider user account mobile  -->
      <div class="back-to-slider-user-account-mobile">
        <img
          @click="backToUserAccountContentPage"
          src="../../assets/images/icons/back-arrow-mobile2.svg"
          alt=""
        />

        <p>حساب کاربری</p>
      </div>

      <!--  personal info  -->

      <div class="personal-info-and-selected-address-row">
        <div v-show="!editPersonalInfoShow" class="personal-info">
          <div ref="personalInfo" id="personal-info"></div>

          <div class="personal-info-title">
            <span>اطلاعات شخصی</span>

            <!-- new changes -->
            <!-- v-if="screenWidth > 426" -->
            <img
              @click="editPersonalInfo"
              src="../../assets/images/icons/edit-inputs-icon.svg"
              alt=""
            />
          </div>

          <div class="personal-info-details">
            <div class="personal-info-details-title">
              <p>نام و نام ‌خانوادگی</p>
              <p>جنسیت</p>
              <p>تاریخ تولد</p>
              <p>کد ملی</p>
              <p>تلفن همراه</p>
              <p>پست الکترونیک</p>
            </div>

            <div class="personal-info-details-content">
              <p>{{ userInfo.full_name }}</p>
              <p v-if="userInfo.gender == 1">مرد</p>
              <p v-else>زن</p>
              <p>{{ userInfo.birth_date }}</p>
              <p>{{ userInfo.national_code }}</p>
              <p>{{ userInfo.phone_number }}</p>
              <p>{{ userInfo.email }}</p>
            </div>
          </div>

          <!-- mobile section -->
          <!-- <div v-if="screenWidth < 426" class="edit-personal-info-mobile">
            <hr />

            <button @click="editPersonalInfo">
              <img
                src="../../assets/images/icons/edit-user-account-info-mobile.svg"
                alt=""
              />
              ویرایش
            </button>
          </div> -->
        </div>

        <div v-show="editPersonalInfoShow" class="edit-personal-info">
          <form class="edit-personal-info-form p-0">
            <div class="gender-div p-0">
              <label for="gender"> جنسیت </label>

              <div
                :class="[
                  'gender-select',
                  { activeBorderFocus: genderActiveFocus },
                ]"
              >
                <select
                  v-model="updateUserInfo.gender"
                  @focus="genderActiveFocus = true"
                  @blur="genderActiveFocus = false"
                  name="gender"
                  id="gender"
                >
                  <option value="1">مرد</option>
                  <option value="0">زن</option>
                </select>
              </div>

              <!-- mobile section -->
              <!--  <div
                @click="showModalForSelectGenderMobile"
                class="gender-select-mobile"
              >
                <span>{{ genderMobileFinal }}</span>

                <img
                  src="../../assets/images/icons/profile-select-arrow-mobile.svg"
                  alt=""
                />
              </div> -->

              <!-- mobile section -->
              <!-- <div
                @click.self="closeGenderModalMobile"
                id="gender-select-modal-mobile-background"
                class="gender-select-modal-mobile-background"
              >
                <div
                  @touchstart.self="closeGenderModalTouchStart"
                  @touchmove.self="closeGenderModalTouchMove"
                  @touchend.self="closeGenderModalTouchEnd"
                  id="gender-select-modal-mobile-content"
                  :style="{ bottom: genderBottom }"
                  class="gender-select-modal-mobile-content"
                >
                  <div class="gender-select-modal-line"></div>

                  <p>تعیین جنسیت</p>

                  <div class="gender-radio-mobile">
                    <label
                      for="gender-man-mobile"
                      class="gender-radio-mobile-man"
                    >
                      <div
                        :class="[
                          'gender-radio-mobile-man-parent',
                          { selectManRadioBorder: genderMobile == 'man-mobile' }
                        ]"
                      >
                        <div
                          :class="[
                            'radio-into',
                            {
                              selectmanRadioBackground:
                                genderMobile == 'man-mobile'
                            }
                          ]"
                        ></div>
                      </div>

                      <span>مرد</span>

                      <input
                        v-model="genderMobile"
                        value="man-mobile"
                        style="display: none"
                        type="radio"
                        id="gender-man-mobile"
                      />
                    </label>

                    <label
                      for="gender-woman-mobile"
                      class="gender-radio-mobile-woman"
                    >
                      <div
                        :class="[
                          'gender-radio-mobile-woman-parent',
                          {
                            selectWomanRadioBorder:
                              genderMobile == 'woman-mobile'
                          }
                        ]"
                      >
                        <div
                          :class="[
                            'radio-into',
                            {
                              selectWomanRadioBackground:
                                genderMobile == 'woman-mobile'
                            }
                          ]"
                        ></div>
                      </div>

                      <span>زن</span>

                      <input
                        v-model="genderMobile"
                        value="woman-mobile"
                        style="display: none"
                        type="radio"
                        id="gender-woman-mobile"
                      />
                    </label>
                  </div>

                  <button
                    type="button"
                    @click="submitGenderModalMobile"
                    :disabled="genderMobile == ''"
                    :class="[
                      'submit-select-gender-modal',
                      { activeSubmitGenderModal: genderMobile != '' }
                    ]"
                  >
                    تایید
                  </button>
                </div>
              </div> -->
            </div>

            <div class="name-div p-0">
              <label for="name">نام</label>
              <input
                v-model="updateUserInfo.name"
                id="name"
                type="text"
                name="name"
              />
            </div>

            <div class="family-div p-0">
              <label for="family">نام خانوادگی</label>
              <input
                v-model="updateUserInfo.family"
                id="family"
                type="text"
                name="name"
              />
            </div>

            <div class="phone-number p-0">
              <label for="phone-number">تلفن همراه</label>
              <input
                :value="updateUserInfo.phone_number"
                id="phone-number"
                type="text"
                disabled
              />
            </div>

            <div class="national-code p-0">
              <label for="national-code">کد ملی</label>
              <input
                v-model="updateUserInfo.national_code"
                type="text"
                id="national-code"
              />
            </div>

            <div class="birthDate p-0">
              <label for="birthDate">تاریخ تولد</label>

              <div class="birthDate-inputs" id="birthDate">
                <div
                  :class="[
                    'birthDate-day',
                    { activeBorderFocus: birthDateDayActiveFocus },
                  ]"
                >
                  <select
                    v-model="updateUserInfo.date"
                    @focus="birthDateDayActiveFocus = true"
                    @blur="birthDateDayActiveFocus = false"
                    id="birthDate-day"
                    name="birthDate-day"
                  >
                    <option
                      v-bind:key="day"
                      v-for="day in birthDate.day"
                      :value="day"
                    >
                      {{ day }}
                    </option>
                  </select>
                </div>

                <div
                  :class="[
                    'birthDate-month',
                    { activeBorderFocus: birthDateMonthActiveFocus },
                  ]"
                >
                  <select
                    v-model="updateUserInfo.month"
                    @focus="birthDateMonthActiveFocus = true"
                    @blur="birthDateMonthActiveFocus = false"
                    id="birthDate-month"
                    name="birthDate-month"
                  >
                    <option
                      v-for="(month, index) in birthDate.month"
                      :key="index"
                      :selected="month['number'] == updateUserInfo.month"
                      :value="month['number']"
                    >
                      {{ month["label"] }}
                    </option>
                  </select>
                </div>

                <div
                  :class="[
                    'birthDate-year',
                    { activeBorderFocus: birthDateYearActiveFocus },
                  ]"
                >
                  <select
                    v-model="updateUserInfo.year"
                    @focus="birthDateYearActiveFocus = true"
                    @blur="birthDateYearActiveFocus = false"
                    id="birthDate-year"
                    name="birthDate-year"
                  >
                    <option
                      v-bind:key="year"
                      v-for="year in birthDate.year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- mobile section -->
              <!--  <div
                @click="showModalBirthDateMobile"
                class="birthDate-inputs-mobile"
              >
                <span>{{ BirthDateMobileSelectedFinal }}</span>

                <img
                  src="../../assets/images/icons/profile-select-arrow-mobile.svg"
                  alt=""
                />
              </div> 

              <div
                @click.self="closeBirthDateModalMobile"
                id="birthDate-select-modal-mobile-background"
                class="birthDate-select-modal-mobile-background"
              >
                <div
                  @touchstart.self="closeBirthDateModalMobileTouchStart"
                  @touchmove.self="closeBirthDateModalMobileTouchMove"
                  @touchend.self="closeBirthDateModalMobileTouchEnd"
                  id="birthDate-select-modal-mobile-content"
                  :style="{ bottom: birthDateBottom }"
                  class="birthDate-select-modal-mobile-content"
                >
                  <div class="birthDate-select-modal-line"></div>

                  <p>انتخاب تاریخ تولد</p>

                  <div class="birthDate-select-modal-day-month-year-content">
                    <div
                      @touchstart="scrollDayMobileStart"
                      @touchmove="scrollDayMobileMove"
                      @touchend="scrollDayMobileEnd"
                      :style="{
                        transform: 'translateY(' + scrollDayTransform + 'px)'
                      }"
                      class="birthDate-select-modal-day"
                    >
                      <p
                        :class="{ todyStyles: id * -70 === scrollDayTransform }"
                        v-for="(day, id) in birthDate.day"
                        :key="id"
                      >
                        {{ day }}
                      </p>
                    </div>

                    <div
                      @touchstart="scrollMonthMobileStart"
                      @touchmove="scrollMonthMobileMove"
                      @touchend="scrollMonthMobileEnd"
                      :style="{
                        transform: 'translateY(' + scrollMonthTransform + 'px)'
                      }"
                      class="birthDate-select-modal-month"
                    >
                      <p
                        v-for="(month, im) in birthDate.month"
                        :class="{
                          todyStyles: im * -70 === scrollMonthTransform
                        }"
                        :key="im"
                      >
                        {{ month }}
                      </p>
                    </div>

                    <div
                      @touchstart="scrollYearMobileStart"
                      @touchmove="scrollYearMobileMove"
                      @touchend="scrollYearMobileEnd"
                      :style="{
                        transform: 'translateY(' + scrollYearTransform + 'px)'
                      }"
                      class="birthDate-select-modal-year"
                    >
                      <p
                        v-for="(year, iy) in birthDate.year"
                        :class="{
                          todyStyles: iy * -70 === scrollYearTransform
                        }"
                        :key="iy"
                      >
                        {{ year }}
                      </p>
                    </div>

                    <div class="selected-birthDate-div"></div>
                  </div>

                  <button
                    type="button"
                    @click="submitBirthDateModalMobile"
                    :disabled="
                      mobileDayBirthDateSelected == '' &&
                        mobileMonthBirthDateSelected == '' &&
                        mobileYearBirthDateSelected == ''
                    "
                    :class="[
                      'submit-select-birthDate-modal',
                      {
                        activeSubmitBirthDateModal:
                          mobileDayBirthDateSelected != '' &&
                          mobileMonthBirthDateSelected != '' &&
                          mobileYearBirthDateSelected != ''
                      }
                    ]"
                  >
                    تایید
                  </button>
                </div>
              </div>-->
            </div>

            <div class="email p-0">
              <label for="email">پست الکترونیکی</label>
              <input v-model="updateUserInfo.email" type="text" id="email" />
            </div>

            <div class="p-0 personal-info-buttons">
              <button @click.prevent="cancelEditPersonalInfo">لغو</button>
              <button @click.prevent="submitUpdateUser">ثبت ویرایش</button>
            </div>
          </form>
        </div>

        <!--  selected address info  -->

        <div
          v-show="!editSelectedAddressInfoShow"
          class="selected-address-info"
        >
          <div ref="selectedAddressInfo" id="selected-address-info"></div>
          <div class="personal-info-title">
            <span>اطلاعات آدرس</span>

            <!-- new changes -->
            <!-- v-if="screenWidth > 426" -->
            <img
              @click="editSelectedAddressInfo"
              src="../../assets/images/icons/edit-inputs-icon.svg"
              alt=""
            />
          </div>

          <div class="personal-info-details">
            <div class="personal-info-details-title">
              <p>عنوان</p>
              <p>استان</p>
              <p>شهر</p>
              <p>کدپستی</p>
              <p>تلفن ثابت</p>
              <p>شماره موبایل</p>
              <p>جزئیات آدرس</p>
            </div>

            <div class="personal-info-details-content">
              <p>{{ address.title }}</p>
              <p>{{ address.province }}</p>
              <p>{{ address.city.province }}</p>
              <p>{{ address.post_code }}</p>
              <p>{{ address.phone_number }}</p>
              <p>{{ address.mobile_number }}</p>
              <p>{{ address.address_details.slice(0, 30) + "..." }}</p>
            </div>
          </div>

          <!-- mobile section -->
          <!-- <div v-if="screenWidth < 426" class="edit-personal-info-mobile">
            <hr />

            <button @click="editSelectedAddressInfo">
              <img
                src="../../assets/images/icons/edit-user-account-info-mobile.svg"
                alt=""
              />
              ویرایش
            </button>
          </div> -->
        </div>

        <div
          v-show="editSelectedAddressInfoShow"
          class="edit-selected-address-info"
        >
          <form class="edit-selected-address-info-form col-12 p-0">
            <div class="col-6 p-0 selected-address-title">
              <label for="selected-address-title">عنوان</label>
              <input
                v-model="editSelectedAddressInputValue.title"
                name="selectedAddressTitle"
                id="selected-address-title"
                type="text"
              />
            </div>

            <div class="postal-code col-6 p-0">
              <label for="postal-code">کد پستی</label>
              <input
                @input="updateAddressError.post_code = []"
                :class="{
                  'error-border': updateAddressError.post_code.length > 0,
                }"
                v-model="editSelectedAddressInputValue.post_code"
                type="text"
                id="postal-code"
              />
              <span
                v-if="updateAddressError.post_code.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.post_code"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="selected-address-state col-6 p-0">
              <label for="selected-address-state">استان</label>

              <div
                :class="[
                  'selected-address-state-div',
                  { activeBorderFocus: selectedAddressStateFocusActive },
                  { 'error-border': updateAddressError.province_id.length > 0 },
                ]"
              >
                <!-- v-model="editSelectedAddressInputValue.province_id" -->
                <select
                  @input="provinceEvent"
                  @focus="selectedAddressStateFocusActive = true"
                  @blur="selectedAddressStateFocusActive = false"
                  id="selected-address-state"
                >
                  <option
                    v-if="provinces.length > 0"
                    v-for="(state, pi) in provinces"
                    :key="pi"
                    :selected="
                      editSelectedAddressInputValue.province_id == state.id
                    "
                    :value="state.id"
                  >
                    {{ state.province }}
                  </option>
                </select>
              </div>

              <span
                v-if="updateAddressError.province_id.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.province_id"
                :key="index"
              >
                {{ err }}
              </span>

              <!-- mobile section -->
              <!--  <div
                @click="showStateModalMobile"
                class="selected-address-state-div-mobile"
              >
                <span>{{ selectedStateMobileFinal }}</span>
                <img
                  src="../../assets/images/icons/profile-select-arrow-mobile.svg"
                  alt=""
                />
              </div>

              <div
                @click.self="closeStateModalMobile"
                id="state-select-modal-mobile-background"
                class="state-select-modal-mobile-background"
              >
                <div
                  @touchstart.self="closeStateModalMobileTouchStart"
                  @touchmove.self="closeStateModalMobileTouchMove"
                  @touchend.self="closeStateModalMobileTouchEnd"
                  :style="{ bottom: stateBottom }"
                  id="state-select-modal-mobile-content"
                  class="state-select-modal-mobile-content"
                >
                  <div class="state-select-modal-line"></div>

                  <p>انتخاب استان</p>

                  <div class="state-search-for-select-state-mobile-content">
                    <div
                      :class="[
                        'state-search-input-div',
                        {
                          'active-state-search-input-div':
                            stateMobileInputSearchValue != ''
                        }
                      ]"
                    >
                      <input
                        v-model="stateMobileInputSearchValue"
                        type="text"
                      />

                      <img
                        v-if="stateMobileInputSearchValue == ''"
                        src="../../assets/images/icons/search-icon-for-mobile.svg"
                        alt=""
                      />

                      <img
                        v-else
                        src="../../assets/images/icons/active-search-icon-input-mobile.svg"
                        alt=""
                      />
                    </div>

                    <div class="state-search-result-content">
                      <label
                        v-for="(state, s) in resultSearch"
                        :key="s"
                        :for="'state' + s"
                      >
                        <div
                          :class="[
                            'state-radio-out-circle',
                            { selectMobileState: stateMobileValue == state }
                          ]"
                        >
                          <div
                            :class="{
                              'state-radio-into-circle':
                                stateMobileValue == state
                            }"
                          ></div>
                        </div>

                        <input
                          style="display: none; outline: none"
                          v-model="stateMobileValue"
                          type="radio"
                          :value="state"
                          :id="'state' + s"
                        />

                        {{ state }}
                      </label>
                    </div>
                  </div>

                  <button
                    type="button"
                    @click="submitStateModalMobile"
                    :disabled="stateMobileValue == ''"
                    :class="[
                      'submit-select-state-modal',
                      { activeSubmitStateModal: stateMobileValue != '' }
                    ]"
                  >
                    تایید
                  </button>
                </div>
              </div>-->
            </div>

            <div class="selected-address-state col-6 p-0">
              <label for="selected-address-state">شهر</label>

              <div
                style="width: 100%"
                :class="[
                  'selected-address-state-div',
                  { activeBorderFocus: selectedAddressCityFocusActive },
                  { 'error-border': updateAddressError.city_id.length > 0 },
                ]"
              >
                <!-- v-model="editSelectedAddressInputValue.city_id" -->
                <select
                  @input="cityEvent"
                  @click.prevent="showEditCities"
                  @focus="selectedAddressCityFocusActive = true"
                  @blur="selectedAddressCityFocusActive = false"
                  id="selected-address-state"
                >
                  <option
                    v-if="cities.length > 0"
                    v-for="(city, ci) in cities"
                    :key="ci"
                    :selected="editSelectedAddressInputValue.city_id == city.id"
                    :value="city.id"
                  >
                    {{ city.province }}
                  </option>
                  <option v-else>خطا در شبکه</option>
                </select>
              </div>

              <span
                v-if="updateAddressError.city_id.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.city_id"
                :key="index"
                >{{ err }}</span
              >

              <!-- mobile section -->
              <!-- <div
                @click="showStateModalMobile"
                class="selected-address-state-div-mobile"
              >
                <span>{{ selectedStateMobileFinal }}</span>
                <img
                  src="../../assets/images/icons/profile-select-arrow-mobile.svg"
                  alt=""
                />
              </div>

              <div
                @click.self="closeStateModalMobile"
                id="state-select-modal-mobile-background"
                class="state-select-modal-mobile-background"
              >
                <div
                  @touchstart.self="closeStateModalMobileTouchStart"
                  @touchmove.self="closeStateModalMobileTouchMove"
                  @touchend.self="closeStateModalMobileTouchEnd"
                  :style="{ bottom: stateBottom }"
                  id="state-select-modal-mobile-content"
                  class="state-select-modal-mobile-content"
                >
                  <div class="state-select-modal-line"></div>

                  <p>انتخاب استان</p>

                  <div class="state-search-for-select-state-mobile-content">
                    <div
                      :class="[
                        'state-search-input-div',
                        {
                          'active-state-search-input-div':
                            stateMobileInputSearchValue != ''
                        }
                      ]"
                    >
                      <input
                        v-model="stateMobileInputSearchValue"
                        type="text"
                      />

                      <img
                        v-if="stateMobileInputSearchValue == ''"
                        src="../../assets/images/icons/search-icon-for-mobile.svg"
                        alt=""
                      />

                      <img
                        v-else
                        src="../../assets/images/icons/active-search-icon-input-mobile.svg"
                        alt=""
                      />
                    </div>

                    <div class="state-search-result-content">
                      <label
                        v-for="(state, s) in resultSearch"
                        :key="s"
                        :for="'state' + s"
                      >
                        <div
                          :class="[
                            'state-radio-out-circle',
                            { selectMobileState: stateMobileValue == state }
                          ]"
                        >
                          <div
                            :class="{
                              'state-radio-into-circle':
                                stateMobileValue == state
                            }"
                          ></div>
                        </div>

                        <input
                          style="display: none; outline: none"
                          v-model="stateMobileValue"
                          type="radio"
                          :value="state"
                          :id="'state' + s"
                        />

                        {{ state }}
                      </label>
                    </div>
                  </div>

                  <button
                    type="button"
                    @click="submitStateModalMobile"
                    :disabled="stateMobileValue == ''"
                    :class="[
                      'submit-select-state-modal',
                      { activeSubmitStateModal: stateMobileValue != '' }
                    ]"
                  >
                    تایید
                  </button>
                </div>
              </div>-->
            </div>

            <div class="selected-address-telephone col-6 p-0">
              <label for="selected-address-telephone">تلفن ثابت</label>
              <input
                placeholder="021-********"
                @input="updateAddressError.phone_number = []"
                :class="{
                  'error-border': updateAddressError.phone_number.length > 0,
                }"
                v-model="editSelectedAddressInputValue.phone_number"
                id="selected-address-telephone"
                type="text"
              />

              <span
                v-if="updateAddressError.phone_number.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.phone_number"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="selected-address-city col-6 p-0">
              <label for="selected-address-city">شماره موبایل</label>
              <input
                placeholder="0912-*******"
                @input="updateAddressError.mobile_number = []"
                :class="{
                  'error-border': updateAddressError.mobile_number.length > 0,
                }"
                v-model="editSelectedAddressInputValue.mobile_number"
                id="selected-address-city"
                type="number"
              />

              <span
                v-if="updateAddressError.mobile_number.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.mobile_number"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="selected-addresses-address col-12 p-0">
              <label for="selected-addresses-address">جزئیات آدرس</label>
              <textarea
                @input="updateAddressError.address_details = []"
                :class="{
                  'error-border': updateAddressError.address_details.length > 0,
                }"
                v-model="editSelectedAddressInputValue.address_details"
                id="selected-addresses-address"
                type="text"
              ></textarea>

              <span
                v-if="updateAddressError.address_details.length > 0"
                class="error-text"
                v-for="(err, index) in updateAddressError.address_details"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="selected-address-buttons">
              <button @click.prevent="cancelEditSelectedAddressInfo">
                لغو
              </button>
              <button
                v-if="'id' in address"
                @click.prevent="submitUpdateAddress(address)"
              >
                ثبت ویرایش
              </button>
              <button v-else @click.prevent="submitNewAddress(address)">
                ثبت آدرس
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="bank-account-and-security-info-row">
        <!--  bank account info  -->

        <div v-show="!editBankAccountInfoShow" class="bank-account-info">
          <div ref="bankAccountInfo" id="bank-account-info"></div>

          <div class="personal-info-title">
            <span>اطلاعات حساب</span>

            <!-- new changes -->
            <!-- v-if="screenWidth > 426" -->
            <img
              @click="editBankAccountInfo"
              src="../../assets/images/icons/edit-inputs-icon.svg"
              alt=""
            />
          </div>

          <div class="personal-info-details">
            <div class="personal-info-details-title">
              <p>شماره شبا</p>
              <p>شماره کارت</p>
            </div>

            <div class="personal-info-details-content">
              <p>{{ userInfo.shaba_number }}</p>
              <p>{{ userInfo.card_number }}</p>
            </div>
          </div>

          <!-- mobile section -->
          <!-- <div v-if="screenWidth < 426" class="edit-personal-info-mobile">
            <hr />
            <button @click="editBankAccountInfo">
              <img
                src="../../assets/images/icons/edit-user-account-info-mobile.svg"
                alt=""
              />
              ویرایش
            </button>
          </div> -->
        </div>

        <div v-show="editBankAccountInfoShow" class="edit-bank-account-info">
          <form class="edit-bank-account-info-form col-12 p-0">
            <div class="bank-account-info-number">
              <div :class="['bank-account-info-number-card', 'p-0']">
                <label for="number-card">شماره کارت</label>
                <input
                  :class="{
                    'error-border':
                      updateUserInfoError.payment_card_number.length > 0,
                  }"
                  @input="updateUserInfoError.payment_card_number = []"
                  placeholder="****-****-****-****"
                  v-model="updateUserInfo.payment_card_number"
                  type="text"
                  id="number-card"
                />

                <span
                  v-for="(
                    err, index
                  ) in updateUserInfoError.payment_card_number"
                  v-show="updateUserInfoError.payment_card_number.length > 0"
                  class="error-text"
                  :key="index"
                >
                  {{ err }}
                </span>
              </div>

              <div :class="['bank-account-info-shaba-number', 'p-0']">
                <label for="shaba-number">شماره شبا</label>

                <div
                  :class="[
                    'bank-account-shaba-number-input',
                    { borderActive: shabaFocus },

                    {
                      'error-border':
                        updateUserInfoError.payment_shaba_number.length > 0,
                    },
                  ]"
                  ref="shabaDiv"
                >
                  <span>IR</span>

                  <input
                    @input="updateUserInfoError.payment_shaba_number = []"
                    placeholder="****-****-****-****-****-****"
                    v-model="updateUserInfo.payment_shaba_number"
                    @focus="shabaFocus = true"
                    @blur="shabaFocus = false"
                    type="text"
                    ref="shabaInput"
                    id="shaba-number"
                  />
                </div>

                <span
                  v-for="(
                    err, index
                  ) in updateUserInfoError.payment_shaba_number"
                  v-show="updateUserInfoError.payment_shaba_number.length > 0"
                  class="error-text"
                  :key="index"
                >
                  {{ err }}
                </span>
              </div>
            </div>

            <div class="bank-account-info-buttons">
              <button @click.prevent="cancelEditBankAccount">لغو</button>
              <button @click.prevent="submitUpdateBankAccount">
                ثبت ویرایش
              </button>
            </div>
          </form>
        </div>

        <!--  personal-info-details-contentty  info  -->

        <!-- <router-view></router-view> -->
        <password-info />
      </div>
    </div>
  </user-account>
</template>

<script>
import UserAccount from "./UserAccount.vue";
import PasswordInfo from "./PasswordInfo.vue";

export default {
  components: { UserAccount, PasswordInfo },
  name: "UserAccountContent",

  data() {
    return {
      editPersonalInfoShow: false,

      editSelectedAddressInfoShow: false,

      editBankAccountInfoShow: false,

      shabaFocus: false,

      birthDate: {
        day: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
        month: [
          { label: "فروردین", number: 1 },
          { label: "اردیبهشت", number: 2 },
          { label: "خرداد", number: 3 },
          { label: "تیر", number: 4 },
          { label: "مرداد", number: 5 },
          { label: "شهریور", number: 6 },
          { label: "مهر", number: 7 },
          { label: "آبان", number: 8 },
          { label: "آذر", number: 9 },
          { label: "دی", number: 10 },
          { label: "بهمن", number: 11 },
          { label: "اسفند", number: 12 },
        ],
        year: [],
      },

      selectedAddress: {
        states: [
          "تهران",
          "خراسان",
          "اصفهان",
          "فارس",
          "کهکلویه و بویر احمد",
          "اذربایجان",
          "مازندران",
          "اذربایجان شرقی",
          "البرز",
        ],
      },

      updateUserInfo: {
        name: "",
        family: "",
        user_name: "",
        phone_number: "",
        email: "",
        gender: "",
        date: "",
        month: "",
        year: "",
        birth_date: "",
        national_code: "",
        payment_shaba_number: "",
        payment_card_number: "",
        subscribe_news_status: "",
      },

      updateUserInfoError: {
        payment_shaba_number: [],
        payment_card_number: [],
      },

      editSelectedAddressInputValue: {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      },

      //  edit address errors
      updateAddressError: {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      },

      genderActiveFocus: false,
      birthDateDayActiveFocus: false,
      birthDateMonthActiveFocus: false,
      birthDateYearActiveFocus: false,
      selectedAddressStateFocusActive: false,
      selectedAddressCityFocusActive: false,

      /* user account content data for mobile */
      screenWidth: null,
      genderMobile: "",
      genderMobileFinal: "مرد",
      genderTouchStart: null,
      changeTouchMove: null,
      genderBottom: null,

      /* BirthDate Modal */

      birthDateBottom: null,
      birthDateTouchStart: null,
      changeTouchMoveBirthDate: null,

      /* Day */
      scrollDayMobileTouchStart: null,
      scrollDayTransformStart: null,
      scrollDayTransform: 0,
      mobileDayBirthDateSelected: "",

      /* Month */
      scrollMonthMobileTouchStart: null,
      scrollMonthTransformStart: null,
      scrollMonthTransform: 0,
      mobileMonthBirthDateSelected: "",

      /* Year */
      scrollYearMobileTouchStart: null,
      scrollYearTransformStart: null,
      scrollYearTransform: 0,
      mobileYearBirthDateSelected: "",

      BirthDateMobileSelectedFinal: "تاریخ تولد خود را وارد کنید",

      /* state mobile modal data */
      stateBottom: "",
      stateMobileValue: "",
      stateMobileInputSearchValue: "",
      selectedStateMobileFinal: "تهران",
      stateMobileTouchStart: null,
      stateMobileTouchMove: null,
    };
  },

  created() {
    // method for sidebar of mobile responsive
    /* 
      if (window != undefined) {
        this.screenWidth = window.screen.width;
      } 
    */

    let app = this;

    for (let i = 1297; i <= 1420; i++) {
      app.birthDate.year.push(i);
    }

    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get userInfo
    this.$store.dispatch("userDispatch", { token: this.token });

    //  dispatch for get all province
    this.$store.dispatch("provincesDispatch");
  },

  mounted() {
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
  },

  methods: {
    // method for v-model of province input event
    provinceEvent(e) {
      this.updateAddressError.province_id = [];
      this.editSelectedAddressInputValue.province_id = e.target.value;
    },
    // method for v-model of city input event
    cityEvent(e) {
      this.updateAddressError.city_id = [];
      this.editSelectedAddressInputValue.city_id = e.target.value;
    },

    //  method for get cities
    showEditCities() {
      this.$store.dispatch("citiesDispatch", {
        province_id: this.editSelectedAddressInputValue.province_id,
      });
    },

    //  method for edit general info of user in profile
    editPersonalInfo() {
      const securityInfo = document.getElementById("security-info");
      this.editPersonalInfoShow = true;

      //  show hide layer
      this.$refs.selectedAddressInfo.style.display = "block";
      this.$refs.bankAccountInfo.style.display = "block";
      securityInfo.style.display = "block";

      this.$forceUpdate();

      this.updateUserInfo.name = this.userInfo.name;
      this.updateUserInfo.family = this.userInfo.family;
      this.updateUserInfo.user_name = this.userInfo.user_name;
      this.updateUserInfo.phone_number = this.userInfo.phone_number;
      this.updateUserInfo.email = this.userInfo.email;
      this.updateUserInfo.gender = this.userInfo.gender;
      this.updateUserInfo.date =
        this.userInfo.birth_date != null
          ? parseInt(this.userInfo.birth_date.split("-")[2])
          : "";
      this.updateUserInfo.month =
        this.userInfo.birth_date != null
          ? parseInt(this.userInfo.birth_date.split("-")[1])
          : "";
      this.updateUserInfo.year =
        this.userInfo.birth_date != null
          ? this.userInfo.birth_date.split("-")[0]
          : "";
      this.updateUserInfo.birth_date = this.userInfo.birth_date;
      this.updateUserInfo.national_code = this.userInfo.national_code;
      //  this.updateUserInfo.payment_shaba_number = this.userInfo.payment_shaba_number;
      //  this.updateUserInfo.payment_card_number = this.userInfo.payment_card_number;
      this.updateUserInfo.subscribe_news_status =
        this.userInfo.subscribe_news_status;
    },

    //  method for edit info of user in profile
    editSelectedAddressInfo() {
      const securityInfo = document.getElementById("security-info");
      this.editSelectedAddressInfoShow = true;

      this.$refs.personalInfo.style.display = "block";
      this.$refs.bankAccountInfo.style.display = "block";
      securityInfo.style.display = "block";

      this.editSelectedAddressInputValue.title = this.address.title;
      this.editSelectedAddressInputValue.city_id = this.address.city.id;
      this.editSelectedAddressInputValue.province_id =
        this.address.city.parent_id;
      this.editSelectedAddressInputValue.post_code = this.address.post_code;
      this.editSelectedAddressInputValue.phone_number =
        this.address.phone_number;
      this.editSelectedAddressInputValue.mobile_number =
        this.address.mobile_number;
      this.editSelectedAddressInputValue.address_details =
        this.address.address_details;
      this.editSelectedAddressInputValue.default = 0;

      //  dispatch get cities
      this.$store.dispatch("citiesDispatch", {
        province_id: this.address.city.parent_id,
      });
    },

    //  method for edit bank account info
    editBankAccountInfo() {
      const securityInfo = document.getElementById("security-info");
      this.editBankAccountInfoShow = true;

      this.$refs.personalInfo.style.display = "block";
      this.$refs.selectedAddressInfo.style.display = "block";
      securityInfo.style.display = "block";

      this.$forceUpdate();

      this.updateUserInfo.payment_shaba_number = this.userInfo.shaba_number;
      this.updateUserInfo.payment_card_number = this.userInfo.card_number;
      this.updateUserInfo.gender = this.userInfo.gender;
      this.updateUserInfo.subscribe_news_status =
        this.userInfo.subscribe_news_status;
    },

    //  method for cancel update general info of user
    cancelEditPersonalInfo() {
      const securityInfo = document.getElementById("security-info");
      this.editPersonalInfoShow = false;

      this.$refs.selectedAddressInfo.style.display = "none";
      this.$refs.bankAccountInfo.style.display = "none";
      securityInfo.style.display = "none";

      this.updateUserInfo = {
        name: "",
        family: "",
        user_name: "",
        phone_number: "",
        email: "",
        gender: "",
        date: "",
        month: "",
        year: "",
        birth_date: "",
        national_code: "",
        payment_shaba_number: "",
        payment_card_number: "",
        subscribe_news_status: "",
      };
    },

    //  method for cancel eit address
    cancelEditSelectedAddressInfo() {
      const securityInfo = document.getElementById("security-info");
      this.editSelectedAddressInfoShow = false;

      this.$refs.personalInfo.style.display = "none";
      this.$refs.bankAccountInfo.style.display = "none";
      securityInfo.style.display = "none";

      this.editSelectedAddressInputValue = {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      };

      //  edit address errors
      this.updateAddressError = {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      };
    },

    //      method for cancel edit bank account
    cancelEditBankAccount() {
      const securityInfo = document.getElementById("security-info");
      this.editBankAccountInfoShow = false;

      this.$refs.personalInfo.style.display = "none";
      this.$refs.selectedAddressInfo.style.display = "none";
      securityInfo.style.display = "none";

      this.updateUserInfo.payment_shaba_number = "";
      this.updateUserInfo.payment_card_number = "";

      this.updateUserInfoError.payment_card_number = [];
      this.updateUserInfoError.payment_shaba_number = [];
    },

    //  method for submit update user
    submitUpdateUser() {
      const securityInfo = document.getElementById("security-info");
      //  add token
      this.updateUserInfo.token = this.token;

      this.updateUserInfo.birth_date = `${this.updateUserInfo.year}-${this.updateUserInfo.month}-${this.updateUserInfo.date}`;

      this.$store
        .dispatch("updateUserDispatch", this.updateUserInfo)
        .then((res) => {
          //  success alert
          if (res.status) {
            //  dispatch for get userInfo
            this.$store.dispatch("userDispatch", { token: this.token });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.editPersonalInfoShow = false;

            this.$refs.selectedAddressInfo.style.display = "none";
            this.$refs.bankAccountInfo.style.display = "none";
            securityInfo.style.display = "none";

            this.updateUserInfo = {
              name: "",
              family: "",
              user_name: "",
              phone_number: "",
              email: "",
              gender: "",
              date: "",
              month: "",
              year: "",
              birth_date: "",
              national_code: "",
              payment_shaba_number: "",
              payment_card_number: "",
              subscribe_news_status: "",
            };
          } else {
            //  warning alert dispatch
            this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    //  method for update address
    submitUpdateAddress(item) {
      const securityInfo = document.getElementById("security-info");
      //  add token
      this.editSelectedAddressInputValue.token = this.token;

      //  address_id
      this.editSelectedAddressInputValue.address_id = item.id;

      // update address dispatch

      this.$store
        .dispatch("updateAddressDispatch", this.editSelectedAddressInputValue)
        .then((res) => {
          if (res.status) {
            //  dispatch for get userInfo
            this.$store.dispatch("userDispatch", { token: this.token });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.editSelectedAddressInfoShow = false;

            this.$refs.personalInfo.style.display = "none";
            this.$refs.bankAccountInfo.style.display = "none";
            securityInfo.style.display = "none";

            this.editSelectedAddressInputValue = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };

            //  edit address errors
            this.updateAddressError = {
              province_id: [],
              address_details: [],
              mobile_number: [],
              phone_number: [],
              post_code: [],
              city_id: [],
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.province_id =
                    res.message.province_id)
                : this.updateAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.address_details =
                    res.message.address_details)
                : this.updateAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.mobile_number =
                    res.message.mobile_number)
                : this.updateAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.phone_number =
                    res.message.phone_number)
                : this.updateAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.post_code = res.message.post_code)
                : this.updateAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.city_id = res.message.city_id)
                : this.updateAddressError.city_id.push(res.message);
            }
          }
        });
    },

    //  method for submit new address
    submitNewAddress() {
      const securityInfo = document.getElementById("security-info");

      //  add token
      this.editSelectedAddressInputValue.token = this.token;
      //  dispatch for new address
      this.$store
        .dispatch("newAddressDispatch", this.editSelectedAddressInputValue)
        .then((res) => {
          if (res.status) {
            //  dispatch for get userInfo
            this.$store.dispatch("userDispatch", { token: this.token });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.editSelectedAddressInfoShow = false;

            this.$refs.personalInfo.style.display = "none";
            this.$refs.bankAccountInfo.style.display = "none";
            securityInfo.style.display = "none";

            this.editSelectedAddressInputValue = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };

            //  edit address errors
            this.updateAddressError = {
              province_id: [],
              address_details: [],
              mobile_number: [],
              phone_number: [],
              post_code: [],
              city_id: [],
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.province_id =
                    res.message.province_id)
                : this.updateAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.address_details =
                    res.message.address_details)
                : this.updateAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.mobile_number =
                    res.message.mobile_number)
                : this.updateAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.phone_number =
                    res.message.phone_number)
                : this.updateAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.post_code = res.message.post_code)
                : this.updateAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.city_id = res.message.city_id)
                : this.updateAddressError.city_id.push(res.message);
            }
          }
        });
    },

    //  method for update account info
    submitUpdateBankAccount() {
      const securityInfo = document.getElementById("security-info");
      //  add token
      this.updateUserInfo.token = this.token;

      this.$store
        .dispatch("updateUserDispatch", this.updateUserInfo)
        .then((res) => {
          //  success alert
          if (res.status) {
            //  dispatch for get userInfo
            this.$store.dispatch("userDispatch", { token: this.token });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            this.editBankAccountInfoShow = false;

            this.$refs.personalInfo.style.display = "none";
            this.$refs.selectedAddressInfo.style.display = "none";
            securityInfo.style.display = "none";

            updateUserInfo = {
              name: "",
              family: "",
              user_name: "",
              phone_number: "",
              email: "",
              gender: "",
              date: "",
              month: "",
              year: "",
              birth_date: "",
              national_code: "",
              payment_shaba_number: "",
              payment_card_number: "",
              subscribe_news_status: "",
            };
          } else {
            if ("payment_shaba_number" in res.message) {
              typeof res.message != String
                ? (this.updateUserInfoError.payment_shaba_number =
                    res.message.payment_shaba_number)
                : this.updateUserInfoError.payment_shaba_number.push(
                    res.message
                  );
            }

            if ("payment_card_number" in res.message) {
              typeof res.message != String
                ? (this.updateUserInfoError.payment_card_number =
                    res.message.payment_card_number)
                : this.updateUserInfoError.payment_card_number.push(
                    res.message
                  );
            }
            //  warning alert dispatch
            /* this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message,
            }); */
          }
        });
    },

    /* mobile methods */

    backToUserAccountContentPage() {
      /* get user account mobile */
      const userAccountContentMobile = document.getElementById(
        "user-account-content"
      );

      userAccountContentMobile.classList.add("hideContent");

      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      header.style.display = "flex";
      userAccountSliderMobile.style.display = "flex";

      let resolved = this.$router.resolve({
        name: "userAccount",
      });

      location.href = resolved.href;
    },

    /* show gender modal for mobile */

    showModalForSelectGenderMobile() {
      document.documentElement.style.overflow = "hidden";

      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "gender-select-modal-mobile-background"
      );
      background.style.height = "100%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "gender-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "100%";

      this.genderBottom = "0px";
    },

    closeGenderModalMobile() {
      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "gender-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "gender-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      document.documentElement.style.overflow = "auto";
    },

    submitGenderModalMobile() {
      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "gender-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "gender-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      document.documentElement.style.overflow = "auto";

      this.genderMobileFinal = this.genderMobile;

      if (this.genderMobileFinal == "man-mobile") {
        this.genderMobileFinal = "مرد";
      } else if (this.genderMobileFinal == "woman-mobile") {
        this.genderMobileFinal = "زن";
      }
    },

    /* Touch Start , Move , End Gender Modal Methods */

    closeGenderModalTouchStart(ev) {
      this.genderTouchStart = ev.touches[0].clientY;
    },

    closeGenderModalTouchMove(ev) {
      let moveTouch = ev.touches[0].clientY;
      this.changeTouchMove = this.genderTouchStart - moveTouch;

      if (this.changeTouchMove < 0) {
        this.genderBottom = this.changeTouchMove + "px";
      }
    },

    // method for mobile section
    closeGenderModalTouchEnd() {
      if (this.changeTouchMove < 0 && this.changeTouchMove < -80) {
        this.genderBottom = -this.screenWidth;

        /* get gender-select-modal-mobile-background  */

        const background = document.getElementById(
          "gender-select-modal-mobile-background"
        );
        background.style.height = "0%";

        /* get gender-select-modal-mobile-content */
        const modalContent = document.getElementById(
          "gender-select-modal-mobile-content"
        );
        modalContent.style.maxHeight = "0px";

        document.documentElement.style.overflow = "auto";
      }
    },

    /* birthDate Mobile modal methods */

    showModalBirthDateMobile() {
      document.documentElement.style.overflow = "hidden";

      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "birthDate-select-modal-mobile-background"
      );
      background.style.height = "100%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "birthDate-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "100%";

      this.birthDateBottom = "0px";
    },

    // method for mobile section
    closeBirthDateModalMobile() {
      document.documentElement.style.overflow = "auto";

      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "birthDate-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "birthDate-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      this.birthDateBottom = -1 * this.screenWidth + "px";
    },

    // method for mobile section
    closeBirthDateModalMobileTouchStart(e) {
      this.birthDateTouchStart = e.touches[0].clientY;
    },

    // method for mobile section
    closeBirthDateModalMobileTouchMove(e) {
      let moveTouch = e.touches[0].clientY;
      this.changeTouchMoveBirthDate = this.birthDateTouchStart - moveTouch;

      if (this.changeTouchMoveBirthDate < 0) {
        this.birthDateBottom = this.changeTouchMoveBirthDate + "px";
      }
    },

    // method for mobile section
    closeBirthDateModalMobileTouchEnd() {
      if (
        this.changeTouchMoveBirthDate < 0 &&
        this.changeTouchMoveBirthDate < -80
      ) {
        this.birthDateBottom = -this.screenWidth;

        /* get gender-select-modal-mobile-background  */

        const background = document.getElementById(
          "birthDate-select-modal-mobile-background"
        );
        background.style.height = "0%";

        /* get gender-select-modal-mobile-content */
        const modalContent = document.getElementById(
          "birthDate-select-modal-mobile-content"
        );
        modalContent.style.maxHeight = "0px";

        document.documentElement.style.overflow = "auto";
      }
    },

    // method for mobile section
    submitBirthDateModalMobile() {
      this.BirthDateMobileSelectedFinal =
        this.mobileYearBirthDateSelected +
        "/" +
        this.mobileMonthBirthDateSelected +
        "/" +
        this.mobileDayBirthDateSelected;

      document.documentElement.style.overflow = "auto";

      /* get gender-select-modal-mobile-background  */

      const background = document.getElementById(
        "birthDate-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get gender-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "birthDate-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      this.birthDateBottom = -1 * this.screenWidth + "px";
    },

    /* scroll birthDate Day mobile */
    scrollDayMobileStart(e) {
      this.scrollDayMobileTouchStart = e.touches[0].clientY;

      this.scrollDayTransformStart = this.scrollDayTransform;
    },

    // method for mobile section
    scrollDayMobileMove(e) {
      let change = e.touches[0].clientY - this.scrollDayMobileTouchStart;

      let floorChangeMax = Math.floor(change / 20);

      if (change > 0 && this.scrollDayTransform < 0) {
        this.scrollDayTransform =
          this.scrollDayTransformStart + floorChangeMax * 70;
      } else if (change < 0 && this.scrollDayTransform > -2100) {
        this.scrollDayTransform =
          this.scrollDayTransformStart + floorChangeMax * 70;
      }
    },

    // method for mobile section
    scrollDayMobileEnd() {
      const result = this.scrollDayTransform / 70;

      this.mobileDayBirthDateSelected = result * -1 + 1;
    },

    /* scroll birthDate Month mobile */
    scrollMonthMobileStart(e) {
      this.scrollMonthMobileTouchStart = e.touches[0].clientY;

      this.scrollMonthTransformStart = this.scrollMonthTransform;
    },

    // method for mobile section
    scrollMonthMobileMove(e) {
      let change = e.touches[0].clientY - this.scrollMonthMobileTouchStart;

      let floorChangeMax = Math.floor(change / 20);

      if (change < 0 && this.scrollMonthTransform > -770) {
        this.scrollMonthTransform =
          this.scrollMonthTransformStart + floorChangeMax * 70;
      } else if (change > 0 && this.scrollMonthTransform < 0) {
        this.scrollMonthTransform =
          this.scrollMonthTransformStart + floorChangeMax * 70;
      }
    },

    // method for mobile section
    scrollMonthMobileEnd() {
      let resultIndex = (this.scrollMonthTransform / 70) * -1;

      this.birthDate.month.forEach((month, m) => {
        if (resultIndex === m) {
          this.mobileMonthBirthDateSelected = m + 1;
        }
      });
    },

    /* scroll birthDate Year mobile */

    scrollYearMobileStart(e) {
      this.scrollYearMobileTouchStart = e.touches[0].clientY;

      this.scrollYearTransformStart = this.scrollYearTransform;
    },

    // method for mobile section
    scrollYearMobileMove(e) {
      let change = e.touches[0].clientY - this.scrollYearMobileTouchStart;
      let floorChangeMax = Math.floor(change / 20);

      if (change < 0 && this.scrollYearTransform > -7210) {
        this.scrollYearTransform =
          this.scrollYearTransformStart + floorChangeMax * 70;
      } else if (change > 0 && this.scrollYearTransform < 0) {
        this.scrollYearTransform =
          this.scrollYearTransformStart + floorChangeMax * 70;
      }
    },

    // method for mobile section
    scrollYearMobileEnd() {
      let resultIndex = (this.scrollYearTransform / 70) * -1;

      this.birthDate.year.forEach((year, y) => {
        if (resultIndex === y) {
          this.mobileYearBirthDateSelected = year;
        }
      });
    },

    /* state modal methods */

    // method for mobile section
    showStateModalMobile() {
      document.documentElement.style.overflow = "hidden";

      /* get state-select-modal-mobile-background  */

      const background = document.getElementById(
        "state-select-modal-mobile-background"
      );
      background.style.height = "100%";

      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "100%";

      this.stateBottom = "0px";
    },

    // method for mobile section
    closeStateModalMobile() {
      document.documentElement.style.overflow = "auto";

      /* get state-select-modal-mobile-background  */

      const background = document.getElementById(
        "state-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      this.stateMobileInputSearchValue = "";
      this.stateMobileValue = "";
    },

    // method for mobile section
    closeStateModalMobileTouchStart(e) {
      this.stateMobileTouchStart = e.touches[0].clientY;
    },

    // method for mobile section
    closeStateModalMobileTouchMove(e) {
      let moveTouch = e.touches[0].clientY;
      this.stateMobileTouchMove = this.stateMobileTouchStart - moveTouch;

      if (this.stateMobileTouchMove < 0) {
        this.stateBottom = this.stateMobileTouchMove + "px";
      }
    },

    // method for mobile section

    closeStateModalMobileTouchEnd() {
      if (this.stateMobileTouchMove < 0 && this.stateMobileTouchMove < -80) {
        this.stateBottom = -this.screenWidth;

        /* get gender-select-modal-mobile-background  */

        const background = document.getElementById(
          "state-select-modal-mobile-background"
        );
        background.style.height = "0%";

        /* get gender-select-modal-mobile-content */
        const modalContent = document.getElementById(
          "state-select-modal-mobile-content"
        );
        modalContent.style.maxHeight = "0px";

        document.documentElement.style.overflow = "auto";
      }
    },

    // method for mobile section

    submitStateModalMobile() {
      this.selectedStateMobileFinal = this.stateMobileValue;

      document.documentElement.style.overflow = "auto";

      /* get state-select-modal-mobile-background  */

      const background = document.getElementById(
        "state-select-modal-mobile-background"
      );
      background.style.height = "0%";

      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content"
      );
      modalContent.style.maxHeight = "0px";

      this.stateMobileInputSearchValue = "";
      this.stateMobileValue = "";
    },
  },

  props: ["showUserAccountContentMobile"],

  computed: {
    resultSearch() {
      if (this.stateMobileInputSearchValue === "") {
        return null;
      } else {
        return this.selectedAddress.states.filter((state) => {
          return state.match(this.stateMobileInputSearchValue);
        });
      }
    },

    //  method for get token
    token() {
      return this.$store.getters.getToken;
    },

    //  method for get user info
    userInfo() {
      if (this.$store.getters.getUserInfo != null) {
        return this.$store.getters.getUserInfo;
      } else {
        return {
          full_name: "",
          gender: 1,
          birth_date: "",
          national_code: "",
          phone_number: "",
          email: "",
        };
      }
    },

    //  method for get address of user
    address() {
      if (
        this.$store.getters.getUserInfo != null &&
        this.$store.getters.getUserInfo.address.length > 0
      ) {
        return this.$store.getters.getUserInfo.address[0];
      } else {
        return {
          title: "",
          province: "",
          city: { province: "" },
          post_code: "",
          phone_number: "",
          mobile_number: "",
          address_details: "",
        };
      }
    },

    //  method for get provinces
    provinces() {
      if (this.$store.getters.getProvinces != null) {
        return this.$store.getters.getProvinces;
      }
    },

    //  method for get cities
    cities() {
      if (this.$store.getters.getCities != null) {
        return this.$store.getters.getCities.cities;
      }
    },
  },
};
</script>

<style src="../../assets/css/userAccount/userAccountContent.css" scoped></style>
