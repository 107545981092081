<template>
  <div :class="['footer', { mobileMB: activeMarginFooter }]">
    <div class="footer-top">
      <div class="footer-top-top">
        <div class="store-name">
          <h6>{{ store.store_name }}</h6>
          <ul>
            <!-- <li>
                            <a href="#">وبلاگ</a>
                        </li> -->

            <li>
              <a href="/about-us">درباره ما</a>
            </li>

            <li>
              <a href="/contact-us">تماس با ما</a>
            </li>

            <li>
              <a href="/privacy" tag="li">حریم خصوصی</a>
            </li>

            <li>
              <a href="/return-policy" tag="li">شرایط بازگشت کالا</a>
            </li>
            <li>
              <a href="/terms-us" tag="li"
                >شرایط استفاده از {{ store.store_name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="product-store">
          <h6>محصولات {{ store.store_name }}</h6>
          <ul>
            <li
              @click="goToSearchPage(category.id)"
              v-for="(category, index) in categories"
              :key="index"
              @click.prevent=""
            >
              {{ category.title }}
            </li>
          </ul>
        </div>
        <div class="shopping-guide">
          <h6>راهنمای خرید</h6>
          <ul>
            <li>نحوه ثبت سفارش</li>
            <li>رویه ارسال سفارش</li>
            <li>شیوه‌های پرداخت</li>
          </ul>
        </div>
        <div class="about-us">
          <h6>فروشگاه {{ store.store_name }}</h6>
          <p>
            {{ store.store_short_des }}
          </p>
        </div>
      </div>
      <div class="footer-top-center">
        <div class="footer-top-center-right">
          <div class="footer-phoneNumber">
            <img src="../../assets/images/icons/footerPhone.png" alt="" />
            <span>{{ store.phone_store }}</span>
          </div>

          <div v-if="store.store_email != null" class="footer-email">
            <img src="../../assets/images/icons/footerEmail.png" alt="" />
            <span>{{ store.store_email }}</span>
          </div>
        </div>

        <!-- commented beacuse haven't api -->
        <div class="footer-top-center-left">
          <input
            type="number"
            placeholder="برای مطلع شدن از آخرین تخفیفات شماره موبایل خود را وارد کنید"
          />
          <button>عضویت</button>
        </div>
      </div>
      <hr class="footer-hr" />
      <div class="footer-top-bottom">
        <div class="footer-top-bottom-right">
          <h6>بازارچه اینترنتی {{ store.store_name }}</h6>
          <p>
            {{ store.about_us_short_des }}
          </p>
        </div>
        <div class="footer-top-bottom-left">
          <!-- commented for E Namad -->

          <img src="../../assets/images/pictures/zarinPall.png" alt="" />
          <img src="../../assets/images/pictures/webParty.png" alt="" />
          <img src="../../assets/images/pictures/samaneE.png" alt="" />
          <img src="../../assets/images/pictures/namadE.png" alt="" />
        </div>
      </div>
    </div>

    <!-- mobile section  (commented beacues haven't api) -->
    <div class="discount-mobile">
      <p>برای مطلع شدن از آخرین تخفیفات شماره موبایل خود را وارد کنید</p>
      <div class="discount-input-mobile">
        <input type="text" />
        <button>عضویت</button>
      </div>
    </div>

    <!-- mobile size section -->
    <div class="footer-top-mobile">
      <div class="footer-top-top-mobile">
        <span>{{ store.store_name }}</span>
        <div class="mobile-list">
          <div class="mobile-list-right">
            <span @click="goToAboutUsMobile">درباره ما</span>
            <span @click="goToContactToUsMobile">تماس با ما</span>
            <span @click="goToQuestionMobile">پرسش و پاسخ</span>
          </div>
          <div class="mobile-list-left">
            <span @click="goToTermsOfReturnProductMobile"
              >شرایط بازگشت کالا</span
            >
            <span @click="goToPrivacyMobile">حریم خصوصی</span>
            <span @click="goToTermsOfUsMobile"
              >شرایط استفاده از {{ store.store_name }}</span
            >
          </div>
        </div>
      </div>

      <!-- commented for E Namad -->

      <div class="footer-top-center-mobile">
        <img src="../../assets/images/pictures/socialMedia.png" alt="" />
      </div>

      <div class="footer-top-bottom-mobile">
        <span>بازارچه اینترنی {{ store.store_name }}</span>
        <div class="collapse-mobile">
          <span
            v-bind:class="{ setShowMore: showMore }"
            id="mobile-show-more-txt"
          >
            {{ store.about_us_short_des }}
          </span>

          <button
            v-if="!showMore"
            @click="footerShowMoreMobile(showMore)"
            class="footer-show-more-mobile"
          >
            <span> نمایش بیشتر </span>
            <font-awesome-icon
              icon="fa-chevron-down"
              class="fa fa-chevron-down"
            ></font-awesome-icon>
          </button>

          <button
            v-else
            @click="footerShowMoreMobile(showMore)"
            class="footer-show-more-mobile"
          >
            <span> نمایش کمتر </span>
            <font-awesome-icon
              icon="fa-chevron-up"
              class="fa"
            ></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-social-media">
        <img
          v-if="linkdin != null"
          @click="goToLinkdin"
          src="../../assets/images/icons/linkedIn.png"
          alt=""
        />
        <img
          v-if="instagram != null"
          @click="goToInstagram"
          class="mr-5"
          src="../../assets/images/icons/instagram.png"
          alt=""
        />
        <img
          v-if="whatsapp"
          @click="goToWhatsapp"
          class="mr-5"
          src="../../assets/images/icons/whatsApp.png"
          alt=""
        />
        <img
          v-if="aparat != null"
          @click="goToAparat"
          class="mr-5"
          src="../../assets/images/icons/aparat.png"
          alt=""
        />
        <img
          v-if="telegram != null"
          @click="goToTelegram"
          class="mr-5"
          src="../../assets/images/icons/telegram.png"
          alt=""
        />
      </div>
      <div class="footer-bottom-description">
        کليه حقوق اين سايت متعلق به فروشگاه ({{ store.store_name }}) می‌باشد
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {
      showMore: false,
      activeMarginFooter: false,

      screenWidth: null,
    };
  },

  methods: {
    footerShowMoreMobile(showMore) {
      if (!showMore) {
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },

    /* go to about us page mobile */
    goToAboutUsMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "aboutUs",
        });

        location.href = resolved.href;
      }
    },

    /* go to contact to us page mobile */

    goToContactToUsMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "contactToUs",
        });

        location.href = resolved.href;
      }
    },

    /* go to question page mobile */
    goToQuestionMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "questions",
        });

        location.href = resolved.href;
      }
    },

    /* go to terms of return page mobile */
    goToTermsOfReturnProductMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "returnPolicy",
        });

        location.href = resolved.href;
      }
    },

    /* go to privacy page mobile */
    goToPrivacyMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "privacy",
        });

        location.href = resolved.href;
      }
    },

    /* go to terms of us page mobile */
    goToTermsOfUsMobile() {
      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        header.style.display = "none";

        let resolved = this.$router.resolve({
          name: "termsOfUs",
        });

        location.href = resolved.href;
      }
    },

    /* go to contact to us page mobile */

    //  method for go to telegram
    goToTelegram() {
      window.open(`https://${this.telegram.value}`);
    },
    goToWhatsapp() {
      window.open(`https://${this.whatsapp.value}`);
    },
    goToAparat() {
      window.open(`https://${this.aparat.value}`);
    },
    goToInstagram() {
      window.open(`https://${this.instagram.value}`);
    },
    goToLinkdin() {
      window.open(`https://${this.linkdin.value}`);
    },

    //  method for go to search page
    // method for go to search page
    goToSearchPage(category_id) {
      let resolved = this.$router.resolve({
        name: "search",
        query: { productCategories: JSON.stringify([category_id]) },
      });

      location.href = resolved.href;
    },
  },

  computed: {
    //  method for get store info
    store() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo;
      } else {
        return {
          store_name: "",
          store_slug: "",
          about_us_short_des: "",
          province: "",
          city: "",
          phone_store: "",
          stars_average: "",
          stars_count: 0,
          product_sale_category: "",
          product_sale_category_fa: "",
          store_short_des: "",
          storeIcon: "",
          store_email: "",
        };
      }
    },

    categories() {
      if (this.$store.getters.getHeaderCategories != null) {
        return this.$store.getters.getHeaderCategories;
      }
    },

    telegram() {
      if (this.$store.getters.getStoreContactUs != null) {
        return this.$store.getters.getStoreContactUs.find((item) => {
          return item.key == "telegram";
        });
      }
    },

    whatsapp() {
      if (this.$store.getters.getStoreContactUs != null) {
        return this.$store.getters.getStoreContactUs.find((item) => {
          return item.key == "whatsapp";
        });
      }
    },
    aparat() {
      if (this.$store.getters.getStoreContactUs != null) {
        return this.$store.getters.getStoreContactUs.find((item) => {
          return item.key == "aparat";
        });
      }
    },
    instagram() {
      if (this.$store.getters.getStoreContactUs != null) {
        return this.$store.getters.getStoreContactUs.find((item) => {
          return item.key == "instagram";
        });
      }
    },
    linkdin() {
      if (this.$store.getters.getStoreContactUs != null) {
        return this.$store.getters.getStoreContactUs.find((item) => {
          return item.key == "linkdin";
        });
      }
    },
  },

  mounted() {
    // get screen width for mobile size
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
    if (
      window != undefined &&
      window.innerWidth < 500 &&
      this.$route.name == "product"
    ) {
      this.activeMarginFooter = true;
    }
  },

  created() {
    // if (
    //   window != undefined &&
    //   window.innerWidth < 500 &&
    //   this.$route.name == "product"
    // ) {
    //   this.activeMarginFooter = true;
    // }
    //  action for get store info
    this.$store.dispatch("storeInfoDispatch");

    // action for get header Categories
    this.$store.dispatch("headerCategoriesDispatch");

    // action for get store contact to us info
    this.$store.dispatch("storeContactUsDispatch");
  },
};
</script>

<style src="../../assets/css/Footer/Footer.css" scoped></style>
