<template>
  <div id="privacy-parent">
    <!-- for mobile size secton -->
    <!-- <div class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage"
                 src="../../assets/images/icons/back-arrow-mobile3.svg"
                 alt="">
            <p>حریم خصوصی</p>
        </div> -->

    <div class="privacy-page">
      <app-header id="app-header-mobile"></app-header>

      <div class="privacy-page-content">
        <h5>قوانین و شرایط لبنید</h5>

        <div class="privacy-page-back-product">
          <div class="privacy-page-back-product-content">
            <ul class="description-ul mt-0">
              <li class="description-li-top">
                کاربر عزیز لبنید، سلام ! وقتی تعداد آدم ها از یک نفر بیشتر شود
                نیاز به قانون و قوانین حس می شود تا هر فرد با حرکت در مسیر آن حق
                و حقوقی را از فرد دیگری ضایع نکند و نیز حقوق خود نیز پایمال نشود
                به همین منظور از شما دعوت می کنیم که با خواندن این چند سطر از
                قوانین و شرایط استفاده از خدمات لبنید مطلع شوید. البته تا فراموش
                نشده اشاره کنیم که نخواندن این قوانین و عدم اطلاع از موارد آن به
                منزله نپذ یرفتن این مطالب نیست و مادامی که شما در این فضا در حال
                گشت و گذار هستید گویی تمامی این قوانین و شرایط را پذیرفته ا ید
                تا اگر زمانی هر گونه اختالفی میان شما و لبنید ایجاد شد با رجوع
                به این موارد و بند های پیش رو در جهت رفع اختلاف قدم برداریم.
                تمامی قوانینی که در ادامه با آن آشنا می شو یم منطبق بر قوانین
                جاری حاکم بر کشور (قوانین جمهوری اسلامی ایران، قانون تجارت
                الکترونیک و قانون حمایت از حقوق مصرف کننده )می باشد . البته جا
                دارد اشاره کنیم که تمامی قوانین و شرایط استفاده از خدمات لبنید
                به اقتضا زمان مرتبا مورد بازنگری قرار می گیرد، پس فراموش نکنید
                در فواصل زمانی به این صفحه مراجعه کنید تا از افزوده شدن قوانین
                جدید و یا تغییر قوانین پیشین مطلع شوید. لبنید به منظور رفع نیاز
                جامعه ی هدف خود بر دو حیطه ی (فروشگاهی) و (آگهی) متمرکز گشته است
                که به طبع آن هر یک از این دو، قوانین و شرایط استفاده خود را دارد
                که پیشتر به صورت مجزا به آن اشاره می شود.
              </li>
            </ul>
          </div>

          <br />
          <br />

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>قوانین و شرایط مشترک هر دو حیطه (تعاریف)</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center">
                لبنید: پلتفرمی آنلاین سایت و اپلیکیشن به منظور آسان سازی
                ارتباطات جامعه آزمایشگاهی از قبیل خرید و فروش کالا ها و خدمات و
                ... شرکت: شرکت سیمرغ کالا یاب به شماره ثبت 609974 میباشد.
              </li>
              <li class="description-li-center">
                سایت: وبسایت رسمی لبنید با دامنٔه www.labniid.com است. تمام
                امتیازات این وبسایت در مالکیت شرکت است. کاربر: تمامی افراد حقیقی
                و یا حقوقی در قالب بازدید کننده سایت و یا عضو سایت که در نهایت
                از هر یک از خدمات پلتفرم لبنید استفاده می کنند. فروشندگان: تمامی
                افراد حقیقی یا حقوقی که بواسطه پلتفرم لبنید اجناس و یا خدمات خود
                را به دیگر کاربران عرضه می کنند.
              </li>

              <br />
              <br />
            </ul>
          </div>

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>شرایط عمومی استفاده از لَبنید</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center mt-0">
                شرایط الزامی استفاده از لبنید :
              </li>
              <li class="description-li-top">داشتن حداقل ۸ سال تمام</li>
              <li class="description-li-top">
                در حال حاضر کاربران محترم لبنید می بایست از اشخاص مرتبط با
                آزمایشگاه بوده و یا فعالیت آن ها به نوعی مرتبط با آزمایشگاه ها
                باشد.
              </li>
              <li class="description-li-top">
                داشتن شماره تلفن همراه فعال که کد تأیید به آن شماره ارسال شود.
              </li>
              <li class="description-li-top">
                فعالیت کاربر باید مغایر با قوانین، آیین نامه ها و دستورالعمل های
                جاری جمهوری اسلامی ایران و نیزدر راستای شرایط و قوانین مندرج در
                لبنید باشد .
              </li>
              <li class="description-li-top">
                صحت و مسئولیت تمام اطلاعات ثبت شده از سوی کاربران در تمامی بخش
                های لبنید بر عهده کاربر مورد نظر بوده و لبنید هیچگونه دخل و
                تصرفی در آن ندارد.
              </li>
              <li class="description-li-top">
                تا جای امکان پیشنهاد می گردد محتواهای ثبت شده در لبنید به زبان
                فارسی باشد.
              </li>
              <li class="description-li-top">
                دسترسی كاربر به سایت در هرشرایطی حتی شرایط خاص و فورس ماژور
                امكان پذیر نباشد، سایت مسئولیتی در قبال زیان های احتمالی وارد
                شده نخواهد داشت .
              </li>
              <br />
            </ul>
          </div>
          <br />

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>حریم خصوصی</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-top">
                کاربر با استفاده از لبنید رضایت خود را از جمع آوری ، ذخیره سازی،
                حفظ اطلاعات شخصی و استفاده از این اطلاعات توسط لبنید اعلام کرده
                اند و لازم به ذکر است اطلاعات کاربران به منزله امانت نزد لبنید
                محفوظ است.
              </li>
              <li class="description-li-top">
                قوانین حریم خصوصی بنا به شرایط تغییر کرده و یا به روز رسانی می
                شود، پس پیشنهاد میگردد کاربران به تناوب قوانین را مطالعه
                فرمایند.
              </li>
              <li class="description-li-top">
                اطلاعات کاربران بنا به دستور مراجع ذی صلاح مطابق با قوانین کشور
                ممکن است در اختیار نهاد های مربوطه قرار گیرد.
              </li>
              <li class="description-li-top">
                مسئولیت حقوقی و قانونی هرگونه جعل عنوان یا عدم صحت اطلاعات فردی
                یا سوء استفاده از مشخصات دیگران بر عهده ی شخص کاربر می باشد.
              </li>
              <li class="description-li-top">
                کاربران لبنید با فعالیت در این پلتفرم، موافقت خود را با عدم
                مسئولیت لبنید در قبال اقدامات سایر کاربران اعلام می دارند.
              </li>
              <li class="description-li-top">
                لبنید در قبال عواقب به اشتراک گذاشتن اطلاعات کاربری حقیقی یا
                حقوقی با اشخاص دیگر و سوء استفاده های احتمالی هیچ گونه مسئولیتی
                ندارد.
              </li>
              <li class="description-li-top">
                برای اطلاعات بیشتر به بخش حریم خصوصی سایت مراجعه فرمایید.
              </li>

              <br />
            </ul>
          </div>
          <br />

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>پشتیبانی سایت</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-top">
                در صورت بروز نقص در لبنید، شرکت موظف است تا حد امکان از توانایی
                های خود برای رفع نقص و پشتیبانی فنی اقدام کند.
              </li>
              <li class="description-li-top">
                در حال حاضر کاربران در صورت نیاز به پشتیبانی میتوانند در تمام
                ساعات اداری با شماره تلفن 91692701-021 تماس بگیرند و یا بواسطه
                لایو چت سایت و یا ارسال تیکت میتوانند پرسشهای خود را با پشتیبانی
                لبنید مطرح کنند.
              </li>
              <br />
            </ul>
          </div>
          <br />

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>ثبت، پردازش و ارسال سفارش</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center mt-0">
                کلیه سفارشات در طی ساعات و روزهای کاری به استثنا تعطیلات رسمی
                پردازش می شود روز کاری شامل شنبه تا چهارشنبه از ساعت 8 تا 18 و
                پنج شنبه از ساعت 8 تا 13 می باشد.
              </li>
              <li class="description-li-center">
                در صورت اتمام موجودی کالایی که ثبت سفارش آن انجام شده است و یا
                مشکل در پردازش آن، وجه دریافت شده به شما استرداد می شود.
              </li>
              <li class="description-li-center">
                کاربران می بایست هنگام سفارش، فرم سفارش را با اطلاعات صحیح و به
                طور کامل تکمیل کنند. بدیهی است درصورت ورود اطلاعات ناقص یا
                نادرست، سفارش کاربر قابل پیگیری و تحویل نخواهد بود. بنابراین درج
                آدرس، ایمیل و شماره تماسهای همراه و ثابت توسط مشتری، به منزله
                مورد تایید بودن صحت آنها است اما در صورتی که این موارد به صورت
                صحیح و کامل درج نشده باشد، لبنید میتواند از مشتری، اطلاعات
                تکمیلی و بیشتر ی درخواست کند و یا سفارش با تاخیر برای مشتری
                آماده و ارسال می گردد.
              </li>
              <li class="description-li-center">
                با توجه به ثبت سیستمی سفارش، به هیچ عنوان امکان صدور فاکتور مجدد
                یا ویرایش آن وجود ندارد. به عنوان مثال آدرسی که خریدار به عنوان
                آدرس تحویلگیرنده ثبت یا انتخاب میکند، در فاکتور درج خواهد شد و
                تغییر آدرس درج شده روی فاکتور پس از پردازش و تا یید سفارش، به
                هیچ عنوان امکانپذیر نخواهد بود.
              </li>
              <li class="description-li-center">
                تحویل سفارش تنها در مکان های دقیق و قابل استناد مثل آزمایشگاه،
                بیمارستان ، شرکت، سازمان و... امکانپذیر است.
              </li>
              <li class="description-li-center">
                افزودن کالا به سبد خرید به معنی رزرو کالا نیست و هیچ گونه حقی را
                برای مشتری آن ایجاد نمی کند. همچنین تا پیش از ثبت نهایی، هرگونه
                تغییر از جمله تغییر در موجودی یا قیمت کالا، روی سبد خرید اعمال
                خواهد شد. بنابراین به مشتریانی که تما یل و تصمیم به خرید قطعی
                دارند، توصیه میشود در اسرع وقت سفارش خود را نهایی کنند تا با
                اتمام موجودی یا تغییر قیمتی کالاها روبرو نشوند.
              </li>
              <li class="description-li-center">
                لبنید در تلاش است تا کلیه سفارشها در نهایت صحت و بدون آسیب به
                دست مشتریان خود در سراسر کشور برسد. بروز هر گونه حادثه در هنگام
                حمل و تحویل به عهده شرکت حمل و نقل و فروشنده است.
              </li>
              <li class="description-li-center">
                آسیبهای ناشی از حمل و نقل باید در عرض 24 ساعت کاری پس از تحویل
                سفارش به اطلاع لبنید برسد تا بتوان از فروشنده پیگیری لازم به عمل
                آید.
              </li>
              <li class="description-li-center">
                از آنجایی که کالا های عرضه شده در لبنید توسط تولید کنندگان و
                وارد کنندگان معتبر تامین می گردد، بروز هر گونه اشکال در رابطه با
                کیفیت و کمیت کالاهای خریداری شده باید تنها از خود کمپانی تولید
                کننده، وارد کننده یا خدمات دهنده پیگیری گردد. الزم به ذکر است
                ظرف 24 ساعت پس از تحویل سفارش، عدم اصالت کالا مثل عدم مغایرت لات
                نامبر و تاریخ انقضا با مورد سفارش داده شده باید به اطلاع لبنید
                رسانده شود تا پیگیری لازم انجام گردد و از بروز مجدد اتفاق
                جلوگیری به عمل آید.
              </li>
              <br />
            </ul>
          </div>
          <br />

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>نظرات کاربران</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center mt-0">
                نقد کاربران باید شامل قوتها و ضعفهای محصول در استفاده ی عملی و
                تجربه ی شخصی باشد و مزایا و معایب به صورت خالصه و تیتر وار در
                محل تعیین شده درج شود.
              </li>
              <li class="description-li-center">
                در قسمت نظرات نباید لینکی از سایت های دیگر قرار گیرد. پس دقت
                داشته باشید تا جای ممکن از هرگونه لینک دادن فرستادن دیگر کاربران
                به سایتهای دیگر و درج ایمیل یا نام کاربری شبکه های اجتماعی
                خودداری کنید.
              </li>
              <li class="description-li-center">
                تنها نظراتی تایید خواهند شد که مرتبط با محصول موردنظر باشند؛ لذا
                بحث های متفرقه و غیرمرتبط با محصول را مطرح نکنید.
              </li>
              <li class="description-li-center">
                کاربران میتوانند نقد خود به هر بخش از لبنید را در قسمت انتقادات
                – پیشنهادات سایت و یا به صورت تلفنی تماس با 91692701-021 اعلام
                کنند؛ لذا هیچگونه نقدی را درباره ی سایت یا خدمات آن در قسمت
                نظرات ننویسید.
              </li>
              <li class="description-li-center">
                لازم است نظرات خود را به صورت نگارشی ثبت کرده و از کوتاه کردن
                کلمات خودداری کنید. از پرسش و پاسخ درباره ی آدرس سایر فروشگاههای
                فیزیکی یا اینترنتی و استفاده از لینک خودداری کنید.
              </li>
              <li class="description-li-center">
                از ثبت اطلاعات شخصی از قبیل ا یمیل، شماره تماس و نام کاربری شبکه
                های اجتماعی در بخش پرسش و پاسخ پرهیز کنید.
              </li>
              <br />
            </ul>
          </div>
          <br />
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "TermsOfUs",
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      // screenWidth: window.screen.width
    };
  },

  methods: {
    // backToSidebarMobileFromAddressesPage() {
    //     /* get about us parent */
    //     const privacyParent = document.getElementById('privacy-parent');
    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');
    //     if (this.screenWidth < 426) {
    //         privacyParent.style.display = 'none';
    //         header.style.display = 'flex';
    //         // console.log(this.prevRouterEnter)
    //         // this.$router.push(this.prevRouterEnter);
    //         this.$router.go(-1);
    //     }
    // }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);
  },

  // mounted() {
  //     if (this.screenWidth < 426 && this.$route.path == '/privacy' || this.$route.path == '/privacy/') {
  //         /* get header mobile */
  //         const header = document.getElementById('app-header-mobile');

  //         header.style.display = 'none'
  //     }
  // }
};
</script>

<style src="../../assets/css/others/privacy.css" scoped></style>
