<template>
  <div class="category-description">
    <!-- , { showMoreCategoryDescriptionForP: showMoreCategoryDescription } -->
    <div
      v-if="'productCategories' in this.$route.query"
      :class="['category-description-content']"
    >
      <h4>{{ getShowCatgories.title }}</h4>

      <!--  -->
      <p
        :class="{
          showMoreCategoryDescriptionForP: showMoreCategoryDescription
        }"
      >
        {{ getShowCatgories.description }}
      </p>

      <button
        v-if="!showMoreCategoryDescription"
        @click="showMoreCategoryDescription = true"
        class="showMore-description"
      >
        <span>بیشتر</span>

        <font-awesome-icon icon="fa-chevron-down" />
        <!-- <i class="fa fa-chevron-down"></i> -->
      </button>

      <button
        @click="showMoreCategoryDescription = false"
        v-else
        class="showMore-description"
      >
        <span>بستن</span>
        <font-awesome-icon icon="fa-chevron-up" />
        <!-- <i class="fa fa-chevron-up"></i> -->
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoryDescription",

  data() {
    return {
      showMoreCategoryDescription: false
    };
  },

  computed: {
    getShowCatgories() {
      if (this.$store.getters.getShowCatgories != null) {
        return this.$store.getters.getShowCatgories;
      } else {
        return {
          title: "",
          description: ""
        };
      }
    }
  }
};
</script>

<style src="../../assets/css/category/categoryDescription.css" scoped></style>
