<template>
  <div id="terms-of-return-parent">
    <!-- <div class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage" src="../../assets/images/icons/back-arrow-mobile3.svg"
                alt="">
            <p>شرایط بازگشت کالا</p>
        </div> -->

    <div class="terms-of-return">
      <app-header id="app-header-mobile"></app-header>

      <div class="terms-of-return-content">
        <h5>شرایط بازگشت کالا</h5>

        <div class="terms-of-return-details">
          <div
            v-for="(termsOfReturn, index) in termsOfReturnProducts"
            :key="index"
            class="terms-of-return-details-7-day"
          >
            <div class="details-7-day-title">
              <hr :style="{ width: termsOfReturn.hrWidth }" />

              <span :style="{ minWidth: termsOfReturn.textWidth }">{{
                termsOfReturn.termsOfReturnTitle
              }}</span>

              <hr :style="{ width: termsOfReturn.hrWidth }" />
            </div>

            <div class="details-7-day-content">
              <ul class="details-7-day-content-ul">
                <li
                  v-for="(content, i) in termsOfReturn.termsOfReturnContent"
                  v-bind:key="i"
                  :class="[
                    'details-7-day-content-li-top',
                    { termsOfHighlight: content.highlight },
                  ]"
                >
                  {{ content.text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "TermsOfReturn",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      termsOfReturnProducts: [
        {
          termsOfReturnTitle: "7 روز ضمانت بازگشت کالا",
          hrWidth: "40%",
          textWidth: "20%",

          termsOfReturnContent: [
            {
              text: "آرامش خاطر مشتریان از خرید خود از مهمترین اولویت‌های فروشگاه اینترنتی اینلای می‌باشد. در همین راستا اینلای تمام تلاش خود را بکار گرفته تا اطلاعات دقیقی از محصولات در سایت قرار داده شود تا مشتریان بتوانند با آگاهی کامل اقدام به خرید نمایند. با وجود این ممکن است مشتریان محترم پس از خرید قصد بازگشت و یا تعویض کالای خریداری شده را داشته باشند که در این راستا اینلای ضمانت 7 روزه جهت بازگرداندن کالا را در اختیار مشتریان خود قرار می دهد که محاسبه این بازه به این صورت است :",
              highlight: false,
            },

            {
              text: "7 روز از زمان تحویل کالا به مشتری تا اخذ محصول ارجاعی توسط بانی",
              highlight: true,
            },

            {
              text: "پس چنان چه قصد تعویض دارید، در حداقل زمان ممکن پس از دریافت کالا اقدام نمایید تا این روال بدون هیچ گونه مشکلی، برای شما انجام شود.",
              highlight: false,
            },
          ],
        },

        {
          termsOfReturnTitle:
            "بازگشت و یا تعویض کالا چه دلایلی می‌تواند داشته باشد؟",

          hrWidth: "29%",
          textWidth: "42%",

          termsOfReturnContent: [
            {
              text: "- مورد پسند نبودن کالای دریافتی: به هر دلیلی اگر محصول خریداری شده خود را نمی‌پسندید می‌توانید آن را به راحتی بازگشت داده و یا تعویض نمایید.\n",
              highlight: false,
            },

            {
              text: "مناسب نبودن سایز محصول دریافتی: مشتریان گرامی می‌توانند از راهنمای سایز محصولات جهت انتخاب بهتر استفاده نمایند با این حال در صورت نامناسب بودن سایز می‌توانند براحتی آن را تعویض نمایند",
              highlight: false,
            },

            {
              text: "عدم مطابقت محصول سفارش داده شده با محصول دریافتی: در صورت داشتن این مشکل با اعلام آن به واحد خدمات پس از فروش جهت بازگشت محصول راهنمایی خواهید شد.( تا 24 ساعت بعد از تحویل باید اطلاع داده شود )",
              highlight: false,
            },

            {
              text:
                "وجود نقص و ایراد در کالای خریداری شده: در صورتی که کالای دریافتی دارای نقص و ایراد، از جمله پارگی،زدگی،شکستگی و... ( ضایعات ) باشد با اعلام آن به واحد خدمات پس از فروش جهت بازگشت راهنمایی خواهید شد ( تا 24 ساعت پس از تحویل کالا باید از طریق تلگرام @tghxdd ، اطلاع داده شود ). در نظر داشته باشید که ایراد وارده بعلت استفاده نادرست از کالا جز شرایط ذکر شده نمی باشد.\n" +
                "-در نظر داشته باشید فاکتور ثبت شده شما یک بار قابلیت تعویض دارد، لذا دقت کافی در هنگام خرید تعویضی تان داشته باشید",
              highlight: false,
            },
          ],
        },

        {
          termsOfReturnTitle: "کدام کالاها قابلیت بازگشت و یا تعویض را دارند ؟",

          hrWidth: "32%",
          textWidth: "36%",

          termsOfReturnContent: [
            {
              text: "تمامی محصولات موجود در سایت اینلای قابلیت بازگشت و یا تعویض را دارند بجز موارد زیر:\n",
              highlight: false,
            },

            {
              text: " - دسته بندی اکسسوری‌ها مانند: انواع لباس‌های زیر (زنانه، مردانه، بچه گانه)، عینک، ساعت، چتر، کمربند، جوراب، شال گردن، دستکش، شال و روسری، لگ و ساپورت، زیورآلات ، سوتین و نیم تنه ، روتختی ، بالشت ، اسباب بازی ...\n",
              highlight: false,
            },

            {
              text: "- محصولات زیبایی و سلامت\n",
              highlight: false,
            },

            {
              text: "- محصولات لوازم خانه\n",
              highlight: false,
            },
            {
              text: "- محصولات لوازم شخصی برقی\n",
              highlight: false,
            },
            {
              text: "- محصولات که در پک های بهداشتی قرار دارند\n",
              highlight: false,
            },

            {
              text: "- کارت های هدیه فروشگاهی.\n",
              highlight: false,
            },

            {
              text: "تبصره: پس از خرید زیورآلات فرجا برای برگشت محصولات می‌توانید زیورآلات این برند را به فروشگاه اینترنتی اینلای بفروشید. بدین گونه که فروشگاه اینترنتی اینلای بر طبق وزنی که در فاکتور درج شده، و بر اساس قیمت روز طلا خرید را انجام می دهد.( هنگام خرید زیورآلات توسط فروشگاه اینترنتی اینلای اجرت ساخت طلا و قیمت جواهر از مبلغ کل کسر می‌گردد)",
              highlight: false,
            },
          ],
        },

        {
          termsOfReturnTitle:
            "کالاهای قابل بازگشت و یا تعویض به هنگام ارسال باید دارای چه شرایطی باشند؟",

          hrWidth: "22%",
          textWidth: "56%",

          termsOfReturnContent: [
            {
              text: "- در زمان مرجوع کردن کالای خریداری شده، مطمئن شوید که کالا را در بسته بندی ویژه خود ( همان گونه که دریافت کرده اید )، و جعبه خود اینلای قرار داده اید.\n",
              highlight: false,
            },

            {
              text: "- سلامت اتیکت محصول در زمان مرجوع کردن بسیار مهم است. قبل از ارجاع کالای خریداری شده، اتیکت باید سالم بوده و از محصول جدا نشده باشد.\n",
              highlight: false,
            },

            {
              text: "- در تمامی کالاها اتیکت کالا باید سالم باشد. در صورت جدا شدن و یا مخدوش شدن تگ‌ها کالای خریداری شده قابل استرداد نمی‌باشد.\n",
              highlight: false,
            },

            {
              text: "- ایراد ظاهری در کالا بوجود نیامده باشد و در حالت نو باقی مانده باشد.\n",
              highlight: false,
            },
            {
              text: "- انواع لباس‌ها: بو نگرفته باشند، دچار چروکی و رفتن خط اتو نشوند، لکه و کثیفی بر روی لباس نباشد و با بسته بندی خود محصول بازگشت داده شود.\n",
              highlight: false,
            },
            {
              text: "- انواع کفش‌ها: کف کفش تمیز و سالم باشد همچنین روی کفش خط خوردگی و تاشدگی نباشد و داخل آن بوی تازگی کفش را بدهد.\n",
              highlight: false,
            },

            {
              text: "کلیه موارد فوق باید به تایید کارشناس کنترل کیفیت و سلامت کالا در فروشگاه اینترنتی اینلای برسد و در صورت تایید کالا قابلیت بازگشت و یا تعویض را خواهد داشت.\n",
              highlight: false,
            },

            {
              text: "شرایط بازگشت و تعویض کالاهایی که در پروموشن خریداری شده‌اند:",
              highlight: false,
            },
          ],
        },

        {
          termsOfReturnTitle: "کالای خود را به چه آدرسی ارسال کنیم؟",

          hrWidth: "36%",
          textWidth: "28%",

          termsOfReturnContent: [
            {
              text: "آدرس : تهران - جنت آباد مرکزی -خیابان دانش - پلاک 0",
              highlight: false,
            },

            {
              text: "شماره تماس : 021-45615 کدپستی : 0000000",
              highlight: false,
            },
          ],
        },

        {
          termsOfReturnTitle: "راه‌های ارتباط با خدمات پس از فروش اینلای",

          hrWidth: "34%",
          textWidth: "32%",

          termsOfReturnContent: [
            {
              text: "شماره تماس: 46915-021\n",
              highlight: false,
            },

            {
              text: "ایمیل : Ali@gmail.com",
              highlight: false,
            },

            {
              text: "تلگرام : تلگرام: @inlay",
              highlight: false,
            },
          ],
        },
      ],

      /* terms of return mobile data */
      // screenWidth: window.screen.width,
    };
  },

  methods: {
    // backToSidebarMobileFromAddressesPage() {
    //     /* get about us parent */
    //     const termsOfReturnMobile = document.getElementById('terms-of-return-parent');
    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');
    //     if (this.screenWidth < 426) {
    //         termsOfReturnMobile.style.display = 'none';
    //         header.style.display = 'flex';
    //         // console.log(this.prevRouterEnter)
    //         // this.$router.push(this.prevRouterEnter);
    //         this.$router.go(-1);
    //     }
    // }
  },

  mounted() {
    // if (this.screenWidth < 426 && this.$route.path == '/terms-of-return' || this.$route.path == '/terms-of-return/') {
    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');
    //     header.style.display = 'none'
    // }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);
  },
};
</script>

<style src="../../assets/css/others/termsOfReturn.css" scoped></style>
