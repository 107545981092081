<template>
  <div class="slider-div">
    <div class="slider-content">
      <div class="slider-div-right">
        <div class="read-more-less-text p-0" id="aboutUs">
          <div class="read-more-less-title p-0">
            <img
              class=""
              src="../../assets/images/icons/3line.png"
              alt="icone"
            />

            <p class="pt-2 pr-2">{{ getAboutUsShort.store_name }}:</p>
          </div>

          <p id="showMore" class="height">
            {{ getAboutUsShort.about_us }}
          </p>

          <button @click="showMore" id="moreBtn">
            <span>بیشتر</span>

            <font-awesome-icon icon="fa-chevron-down" id="moreImg" />
          </button>

          <button @click="showMore" id="lessBnt">
            <span>کمتر</span>

            <font-awesome-icon icon="fa-chevron-up" id="lessImg" />
          </button>
        </div>

        <div class="img-description" id="imgDescription">
          <img
            :src="getStoreBanner != null ? getStoreBanner.media_link : ''"
            :alt="getStoreBanner != null ? getStoreBanner.alt : ''"
          />
          <span class="img-description-cover"></span>
          <div class="img-description-btn">
            <p>
              {{ getStoreBanner != null ? getStoreBanner.banner_title : "" }}
            </p>
            <!-- v-if="getStoreBanner.banner_link != ''" -->
            <button>
              <a
                target="_blank"
                :href="
                  'http://' +
                  (getStoreBanner != null ? getStoreBanner.banner_link : '')
                "
                >خرید</a
              >
              <font-awesome-icon
                :style="{ marginRight: '5px' }"
                icon="fa-chevron-left"
              />
            </button>
          </div>
        </div>
      </div>
      <!--        <div class="col-md-7 p-0 slider-div-left">-->

      <!-- <div class="slider-div-left p-0 carousel slide" id="carouselExampleIndicators" data-ride="carousel">

                <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                </ol>

                <div class="carousel-inner">

                    <div v-for="(src, index) in carouselItemImages" :key="index" class="carousel-item"
                        :class="{ 'active': index == 0 }">

                        <div class="carousel-item-img" :style="{ 'background-image': 'url(' + src.sliderImg + ')' }">

                        </div>

                    </div>


                </div>


                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">

                    <img src="../../assets/images/icons/nextbtn-slider-svg.svg" alt="">

            
                    <span class="sr-only">Next</span>

                </a>


                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <img src="../../assets/images/icons/prevbtn-slider-svg.svg" alt="">
                    <span class="sr-only">Previous</span>
                </a>

            </div> -->

      <div class="slider-div-left p-0 slide">
        <b-carousel
          ref="myCarousel"
          class="carousel"
          img-width="100"
          img-height="500"
          :interval="5000"
          :indicators="true"
          background="#fff"
        >
          <b-carousel-slide
            class="carousel-item"
            v-for="(item, imgIndex) in getStoreSliders"
            :key="imgIndex"
            v-show="getStoreSliders.length > 0"
          >
            <template #img>
              <img
                loading="lazy"
                class="carousel-item-img"
                width="1024"
                height="480"
                :src="getStoreSliders.length > 0 ? item.media_link : ''"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>

          <a class="carousel-control-next" @click="nextSlide">
            <font-awesome-icon icon="fa-chevron-right" />
            <!-- <img src="../../assets/images/icons/nextbtn-slider-svg.svg" alt=""> -->
            <!-- <span class="sr-only">Next</span> -->
          </a>

          <a class="carousel-control-prev" @click="prevSlide">
            <font-awesome-icon icon="fa-chevron-left" />
            <!-- <img src="../../assets/images/icons/prevbtn-slider-svg.svg" alt=""> -->
            <!-- <span class="sr-only">Previous</span> -->
          </a>
        </b-carousel>
      </div>
    </div>
  </div>

  <!--    </div>-->
</template>

<script>
export default {
  name: "Slider",

  data() {
    return {
      show: "true",

      carouselItemImages: [
        {
          sliderImg: require("../../assets/images/pictures/sliderImg.png"),
        },
        {
          sliderImg: require("../../assets/images/pictures/imgDes.png"),
        },
        {
          sliderImg: require("../../assets/images/pictures/sliderImg.png"),
        },
        {
          sliderImg: require("../../assets/images/pictures/imgDes.png"),
        },
      ],

      slide: 0,
      sliding: null,
    };
  },

  methods: {
    // method for show more and less about us of store
    showMore() {
      //  get p element
      let text = document.getElementById("showMore");

      // get div about-us element
      let aboutUs = document.getElementById("aboutUs");

      //    get div imgDescription
      let imgDes = document.getElementById("imgDescription");

      //    get more Btn
      let moreBtn = document.getElementById("moreBtn");

      //  get less Btn
      let lessBtn = document.getElementById("lessBnt");

      if (this.show) {
        text.style.height = "500px";
        aboutUs.style.transition = "height 0.5s ease-in!important";
        aboutUs.style.height = "100%";
        imgDes.style.opacity = "0";
        // imgDes.style.visibility= 'hidden';
        imgDes.style.display = "none";
        moreBtn.style.display = "none";
        this.show = false;
        lessBtn.style.display = "flex";
      } else {
        text.style.height = "145px";
        aboutUs.style.transition = "0.5s linear!important";
        aboutUs.style.height = "60%";
        // imgDes.style.transition = 'opacity 1s linear!important visibility .5s linear!important';
        imgDes.style.opacity = "1";
        // imgDes.style.visibility = 'visible';
        imgDes.style.display = "flex";
        moreBtn.style.display = "flex";
        this.show = true;
        lessBtn.style.display = "none";
      }
    },

    onSlideStart(slide) {
      // this.sliding = true
    },
    onSlideEnd(slide) {
      // this.sliding = false
    },

    nextSlide() {
      this.$refs.myCarousel.next();
    },

    prevSlide() {
      this.$refs.myCarousel.prev();
    },
  },

  computed: {
    // get store name and aboutus short des
    getAboutUsShort() {
      if (this.$store.getters.getStoreInfo != null) {
        return {
          store_name: this.$store.getters.getStoreInfo.store_name,
          about_us: this.$store.getters.getStoreInfo.about_us_short_des,
        };
      } else {
        return {
          store_name: "",
          about_us: "",
        };
      }
    },

    // get store banner (type 2)
    getStoreBanner() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_banners.length > 0
      ) {
        return this.$store.getters.getStoreInfo.store_banners.find(
          (item, index) => item.banner_type == 2
        );
      } else {
        return null;
      }
    },

    getStoreSliders() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_sliders
      ) {
        return this.$store.getters.getStoreInfo.store_sliders;
      } else {
        return [];
      }
    },
  },
};
</script>


<style src="../../assets/css/store/slider.css" scoped>
</style>