import { render, staticRenderFns } from "./GeneralSpecifications.vue?vue&type=template&id=640562ee&scoped=true&"
import script from "./GeneralSpecifications.vue?vue&type=script&lang=js&"
export * from "./GeneralSpecifications.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/product/generalSpecifications.css?vue&type=style&index=0&id=640562ee&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "640562ee",
  null
  
)

export default component.exports