<template>
  <div id="questions-parent">
    <!-- mobile header -->
    <!--  <div id="question-main-title" class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage" src="../../assets/images/icons/back-arrow-mobile3.svg"
                alt="">
            <p>پرسش و پاسخ</p>
        </div> -->

    <div class="common-questions">
      <app-header id="app-header-mobile"></app-header>

      <!--  common questions for desktop  -->
      <div class="common-questions-content">
        <div class="common-questions-categories">
          <h5>دسته بندی سولات</h5>

          <div
            v-for="(categoryContent, index) in allQuestions"
            v-bind:key="index"
            :id="`category-box${index}`"
            :class="[
              'common-questions-categories-content',
              { borderActive: categoryContent.categoryShow }
            ]"
          >
            <div
              @click="ShowAllQuestionsCategories(categoryContent, index)"
              class="categories-content-title"
            >
              <div class="categories-content-title-right">
                <img src="../../assets/images/icons/faqIcon.svg" alt="" />

                <span>{{ categoryContent.title }}</span>
              </div>

              <font-awesome-icon
                @click:only="ShowAllQuestionsCategories(categoryContent, index)"
                :id="'showCategoryAll' + index"
                icon="fa-chevron-down"
              />
            </div>

            <!-- v-show="categoryContent.showAllQuestions[0]" -->
            <div class="categories-content-details-questions">
              <div
                v-for="(questionAndAnswer, i) in categoryContent.questions"
                :key="i"
                :id="`category-question${index}${i}`"
                :class="[
                  'categories-content-details-single-question',
                  { borderActive: questionAndAnswer.questionShow }
                ]"
              >
                <div
                  @click="showCategoryDetails(questionAndAnswer, index, i)"
                  class="categories-content-details-single-question-title"
                >
                  <div class="single-question-title-right">
                    <img
                      src="../../assets/images/icons/question-icon.svg"
                      alt=""
                    />
                    <span>
                      {{
                        questionAndAnswer.questionShow
                          ? questionAndAnswer.title_question
                          : questionAndAnswer.title_question.slice(0, 250) +
                            "..."
                      }}
                    </span>
                  </div>

                  <font-awesome-icon
                    :id="'showCategoryDetails' + index + i"
                    @click:only="
                      showCategoryDetails(questionAndAnswer, index, i)
                    "
                    icon="fa-chevron-down"
                  />
                </div>

                <div
                  :id="`category-question-answer${index}${i}`"
                  class="categories-content-details-single-question-content"
                >
                  <hr />

                  <span class="single-content-answer">
                    {{ questionAndAnswer.answer }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- fq questions section -->
        <div class="common-your-questions-content">
          <h3>سوالات متداول شما</h3>

          <div class="common-your-questions-content-details">
            <div
              v-for="(commonQ, x) in fqQuestions"
              :key="x"
              :id="`single-fq-Question${x}`"
              :class="[
                'common-your-questions-content-single',
                { borderActive: commonQ.show }
              ]"
            >
              <div
                @click="showCommonQuestions(commonQ, x)"
                class="common-your-questions-content-single-title"
              >
                <div class="common-single-title-right">
                  <img
                    src="../../assets/images/icons/question-icon.svg"
                    alt=""
                  />
                  <span>{{
                    commonQ.show
                      ? commonQ.title_question
                      : commonQ.title_question.slice(0, 250) + "..."
                  }}</span>
                  <!-- <span>؟</span> -->
                </div>

                <font-awesome-icon
                  icon="fa-chevron-down"
                  :id="'commonQuestion' + x"
                  @click:only="showCommonQuestions(commonQ, x)"
                />
              </div>

              <div
                :id="`fq-answer${x}`"
                class="common-your-questions-content-single-content"
              >
                <hr />
                <span>
                  {{ commonQ.answer }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- send question section -->
        <div class="question-send">
          <h5>فرم ارسال سوال</h5>

          <div class="question-send-content">
            <div class="question-send-content-img">
              <img
                src="../../assets/images/icons/question-send-img.svg"
                alt=""
              />
              <span>شما میتوانید از این طریق با ما در ارتباط باشید</span>
            </div>

            <form action="" class="question-send-content-form">
              <div class="email-and-mobile-number">
                <div class="question-send-content-email">
                  <label for="email">ایمیل</label>
                  <input
                    @input="removeValidErrorEmail"
                    :class="{
                      borderActiveForFocus: emailFocusActive,
                      validErrorBorder:
                        validationErrors.email &&
                        validationErrors.email.length > 0
                    }"
                    @focus="emailFocusActive = true"
                    @blur="emailFocusActive = false"
                    v-model="QuestionForm.email"
                    type="text"
                    id="email"
                  />
                  <span
                    v-for="(error, index) in validationErrors.email"
                    :key="index"
                    class="question-validation-error"
                    v-show="
                      validationErrors.email &&
                        validationErrors.email.length > 0
                    "
                  >
                    {{ error }}
                  </span>
                </div>

                <div class="question-send-content-mobile-number">
                  <label for="mobileNumber">تلفن همراه</label>
                  <input
                    @input="removeValidErrorPhoneNumer"
                    v-model="QuestionForm.phone_number"
                    :class="{
                      validErrorBorder:
                        validationErrors.phone_number &&
                        validationErrors.phone_number.length > 0,
                      borderActiveForFocus: mobileNumberFocusActive
                    }"
                    @focus="mobileNumberFocusActive = true"
                    @blur="mobileNumberFocusActive = false"
                    type="number"
                    id="mobileNumber"
                  />

                  <span
                    v-for="(error, index) in validationErrors.phone_number"
                    :key="index"
                    class="question-validation-error"
                    v-show="
                      validationErrors.phone_number &&
                        validationErrors.phone_number.length > 0
                    "
                  >
                    {{ error }}
                  </span>
                </div>
              </div>

              <div class="question-title">
                <label for="questionTitle"> عنوان سوال</label>
                <input
                  @input="removeValidErrorMessageTitle"
                  v-model="QuestionForm.message_title"
                  :class="{
                    borderActiveForFocus: questionTitleFocusActive,
                    validErrorBorder:
                      validationErrors.message_title &&
                      validationErrors.message_title.length > 0
                  }"
                  @focus="questionTitleFocusActive = true"
                  @blur="questionTitleFocusActive = false"
                  type="text"
                  id="questionTitle"
                />

                <span
                  v-for="(error, index) in validationErrors.message_title"
                  :key="index"
                  class="question-validation-error"
                  v-show="
                    validationErrors.message_title &&
                      validationErrors.message_title.length > 0
                  "
                >
                  {{ error }}
                </span>
              </div>

              <div class="question-content">
                <label for="questionText">متن سوال</label>
                <textarea
                  @input="removeValidErrorMessageText"
                  v-model="QuestionForm.message_text"
                  :class="{
                    borderActiveForFocus: questionContentFocusActive,
                    validErrorBorder:
                      validationErrors.message_text &&
                      validationErrors.message_text.length > 0
                  }"
                  @focus="questionContentFocusActive = true"
                  @blur="questionContentFocusActive = false"
                  name="question-text"
                  id="questionText"
                  rows="10"
                >
                </textarea>

                <span
                  v-for="(error, index) in validationErrors.message_text"
                  :key="index"
                  class="question-validation-error"
                  v-show="
                    validationErrors.message_text &&
                      validationErrors.message_text.length > 0
                  "
                >
                  {{ error }}
                </span>
              </div>

              <button
                :disabled="
                  QuestionForm.phone_number == '' &&
                    QuestionForm.message_text == ''
                "
                :class="[
                  'question-send-btn',
                  {
                    ActiveQuestionSendBtn:
                      QuestionForm.message_text != '' &&
                      QuestionForm.phone_number != ''
                  }
                ]"
                @click.prevent="submitPostMessage"
              >
                ارسال
              </button>
            </form>
          </div>
        </div>
      </div>

      <!--  common questions for mobile  -->
      <!-- <div id="common-questions-content-mobile" class="common-questions-content-mobile">

                <div class="common-questions-content-mobile-categories">
                    <h5>دسته بندی سوالات</h5>

                    <div class="common-questions-categories-content-mobile">

                        <div @click="showCategoryQuestionsContentMobile(cq)"
                            v-for="(category, cq) in categoryQuestionsContent" :key="cq"
                            class="common-questions-categories-single-mobile">

                            <div class="common-questions-categories-single-mobile-right">
                                <img :src="category.categoryImg" alt="">
                                <p>{{ category.categoryTitle }}</p>
                            </div>


                            <img src="../../assets/images/icons/link-arrow-mobile.svg" alt="">
                        </div>

                    </div>
                </div>


                <div class="common-questions-content-send-question-form-mobile">
                    <h5>فرم ارسال سوال</h5>

                    <div class="send-question-form-title-of-question">
                        <label for="title-question-mobile">
                            عنوان سوال
                        </label>
                        <input type="text" id="title-question-mobile">
                    </div>

                    <div class="send-question-form-title-of-question">
                        <label for="telephone-question-mobile">
                            عنوان سوال
                        </label>
                        <input type="text" id="telephone-question-mobile">
                    </div>

                    <div class="send-question-form-title-of-question">
                        <label for="email-question-mobile">
                            عنوان سوال
                        </label>
                        <input type="text" id="email-question-mobile">
                    </div>

                    <div class="send-question-form-title-of-question">
                        <label for="text-question-mobile">
                            عنوان سوال
                        </label>
                        <textarea id="text-question-mobile"></textarea>
                    </div>

                    <button class="send-question-submit-mobile">ارسال</button>
                </div>


            </div> -->
    </div>

    <!-- mobile size section -->
    <!-- <div v-show="categoryContent.showAllQuestions[0]" v-for="(categoryContent, c) in categoryQuestionsContent"
            :key="c" class="category-question-content-details">

            <div class="profile-addresses-mobile-title">
                <img @click="backToCategoryQuestionsPage(c)" src="../../assets/images/icons/back-arrow-mobile3.svg"
                    alt="">
                <p>سوالات {{ categoryContent.categoryTitle }}</p>
            </div>

            <div class="all-question-of-category-parent">
                <div v-for="(qa, cc) in categoryContent.categoryDetailsQuestion" :key="cc"
                    class="all-question-of-category-single">

                    <div class="question-text-mobile">
                        <p>{{ qa.question }}</p>
                    </div>

                    <hr>

                    <div class="answer-text-mobile">
                        <p :class="{ 'answer-text-mobile-show': categoryContent.categoryDetailsShow[cc] }">
                            {{ qa.answer }}
                        </p>
                    </div>

                    <button v-if="!categoryContent.categoryDetailsShow[cc]"
                        @click="showQuestionAnswerDetailsMobile(c, cc)" class="open-answer-content-btn-mobile">
                        پاسخ
                        <i class="fa fa-chevron-down"></i>
                    </button>

                    <button v-else @click="showQuestionAnswerDetailsMobile(c, cc)"
                        class="open-answer-content-btn-mobile">
                        بستن
                        <i class="fa fa-chevron-up"></i>
                    </button>

                </div>
            </div>

        </div> -->

    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

const registerLoginCategoryQuestionImg = require("../../assets/images/icons/questions-categories-login-register-icon.svg");
const orderRegistrationProcessImg = require("../../assets/images/icons/order-registration-proccess.svg");
const TrackOrderSubmissionImg = require("../../assets/images/icons/track-order-submission.svg");

export default {
  name: "Questions",

  components: {
    "app-header": Header,
    "app-footer": Footer
  },

  data() {
    return {
      // categoryQuestionsContent: [

      //     {

      //         categoryImg: registerLoginCategoryQuestionImg,

      //         categoryTitle: 'ورود و ثبت نام',

      //         showAllQuestions: [],

      //         categoryDetailsQuestion: [

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت %%%%%%%%%%%%%%%%%%%%%%%%%%%% %%%%%%%%%%%%%%%%%%%',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ $$$$$$$$$$$$$$$$$$$$$',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد################## #############',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             }

      //         ],

      //         categoryDetailsShow: [],

      //     },

      //     {

      //         categoryImg: orderRegistrationProcessImg,

      //         categoryTitle: 'روند ثبت سفارش',

      //         showAllQuestions: [],

      //         categoryDetailsQuestion: [

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             }

      //         ],

      //         categoryDetailsShow: [],

      //     },

      //     {

      //         categoryImg: TrackOrderSubmissionImg,

      //         categoryTitle: 'روند ثبت سفارش',

      //         showAllQuestions: [],

      //         categoryDetailsQuestion: [

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             },

      //             {
      //                 question: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه',
      //                 answer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //                     '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'

      //             }

      //         ],

      //         categoryDetailsShow: [],

      //     }
      // ],

      // commonQuestionsContent: [
      //     {
      //         commonQuestion: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ',
      //         questionAlign: '؟',
      //         commonAnswer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //             '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //     },

      //     {
      //         commonQuestion: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ',
      //         questionAlign: '؟',
      //         commonAnswer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //             '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //     },

      //     {
      //         commonQuestion: 'تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ',
      //         questionAlign: '؟',
      //         commonAnswer: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n' +
      //             '.بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده'
      //     }
      // ],

      commonQuestionsContentShow: [],

      emailFocusActive: false,

      mobileNumberFocusActive: false,

      questionTitleFocusActive: false,

      questionContentFocusActive: false,

      QuestionForm: {
        email: "",
        phone_number: "",
        message_title: "",
        message_text: "",
        token: ""
      },

      validationErrors: {
        email: [],
        phone_number: [],
        message_title: [],
        message_text: []
      }

      /* mobiles data's */

      // screenWidth: window.screen.width,
    };
  },

  // mounted() {

  //     for (let i = 0; i < this.categoryQuestionsContent.length; i++) {

  //         this.categoryQuestionsContent[i].showAllQuestions.push(false);

  //         this.$forceUpdate();
  //         // console.log(this.categoryQuestionsContent[i].categoryDetailsQuestion);

  //         for (let j = 0; j < this.categoryQuestionsContent[i].categoryDetailsQuestion.length; j++) {

  //             this.categoryQuestionsContent[i].categoryDetailsShow.push(false);

  //             this.$forceUpdate();
  //             // console.log(this.categoryQuestionsContent[i].categoryDetailsShow[j])
  //         }

  //     }

  //     for (let i = 0; i < this.commonQuestionsContent.length; i++) {
  //         this.commonQuestionsContentShow.push(false);
  //         this.$forceUpdate();
  //     }

  //     if (this.screenWidth < 426 && this.$route.path == '/questions' || this.$route.path == '/questions/') {
  //         /* get header */

  //         const header = document.getElementById('app-header-mobile');
  //         header.style.display = 'none';
  //     }

  // },

  methods: {
    ShowAllQuestionsCategories(category, index) {
      const showCategoryAll = document.getElementById(
        "showCategoryAll" + index
      );
      const categoryBox = document.getElementById("category-box" + index);

      if (category.categoryShow == false) {
        console.log("hello");

        showCategoryAll.style.transform = "rotate(180deg)";
        showCategoryAll.style.color = this.$mainColor;

        categoryBox.style.maxHeight = "10000px";

        category.categoryShow = true;
        this.$forceUpdate();
      } else {
        showCategoryAll.style.transform = "rotate(0deg)";
        showCategoryAll.style.color = "#A8A8A8";

        if(window != undefined && window.screen.width > 500) {
            categoryBox.style.maxHeight = "82px";

        }else {
            
            categoryBox.style.maxHeight = "62px";
        }

        category.categoryShow = false;
        this.$forceUpdate();
      }

      // this.categoryQuestionsContent[index].showAllQuestions[index] = !this.categoryQuestionsContent[index].showAllQuestions[index];
    },

    showCategoryDetails(questionAndAnswer, index, i) {
      const showCategoryDetails = document.getElementById(
        "showCategoryDetails" + index + i
      );
      const question = document.getElementById("category-question" + index + i);
      const questionContent = document.getElementById(
        "category-question-answer" + index + i
      );

      if (questionAndAnswer.questionShow == false) {
        showCategoryDetails.style.color = this.$mainColor;
        showCategoryDetails.style.transform = "rotate(180deg)";
        questionContent.style.maxHeight = "1000px";
        question.style.maxHeight = "1000px";

        questionAndAnswer.questionShow = true;
        this.$forceUpdate();
      } else {
        showCategoryDetails.style.transform = "rotate(0deg)";
        question.style.maxHeight = "100px";
        showCategoryDetails.style.color = "#A8A8A8";
        questionContent.style.maxHeight = "0px";

        questionAndAnswer.questionShow = false;
        this.$forceUpdate();
      }
    },

    showCommonQuestions(question, x) {
      const commonQuestion = document.getElementById("commonQuestion" + x);
      const parentDiv = document.getElementById("single-fq-Question" + x);
      const fqAnswer = document.getElementById("fq-answer" + x);

      if (question.show === false) {
        commonQuestion.style.transform = "rotate(180deg)";
        commonQuestion.style.color = this.$mainColor;
        parentDiv.style.maxHeight = "10000px";
        fqAnswer.style.maxHeight = "10000px";
        this.$forceUpdate();

        question.show = true;
      } else {
        commonQuestion.style.transform = "rotate(0deg)";
        commonQuestion.style.color = "#A8A8A8";
        parentDiv.style.maxHeight = "100px";
        fqAnswer.style.maxHeight = "0px";

        question.show = false;
        this.$forceUpdate();
      }
    },

    /* mobile methods */

    /* back for main question page */
    backToSidebarMobileFromAddressesPage() {
      /* get about us parent */
      const contactToUsParent = document.getElementById("questions-parent");

      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      if (this.screenWidth < 426) {
        contactToUsParent.style.display = "none";
        header.style.display = "flex";
        // console.log(this.prevRouterEnter)
        // this.$router.push(this.prevRouterEnter);
        this.$router.go(-1);
      }
    },

    /* show category content */
    showCategoryQuestionsContentMobile(cq) {
      this.categoryQuestionsContent.forEach(e => {
        e.showAllQuestions[0] = false;
      });

      /* get question main title */

      const mainHeader = document.getElementById("question-main-title");
      mainHeader.style.display = "none";

      /* get common questions content mobile */
      const questionsContentMobile = document.getElementById(
        "common-questions-content-mobile"
      );
      questionsContentMobile.style.display = "none";

      /* get common-questions */
      const commonQuestions = document.querySelector(".common-questions");
      commonQuestions.classList.add("p-0");

      this.categoryQuestionsContent[cq].showAllQuestions[0] = true;
      this.$forceUpdate();
    },

    /* back to main question page from details */
    backToCategoryQuestionsPage(c) {
      console.log(c);

      this.categoryQuestionsContent[c].showAllQuestions[0] = false;
      this.$forceUpdate();

      /* get question main title */

      const mainHeader = document.getElementById("question-main-title");
      mainHeader.style.display = "flex";

      /* get common questions content mobile */
      const questionsContentMobile = document.getElementById(
        "common-questions-content-mobile"
      );
      questionsContentMobile.style.display = "flex";

      /* get common-questions */
      const commonQuestions = document.querySelector(".common-questions");
      commonQuestions.classList.remove("p-0");
    },

    /* show answer question in details page */
    showQuestionAnswerDetailsMobile(c, cc) {
      this.categoryQuestionsContent[c].categoryDetailsShow.forEach((e, i) => {
        if (i != cc) {
          this.categoryQuestionsContent[c].categoryDetailsShow[i] = false;
        }
      });

      this.categoryQuestionsContent[c].categoryDetailsShow[cc] = !this
        .categoryQuestionsContent[c].categoryDetailsShow[cc];
      this.$forceUpdate();
    },

    // method for post new message (submit event)
    submitPostMessage() {
      // set token if user login
      this.QuestionForm.token = this.$store.getters.getToken;
      //  empty validation errors
      this.validationErrors.email = [];
      this.validationErrors.phone_number = [];
      this.validationErrors.message_title = [];
      this.validationErrors.message_text = [];

      //  dispatch post new message
      this.$store
        .dispatch("postMessageDispatch", this.QuestionForm)
        .then(res => {
          if (res.status) {
            this.QuestionForm.email = "";
            this.QuestionForm.phone_number = "";
            this.QuestionForm.message_title = "";
            this.QuestionForm.message_text = "";

            // alert success for send new message
            // alert of success
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message
            });
          } else {
            // check typeof message of response is string or not
            if (typeof res.message == "string") {
              if ("phone_number" in res.message) {
                this.validationErrors.phone_number.push(res.message);
              } else if ("message_text" in res.message) {
                this.validationErrors.message_text.push(res.message);
              } else if ("email" in res.message) {
                this.validationErrors.email.push(res.message);
              } else if ("message_title" in res.message) {
                this.validationErrors.message_title.push(res.message);
              }
            } else {
              this.validationErrors.phone_number = res.message.phone_number;
              this.validationErrors.message_text = res.message.message_text;
              this.validationErrors.message_title = res.message.message_title;
              this.validationErrors.email = res.message.email;
              this.$forceUpdate();
            }
          }
        });
    },

    // method for remove validation error of phone number
    removeValidErrorPhoneNumer() {
      this.validationErrors.phone_number = [];
      this.$forceUpdate();
    },
    // method for remove validation error of phone number
    removeValidErrorEmail() {
      this.validationErrors.email = [];
      this.$forceUpdate();
    },
    // method for remove validation error of phone number
    removeValidErrorMessageTitle() {
      this.validationErrors.message_title = [];
      this.$forceUpdate();
    },
    // method for remove validation error of phone number
    removeValidErrorMessageText() {
      this.validationErrors.message_text = [];
      this.$forceUpdate();
    },

    asyncData({ route, store }) {
      store.dispatch("allQuestionsDispatch");
      store.dispatch("fqQuestionsDispatch");
    }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    // dispatch for get all questions
    this.$store.dispatch("allQuestionsDispatch");
    this.$store.dispatch("fqQuestionsDispatch");
  },

  computed: {
    // questions have categorise
    allQuestions() {
      if (this.$store.getters.getAllQuestions != null) {
        let state = this.$store.getters.getAllQuestions;
        state.map(item => {
          item.categoryShow = false;
          item.questions.map(subItem => {
            subItem.questionShow = false;
          });
        });
        return state;
      }
    },

    // question for faqs
    fqQuestions() {
      if (this.$store.getters.getFqQuestions != null) {
        let state = this.$store.getters.getFqQuestions;
        state.map((item, index) => {
          item.show = false;
        });
        return state;
      }
    }
  }
};
</script>

<style src="../../assets/css/others/questions.css" scoped></style>
