<template>
  <UserAccount>
    <div id="profile-addresses" class="p-0 profile-addresses">
      <!-- mobile section -->
      <div class="profile-addresses-mobile-title">
        <img
          @click="backToSidebarMobileFromAddressesPage"
          src="../../assets/images/icons/back-arrow-mobile3.svg"
          alt=""
        />
        <p>آدرس های من</p>
      </div>

      <div class="p-0 profile-addresses-create">
        <!-- mobile section -->
        <div class="add-new-address-mobile" v-if="screenWidth < 426">
          <button @click="addNewAddress" class="add-new-address-button-mobile">
            افزودن آدرس جدید
            <img src="../../assets/images/icons/plus-icon-mobile.svg" alt="" />
          </button>
        </div>

        <!--  -->
        <div v-else @click="addNewAddress" class="add-new-address-button">
          <img
            src="../../assets/images/icons/add-new-address-icon.svg"
            alt=""
          />
          <p>افزودن آدرس جدید</p>
        </div>

        <div v-show="showCreateAddressPage" class="add-new-address">
          <div class="add-new-address-inputs p-0">
            <div class="add-new-address-title-input">
              <label for="new-address-title">عنوان</label>
              <input
                v-model="addNewAddressInputs.title"
                type="text"
                id="new-address-title"
              />
            </div>

            <div class="add-new-address-postal-code-input">
              <label for="new-address-postal-code">کد پستی</label>
              <input
                @input="newAddressError.post_code = []"
                :class="{
                  'error-border': newAddressError.post_code.length > 0,
                }"
                v-model="addNewAddressInputs.post_code"
                type="text"
                id="new-address-postal-code"
              />

              <span
                v-if="newAddressError.post_code.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.post_code"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="add-new-address-const-telephone">
              <label for="const-telephone">تلفن ثابت</label>
              <input
                @input="newAddressError.phone_number = []"
                :class="{
                  'error-border': newAddressError.phone_number.length > 0,
                }"
                v-model="addNewAddressInputs.phone_number"
                type="text"
                id="const-telephone"
              />
              <span
                v-if="newAddressError.phone_number.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.phone_number"
                :key="index"
                >{{ err }}</span
              >
            </div>

            <div class="add-new-address-city">
              <label for="new-address-city">شماره موبایل</label>
              <input
                @input="newAddressError.mobile_number = []"
                :class="{
                  'error-border': newAddressError.mobile_number.length > 0,
                }"
                v-model="addNewAddressInputs.mobile_number"
                type="text"
                id="new-address-city"
              />

              <span
                v-if="newAddressError.mobile_number.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.mobile_number"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="add-new-address-state">
              <label for="new-address-state"> استان </label>

              <div
                :class="[
                  'add-new-address-state-select',
                  { 'error-border': newAddressError.province_id.length > 0 },
                ]"
              >
                <!-- v-model="addNewAddressInputs.province_id" -->
                <select
                  @input="
                    (e) => {
                      newAddressError.province_id = [];
                      addNewAddressInputs.province_id = e.target.value;
                    }
                  "
                  name=""
                  id="new-address-state"
                >
                  <option
                    v-if="provinces.length > 0"
                    v-for="(state, pi) in provinces"
                    :key="pi"
                    :value="state.id"
                  >
                    {{ state.province }}
                  </option>
                </select>
              </div>

              <span
                v-if="newAddressError.province_id.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.province_id"
                :key="index"
              >
                {{ err }}
              </span>

              <!-- mobile section -->

              <!-- <div @click="showModalStateForNewAddress" class="selected-address-state-div-mobile">
                                <span>{{ selectedStateMobileFinalNewAddress }}</span>
                                <img src="../../assets/images/icons/profile-select-arrow-mobile.svg" alt="">
                            </div> -->

              <!-- mobile section -->
              <!-- <div @click.self="closeStateModalMobileNewAddress"
                                :id="'state-select-modal-mobile-background-new-address'"
                                class="state-select-modal-mobile-background">

                                <div @touchstart.self="closeStateModalMobileTouchStartNewAddress"
                                    @touchmove.self="closeStateModalMobileTouchMoveNewAddress"
                                    @touchend.self="closeStateModalMobileTouchEndNewAddress"
                                    :style="{ bottom: stateBottomNewAddress }"
                                    :id="'state-select-modal-mobile-content-new-address'"
                                    class="state-select-modal-mobile-content">

                                    <div class="state-select-modal-line"></div>

                                    <p>انتخاب استان</p>


                                    <div class="state-search-for-select-state-mobile-content">

                                        <div
                                            :class="['state-search-input-div',
                                            { 'active-state-search-input-div': stateMobileInputSearchValueNewAddress != '' }]">

                                            <input v-model="stateMobileInputSearchValueNewAddress" type="text">

                                            <img v-if="stateMobileInputSearchValueNewAddress == ''"
                                                src="../../assets/images/icons/search-icon-for-mobile.svg" alt="">

                                            <img v-else
                                                src="../../assets/images/icons/active-search-icon-input-mobile.svg"
                                                alt="">

                                        </div>


                                        <div class="state-search-result-content">

                                            <label v-for="(state, s) in resultSearchComputed" :key="s"
                                                :for="'state' + s">

                                                <div :class="['state-radio-out-circle',
                                                { selectMobileState: stateMobileValueNewAddress == state }]">
                                                    <div :class="{
                                                        'state-radio-into-circle':
                                                            stateMobileValueNewAddress == state
                                                    }"></div>
                                                </div>

                                                <input style="display: none" v-model="stateMobileValueNewAddress"
                                                    type="radio" :value="state" :id="'state' + s">

                                                {{ state }}

                                            </label>

                                        </div>

                                    </div>


                                    <button type="button" @click="submitStateModalMobileNewAddress"
                                        :disabled="stateMobileValueNewAddress == ''" :class="['submit-select-state-modal',
                                        { activeSubmitStateModal: stateMobileValueNewAddress != '' }]">
                                        تایید
                                    </button>

                                </div>
                            </div> -->
            </div>

            <div class="add-new-address-state">
              <label for="new-address-state">شهر</label>

              <div
                :class="[
                  'add-new-address-state-select',
                  { 'error-border': newAddressError.city_id.length > 0 },
                ]"
              >
                <!-- v-model="addNewAddressInputs.city_id" -->

                <select
                  @input="
                    (e) => {
                      newAddressError.city_id = [];
                      addNewAddressInputs.city_id = e.target.value;
                    }
                  "
                  @click.prevent="showCities"
                  name=""
                  id="new-address-state"
                >
                  <option
                    v-if="cities.length > 0"
                    v-for="(city, ci) in cities"
                    :key="ci"
                    :value="city.id"
                  >
                    {{ city.province }}
                  </option>

                  <option v-else>خطا در شبکه</option>
                </select>
              </div>

              <span
                v-if="newAddressError.city_id.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.city_id"
                :key="index"
              >
                {{ err }}
              </span>
            </div>

            <div class="add-new-address-details">
              <label for="new-address-details">جزئیات آدرس</label>
              <textarea
                @input="newAddressError.address_details = []"
                v-model="addNewAddressInputs.address_details"
                type="text"
                :class="{
                  'error-border': newAddressError.address_details.length > 0,
                }"
                id="new-address-details"
              ></textarea>
              <span
                v-if="newAddressError.address_details.length > 0"
                class="error-text"
                v-for="(err, index) in newAddressError.address_details"
                :key="index"
              >
                {{ err }}
              </span>
            </div>
          </div>

          <div class="new-address-create-buttons">
            <button @click="cancelAddNewAddress">لغو</button>

            <button @click.prevent="submitNewAddress">ثبت آدرس</button>
          </div>
        </div>
      </div>

      <div class="selected-addresses-info p-0">
        <div
          v-for="(address, index) in addresses"
          v-bind:key="index"
          class="selected-addresses-show-and-edit p-0"
        >
          <div
            v-show="!editSelectedAddressInfoShow[index]"
            class="single-address-info"
          >
            <div
              :id="index"
              style="
                position: absolute;
                display: none;
                width: 100%;
                height: 105%;
                top: -10px;
                left: 0px;
                background-color: rgba(255, 255, 255, 0.9);
                z-index: 10;
              "
            ></div>

            <div class="single-address-title">
              <h5>{{ address.title }}</h5>

              <div class="address-edit-and-delete">
                <img
                  @click="editSelectedAddressInfo(index, address)"
                  src="../../assets/images/icons/edit-inputs-icon.svg"
                  alt=""
                />

                <img
                  @click="deleteCartSelectedAddress(address)"
                  src="../../assets/images/icons/delete-profile-icons.svg"
                  alt=""
                />
              </div>
            </div>

            <div class="single-address-content p-0 col-12">
              <div class="single-address-details-title">
                <p>عنوان</p>
                <p>استان</p>
                <p>شهر</p>
                <p>کد پستی</p>
                <p>تلفن ثابت</p>
                <p>شماره موبایل</p>
                <p>جزئیات آدرس</p>
              </div>

              <div class="single-address-details-content">
                <p>{{ address.title }}</p>
                <p>{{ address.province }}</p>
                <p>{{ address.city.province }}</p>
                <p>{{ address.post_code }}</p>
                <p>{{ address.phone_number }}</p>
                <p>{{ address.mobile_number }}</p>
                <p>{{ address.address_details.slice(0, 40) + "..." }}</p>
              </div>
            </div>

            <!-- mobile section -->
            <!-- <div class="edit-and-delete-buttons-mobile">
                            <button @click="editSelectedAddressInfo(index)" class="edit-address-mobile">
                                ویرایش
                                <img src="../../assets/images/icons/edit-address-icon-mobile.svg" alt="">
                            </button>

                            <button @click="deleteSelectedAddressInfo(index)" class="delete-address-mobile">
                                حذف
                                <img src="../../assets/images/icons/delete-address-mobile-icon.svg" alt="">
                            </button>
                        </div> -->
          </div>

          <div
            v-show="editSelectedAddressInfoShow[index]"
            class="edit-selected-address-info"
          >
            <form class="edit-selected-address-info-form col-12 p-0">
              <div class="p-0 selected-address-title">
                <label for="selected-address-title1">عنوان</label>
                <input
                  v-model="editSelectedAddressInputValue.title"
                  name="selectedAddressTitle"
                  id="selected-address-title1"
                  type="text"
                />
              </div>

              <div class="postal-code p-0">
                <label for="postal-code1">کد پستی</label>
                <input
                  @input="updateAddressError.post_code = []"
                  :class="{
                    'error-border': updateAddressError.post_code.length > 0,
                  }"
                  v-model="editSelectedAddressInputValue.post_code"
                  type="text"
                  id="postal-code1"
                />

                <span
                  v-if="updateAddressError.post_code.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.post_code"
                  :key="index"
                  >{{ err }}</span
                >
              </div>

              <div class="selected-address-state p-0">
                <label for="selected-address-state1">استان</label>

                <div
                  :class="[
                    'selected-address-state-div',
                    {
                      borderActiveFocus:
                        selectedAddressStateFocusActiveAddressPage,
                    },
                    {
                      'error-border': updateAddressError.province_id.length > 0,
                    },
                  ]"
                >
                  <!-- v-model="editSelectedAddressInputValue.province_id" -->

                  <select
                    @input="
                      (e) => {
                        updateAddressError.province_id = [];
                        editSelectedAddressInputValue.province_id =
                          e.target.value;
                      }
                    "
                    @focus="selectedAddressStateFocusActiveAddressPage = true"
                    @blur="selectedAddressStateFocusActiveAddressPage = false"
                    id="selected-address-state1"
                  >
                    <option
                      v-if="provinces.length > 0"
                      v-for="(state, pi) in provinces"
                      :key="pi"
                      :selected="
                        editSelectedAddressInputValue.province_id == state.id
                      "
                      :value="state.id"
                    >
                      {{ state.province }}
                    </option>
                  </select>
                </div>
                <span
                  v-if="updateAddressError.province_id.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.province_id"
                  :key="index"
                >
                  {{ err }}
                </span>

                <!-- mobile section -->
                <!-- <div @click="showModalStateForAddressMob(index)"
                                    class="selected-address-state-div-mobile">
                                    <span>{{ addresses.stateEditDataMobile.selectedStateMobileFinal }}</span>
                                    <img src="../../assets/images/icons/profile-select-arrow-mobile.svg" alt="">
                                </div> -->

                <!-- mobile section -->
                <!-- <div @click.self="closeStateModalMobile(index)"
                                    :id="'state-select-modal-mobile-background' + index"
                                    class="state-select-modal-mobile-background">

                                    <div @touchstart.self="closeStateModalMobileTouchStart"
                                        @touchmove.self="closeStateModalMobileTouchMove"
                                        @touchend.self="closeStateModalMobileTouchEnd(index)"
                                        :style="{ bottom: stateBottom }"
                                        :id="'state-select-modal-mobile-content' + index"
                                        class="state-select-modal-mobile-content">

                                        <div class="state-select-modal-line"></div>

                                        <p>انتخاب استان</p>


                                        <div class="state-search-for-select-state-mobile-content">

                                            <div
                                                :class="['state-search-input-div',
                                                { 'active-state-search-input-div': addresses.stateEditDataMobile.stateMobileInputSearchValue != '' }]">

                                                <input
                                                    v-model="addresses.stateEditDataMobile.stateMobileInputSearchValue"
                                                    @input="resultSearch(index)" type="text">

                                                <img v-if="addresses.stateEditDataMobile.stateMobileInputSearchValue == ''"
                                                    src="../../assets/images/icons/search-icon-for-mobile.svg" alt="">

                                                <img v-else
                                                    src="../../assets/images/icons/active-search-icon-input-mobile.svg"
                                                    alt="">

                                            </div>


                                            <div class="state-search-result-content">

                                                <label v-for="(state, s) in resultSearch(index)" :key="s"
                                                    :for="'state' + s">

                                                    <div
                                                        :class="['state-radio-out-circle',
                                                        { selectMobileState: addresses.stateEditDataMobile.stateMobileValue == state }]">
                                                        <div :class="{
                                                            'state-radio-into-circle':
                                                                addresses.stateEditDataMobile.stateMobileValue == state
                                                        }"></div>
                                                    </div>

                                                    <input style="display: none"
                                                        v-model="addresses.stateEditDataMobile.stateMobileValue"
                                                        type="radio" :value="state" :id="'state' + s">

                                                    {{ state }}

                                                </label>

                                            </div>

                                        </div>


                                        <button type="button" @click="submitStateModalMobile(index)"
                                            :disabled="addresses.stateEditDataMobile.stateMobileValue == ''"
                                            :class="['submit-select-state-modal',
                                            { activeSubmitStateModal: addresses.stateEditDataMobile.stateMobileValue != '' }]">
                                            تایید
                                        </button>

                                    </div>
                                </div> -->
              </div>

              <div class="selected-address-state p-0">
                <label for="selected-address-state1">شهر</label>

                <div
                  style="width: 100%"
                  :class="[
                    'selected-address-state-div',
                    { 'error-border': updateAddressError.city_id.length > 0 },
                    {
                      borderActiveFocus:
                        selectedAddressStateFocusActiveAddressPage,
                    },
                  ]"
                >
                  <!-- v-model="editSelectedAddressInputValue.city_id" -->

                  <select
                    @input="
                      (e) => {
                        updateAddressError.city_id = [];
                        editSelectedAddressInputValue.city_id = e.target.value;
                      }
                    "
                    @click.prevent="showEditCities"
                    @focus="selectedAddressStateFocusActiveAddressPage = true"
                    @blur="selectedAddressStateFocusActiveAddressPage = false"
                    value=""
                    id="selected-address-state1"
                  >
                    <option
                      v-if="cities.length > 0"
                      v-for="(city, ci) in cities"
                      :key="ci"
                      :selected="
                        editSelectedAddressInputValue.city_id == city.id
                      "
                      :value="city.id"
                    >
                      {{ city.province }}
                    </option>
                    <option v-else>خطا در شبکه</option>
                  </select>
                </div>
                <span
                  v-if="updateAddressError.city_id.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.city_id"
                  :key="index"
                  >{{ err }}</span
                >
              </div>

              <div class="selected-address-city p-0">
                <label for="selected-address-city1">شماره موبایل</label>
                <input
                  @input="updateAddressError.mobile_number = []"
                  :class="{
                    'error-border': updateAddressError.mobile_number.length > 0,
                  }"
                  v-model="editSelectedAddressInputValue.mobile_number"
                  id="selected-address-city1"
                  type="number"
                />

                <span
                  v-if="updateAddressError.mobile_number.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.mobile_number"
                  :key="index"
                >
                  {{ err }}
                </span>
              </div>

              <div class="selected-address-telephone p-0">
                <label for="selected-address-telephone1">تلفن ثابت</label>
                <input
                  @input="updateAddressError.phone_number = []"
                  :class="{
                    'error-border': updateAddressError.phone_number.length > 0,
                  }"
                  v-model="editSelectedAddressInputValue.phone_number"
                  id="selected-address-telephone1"
                  type="number"
                />
                <span
                  v-if="updateAddressError.phone_number.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.phone_number"
                  :key="index"
                  >{{ err }}</span
                >
              </div>

              <div class="selected-addresses-address p-0">
                <label for="selected-addresses-address1">جزئیات آدرس</label>
                <textarea
                  @input="updateAddressError.address_details = []"
                  :class="{
                    'error-border':
                      updateAddressError.address_details.length > 0,
                  }"
                  v-model="editSelectedAddressInputValue.address_details"
                  id="selected-addresses-address1"
                  type="text"
                ></textarea>

                <span
                  v-if="updateAddressError.address_details.length > 0"
                  class="error-text"
                  v-for="(err, index) in updateAddressError.address_details"
                  :key="index"
                  >{{ err }}</span
                >
              </div>

              <div class="selected-address-buttons">
                <button @click.prevent="cancelEditSelectedAddressInfo(index)">
                  لغو
                </button>
                <button @click.prevent="submitUpdateAddress(address, index)">
                  ثبت ویرایش
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </UserAccount>
</template>

<script>
import UserAccount from "./UserAccount.vue";
export default {
  name: "Addresses",
  components: { UserAccount },
  data() {
    return {
      editSelectedAddressInfoShow: [],
      hideDivItems: [],
      showCreateAddressPage: false,
      hideAddress: false,
      selectedAddress: {
        states: [
          "تهران",
          "خراسان",
          "اصفهان",
          "فارس",
          "کهکلویه و بویر احمد",
          "آذربایجان",
          "مازندران",
          "البرز",
        ],
      },

      //  add new address state
      addNewAddressInputs: {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      },
      //  add new address errors
      newAddressError: {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      },
      //  edit address state
      editSelectedAddressInputValue: {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      },

      //  edit address errors
      updateAddressError: {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      },

      // selectedAddressObject: [
      //     {
      //         title: "اطلاعات شخصی 1",
      //         AddressTitle: {
      //             title: "عنوان",
      //             state: "استان",
      //             city: "شهر",
      //             postalCode: "کد پستی",
      //             constTelephone: "تلفن ثابت",
      //             address: "آدرس"
      //         },
      //         AddressContent: {
      //             title: ":->",
      //             state: "تهران",
      //             city: "تهران",
      //             postalCode: "09122277626",
      //             constTelephone: "02125685941",
      //             address: ".این سفارش در تاریخ دوشنبه 25 آبان ساعت 5 عصر به آدرس استان"
      //         },
      //         stateEditDataMobile: {
      //             stateMobileValue: "",
      //             stateMobileInputSearchValue: "",
      //             selectedStateMobileFinal: "تهران",
      //         }
      //     },
      //     {
      //         title: "اطلاعات شخصی 2",
      //         AddressTitle: {
      //             title: "عنوان",
      //             state: "استان",
      //             city: "شهر",
      //             postalCode: "کد پستی",
      //             constTelephone: "تلفن ثابت",
      //             address: "آدرس"
      //         },
      //         AddressContent: {
      //             title: ":->",
      //             state: "تهران",
      //             city: "تهران",
      //             postalCode: "09122277626",
      //             constTelephone: "02125685941",
      //             address: ".این سفارش در تاریخ دوشنبه 25 آبان ساعت 5 عصر به آدرس استان"
      //         },
      //         stateEditDataMobile: {
      //             stateMobileValue: "",
      //             stateMobileInputSearchValue: "",
      //             selectedStateMobileFinal: "تهران",
      //         }
      //     },
      //     {
      //         title: "اطلاعات شخصی 3",
      //         AddressTitle: {
      //             title: "عنوان",
      //             state: "استان",
      //             city: "شهر",
      //             postalCode: "کد پستی",
      //             constTelephone: "تلفن ثابت",
      //             address: "آدرس"
      //         },
      //         AddressContent: {
      //             title: ":->",
      //             state: "تهران",
      //             city: "تهران",
      //             postalCode: "09122277626",
      //             constTelephone: "02125685941",
      //             address: ".این سفارش در تاریخ دوشنبه 25 آبان ساعت 5 عصر به آدرس استان",
      //         },
      //         stateEditDataMobile: {
      //             stateMobileValue: "",
      //             stateMobileInputSearchValue: "",
      //             selectedStateMobileFinal: "تهران",
      //         }
      //     },
      // ],
      selectedAddressStateFocusActiveAddressPage: false,
      /* mobile data */
      screenWidth: null,
      /* state mobile modal data */
      stateBottom: "",
      stateMobileTouchStart: null,
      stateMobileTouchMove: null,
      /* state mobile modal new address data */
      stateBottomNewAddress: "",
      stateMobileTouchStartNewAddress: null,
      stateMobileTouchMoveNewAddress: null,
      stateMobileValueNewAddress: "",
      stateMobileInputSearchValueNewAddress: "",
      selectedStateMobileFinalNewAddress: "تهران",
    };
  },
  mounted() {
    // for mobile
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
    if (
      (this.screenWidth < 426 && this.$route.path == "/profile/address") ||
      this.$route.path == "/profile/address/"
    ) {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      header.style.display = "none";
      userAccountSliderMobile.style.display = "none";
    }
    // for (let i = 0; i < this.selectedAddressObject.length; i++) {
    //     this.editSelectedAddressInfoShow.push(false);
    //     this.hideDivItems.push(i);
    // }
    // this.$forceUpdate();
    // console.log(this.hideDivItems);
  },
  methods: {
    //  method for show edit form
    editSelectedAddressInfo(index, item) {
      //  get all cities
      this.$store.dispatch("citiesDispatch", {
        province_id: item.city.parent_id,
      });

      this.$store
        .dispatch("editAddressDispatch", {
          token: this.token,
          address_id: item.id,
        })
        .then((res) => {
          if (res.status) {
            this.editSelectedAddressInfoShow[index] = true;
            this.$forceUpdate();
            this.hideDivItems.forEach((i) => {
              if (i != index) {
                document.getElementById(i).style.display = "block";
              }
            });

            this.editSelectedAddressInputValue = {
              title: res.data.title,
              city_id: res.data.city.id,
              province_id: res.data.province_id,
              post_code: res.data.post_code,
              phone_number: res.data.phone_number,
              mobile_number: res.data.mobile_number,
              address_details: res.data.address_details,
              default: 0,
            };
          }
        });

      this.$forceUpdate();
    },

    // delete address mobile
    // deleteSelectedAddressInfo(index) {
    //     console.log(index);
    //     this.selectedAddressObject.splice(index, 1);
    //     // this.$forceUpdate();
    // },
    cancelEditSelectedAddressInfo(index) {
      this.editSelectedAddressInfoShow[index] = false;
      this.$forceUpdate();
      this.hideDivItems.forEach((item) => {
        if (item != index) {
          document.getElementById(item).style.display = "none";
        }
      });
    },

    //  method for show add new address form
    addNewAddress() {
      this.showCreateAddressPage = true;
      this.hideDivItems.forEach((item) => {
        document.getElementById(item).style.display = "block";
      });
    },

    //  method for hide add new address form
    cancelAddNewAddress() {
      this.showCreateAddressPage = false;
      this.hideDivItems.forEach((item) => {
        document.getElementById(item).style.display = "none";
      });
      this.addNewAddressInputs = {
        title: "",
        city_id: "",
        province_id: "",
        post_code: "",
        phone_number: "",
        mobile_number: "",
        address_details: "",
        default: 0,
      };

      this.newAddressError = {
        province_id: [],
        address_details: [],
        mobile_number: [],
        phone_number: [],
        post_code: [],
        city_id: [],
      };

      this.$forceUpdate();
    },

    //  method for submit new address
    submitNewAddress() {
      //  add token
      this.addNewAddressInputs.token = this.token;
      //  dispatch for new address
      this.$store
        .dispatch("newAddressDispatch", this.addNewAddressInputs)
        .then((res) => {
          if (res.status) {
            //  action for get all addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });

            //  action for show success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            // hide form
            this.showCreateAddressPage = false;

            for (let i = 0; i < this.addresses.length; i++) {
              // this.editSelectedAddressInfoShow.push(false);
              this.hideDivItems.push(i);
              document.getElementById(i).style.display = "none";
            }
            this.$forceUpdate();

            this.addNewAddressInputs = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.province_id = res.message.province_id)
                : this.newAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.address_details =
                    res.message.address_details)
                : this.newAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.mobile_number =
                    res.message.mobile_number)
                : this.newAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.phone_number = res.message.phone_number)
                : this.newAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.post_code = res.message.post_code)
                : this.newAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.newAddressError.city_id = res.message.city_id)
                : this.newAddressError.city_id.push(res.message);
            }
          }
        });
    },

    /* delete selected Address methods */
    deleteCartSelectedAddress(item) {
      this.$store
        .dispatch("deleteAddressDispatch", {
          token: this.token,
          address_id: item.id,
        })
        .then((res) => {
          if (res.status) {
            //  action for get all addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });
            //  action for show success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });
          } else {
            //  action for show warning alert
            this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },

    //  method for update address
    submitUpdateAddress(item, index) {
      //  add token
      this.editSelectedAddressInputValue.token = this.token;

      //  address_id
      this.editSelectedAddressInputValue.address_id = item.id;

      // update address dispatch
      this.$store
        .dispatch("updateAddressDispatch", this.editSelectedAddressInputValue)
        .then((res) => {
          if (res.status) {
            // refresh addresses
            this.$store.dispatch("userAddressesDispatch", {
              token: this.token,
            });

            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            //  cancle edit mode
            this.editSelectedAddressInfoShow[index] = false;

            this.$forceUpdate();

            this.hideDivItems.forEach((i) => {
              if (i != index) {
                document.getElementById(i).style.display = "none";
              }
            });

            //  empty update state
            this.editSelectedAddressInputValue = {
              title: "",
              city_id: "",
              province_id: "",
              post_code: "",
              phone_number: "",
              mobile_number: "",
              address_details: "",
              default: 0,
            };
          } else {
            //  show errors
            if ("province_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.province_id =
                    res.message.province_id)
                : this.updateAddressError.province_id.push(res.message);
            }

            if ("address_details" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.address_details =
                    res.message.address_details)
                : this.updateAddressError.address_details.push(res.message);
            }

            if ("mobile_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.mobile_number =
                    res.message.mobile_number)
                : this.updateAddressError.mobile_number.push(res.message);
            }

            if ("phone_number" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.phone_number =
                    res.message.phone_number)
                : this.updateAddressError.phone_number.push(res.message);
            }

            if ("post_code" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.post_code = res.message.post_code)
                : this.updateAddressError.post_code.push(res.message);
            }

            if ("city_id" in res.message) {
              typeof res.message != String
                ? (this.updateAddressError.city_id = res.message.city_id)
                : this.updateAddressError.city_id.push(res.message);
            }
          }
        });
    },

    /* mobile methods */
    showModalStateForAddressMob(id) {
      console.log(id);
      document.documentElement.style.overflow = "hidden";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background" + id
      );
      background.style.height = "100%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content" + id
      );
      modalContent.style.maxHeight = "100%";
      this.stateBottom = "0px";
    },
    closeStateModalMobile(id) {
      document.documentElement.style.overflow = "auto";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background" + id
      );
      background.style.height = "0%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content" + id
      );
      modalContent.style.maxHeight = "0px";
      this.selectedAddressObject[
        id
      ].stateEditDataMobile.stateMobileInputSearchValue = "";
      this.selectedAddressObject[id].stateEditDataMobile.stateMobileValue = "";
    },
    closeStateModalMobileTouchStart(e) {
      this.stateMobileTouchStart = e.touches[0].clientY;
    },
    closeStateModalMobileTouchMove(e) {
      let moveTouch = e.touches[0].clientY;
      this.stateMobileTouchMove = this.stateMobileTouchStart - moveTouch;
      // console.log(this.genderTouchStart, moveTouch, this.changeTouchMove)
      if (this.stateMobileTouchMove < 0) {
        this.stateBottom = this.stateMobileTouchMove + "px";
      }
    },
    // closeStateModalMobileTouchEnd(id) {
    //     if (this.stateMobileTouchMove < 0 && this.stateMobileTouchMove < -80) {
    //         this.stateBottom = -this.screenWidth;
    //         /* get gender-select-modal-mobile-background  */
    //         const background = document.getElementById("state-select-modal-mobile-background" + id);
    //         background.style.height = "0%";
    //         /* get gender-select-modal-mobile-content */
    //         const modalContent = document.getElementById("state-select-modal-mobile-content" + id);
    //         modalContent.style.maxHeight = "0px";
    //         document.documentElement.style.overflow = "auto";
    //     }
    // },
    submitStateModalMobile(id) {
      this.selectedAddressObject[
        id
      ].stateEditDataMobile.selectedStateMobileFinal =
        this.selectedAddressObject[id].stateEditDataMobile.stateMobileValue;
      console.log(id);
      document.documentElement.style.overflow = "auto";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background" + id
      );
      background.style.height = "0%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content" + id
      );
      modalContent.style.maxHeight = "0px";
      this.selectedAddressObject[
        id
      ].stateEditDataMobile.stateMobileInputSearchValue = "";
      this.selectedAddressObject[id].stateEditDataMobile.stateMobileValue = "";
    },
    /* result search for state mobile */
    resultSearch(index) {
      if (
        this.selectedAddressObject[index].stateEditDataMobile
          .stateMobileInputSearchValue === ""
      ) {
        return null;
      } else {
        return this.selectedAddress.states.filter((state) => {
          return state.match(
            this.selectedAddressObject[index].stateEditDataMobile
              .stateMobileInputSearchValue
          );
        });
      }
    },
    backToSidebarMobileFromAddressesPage() {
      /* get profile addresses */
      const profileAddresses = document.getElementById("profile-addresses");
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      if (this.screenWidth < 426) {
        profileAddresses.style.display = "none";
        header.style.display = "flex";
        userAccountSliderMobile.style.display = "flex";
        let resolved = this.$router.resolve({
          name: "userAccount",
        });

        location.href = resolved.href;
      }
    },
    /* new address state modal methods */
    showModalStateForNewAddress() {
      document.documentElement.style.overflow = "hidden";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background-new-address"
      );
      background.style.height = "100%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content-new-address"
      );
      modalContent.style.maxHeight = "100%";
      this.stateBottomNewAddress = "0px";
    },
    closeStateModalMobileNewAddress() {
      document.documentElement.style.overflow = "auto";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background-new-address"
      );
      background.style.height = "0%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content-new-address"
      );
      modalContent.style.maxHeight = "0px";
      this.stateMobileInputSearchValueNewAddress = "";
      this.stateMobileValueNewAddress = "";
    },
    closeStateModalMobileTouchStartNewAddress(e) {
      this.stateMobileTouchStartNewAddress = e.touches[0].clientY;
    },
    closeStateModalMobileTouchMoveNewAddress(e) {
      let moveTouch = e.touches[0].clientY;
      this.stateMobileTouchMoveNewAddress =
        this.stateMobileTouchStartNewAddress - moveTouch;
      // console.log(this.genderTouchStart, moveTouch, this.changeTouchMove)
      if (this.stateMobileTouchMoveNewAddress < 0) {
        this.stateBottomNewAddress = this.stateMobileTouchMoveNewAddress + "px";
      }
    },
    // closeStateModalMobileTouchEndNewAddress() {
    //     if (this.stateMobileTouchMoveNewAddress < 0 && this.stateMobileTouchMoveNewAddress < -80) {
    //         this.stateBottomNewAddress = -this.screenWidth;
    //         /* get gender-select-modal-mobile-background  */
    //         const background = document.getElementById("state-select-modal-mobile-background-new-address");
    //         background.style.height = "0%";
    //         /* get gender-select-modal-mobile-content */
    //         const modalContent = document.getElementById("state-select-modal-mobile-content-new-address");
    //         modalContent.style.maxHeight = "0px";
    //         document.documentElement.style.overflow = "auto";
    //     }
    // },
    submitStateModalMobileNewAddress() {
      this.selectedStateMobileFinalNewAddress = this.stateMobileValueNewAddress;
      document.documentElement.style.overflow = "auto";
      console.log(document.documentElement.style.overflow);
      /* get state-select-modal-mobile-background  */
      const background = document.getElementById(
        "state-select-modal-mobile-background-new-address"
      );
      background.style.height = "0%";
      /* get state-select-modal-mobile-content */
      const modalContent = document.getElementById(
        "state-select-modal-mobile-content-new-address"
      );
      modalContent.style.maxHeight = "0px";
      this.stateMobileInputSearchValueNewAddress = "";
      this.stateMobileValueNewAddress = "";
    },

    //  method for show cities of select box
    showCities() {
      this.$store.dispatch("citiesDispatch", {
        province_id: this.addNewAddressInputs.province_id,
      });
    },
    showEditCities() {
      this.$store.dispatch("citiesDispatch", {
        province_id: this.editSelectedAddressInputValue.province_id,
      });
    },
  },
  computed: {
    resultSearchComputed() {
      if (this.stateMobileInputSearchValueNewAddress === "") {
        return null;
      } else {
        return this.selectedAddress.states.filter((state) => {
          return state.match(this.stateMobileInputSearchValueNewAddress);
        });
      }
    },

    //  method for get addresses
    addresses() {
      if (this.$store.getters.getUserAddresses != null) {
        for (let i = 0; i < this.$store.getters.getUserAddresses.length; i++) {
          this.editSelectedAddressInfoShow.push(false);
          this.hideDivItems.push(i);
        }
        return this.$store.getters.getUserAddresses;
      }
    },

    // method for get token
    token() {
      return this.$store.getters.getToken;
    },

    //  method for get provinces
    provinces() {
      if (this.$store.getters.getProvinces != null) {
        return this.$store.getters.getProvinces;
      }
    },
    //  method for get cities
    cities() {
      if (this.$store.getters.getCities != null) {
        return this.$store.getters.getCities.cities;
      }
    },
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get all addresses of user
    this.$store.dispatch("userAddressesDispatch", { token: this.token });

    //  dispatch for get all province
    this.$store.dispatch("provincesDispatch");
  },
};
</script>

<style src="../../assets/css/userAccount/addresses.css" scoped></style>
