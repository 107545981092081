import http from "../axios/axios";

// const value for store slug labniid project
// const store_slug = "LABNIID";

// const value for store slug default vitsell
const store_slug = "nina";

// promise for get Header Categories
export const httpHeaderCategories = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/productCategoryStore/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// promise for get store info
export const httpStoreInfo = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/store/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// promise for get aboutUs info
export const httpStoreAboutUsInfo = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeAboutUs/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// promise for get contactUs info
export const httpStoreContactUs = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/contactToUs/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const httpShowProductInfo = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`productStore/${store_slug}/${payload.id}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        } else {
          location.href = "/404";
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// paginated product comments
export const httpProductComments = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(
        `/storeProductComment/${store_slug}/relatedList?product_id=${payload.product_id
        }&page=${payload.page}&isPaginated=${1}`
      )
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// all product comments
export const httpAllProductComments = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(
        `/storeProductComment/${store_slug}/relatedList?product_id=${payload.product_id
        }&page=${payload.page}&isPaginated=${0}`
      )
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const httpAllProducts = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(
        `/productStore/${store_slug}?page=${payload.page}&search=${payload.search}&mostSaleProducts=${payload.mostSaleProducts}&newestProducts=${payload.newestProducts}&cheapestProducts=${payload.cheapestProducts}&mostExpensiveProducts=${payload.mostExpensiveProducts}&availableStatus=${payload.availableStatus}&minPrice=${payload.minPrice}&maxPrice=${payload.maxPrice}&propertyKey=${payload.propertyKey}&propertyValues=${payload.propertyValues}&productCategories=${payload.productCategories}`
      )
      .then((res) => {
        if (res.data.status) {
          resolve(res.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get color prop and all values for filters in main page of store
export const httpColorProp = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeProp/${store_slug}/relatedList`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get products of discount slider
export const httpDiscountSlider = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/productStore/${store_slug}/report`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.productPricesSlider);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get products of related slider
export const httpRelatedSlider = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(
        `/productStore/${store_slug}/relatedList?product_id=${payload.product_id}`
      )
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// api for get fq questions in store main page
export const httpFqQuestions = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeFrequentlyQuestion/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// api for get all questions in questions page
export const httpAllQuestions = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeFrequentlyQuestionCategory/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http request for get show specific categories
export const httpShowCategories = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/productCategoryStore/${store_slug}/${payload}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http request fo post phone number in login
export const httpPhoneNumber = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .post("/storeUser/auth/phoneNumber", {
        phone_number: payload,
      })
      .then((res) => {
        if (res.data.status) {
          resolve({ status: res.data.status });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for login with verification code
export const httpVerificationCode = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .post("/storeUser/auth/checkVerifyCode", payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            access_token: res.data.access_token,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for login with password
export const httpLoginPass = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .post("/storeUser/auth/checkPassword", payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            access_token: res.data.access_token,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for post product comment
export const httpPostComment = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/storeProductComment/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for post new message from users of store
export const httpPostMessage = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/storeUserMessage/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get user loggedIn info
export const httpUser = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeUser/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for add product to cart
export const httpAddToCart = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/storeCart/${store_slug}`, payload)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for remove product from cart in show product page
export const httpRemoveProductFromCart = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .delete(
        `/storeCart/destroyProduct/${store_slug}?productPrice_id=${payload.productPrice_id}&seller_id=${payload.seller_id}`
      )
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for remove product from cart in cart page
export const httpRemoveFromCart = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .delete(
        `/storeCart/${store_slug}/${payload.cartItem_id}?cart_id=${payload.cart_id}`
      )
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get all cart items info
export const httpCartItems = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeCart/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        } else {
          resolve({
            order_items: [],
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// htt req for update count of produtc into cart
export const httpUpdateCount = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(
        `/storeCart/${store_slug}/${payload.orderItem_id}?cart_id=${payload.cart_id}`,
        payload
      )
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  method for get all addresses of user
export const httpAddresses = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/userAddress/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve(res.data.data);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get all province of iran
export const httpProvinces = () => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeProvince`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get all cities of specific province
export const httpCities = (payload) => {
  return new Promise((resolve, reject) => {
    http()
      .get(`/storeProvince/${payload.province_id}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for post new address of user
export const httpNewAddress = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/userAddress/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for destroy address of user
export const httpDeleteAddress = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .delete(`/userAddress/${store_slug}/${payload.address_id}`)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get selected edit address
export const httpEditAddress = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/userAddress/${store_slug}/${payload.address_id}/edit`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for update address
export const httpUpdateAddress = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(`/userAddress/${store_slug}/${payload.address_id}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
            message: res.data.message,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for change cart delivery address and store delivery type
export const httpChangeCart = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(`/storeCart/changeCart/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.cart,
            message: res.data.message,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get storeDeliveryTypes of cart(order)
export const httpStoreDeliveryTypes = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeDeliveryType/${store_slug}?order_id=${payload.order_id}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get all userMessages of profile
export const httpUserMessages = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeUserMessage/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get all userComments of profile
export const httpUserComments = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeUserComment/${store_slug}?page=${payload.page}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for desroy comment of user in profile
export const httpDestroyComment = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .delete(`/storeUserComment/${store_slug}/${payload.comment_id}`)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for update userInfo in profile
export const httpUpdateUser = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(`/storeUser/profile/${store_slug}`, payload)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for get orders of user in profile
export const httpUserOrders = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(
        `storeUser/orders/${store_slug}?order_status=${payload.order_status}`
      )
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
            count_result: res.data.count_result,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for show specific order info in profile of user
export const httpShowOrder = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`/storeUser/${store_slug}/${payload.order_id}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            data: res.data.data,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      });
  });
};

//  http req for logOut user
export const httpLogOut = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/storeUser/auth/logout`, payload)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for change pass with current pass
export const httpChangePassWithCurrent = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(`/storeUser/forgetPass/currentPass/${store_slug}`, payload)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for send verify code for change pass
export const httpGetVerifyCodeForChangePass = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`storeUser/forgetPass/getVerifyCode/${store_slug}`)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for change pass with verifyCode
export const httpChangePassWithVerifyCode = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .put(`storeUser/forgetPass/verifyCode/${store_slug}`, payload)
      .then((res) => {
        resolve({
          status: res.data.status,
          message: res.data.message,
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http req for get returned order
export const httpGetAllReturnedOrders = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`storeUser/returnedOrders/${store_slug}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            data: res.data.data,
            status: res.data.status,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const httpShowReturnOrder = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .get(`storeReturnedOrder/${store_slug}/${payload.orderItem_id}`)
      .then((res) => {
        if (res.data.status) {
          resolve({
            data: res.data.data,
            status: res.data.status,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//  http req for create return order
export const httpCreateReturnOrder = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`storeReturnedOrder/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// http request for send info demo user
export const httpSubmitDemoUser = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post("demoUser", payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            message: res.data.message,
            access_token: res.data.access_token,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//*** http request for checkout step one (gateway)
export const httpOrderPaymentGateway = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/StoreOrderPayment/gateway/${store_slug}`, payload)
      .then((res) => {
        if (res.data.status) {
          resolve({
            status: res.data.status,
            gateway_link: res.data.gateway_link,
          });
        } else {
          resolve({
            status: res.data.status,
            message: res.data.message,
          });
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

//*** http request for checkout step one (verify orderPayment)
export const httpOrderPaymentVerify = (payload) => {
  return new Promise((resolve, reject) => {
    http(payload.token)
      .post(`/StoreOrderPayment/verifyPayment/${store_slug}`, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
