import { render, staticRenderFns } from "./FrequentlyAskedQuestions.vue?vue&type=template&id=17d0ee72&scoped=true&"
import script from "./FrequentlyAskedQuestions.vue?vue&type=script&lang=js&"
export * from "./FrequentlyAskedQuestions.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/store/frequentlyQuestions.css?vue&type=style&index=0&id=17d0ee72&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17d0ee72",
  null
  
)

export default component.exports