<template>
  <div id="privacy-parent">
    <!-- for mobile size secton -->
    <!-- <div class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage"
                 src="../../assets/images/icons/back-arrow-mobile3.svg"
                 alt="">
            <p>حریم خصوصی</p>
        </div> -->

    <div class="privacy-page">
      <app-header id="app-header-mobile"></app-header>

      <div class="privacy-page-content">
        <h5>حریم خصوصی</h5>

        <div class="privacy-page-back-product">
          <div class="privacy-page-back-product-title">
            <hr />
            <h5>مفهوم حریم خصوصی</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-top">
                «اطلاعاتی که در صورت افشا شدن موجب صدمات جبران ناپذیر جسمی و
                روحی به افراد جامعه خواهد شد.» از قبیل این اطلاعات می توان به؛
                کد ملی، تاریخ تولد، وضعیت تاهل، نشانی محل سکونت یا محل کار،
                اطلاعات مالی و... اشاره کرد .
              </li>

              <li class="description-li-center">
                " کاربران گرامی در ابتدا باید توجه داشته باشید که قوانین مربوط
                به بخش حریم خصوصی ممکن است در بازه های زمانی متفاوت مورد بازنگری
                و بروز رسانی قرار گیرند لذا به شما سروران توصیه می شود همواره به
                این بخش مراجعه کنید تا از موارد تغییر یافته یا افزوده شده مطلع
                گردید. (لازم به ذکر است عدم مراجعه به منزله قبول شرایط جدید
                است.")

                <br />
              </li>
              <li class="description-li-center">
                ما در لبنید همواره بر اصل پایبندی به قانون جدی هستیم و هدف اصلی
                ما قدم برداشتن در مسیرها و رویه های قانونی است . در همین راستا
                به منظور تامین نیاز های شما کاربران عزیز به برخی اطلاعات هویتی
                شما نیازمندیم که صرفا در جهت موارد مرتبط با فعالیت های اداری شما
                استفاده می شود. برای مثال در صورت درخواست مشتریان حقوقی می
                توانیم فاکتور رسمی با گواهی ارزش افزوده یا بدون آن صادر کنیم که
                در همین راستا به اطلاعاتی از قبیل نام شرکت یا سازمان، شماره ثبت،
                کد اقتصادی /شناسه ملی و... برای این دسته از دوستان الزم است. در
                ادامه به اطلاعاتی که از شما برای انجام امور مربوطه نیاز است
                اشاره میکنیم.

                <br />
              </li>

              <br />
              <br />
            </ul>
          </div>

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>افراد حقیقی:</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center">
                نام و نام خانوادگی، کد ملی، تاریخ تولد، شماره تلفن همراه فعال و
                به نام شخص،آدرس ایمیل، آدرس و کد پستی به منظور ارسال کالا ،
                شماره شبا و کارت بانکی در صورت لغو سفارش و به جهت بازگرداندن
                وجه.
              </li>

              <br />
              <br />
            </ul>
          </div>

          <div class="privacy-page-back-product-title">
            <hr />
            <h5>افراد حقوقی:</h5>
            <hr />
          </div>

          <div class="privacy-page-back-product-content">
            <ul class="description-ul">
              <li class="description-li-center">
                نام سازمان، موسسه یا شرکت، نوع شرکت (سهامی عام – سهامی خاص –
                مسیئولیت محدود – تعاونی)، شماره ثبت (که در روزنامه رسمی درج
                شده)، کد اقتصادی (با توجه به برگه ارزش افزوده )، و مدارک هویتی و
                اقامتی صاحبان حق امضاء (طبق روزنامه رسمی نام کسانی که بعنوان
                صاحبان شرکت حق امضای اوراق و مستندات را دارند)، شماره ثبت، شناسه
                ملی، آدرس و کد پستی (به منظور ارسال کالا خریداری شده)، شماره شبا
                و کارت بانکی (در صورت لغو سفارش و به جهت بازگرداندن وجه)، آدرس
                ایمیل، شماره تلفن ثابت و همراه مربوط به شرکت یا سازمان و ...
              </li>

              <br />

              <li class="description-li-center">
                لازم به ذکر است تمام مکاتبات و اعالنات لبنید به کاربران تنها از
                طریق آدرس پستی، ایمیل و تلفن های اعلامی کاربران صورت می گیرد
                بنابراین درج آدرس، ایمیل و شماره تماسهای همراه و ثابت توسط
                مشتری، به منزله مورد تایید بودن صحت آنها است. لبنید با شماره های
                (91692701-021) ، (100040044040) و آدرس ایمیل
                (labniidofficial@gmail.com)با کاربران خود ارتباط برقرار می کند.
                بنابراین ارسال هرگونه پیامک تحت عنوان لبنید، با هر شماره دیگری
                تخلف و سوء استفاده از نام لبنید است و در صورت دریافت چنین
                پیامکی، لطفا جهت پیگیری قانونی آن را از طرق شماره تماس بالا به
                لبنید اطلاع دهید.
              </li>

              <br />
              <li class="description-li-center">
                لبنید نقد و نظرهای ارسالی کاربران را در راستای رعایت قوانین خود
                ممکن است ویرایش کند و اگر نظر یا پیام ارسال شده توسط کاربر،
                مشمول مصادیق محتوای مجرمانه باشد، میتواند از اطلاعات ثبت شده
                برای پیگیری قانونی استفاده کند. کاربران ضمن استفاده از خدمات
                لبنید، حق ویرایش اطلاعات و استفاده از آنها را در چارچوب فوق
                الذکر به لبنید اعطا نموده و حق اعتراض را از خود سلب مینمایند.
              </li>

              <br />

              <li class="description-li-center">
                باید تاکید شود که حفظ و نگهداری رمز عبور و نام کاربری بر عهده
                کاربران است و لذا برای جلوگیری از هرگونه سواستفاده احتمالی،
                کاربران نباید آن اطلاعات را برای شخص دیگری فاش کنند. در صورتی که
                کاربر شماره همراه خود را به فردی دیگر واگذار کرد، جهت جلوگیری از
                سواستفاده یا مشکالت احتمالی کاربران باید شماره موبایل خود را در
                پروفایل تغییر داده و شماره جدیدی ثبت نمایند. مسئولیت هرگونه
                خسارت احتمالی که ناشی از عدم رعایت موارد فوق الذکر توسط کاربر
                باشد، با خود کاربر بوده وکاربر حق هر گونه ادعا علیه لبنید را از
                خود سلب میکند.
              </li>

              <br />

              <li class="description-li-center">
                از نظر لبنید هویت شخصی کاربران محرمانه بوده و اطلاعات شخصی آنان
                به هیچ شخص یا سازمان دیگری منتقل نمیشود، مگر اینکه با حکم مقام
                قضایی یا اداری صالحه یا طبق قوانین و مقررات رایج کشور موظف باشد
                در اختیار مراجع ذیصالح قرار دهد.در این موارد هیچ گونه مسئولیت
                قانونی مبنی بر جبران خسارت برای لبنید وجود ندارد و کاربران با
                اعالم رضایت خود در استفاده از خدمات وب سایت ضمنا حق هرگونه
                اعتراض را از خود سلب نموده اند.
              </li>
              <br />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "Privacy",
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      // screenWidth: window.screen.width
    };
  },

  methods: {
    // backToSidebarMobileFromAddressesPage() {
    //     /* get about us parent */
    //     const privacyParent = document.getElementById('privacy-parent');
    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');
    //     if (this.screenWidth < 426) {
    //         privacyParent.style.display = 'none';
    //         header.style.display = 'flex';
    //         // console.log(this.prevRouterEnter)
    //         // this.$router.push(this.prevRouterEnter);
    //         this.$router.go(-1);
    //     }
    // }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);
  },

  // mounted() {
  //     if (this.screenWidth < 426 && this.$route.path == '/privacy' || this.$route.path == '/privacy/') {
  //         /* get header mobile */
  //         const header = document.getElementById('app-header-mobile');

  //         header.style.display = 'none'
  //     }
  // }
};
</script>

<style src="../../assets/css/others/privacy.css" scoped></style>
