<template>
  <div class="app-cart">
    <app-header id="app-header-mobile"></app-header>

    <div class="app-cart-details">
      <div class="w-100">
        <!--  cart content for Desktop  -->
        <div class="cart-content-parent">
          <div class="cart-route">
            <div class="cart-img-div">
              <div class="cart-img">
                <img
                  class="img-fluid"
                  src="../../assets/images/pictures/cart-img-icon.svg"
                  alt=""
                />
                <p>سبد خرید</p>
              </div>

              <div class="cart-to-delivery-route-line"></div>

              <div class="delivery-img">
                <img
                  src="../../assets/images/pictures/delivery-information-icon.svg"
                  alt=""
                />
                <p>اطلاعات ارسال</p>
              </div>

              <div class="delivery-to-paymentInfo-route-line"></div>

              <div class="payment-information">
                <img
                  src="../../assets/images/pictures/payment-information-icon2.svg"
                  alt=""
                />
                <p>اطلاعات پرداخت</p>
              </div>
            </div>
          </div>

          <div class="cart-review-product-info">
            <div
              v-show="cartItems.length > 0"
              v-for="(cartProduct, index) in cartItems"
              v-bind:key="index"
              class="cart-review-product-info-content"
            >
              <img :src="cartProduct.product_image" alt="" />

              <div class="cart-review-product-content">
                <div class="product-name-and-edit-delete">
                  <div class="product-name">
                    <p>{{ cartProduct.product_name }}</p>
                    <span>{{ cartProduct.latin_name }}</span>
                  </div>

                  <div class="edit-delete-icon">
                    <img
                      @click="goToProduct(cartProduct)"
                      src="../../assets/images/icons/eye.svg"
                      alt=""
                    />
                    <img
                      @click="deleteCartProduct(cartProduct)"
                      src="../../assets/images/icons/cart-review-delete-icon.svg"
                      alt=""
                    />
                  </div>
                </div>

                <div class="cart-product-info">
                  <div class="count-color-discount">
                    <div class="count">
                      <span>تعداد :</span>

                      <div class="add-reduce-count">
                        <font-awesome-icon
                          @click="plusCount(cartProduct)"
                          icon="fa-plus"
                          class="add-btn"
                        />

                        <font-awesome-icon
                          v-if="cartProduct.isLoading"
                          class="show-count"
                          icon="fa-solid fa-ellipsis"
                        />

                        <span v-else class="show-count">{{
                          cartProduct.product_count
                        }}</span>

                        <font-awesome-icon
                          @click="minusCount(cartProduct)"
                          icon="fa-minus"
                          class="reduce-count"
                        />
                      </div>
                    </div>

                    <div class="color">
                      <span v-if="cartProduct.prop_key != null"
                        >{{ cartProduct.prop_key }} :</span
                      >
                      <span
                        v-if="
                          cartProduct.prop_key == 'رنگ' ||
                          cartProduct.prop_key == 'color'
                        "
                        :style="{
                          backgroundColor: cartProduct.prop_alternativeValue,
                        }"
                        class="color-box"
                      ></span>

                      <span v-else class="prop-box">{{
                        cartProduct.prop_mainValue
                      }}</span>
                    </div>

                    <div class="discount">
                      <span>تخفیف :</span>
                      <span>{{ numberFormat(cartProduct.sum_discount) }}</span>
                      <span>تومان</span>
                    </div>

                    <div class="product-price">
                      <span>قیمت محصول :</span>
                      <span>
                        {{ numberFormat(cartProduct.sum_price) }}
                      </span>
                      <span>تومان</span>
                    </div>
                  </div>

                  <!-- delivery type section -->
                  <!-- <div class="deliveryType-deliveryPrice-productPrice">
                    <div class="delivery-type">
                      <label for="delivery-type">نوع ارسال :</label>

                      <div class="delivery-type-select">
                        <select name="delivery-type" id="delivery-type">
                          <option
                            v-for="(deliveryType,
                            index) in cartProduct.productDeliveryType"
                            v-bind:key="index"
                            :value="deliveryType"
                          >
                            {{ deliveryType }}
                          </option>
                        </select>
                      </div>
                    </div>

                    delivery price section

                    <div class="delivery-price">
                      <span>هزینه ارسال :</span>
                      <span>{{ cartProduct.deliveryPrice }}</span>
                    </div>

                    <div class="product-price">
                      <span>قیمت محصول :</span>
                      <span>
                        {{ cartProduct.sum_price }}
                      </span>
                    </div>
                  </div> -->
                </div>

                <hr
                  v-if="cartItems.length != index + 1"
                  class="cart-review-product-hr"
                />
              </div>
            </div>

            <div
              :style="{
                justifyContent: 'center',
                width: '100%',
                alignItems: 'center',
                maxWidth: '10000px',
              }"
              class="product-name"
              v-show="cartItems.length == 0"
            >
              <p>سبد خرید شما خالی است !</p>
            </div>
          </div>

          <div v-show="cartItems.length > 0" class="discount-and-total-info">
            <!-- discount code input section -->
            <div class="discount-description-input">
              <div class="discount-description">
                <div class="discount-description-right">
                  <span class="dote"></span>
                  <span class="description-right">
                    هفت روز فرصت عودت کالا بدون قید و شرط</span
                  >
                </div>

                <div class="discount-description-center">
                  <span class="dote"></span>
                  <span class="description-center">
                    تضمین بازگشت پول در صورت عدم رضایت</span
                  >
                </div>
              </div>

              <div
                :class="[
                  'discount-input',
                  { activeInput: activeBorderDiscountInput },
                ]"
              >
                <input
                  @focus="activeBorderDiscountInput = true"
                  @blur="activeBorderDiscountInput = false"
                  v-model="cartDiscount"
                  placeholder="در صورتی که کوپن تخفیف دارید در کادر وارد نمایید"
                  type="text"
                  name="discount-input"
                />

                <button
                  :class="[
                    'discount-btn',
                    { activeCartDiscountButton: cartDiscount.length != 0 },
                  ]"
                >
                  اعمال
                </button>
              </div>
            </div>
            <hr class="discount-hr" />

            <div class="total-info">
              <div class="total-info-right">
                <div class="selected-product">
                  <span>تعداد محصولات :</span>
                  <span>{{ totalCount }}</span>
                  <span>عدد</span>
                </div>
                <div class="selected-product">
                  <span>قیمت محصولات :</span>
                  <span>{{ numberFormat(totalPrice) }} </span>
                  <span>تومان</span>
                </div>
                <div class="selected-product">
                  <span>مجموع تخفیفات : </span>
                  <span>{{ numberFormat(totalDiscount) }}</span>
                  <span>تومان</span>
                </div>
              </div>

              <div class="total-info-left">
                <!-- <div class="delivery-price-total">
                            <span> هزینه ارسال : </span>
                            <span>رایگان</span>
                        </div> -->
                <div class="amount-payable">
                  <span>مبلغ قابل پرداخت : </span>
                  <span>{{ numberFormat(cartPrice) }}</span>
                  <span>تومان</span>
                </div>
              </div>
            </div>
          </div>

          <div class="description-and-submit">
            <div class="description-for-add-to-cart">
              <span>افزودن کالا به سبد خرید به معنی رزرو آن نیست ، </span>
              <span>
                با توجه به محدودیت موجودی سبد خود را ثبت و خرید را تکمیل
                کنید.</span
              >
            </div>

            <button @click="goToDeliveryAddress" class="submit">
              ثبت و ادامه
            </button>
          </div>
        </div>

        <!-- cart content for Mobile  -->

        <div class="cart-content-mobile">
          <!--  cart route mobile  -->
          <div class="cart-route-mobile">
            <div class="cart-route-mobile-content">
              <div class="cart-route-mobile-img">
                <img
                  src="../../assets/images/icons/image-route-cart-mobile.svg"
                  alt=""
                />
              </div>
              <p>سبد خرید</p>
            </div>

            <div class="route-mobile-line"></div>

            <div class="cart-route-mobile-delivery-information">
              <div class="deliveryInformation-route-mobile-img">
                <img
                  src="../../assets/images/icons/delivery-information-route-mobile-img.svg"
                  alt=""
                />
              </div>

              <p>اطلاعات ارسال</p>
            </div>

            <div class="route-mobile-line"></div>

            <div class="cart-route-mobile-payment">
              <div class="payment-route-mobile-img">
                <!-- payment icon mobile -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.837"
                  height="28.604"
                  viewBox="0 0 31.837 28.604"
                >
                  <g
                    id="payment-route-mobile-img"
                    transform="translate(-36.229 -124.983)"
                  >
                    <path
                      id="Stroke_1"
                      data-name="Stroke 1"
                      d="M10.685,8.255H4.267A4.2,4.2,0,0,1,0,4.128,4.2,4.2,0,0,1,4.267,0h6.417"
                      transform="translate(56.631 134.956)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_3"
                      data-name="Stroke 3"
                      d="M.794.456H.3"
                      transform="translate(60.83 138.533)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_5"
                      data-name="Stroke 5"
                      d="M8.318,0h13.7a8.187,8.187,0,0,1,8.318,8.048V19.056A8.187,8.187,0,0,1,22.019,27.1H8.318A8.187,8.187,0,0,1,0,19.056V8.048A8.187,8.187,0,0,1,8.318,0Z"
                      transform="translate(36.979 125.733)"
                      fill="none"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_7"
                      data-name="Stroke 7"
                      d="M0,.456H8.558"
                      transform="translate(44.168 132.238)"
                      fill="#4990e2"
                      stroke="#ccc"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
              </div>

              <p>پرداخت</p>
            </div>
          </div>

          <!--  cart review product info  -->
          <div class="cart-review-product-info-mobile">
            <div class="products-count">
              <p>تعداد محصولات :</p>
              <p>{{ totalCount }} عدد</p>
            </div>

            <div class="products-price">
              <p>قیمت محصولات :</p>
              <p>{{ numberFormat(totalPrice) }} تومان</p>
            </div>

            <!-- <div class="delivery-price">
              <p>هزینه ارسال :</p>
              <p>{{}}</p>
            </div> -->

            <div class="total-discount">
              <p>مجموع تخفیفات :</p>
              <p>{{ numberFormat(totalDiscount) }} تومان</p>
            </div>

            <div class="final-price">
              <p>مبلغ قابل پرداخت :</p>
              <p>{{ numberFormat(cartPrice) }} تومان</p>
            </div>

            <hr class="cart-review-product-info-mobile-hr" />

            <div class="discount-code-mobile">
              <p>در صورتی که کوپن تخفیف دارید در کادر زیر وارد نمایید</p>

              <div class="discount-code-mobile-input">
                <input v-model="cartDiscount" type="text" />
                <button
                  :class="{ discountStyleMobile: cartDiscount.length != 0 }"
                  :disabled="cartDiscount.length == 0"
                >
                  اعمال
                </button>
              </div>
            </div>
          </div>

          <!--  cart products  -->
          <div v-show="cartItems.length > 0" class="cart-products-mobile">
            <div
              v-for="(cartProduct, index) in cartItems"
              :key="index"
              :class="['cart-product-mobile-single', { marginTop: index > 0 }]"
            >
              <div class="cart-product-mobile-single-header">
                <p>
                  {{
                    cartProduct.product_name.length > 30
                      ? cartProduct.product_name.slice(0, 30)
                      : cartProduct.product_name
                  }}
                </p>

                <div class="mobile-single-header-edit-and-delete">
                  <img
                    @click="goToProduct(cartProduct)"
                    src="../../assets/images/icons/eye.svg"
                    alt=""
                  />
                  <img
                    @click="deleteCartProduct(cartProduct)"
                    src="../../assets/images/icons/product-delete-mobile-icon.svg"
                    alt=""
                  />
                </div>
              </div>

              <div class="cart-product-mobile-single-content">
                <div class="cart-product-mobile-single-content-right">
                  <div :style="{ marginTop: 0 }" class="color">
                    <span v-if="cartProduct.prop_key != null"
                      >{{ cartProduct.prop_key }} :</span
                    >
                    <div
                      v-if="
                        cartProduct.prop_key == 'رنگ' ||
                        cartProduct.prop_key == 'color'
                      "
                      :style="{
                        backgroundColor: cartProduct.prop_alternativeValue,
                      }"
                      class="color-product-mobile"
                    ></div>

                    <span v-else class="prop-box">
                      {{ cartProduct.prop_mainValue }}
                    </span>
                  </div>
                  <!-- <p>{{ cartProduct.deliveryPrice }}</p> -->
                  <p>تخفیف : {{ numberFormat(cartProduct.sum_discount) }}</p>
                  <p>قیمت : {{ numberFormat(cartProduct.sum_price) }}</p>

                  <div class="add-and-reduce-counter">
                    <font-awesome-icon
                      @click="plusCount(cartProduct)"
                      icon="fa-plus"
                      class="add-btn"
                    />

                    <font-awesome-icon
                      v-if="cartProduct.isLoading"
                      class="show-count"
                      icon="fa-solid fa-ellipsis"
                    />
                    <p v-else>{{ cartProduct.product_count }}</p>
                    <font-awesome-icon
                      @click="minusCount(cartProduct)"
                      icon="fa-minus"
                      class="reduce-count"
                    />
                  </div>
                </div>

                <div class="cart-product-mobile-single-content-left">
                  <img :src="cartProduct.product_image" alt="تصویر محصول" />
                </div>
              </div>
            </div>
          </div>

          <!--  Cart Product Description  -->
          <!-- <div class="cart-product-description">
                <p>
                    <span>افزودن کالا به سبد خرید به معنی رزرو آن نیست</span>
                    با توجه به محدودیت موجودی سبد خود را ثبت و خرید را تکمیل کنید
                </p>
            </div> -->

          <!--  marketing mobile  -->

          <div class="cart-marketing-mobile">
            <div class="guaranteed-delivery-of-goods">
              <img
                src="../../assets/images/icons/mobile-marketing-saddarsad.svg"
                alt=""
              />
              <div class="guaranteed-delivery-of-goods-title">
                <p>تضمین</p>
                <p>تحویل کالا</p>
              </div>
            </div>

            <div class="professional-consultation">
              <img
                src="../../assets/images/icons/mobile-marketing-moshavere.svg"
                alt=""
              />
              <div class="professional-consultation-title">
                <p>مشاوره</p>
                <p>تخصصی</p>
              </div>
            </div>

            <div class="delivery-express">
              <img
                src="../../assets/images/icons/mobile-marketing-express.svg"
                alt=""
              />
              <div class="delivery-express-title">
                <p>تحویل</p>
                <p>اکسپرس</p>
              </div>
            </div>
          </div>

          <div class="cart-submit-and-continuation">
            <button
              @click="goToDeliveryAddress"
              class="submit-and-continuation"
            >
              ثبت و ادامه
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
const productImg1 = require("../../assets/images/pictures/cart-product-img.svg");
const productImg2 = require("../../assets/images/pictures/cart-product2.svg");
import { numberFormat } from "../../assets/helper/helper";

import Header from "../header/Header";
import Footer from "../footer/Footer";

export default {
  name: "CartContent",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      cartProducts: [
        {
          productName: "لاک ناخن بورژوا مدل تانو",
          productSubName: "Bourgeois nail polish Tano model",
          productImg: productImg1,
          productCount: "2",
          productColor: "#FF001D",
          productDiscount: "%10",
          productDeliveryType: ["عادی", "پیشتاز"],
          deliveryPrice: "رایگان",
          productPrice: "52,500 تومان",
        },

        {
          productName: "شامپو ضد ریزش کرونا",
          productSubName: "Bourgeois nail polish Tano model",
          productImg: productImg2,
          productCount: "2",
          productColor: "#34a7b2",
          productDiscount: "%10",
          productDeliveryType: ["عادی", "پیشتاز"],
          deliveryPrice: "رایگان",
          productPrice: "60,500 تومان",
        },

        {
          productName: "شامپو ضد ریزش کرونا",
          productSubName: "Bourgeois nail polish Tano model",
          productImg: productImg2,
          productCount: "2",
          productColor: "#34a7b2",
          productDiscount: "%10",
          productDeliveryType: ["عادی", "پیشتاز"],
          deliveryPrice: "رایگان",
          productPrice: "60,500 تومان",
        },

        {
          productName: "لاک ناخن بورژوا مدل تانو",
          productSubName: "Bourgeois nail polish Tano model",
          productImg: productImg1,
          productCount: "2",
          productColor: "#FF001D",
          productDiscount: "%10",
          productDeliveryType: ["عادی", "پیشتاز"],
          deliveryPrice: "رایگان",
          productPrice: "52,500 تومان",
        },

        {
          productName: "لاک ناخن بورژوا مدل تانو",
          productSubName: "Bourgeois nail polish Tano model",
          productImg: productImg1,
          productCount: "2",
          productColor: "#FF001D",
          productDiscount: "%10",
          productDeliveryType: ["عادی", "پیشتاز"],
          deliveryPrice: "رایگان",
          productPrice: "52,500 تومان",
        },
      ],

      cartDiscount: "",

      activeBorderDiscountInput: false,
    };
  },

  /*watch: {
        cartDiscount() {
            console.log(this.cartDiscount);
        }
    },*/

  methods: {
    numberFormat,
    //  method for see product
    goToProduct(item) {
      //  redirect
      location.href = `product/vsp-${item.product_id}/${item.url}`;
    },

    //  method for delete item of cart
    deleteCartProduct(item) {
      // //  remove cart item
      this.$store
        .dispatch("removeFromCartDispatch", {
          cartItem_id: item.orderItem_id,
          cart_id: this.cartId,
          token: this.token,
        })
        .then((res) => {
          if (res.status) {
            this.$store.dispatch("cartItemsDispatch", { token: this.token });
          }
        });
    },

    //  method plus count of products
    plusCount(item) {
      let productCount = item.product_count + 1;

      item.isLoading = true;
      this.$forceUpdate();
      this.$store
        .dispatch("updateCountDispatch", {
          product_count: productCount,
          token: this.token,
          orderItem_id: item.orderItem_id,
          cart_id: this.cartId,
        })
        .then((res) => {
          item.isLoading = false;
          this.$forceUpdate();
          if (res.status) {
            item.product_count += 1;
            // get new changes of cart
            this.$store.dispatch("cartItemsDispatch", { token: this.token });
          } else {
            //  dispatch alert error
            this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message,
            });
          }
        });
    },
    //  method minus count of products
    minusCount(item) {
      if (item.product_count > 1) {
        let productCount = item.product_count - 1;

        item.isLoading = true;
        this.$forceUpdate();
        this.$store
          .dispatch("updateCountDispatch", {
            product_count: productCount,
            token: this.token,
            orderItem_id: item.orderItem_id,
            cart_id: this.cartId,
          })
          .then((res) => {
            item.isLoading = false;
            this.$forceUpdate();
            if (res.status) {
              item.product_count -= 1;
              // get new changes of cart
              this.$store.dispatch("cartItemsDispatch", { token: this.token });
            } else {
              //  dispatch alert error
              this.$store.dispatch("warningAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          });
      }
    },

    goToDeliveryAddress() {
      // go to delivery address section of cart
      let resolved = this.$router.resolve({
        name: "deliveryInformation",
      });

      location.href = resolved.href;
    },

    cartProductMinus(index) {
      for (let i = 0; i < this.cartProducts.length; i++) {
        if (this.cartProducts[i].productCount > 0 && i == index) {
          this.cartProducts[i].productCount--;
        }
      }
    },

    //  method for show async data in ssr
    asyncData({ route, store }) {
      store.dispatch("cartItemsDispatch", { token: this.token });
    },
  },

  computed: {
    //  method for get token
    token() {
      if (this.$store.getters.getToken != "") {
        return this.$store.getters.getToken;
      }
    },

    //  method for get cart items
    cartItems() {
      if (
        this.$store.getters.getCartItems != null &&
        this.$store.getters.getCartItems.order_items.length > 0
      ) {
        this.$store.getters.getCartItems.order_items.map((item) => {
          item.isLoading = false;
        });

        return this.$store.getters.getCartItems.order_items;
      } else {
        return [];
      }
    },

    //  method for get cart id
    cartId() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems.order_id;
      }
    },

    //  method for get count of total count items
    totalCount() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems.total_count;
      }
    },
    //  method fpr get total price of cart
    totalPrice() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems.total_price;
      }
    },

    //  method for get total discount of cart
    totalDiscount() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems.total_discount_without_coupon;
      }
    },

    //  method for get final cart price (computed of price and discount)
    cartPrice() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems.cart_price;
      }
    },
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get cart items
    this.$store.dispatch("cartItemsDispatch", { token: this.token });
  },
};
</script>

<style src="../../assets/css/cart/cartContent.css" scoped></style>
