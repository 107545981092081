const mutations = {
  setShowFilterMobile(state, payload) {
    state.showFilterMobile = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },

  setShowDemoModal(state, payload) {
    state.showDemoModal = {
      show: payload.show,
      count: payload.count,
    };
  },

  setShowAppSuggestion(state, payload) {
    state.showAppSuggestion = payload;
  },

  setProfileImage(state, payload) {
    state.profileImage = payload;
  },

  setShowBackground(state, payload) {
    state.showBackground = payload;
  },
  setAlert(state, payload) {
    state.alert = payload;
  },
  setWarningAlert(state, payload) {
    state.warningAlert = payload;
  },
  setErrorAlert(state, payload) {
    state.errorAlert = payload;
  },
  setHeaderCategories(state, payload) {
    state.headerCategories = payload;
  },

  setStoreInfo(state, payload) {
    state.storeInfo = payload;
  },

  setStoreAboutUs(state, payload) {
    state.storeAboutUs = payload;
  },

  setStoreContactUs(state, payload) {
    state.storeContactUs = payload;
  },

  setShowProductInfo(state, payload) {
    state.showProductInfo = payload;
  },

  setActiveSelectedvariable_id(state, payload) {
    state.activeSelectedVariable_id = payload;
  },

  setRatingTypes(state, payload) {
    state.ratingTypes = payload;
  },
  setProductSellers(state, payload) {
    state.productSellers = payload;
  },

  setProductComments(state, payload) {
    state.productComments = payload;
  },

  setAllProductComments(state, payload) {
    state.allProductComments = payload;
  },

  setProductCommentsPagination(state, payload) {
    state.productCommentsPagination = payload;
  },

  setProductCommentsRates(state, payload) {
    state.productCommentsRates = payload;
  },

  setAllProductCommentsRates(state, payload) {
    state.allProductCommentsRates = payload;
  },

  setAllProducts(state, payload) {
    state.allProducts = payload;
  },

  setAllProductsPagination(state, payload) {
    state.allProductsPagination = payload;
  },

  setColorProp(state, payload) {
    state.colorProp = payload;
  },

  setDiscountSlider(state, payload) {
    state.discountSlider = payload;
  },

  setRelatedSlider(state, payload) {
    state.relatedSlider = payload;
  },

  setFqQuestions(state, payload) {
    state.fqQuestions = payload;
  },

  setAllQuestions(state, payload) {
    state.allQuestions = payload;
  },

  setShowCategories(state, payload) {
    state.showCategories = payload;
  },

  setUserInfo(state, payload) {
    state.userInfo = payload;
  },

  setCartItems(state, payload) {
    state.cartItems = payload;
  },

  setUserAddresses(state, payload) {
    state.userAddresses = payload;
  },

  setProvinces(state, payload) {
    state.provinces = payload;
  },

  setCities(state, payload) {
    state.cities = payload;
  },

  setStoreDeliveryTypes(state, payload) {
    state.storeDeliveryTypes = payload;
  },

  setUserMessages(state, payload) {
    state.userMessages = payload;
  },

  setUserComments(state, payload) {
    state.userComments = payload;
  },

  setUserOrders(state, payload) {
    state.userOrders = payload;
  },

  setShowOrder(state, payload) {
    state.showOrder = payload;
  },

  setShowLoader(state, payload) {
    state.showLoader = payload;
  },

  setReturnedOrders(state, payload) {
    state.returnedOrders = payload;
  },

  setShowReturnOrder(state, payload) {
    state.showReturnOrder = payload;
  },
};

export default mutations;
