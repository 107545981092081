<template>
  <div class="app-cart">
    <app-header id="app-header-mobile"></app-header>

    <div class="app-cart-details">
      <div class="w-100">
        <!--  cart payment information in desktop  -->
        <div class="cart-payment-information">
          <div class="cart-route">
            <div class="cart-img-div">
              <div class="cart-img">
                <img
                  class="img-fluid"
                  src="../../assets/images/pictures/cart-img-icon.svg"
                  alt=""
                />
                <p>سبد خرید</p>
              </div>

              <div class="cart-to-delivery-route-line"></div>

              <div class="delivery-img">
                <img
                  src="../../assets/images/pictures/active-delivery-information-icon.svg"
                  alt=""
                />
                <p>اطلاعات ارسال</p>
              </div>

              <div class="delivery-to-paymentInfo-route-line"></div>

              <div class="payment-information">
                <img
                  src="../../assets/images/icons/cart-payment-route-img.svg"
                  alt=""
                />
                <p>اطلاعات پرداخت</p>
              </div>
            </div>
          </div>

          <div class="summary-of-cart">
            <h5 class="summary-of-cart-title">
              خلاصه سبد خرید
              <span>({{ cart.total_count }} محصول)</span>
            </h5>

            <div class="summary-of-cart-content">
              <div class="discount-description-input">
                <div class="discount-description">
                  <div class="discount-description-right">
                    <span class="dote"></span>
                    <span class="description-right">
                      هفت روز فرصت عودت کالا بدون قید و شرط</span
                    >
                  </div>

                  <div class="discount-description-center">
                    <span class="dote"></span>
                    <span class="description-center">
                      تضمین بازگشت پول در صورت عدم رضایت</span
                    >
                  </div>
                </div>

                <div
                  :class="[
                    'discount-input',
                    { activeInput: activeBorderDiscountInput },
                  ]"
                >
                  <input
                    @focus="activeBorderDiscountInput = true"
                    @blur="activeBorderDiscountInput = false"
                    v-model="cartDiscount"
                    placeholder="در صورتی که کوپن تخفیف دارید در کادر وارد نمایید"
                    type="text"
                    name="discount-input"
                  />

                  <button
                    :class="[
                      'discount-btn',
                      { activeCartDiscountButton: cartDiscount.length != 0 },
                    ]"
                  >
                    اعمال
                  </button>
                </div>
              </div>
              <hr class="discount-hr" />

              <div class="summary-of-cart-content-top">
                <div class="summary-of-cart-content-top-right">
                  <div class="summary-of-cart-content-top-product-price">
                    <span>قیمت محصولات :</span>
                    <span>{{ numberFormat(cart.total_price) }} تومان</span>
                  </div>

                  <div class="summary-of-cart-content-top-discount-total">
                    <span>مجموع تخفیفات :</span>
                    <span
                      >{{
                        numberFormat(cart.total_discount_with_coupon)
                      }}
                      تومان</span
                    >
                  </div>
                </div>

                <div class="summary-of-cart-content-top-left">
                  <div class="summary-of-cart-content-top-delivery-price-total">
                    <span>مجموع هزینه ارسال :</span>
                    <span>{{ numberFormat(cart.delivery_price) }} تومان</span>
                  </div>

                  <div class="summary-of-cart-content-top-paymentable-price">
                    <span>مبلغ قابل پرداخت :</span>
                    <span>{{ numberFormat(cart.cart_price) }} تومان</span>
                  </div>
                </div>
              </div>

              <hr class="summary-of-cart-content-hr" />

              <div class="summary-of-cart-content-bottom">
                <span>آدرس تحویل :</span>

                <span>
                  این سفارش به آدرس استان
                  {{ cart.delivery_address.province }}- شهرستان
                  {{ cart.delivery_address.city.province }} -{{
                    cart.delivery_address.address_details
                  }}
                  ارسال می گردد .
                </span>
              </div>
            </div>
          </div>

          <div
            :class="[
              'quickly-products-show',
              { borderTitle: showAndHideQuicklyProducts },
              { quicklyProductsShowDetails: showAndHideQuicklyProducts },
            ]"
          >
            <div
              @click="quicklyProductsShowToggle"
              class="quickly-products-show-title"
            >
              <p>محصولات شما</p>
              <font-awesome-icon
                :class="{
                  quicklyProductsShowArrow: showAndHideQuicklyProducts,
                }"
                icon="fa-chevron-down"
              />
            </div>

            <div class="quickly-products-show-details">
              <div
                v-for="(product, index) in cart.order_items"
                v-bind:key="index"
                :class="[
                  'quickly-product-show-box',
                  { marginRight: index > 0 },
                  /*, {marginTop: index > 3}*/
                ]"
              >
                <div class="quickly-product-show-box-title">
                  <span>{{
                    product.product_name.length > 40
                      ? product.product_name.slice(0, 40) + "..."
                      : product.product_name
                  }}</span>

                  <hr class="quickly-product-show-box-title-hr" />
                </div>

                <div class="quickly-product-show-box-content">
                  <div class="quickly-product-show-box-content-description">
                    <ul>
                      <li
                        v-if="
                          product.prop_key == 'رنگ' ||
                          product.prop_key == 'color'
                        "
                        :style="{
                          backgroundColor: product.prop_alternativeValue,
                        }"
                        class="product-color"
                      ></li>

                      <li
                        v-else
                        v-show="product.prop_key != null"
                        class="product-color"
                      >
                        {{ product.prop_key + ":" + product.prop_mainValue }}
                      </li>

                      <li>{{ product.product_count }} عدد</li>
                      <li>{{ product.discount }} تومان تخفیف</li>
                      <li>{{ product.price }} تومان</li>
                    </ul>
                  </div>
                  <img :src="product.product_image" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="selected-how-to-pay">
            <h5>انتخاب نحوه پرداخت</h5>

            <div class="selected-how-to-pay-content">
              <label
                for="payment-in-internet"
                :class="[
                  'payment-in-internet',
                  { activePayInInternet: paymentWay == 'paymentInInternet' },
                ]"
              >
                <div class="payment-in-internet-top">
                  <input
                    value="paymentInInternet"
                    v-model="paymentWay"
                    type="radio"
                    style="display: none"
                    id="payment-in-internet"
                  />
                  <span
                    :class="[
                      'paymentInInternetRadioBtn',
                      {
                        activePaymentInInternetRadioBtn:
                          paymentWay == 'paymentInInternet',
                      },
                    ]"
                  ></span>
                  <img
                    v-show="paymentWay != 'paymentInInternet'"
                    src="../../assets/images/icons/deActive-payment-in-internet.svg"
                    alt=""
                  />
                  <img
                    v-show="paymentWay == 'paymentInInternet'"
                    src="../../assets/images/icons/active-payment-in-internet.svg"
                    alt=""
                  />

                  <div class="payment-in-internet-description">
                    <p>پرداخت اینترنتی</p>
                    <span>پرداخت از طریق کلیه کارتهای عضو شتاب</span>
                  </div>
                </div>

                <div
                  v-show="paymentWay == 'paymentInInternet'"
                  class="payment-in-internet-select-bank"
                >
                  <!-- commented for labniid project -->
                  <!-- <label
                    for="mellatBank"
                    :class="[
                      'bank1',
                      {
                        selectedBankActiveStyles:
                          paymentWay == 'paymentInInternet' &&
                          selectedBank == 'mellatBank',
                      },
                    ]"
                  >
                    <input
                      style="display: none"
                      type="radio"
                      id="mellatBank"
                      value="mellatBank"
                      v-model="selectedBank"
                    />
                    <img
                      src="../../assets/images/icons/mellat-bank.svg"
                      alt=""
                    />
                    <span>درگاه بانک ملت</span>
                  </label> -->

                  <!-- commented for labniid project -->
                  <!-- <label
                    for="samanBank"
                    :class="[
                      'bank2',
                      {
                        selectedBankActiveStyles:
                          paymentWay == 'paymentInInternet' &&
                          selectedBank == 'samanBank',
                      },
                    ]"
                  >
                    <input
                      style="display: none"
                      type="radio"
                      id="samanBank"
                      value="samanBank"
                      v-model="selectedBank"
                    />
                    <img
                      src="../../assets/images/icons/saman-bank.svg"
                      alt=""
                    />
                    <span>درگاه بانک سامان</span>
                  </label> -->

                  <label
                    for="zarinPall"
                    :class="[
                      'bank3',
                      {
                        selectedBankActiveStyles:
                          paymentWay == 'paymentInInternet' &&
                          selectedBank == 'zarinPall',
                      },
                    ]"
                  >
                    <input
                      style="display: none"
                      type="radio"
                      id="zarinPall"
                      value="zarinPall"
                      v-model="selectedBank"
                    />
                    <img
                      src="../../assets/images/icons/zarin-pall.svg"
                      alt=""
                    />
                    <span>درگاه ذرین پال</span>
                  </label>
                </div>
              </label>

              <!-- commented for labniid -->

              <!-- 
                <label
                for="payment-in-location"
                :class="[
                  'payment-in-location',
                  {
                    selectedBankActiveStyles: paymentWay == 'paymentInLocation',
                  },
                ]"
              >
                <input
                  style="display: none"
                  value="paymentInLocation"
                  v-model="paymentWay"
                  type="radio"
                  id="payment-in-location"
                />

                <span
                  :class="[
                    'paymentInLocation',
                    {
                      activePaymentInLocation:
                        paymentWay == 'paymentInLocation',
                    },
                  ]"
                ></span>
                <img
                  v-show="paymentWay != 'paymentInLocation'"
                  src="../../assets/images/icons/deActive-payment-in-locations.svg"
                  alt=""
                />
                <img
                  v-show="paymentWay == 'paymentInLocation'"
                  src="../../assets/images/icons/active-payment-in-location.svg"
                  alt=""
                />

                <div class="payment-in-location-description">
                  <p>پرداخت در محل</p>
                  <span
                    >شما مبلغ سفارش را هنگام تحویل کالا پرداخت خواهید کرد</span
                  >
                </div>
              </label> -->
            </div>
          </div>

          <div class="description-and-finish-buying">
            <p>
              با انتخاب دکمه پرداخت و تکمیل خرید، ‌موافقت خود را با
              <span @click="goToTermsOfUs" class="rules">قوانین و مقررات</span>
              و
              <span @click="goToPrivacy" class="rules">حریم خصوصی</span>
              و همچنین <br />
              <span @click="goToReturnPolicy" class="back-product"
                >شرایط بازگشت</span
              >
              سفارشات {{ getStoreName }} اعلام نموده‌اید
            </p>

            <button @click.prevent="checkout" class="submit-buying">
              پرداخت و تکمیل خرید
            </button>
          </div>
        </div>

        <!--  cart payment information in mobile  -->

        <div class="cart-payment-information-mobile">
          <!--   cart payment information mobile route -->
          <div class="cart-route-mobile">
            <div class="cart-route-mobile-content">
              <div class="cart-route-mobile-img">
                <img
                  src="../../assets/images/icons/image-route-cart-mobile.svg"
                  alt=""
                />
              </div>
              <p>سبد خرید</p>
            </div>

            <div class="route-mobile-line1"></div>

            <div class="cart-route-mobile-delivery-information">
              <div class="deliveryInformation-route-mobile-img">
                <img
                  src="../../assets/images/icons/cart-delivery-information-mobile-img.svg"
                  alt=""
                />
              </div>

              <p>اطلاعات ارسال</p>
            </div>

            <div class="route-mobile-line1"></div>

            <div class="cart-route-mobile-payment">
              <div class="payment-route-mobile-img">
                <!-- active payment icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.838"
                  height="28.604"
                  viewBox="0 0 31.838 28.604"
                >
                  <g
                    id="payment-route-mobile-img"
                    transform="translate(-36.228 -124.983)"
                  >
                    <path
                      id="Stroke_1"
                      data-name="Stroke 1"
                      d="M10.685,8.255H4.267A4.2,4.2,0,0,1,0,4.128,4.2,4.2,0,0,1,4.267,0h6.417"
                      transform="translate(56.631 134.956)"
                      fill="none"
                      stroke="#4990e2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_3"
                      data-name="Stroke 3"
                      d="M.794.456H.3"
                      transform="translate(60.831 138.533)"
                      fill="none"
                      stroke="#4990e2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_5"
                      data-name="Stroke 5"
                      d="M8.318,0h13.7a8.187,8.187,0,0,1,8.318,8.048V19.056A8.187,8.187,0,0,1,22.019,27.1H8.318A8.187,8.187,0,0,1,0,19.056V8.048A8.187,8.187,0,0,1,8.318,0Z"
                      transform="translate(36.978 125.733)"
                      fill="none"
                      stroke="#4b4b4b"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                    <path
                      id="Stroke_7"
                      data-name="Stroke 7"
                      d="M0,.456H8.558"
                      transform="translate(44.167 132.238)"
                      fill="#4990e2"
                      stroke="#4990e2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
              </div>

              <p>پرداخت</p>
            </div>
          </div>

          <!--  cart review product info  -->
          <div class="cart-review-product-info-mobile">
            <div class="products-count">
              <p>تعداد محصولات :</p>
              <p>{{ cart.total_count }} عدد</p>
            </div>

            <div class="products-price">
              <p>قیمت محصولات :</p>
              <p>{{ numberFormat(cart.total_price) }} تومان</p>
            </div>

            <div class="delivery-price">
              <p>هزینه ارسال :</p>
              <p>{{ numberFormat(cart.delivery_price) }} تومان</p>
            </div>

            <div class="total-discount">
              <p>مجموع تخفیفات :</p>
              <p>{{ numberFormat(cart.total_discount_with_coupon) }} تومان</p>
            </div>

            <div class="final-price">
              <p>مبلغ قابل پرداخت :</p>
              <p>{{ numberFormat(cart.cart_price) }} تومان</p>
            </div>

            <hr class="cart-review-product-info-mobile-hr" />

            <div class="discount-code-mobile">
              <p>
                این سفارش به آدرس استان
                {{ cart.delivery_address.province }}- شهرستان
                {{ cart.delivery_address.city.province }} -{{
                  cart.delivery_address.address_details
                }}
                ارسال می گردد .
              </p>
            </div>
          </div>

          <!--  choose how to pay tags  -->

          <di class="choose-how-to-pay-mobile">
            <div class="choose-how-to-pay-mobile-title">
              <p>انتخاب نحوه پرداخت</p>
            </div>

            <div class="choose-how-to-pay-mobile-content">
              <label
                for="payment-in-internet-mobile"
                :class="[
                  'pay-with-internet-mobile',
                  {
                    paymentInLocationActiveMobile:
                      paymentWay == 'paymentInInternet',
                  },
                ]"
              >
                <div class="pay-with-internet-mobile-title">
                  <div
                    :class="[
                      'checkBox-my-address-mobile',
                      {
                        checkBoxMyAddressMobile:
                          paymentWay == 'paymentInInternet',
                      },
                    ]"
                  ></div>
                  <input
                    style="display: none"
                    v-model="paymentWay"
                    value="paymentInInternet"
                    type="radio"
                    id="payment-in-internet-mobile"
                  />

                  <div class="pay-with-internet-mobile-title-des">
                    <p>پرداخت اینترنتی</p>
                    <span>پرداخت از طریق کلیه کارتهای عضو شتاب</span>
                  </div>
                </div>

                <div class="pay-with-internet-mobile-content">
                  <!-- commented for labniid project -->
                  <!--  <label
                    for="mellatBank-mobile"
                    :class="[
                      'internet-mobile-content-mellat',
                      { selectedBankStyle: selectedBank == 'mellatBank' },
                    ]"
                  >
                    <input
                      style="display: none"
                      v-model="selectedBank"
                      value="mellatBank"
                      type="radio"
                      id="mellatBank-mobile"
                    />

                    <img
                      src="../../assets/images/pictures/mellat-mobile-icon.svg"
                      alt=""
                    />
                    <p>درگاه بانک ملت</p>
                  </label> -->

                  <!-- commented for labniid project -->
                  <!-- <label
                    for="samanBank-mobile"
                    :class="[
                      'internet-mobile-content-saman',
                      { selectedBankStyle: selectedBank == 'samanBank' },
                    ]"
                  >
                    <input
                      style="display: none"
                      v-model="selectedBank"
                      value="samanBank"
                      type="radio"
                      id="samanBank-mobile"
                    />

                    <img
                      src="../../assets/images/pictures/saman-mobile-icon.svg"
                      alt=""
                    />
                    <p>درگاه بانک سامان</p>
                  </label> -->

                  <label
                    for="zarinPall-mobile"
                    :class="[
                      'internet-mobile-content-zarinPall',
                      { selectedBankStyle: selectedBank == 'zarinPall' },
                    ]"
                  >
                    <input
                      style="display: none"
                      v-model="selectedBank"
                      value="zarinPall"
                      type="radio"
                      id="zarinPall-mobile"
                    />

                    <img
                      src="../../assets/images/pictures/zarinPall-mobile-icon.svg"
                      alt=""
                    />
                    <p>درگاه ذرین پال</p>
                  </label>
                </div>
              </label>

              <!-- commented for labniid project -->
              <!-- <label
                for="payment-in-location-mobile"
                :class="[
                  'pay-in-location-mobile',
                  {
                    paymentInLocationActiveMobile:
                      paymentWay == 'paymentInLocation',
                  },
                ]"
              >
                <div
                  :class="[
                    'checkBox-my-address-mobile',
                    {
                      checkBoxMyAddressMobile:
                        paymentWay == 'paymentInLocation',
                    },
                  ]"
                ></div>

                <input
                  style="display: none"
                  v-model="paymentWay"
                  value="paymentInLocation"
                  type="radio"
                  id="payment-in-location-mobile"
                />

                <div class="pay-with-internet-mobile-title-des">
                  <p>پرداخت در محل</p>
                  <span
                    >شما مبلغ سفارش را هنگام تحویل کالا پرداخت خواهید کرد</span
                  >
                </div>
              </label> -->
            </div>
          </di>

          <!--  you'r products  -->

          <div class="your-products-mobile">
            <div class="your-products-mobile-title">
              <p>محصولات شما</p>
            </div>

            <div class="your-products-mobile-content">
              <div
                v-for="(product, index) in cart.order_items"
                v-bind:key="index"
                :class="[
                  'your-products-mobile-single',
                  { marginRight: index > 0 },
                  { marginLeft: index == quicklyShowProducts.length - 1 },
                ]"
              >
                <div class="your-products-mobile-single-title">
                  <p>
                    {{
                      product.product_name.length > 30
                        ? product.product_name.slice(0, 30) + "..."
                        : product.product_name
                    }}
                  </p>
                  <hr />
                </div>

                <div class="your-products-mobile-single-content">
                  <div class="your-products-mobile-single-content-right">
                    <div
                      v-if="
                        product.prop_key == 'رنگ' || product.prop_key == 'color'
                      "
                      :style="{
                        backgroundColor: product.prop_alternativeValue,
                      }"
                      class="product-mobile-color"
                    ></div>
                    <p v-else v-show="product.prop_key != null">
                      {{ product.prop_key + ":" + product.prop_mainValue }}
                    </p>
                    <p>{{ product.product_count }} عدد</p>
                    <p>{{ product.discount }} تومان تخفیف</p>
                    <p>{{ product.price }} تومان</p>
                  </div>

                  <div class="your-products-mobile-single-content-left">
                    <img :src="product.product_image" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--  delivery information mobile description  -->

          <div class="cart-product-description">
            <p>
              با انتخاب دکمه پرداخت و تکمیل خرید، ‌موافقت خود را با
              <span @click="goToTermsOfUs">قوانین و مقررات</span> و
              <span @click="goToPrivacy">حریم خصوصی</span>
              و همچنین
              <span @click="goToReturnPolicy">شرایط بازگشت</span>
              سفارشات {{ getStoreName }} اعلام نموده‌اید.
            </p>
          </div>

          <!--  marketing mobile  -->

          <div class="cart-marketing-mobile">
            <div class="guaranteed-delivery-of-goods">
              <img
                src="../../assets/images/icons/mobile-marketing-saddarsad.svg"
                alt=""
              />
              <div class="guaranteed-delivery-of-goods-title">
                <p>تضمین</p>
                <p>تحویل کالا</p>
              </div>
            </div>

            <div class="professional-consultation">
              <img
                src="../../assets/images/icons/mobile-marketing-moshavere.svg"
                alt=""
              />
              <div class="professional-consultation-title">
                <p>مشاوره</p>
                <p>تخصصی</p>
              </div>
            </div>

            <div class="delivery-express">
              <img
                src="../../assets/images/icons/mobile-marketing-express.svg"
                alt=""
              />
              <div class="delivery-express-title">
                <p>تحویل</p>
                <p>اکسپرس</p>
              </div>
            </div>
          </div>

          <div class="delivery-information-submit-and-continuation">
            <button @click.prevent="checkout" class="submit-and-continuation">
              پرداخت و تکمیل خرید
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
const quicklyShowProductImg = require("../../assets/images/pictures/quickly-show-product-shampo.svg");
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { numberFormat } from "../../assets/helper/helper";

export default {
  name: "PaymentInformation",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      showAndHideQuicklyProducts: false,
      activeBorderDiscountInput: false,

      quicklyShowProducts: [
        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },

        {
          productName: "رکسونا برای اقایان",
          productColor: "#FA378B",
          productCount: "2 عدد",
          productDiscount: "%10 تخفیف",
          productPrice: "5,200,000 تومان",
          productImg: quicklyShowProductImg,
        },
      ],
      paymentWay: "paymentInInternet",

      selectedBank: "zarinPall",

      cartDiscount: "",
    };
  },

  watch: {
    selectedBank() {
      if (this.selectedBank != "") {
        this.paymentWay = "paymentInInternet";
        console.log(this.selectedBank, this.paymentWay);
      }
    },

    paymentWay() {
      if (this.paymentWay == "paymentInLocation") {
        this.selectedBank = "";
        console.log(this.paymentWay, this.selectedBank);
      } else if (
        this.paymentWay == "paymentInInternet" &&
        this.selectedBank == ""
      ) {
        this.selectedBank = "mellatBank";
      }
    },
  },

  methods: {
    numberFormat,

    // method for go to privacy page
    goToPrivacy() {
      let resolved = this.$router.resolve({
        name: "privacy",
      });

      location.href = resolved.href;
    },

    // method for go to return privacy page
    goToReturnPolicy() {
      let resolved = this.$router.resolve({
        name: "returnPolicy",
      });

      location.href = resolved.href;
    },

    // method for go to terms of us page
    goToTermsOfUs() {
      let resolved = this.$router.resolve({
        name: "termsOfUs",
      });

      location.href = resolved.href;
    },

    quicklyProductsShowToggle() {
      this.showAndHideQuicklyProducts = !this.showAndHideQuicklyProducts;
    },

    //*** method for final checkout
    checkout() {
      let orderPaymentData = {};
      orderPaymentData.order_id = this.cart.order_id;
      orderPaymentData.total_payment_amount = this.cart.cart_price;
      orderPaymentData.total_payment_discount =
        this.cart.total_discount_with_coupon;
      orderPaymentData.payment_type = "آنلاین";
      orderPaymentData.token = this.token;

      this.$store
        .dispatch("checkoutGatewayDispatch", orderPaymentData)
        .then((res) => {
          if (res.status) {
            location.href = res.gateway_link;
          } else {
            if (typeof res.message == "string") {
              this.$store.dispatch("warningAlertDispatch", {
                show: true,
                text: res.message,
              });
            } else {
              if ("order_id" in res.message) {
                this.$store.dispatch("warningAlertDispatch", {
                  show: true,
                  text: res.message.order_id[0],
                });
              } else if ("total_payment_amount" in res.message) {
                this.$store.dispatch("warningAlertDispatch", {
                  show: true,
                  text: res.message.total_payment_amount[0],
                });
              }
            }
          }
        });
    },
  },

  computed: {
    token() {
      return this.$store.getters.getToken;
    },

    //  computed for get store name
    getStoreName() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.store_name;
      }
    },
    // getter for get cart info
    cart() {
      if (this.$store.getters.getCartItems != null) {
        return this.$store.getters.getCartItems;
      } else {
        return {
          total_count: 0,
          delivery_address: {
            province: "",
            city: { province: "" },
            address_details: "",
          },
        };
      }
    },
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  action for get cart info
    this.$store.dispatch("cartItemsDispatch", { token: this.token });
  },
};
</script>

<style src="../../assets/css/cart/paymentInformation.css" scoped></style>
