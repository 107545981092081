<template>
  <div :style="{ position: 'relative' }">
    <div v-show="!getShowFilterMobile" class="col-12 p-0 m-0 store">
      <app-header id="app-header-mobile"></app-header>

      <!-- brand top section -->
      <app-brand></app-brand>

      <!-- app slider section -->
      <app-slider></app-slider>

      <!-- marketing section -->
      <app-marketing></app-marketing>

      <app-products-list></app-products-list>

      <banner-brand></banner-brand>

      <discount-slider></discount-slider>

      <frequently-asked-questions></frequently-asked-questions>

      <app-footer></app-footer>
    </div>

    <filters-mobile v-show="getShowFilterMobile"></filters-mobile>

    <!-- modal for get info of demo user -->
    <!-- <demo-modal /> -->
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Brand from "./Brand.vue";
import Marketing from "./Marketing.vue";
import ProductsList from "./ProductsList.vue";
import DiscountSlider from "./DiscountSlider";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import Slider from "./Slider";
import BannerBrand from "./BannerBrand";
import DemoModal from "../others/DemoModal.vue";
import FiltersMobile from "./FiltersMobile";

export default {
  name: "store",
  components: {
    "app-header": Header,
    "app-brand": Brand,
    "app-slider": Slider,
    "app-marketing": Marketing,
    "app-products-list": ProductsList,
    "banner-brand": BannerBrand,
    "discount-slider": DiscountSlider,
    "frequently-asked-questions": FrequentlyAskedQuestions,
    "app-footer": Footer,
    "filters-mobile": FiltersMobile,
    DemoModal,
  },

  data() {
    return {
      showFilterMobile: false,
    };
  },

  computed: {
    getShowFilterMobile() {
      return this.$store.getters.getShowFilterMobile;
    },
  },

  methods: {},
};
</script>

<style src="../../assets/css/store/store.css" scoped></style>
