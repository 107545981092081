const states = {
  // state for show filter mobile
  showFilterMobile: false,
  token: "",
  profileImage: "",
  showDemoModal: {
    show: false,
    count: 0,
  },
  showBackground: false,
  // state for show download app suggestion
  showAppSuggestion: false,
  alert: {
    show: false,
    text: "",
  },
  warningAlert: {
    show: false,
    text: "",
  },
  errorAlert: {
    show: false,
    text: "",
  },
  headerCategories: [],
  storeInfo: null,
  storeAboutUs: [],
  storeContactUs: [],
  showProductInfo: null,
  ratingTypes: [],
  productSellers: [],
  activeSelectedVariable_id: [],
  productComments: [],
  allProductComments: [],
  productCommentsPagination: null,
  productCommentsRates: null,
  allProductCommentsRates: null,
  allProducts: [],
  allProductsPagination: null,
  colorProp: null,
  discountSlider: null,
  relatedSlider: null,
  fqQuestions: null,
  allQuestions: null,
  showCategories: null,
  userInfo: null,
  cartItems: null,
  userAddresses: null,
  provinces: null,
  cities: null,
  storeDeliveryTypes: null,
  userMessages: null,
  userComments: null,
  userOrders: null,
  showOrder: null,
  showLoader: false,
  returnedOrders: null,
  showReturnOrder: null,
};

export default states;
