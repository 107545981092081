<template>
  <div id="product" class="col-12 p-0 m-0 product">
    <app-header
      id="app-header-mobile"
      v-bind:showAllComments="showAllComments"
    ></app-header>

    <app-brand v-bind:showAllComments="showAllComments"></app-brand>

    <product-details v-bind:showAllComments="showAllComments"></product-details>

    <Sellers v-show="!showAllComments" />

    <discount-slider></discount-slider>

    <div class="product-description">
      <!--  product description for desktop size   -->
      <div class="product-description-content">
        <div class="product-description-general-specifications">
          <general-specifications></general-specifications>
        </div>

        <div class="product-description-comments-review">
          <points-and-comments-users></points-and-comments-users>

          <app-review class="mt-4"></app-review>
        </div>
      </div>

      <!--  product description for mobile size content -->
      <div v-show="!showAllComments" class="product-description-content-mobile">
        <!-- product specification mobile -->
        <div
          :class="[
            'product-specifications-mobile',
            {
              borderBackgroundProductSpecificationMobile:
                productSpecificationsMobile,
            },
          ]"
        >
          <div
            @click="productSpecificationsMobile = !productSpecificationsMobile"
            class="product-specifications-mobile-title"
          >
            <h4>مشخصات</h4>
            <font-awesome-icon
              icon="fa-chevron-down"
              :class="[
                'fa',
                'fa-chevron-down',
                {
                  productSpecificationsMobileArrow: productSpecificationsMobile,
                },
              ]"
            />
          </div>

          <div
            :class="[
              'product-specifications-mobile-content',
              {
                showProductSpecificationsMobileContent:
                  productSpecificationsMobile,
              },
            ]"
          >
            <ul class="product-specifications-mobile-content-ul">
              <li
                v-for="(prop, propIndex) in getGeneralSpecification"
                :key="propIndex"
                class="product-specifications-content-li"
              >
                <span>{{ prop.key }} :</span>
                <span>{{ prop.value }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- product review mobile -->
        <div
          :class="[
            'product-considered-mobile',
            {
              borderBackgroundProductSpecificationMobile:
                showConsideredContentBox,
            },
          ]"
        >
          <div
            @click="showConsideredContentBox = !showConsideredContentBox"
            class="product-considered-mobile-title"
          >
            <h4>بررسی محصول</h4>
            <font-awesome-icon
              icon="fa-chevron-down"
              :class="[
                'fa',
                'fa-chevron-down',
                { productSpecificationsMobileArrow: showConsideredContentBox },
              ]"
            />
          </div>

          <div
            :class="[
              'product-considered-mobile-content',
              {
                showProductSpecificationsMobileContent:
                  showConsideredContentBox,
              },
            ]"
          >
            <div class="product-considered-mobile-content-title-button">
              <button
                @click="consideredShowContent = btn"
                v-for="(consideredButton, btn) in getProductReviews"
                :key="btn"
                :class="[
                  'product-considered-mobile-title-button-single',
                  { ConsideredButtonActive: consideredShowContent == btn },
                ]"
              >
                {{ consideredButton.title }}
              </button>
            </div>

            <div
              v-for="(consideredDescription, des) in getProductReviews"
              :key="des"
              class="product-considered-description"
            >
              <p v-show="consideredShowContent == des">
                {{ consideredDescription.description }}
              </p>
            </div>
          </div>
        </div>

        <!-- TODO product comments mobile -->
        <div
          :class="[
            'product-users-comments-mobile',
            {
              borderBackgroundProductSpecificationMobile:
                showProductUsersCommentsContent,
            },
          ]"
        >
          <div
            @click="
              showProductUsersCommentsContent =
                !showProductUsersCommentsContent;
              ShowFormAddNewComment = true;
            "
            class="product-users-comments-mobile-title"
          >
            <h4>نظرات کاربران</h4>

            <!-- <p v-if="showProductUsersCommentsContent">
              اولین نفری باشید که نظر می‌دهید
            </p> -->

            <font-awesome-icon
              icon="fa-chevron-down"
              :class="[
                'fa',
                'fa-chevron-down',
                {
                  productSpecificationsMobileArrow:
                    showProductUsersCommentsContent,
                },
              ]"
            />
          </div>

          <div
            :class="[
              'product-users-comments-mobile-content',
              {
                showProductUsersCommentContentMobile:
                  showProductUsersCommentsContent,
              },
            ]"
          >
            <div class="show-quality-worthBuying-innovation">
              <!-- productComment Rates -->
              <div
                v-if="token == ''"
                class="quality-worthBuying-innovation-content"
              >
                <div
                  v-for="(rate, index) in productRates"
                  :key="index"
                  class="show-quality-content"
                >
                  <div class="quality-chart-outside">
                    <div
                      v-show="0 < rate.average_rate && rate.average_rate <= 100"
                      class="fill-quality-chart-1"
                    ></div>
                    <div
                      v-show="
                        25 < rate.average_rate && rate.average_rate <= 100
                      "
                      class="fill-quality-chart-3"
                    ></div>
                    <div
                      v-show="
                        50 < rate.average_rate && rate.average_rate <= 100
                      "
                      class="fill-quality-chart-4"
                    ></div>
                    <div
                      v-show="
                        75 < rate.average_rate && rate.average_rate <= 100
                      "
                      class="fill-quality-chart-2"
                    ></div>
                    <div class="quality-chart-inside">
                      <h4>{{ Math.ceil(rate.average_rate) }} %</h4>
                    </div>
                  </div>

                  <p>{{ rate.category_rating_type }}</p>
                </div>
              </div>

              <!-- add new comment form -->
              <div v-if="token != ''" class="add-new-comment-form-mobile">
                <form
                  @submit.prevent="productCommentSubmit"
                  class="new-comment-form w-100"
                >
                  <div class="new-quality-worthBuying-innovation">
                    <div
                      v-for="(type, index) in categoryRatingTypes"
                      :key="index"
                      class="add-new-quality-chart"
                    >
                      <circle-slider
                        v-model="type.rate"
                        circle-color="#EEEEEE"
                        knob-color="#3FBDCC"
                        progress-color="#3FBDCC"
                        :step-size="25"
                        :circle-width="8"
                      ></circle-slider>
                      <h4 class="show-quality-value">
                        {{ productComment.product_rates[index].rate }}%
                      </h4>
                      <p>{{ type.title }}</p>
                    </div>
                  </div>

                  <div
                    :class="[
                      'new-comment-form-div',
                      {
                        validErrorBorder:
                          (validationError.comment_text &&
                            validationError.comment_text.length > 0) ||
                          (validationError.buy_suggestion &&
                            validationError.buy_suggestion.length > 0),
                      },
                    ]"
                  >
                    <textarea
                      @input="removeValidationErrorComment"
                      v-model="productComment.comment_text"
                      name="comment-content-mobile"
                      placeholder="لطفا نظر خود را در مورد محصول بنویسید ..."
                      id=""
                    >
                    </textarea>

                    <hr />

                    <div class="new-comment-suggestion-radio">
                      <p>آیا خرید این محصول را به دیگران پیشنهاد میکنید؟</p>

                      <div class="suggestion-radio-buttons">
                        <label for="yes-suggestion" class="suggestion-yes">
                          <div class="radio-border">
                            <div
                              :class="{
                                'radio-fill':
                                  productComment.buy_suggestion == '1',
                              }"
                            ></div>
                          </div>
                          <input
                            @input="removeValidationErrorSuggestion"
                            v-model="productComment.buy_suggestion"
                            value="1"
                            style="display: none"
                            id="yes-suggestion"
                            type="radio"
                          />
                          <span>بله</span>
                        </label>

                        <label for="no-suggestion" class="suggestion-no">
                          <div class="radio-border">
                            <div
                              :class="{
                                'radio-fill':
                                  productComment.buy_suggestion == '0',
                              }"
                            ></div>
                          </div>
                          <input
                            @input="removeValidationErrorSuggestion"
                            v-model="productComment.buy_suggestion"
                            value="0"
                            style="display: none"
                            id="no-suggestion"
                            type="radio"
                          />
                          <span>خیر</span>
                        </label>

                        <label
                          for="notSure-suggestion"
                          class="suggestion-notSure"
                        >
                          <div class="radio-border">
                            <div
                              :class="{
                                'radio-fill':
                                  productComment.buy_suggestion == '2',
                              }"
                            ></div>
                          </div>
                          <input
                            @input="removeValidationErrorSuggestion"
                            v-model="productComment.buy_suggestion"
                            value="2"
                            style="display: none"
                            id="notSure-suggestion"
                            type="radio"
                          />
                          <span>مطمئن نیستم</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <span
                    class="comment-validation-error"
                    v-for="(error, index) in validationError.comment_text"
                    :key="index"
                    v-show="
                      validationError.comment_text &&
                      validationError.comment_text.length > 0
                    "
                  >
                    {{ error }}
                  </span>

                  <span
                    class="comment-validation-error"
                    v-for="(error, index) in validationError.buy_suggestion"
                    :key="index"
                    v-show="
                      validationError.buy_suggestion &&
                      validationError.buy_suggestion.length > 0
                    "
                  >
                    {{ error }}
                  </span>

                  <button
                    @clic.prevent="productCommentSubmit"
                    class="submit-comment-mobile"
                  >
                    ثبت نظر
                  </button>
                </form>
              </div>

              <p v-if="token == ''" class="description-for-send-comment">
                برای ثبت نظر، لازم است ابتدا وارد حساب کاربری خود شوید
              </p>

              <button
                v-if="token == ''"
                @click="goToLoginPage"
                class="login-and-register-for-send-comment"
              >
                ورود / ثبت نام
              </button>

              <!-- commented beacuse not neccessery -->
              <!-- <p
                v-if="ShowFormAddNewComment"
                v-show="!loginedOrRegister"
                class="description-for-send-comment"
              >
                شما هم می‌توانید در مورد این کالا نظر بدهید
              </p> 

              <button
                v-if="ShowFormAddNewComment"
                @click="ShowFormAddNewComment = false"
                v-show="!loginedOrRegister"
                class="add-new-comment-mobile"
              >
                <i class="fas fa-plus"></i>
                افزودن نظر جدید
              </button>

              -->

              <!-- productComment mobile -->
              <div class="comments-mobile">
                <div
                  v-for="(commentMobile, index) in demoProductComments"
                  :key="index"
                  :class="['single-comments-mobile', { marginTop: index > 0 }]"
                >
                  <h4>
                    {{
                      commentMobile.full_name == ""
                        ? commentMobile.username != null
                          ? commentMobile.username
                          : "کاربر فروشگاه"
                        : commentMobile.full_name
                    }}
                  </h4>
                  <p>{{ commentMobile.created_at }}</p>

                  <span
                    v-if="commentMobile.buy_suggestion == 1"
                    :class="[
                      'suggestion-box-comments-mobile',
                      {
                        suggestionClass: commentMobile.buy_suggestion == '1',
                      },
                    ]"
                  >
                    خرید این محصول را پیشنهاد میکنم .
                  </span>

                  <span
                    v-if="commentMobile.buy_suggestion == 0"
                    :class="[
                      'suggestion-box-comments-mobile',
                      {
                        notSuggestionClass: commentMobile.buy_suggestion == '0',
                      },
                    ]"
                  >
                    خرید این محصول را پیشنهاد نمیکنم.
                  </span>

                  <span
                    v-if="commentMobile.buy_suggestion == 2"
                    :class="[
                      'suggestion-box-comments-mobile',
                      {
                        notSureClass: commentMobile.buy_suggestion == '2',
                      },
                    ]"
                  >
                    مطمئن نیستم .
                  </span>

                  <!-- <div class="size-and-color-mobile">
                    <div class="size-mobile">
                      <span>سایز :</span>
                      <span>{{ commentMobile.size }}</span>
                    </div>
                    <div class="color-mobile">
                      <span>رنگ :</span>
                      <div
                        :style="{ backgroundColor: commentMobile.color }"
                        class="color-box-mobile"
                      ></div>
                    </div>
                  </div> -->

                  <p class="comment-content-mobile">
                    {{ commentMobile.comment_text }}
                  </p>
                </div>

                <div @click="showAllCommentsMethod" class="show-all-comments">
                  <h4>مشاهده همه نظرات</h4>

                  <font-awesome-icon
                    icon="fa-chevron-right"
                    :class="[
                      'fa',
                      'fa-chevron-right',
                      {
                        productSpecificationsMobileArrow:
                          showProductUsersCommentsContent,
                      },
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- related products mobile -->
        <div class="related-products-mobile">
          <div class="related-products-mobile-title">
            <h4>محصولات مرتبط</h4>
          </div>

          <div class="related-products-mobile-content">
            <div
              v-for="(relatedProduct, index) in getProducts"
              :key="index"
              class="related-product-mobile-single"
              @click="openPrductHaveDiscount(relatedProduct)"
            >
              <div class="single-product-mobile-img">
                <img :src="relatedProduct.productGallery" alt="" />
              </div>

              <div class="single-product-mobile-description">
                <h4>
                  {{
                    relatedProduct.product_name.length > 25
                      ? relatedProduct.product_name.slice(0, 25) + "..."
                      : relatedProduct.product_name
                  }}
                </h4>
              </div>

              <!--<div
                class="w-100 d-flex justify-content-end"
                style="position: relative; margin-top: 20px"
              >
                <div class="price-discount-box-mobile">
                  <span>{{ numberFormat(relatedProduct.product_price) }}</span>
                   <div class="discount-box-mobile">
                    <span>{{ relatedProduct.discount }}</span>
                  </div> 
                </div>
              </div>-->

              <div class="final-price-mobile">
                <h4>{{ numberFormat(relatedProduct.product_price) }}</h4>
                <p>تومان</p>
              </div>

              <!-- star and comments -->
              <div class="star-and-comment">
                <div class="comments">
                  <img
                    src="../../assets/images/icons/new-product-comment.svg"
                    alt=""
                  />
                  <span>{{ relatedProduct.productComment_count }} +</span>
                </div>

                <div v-if="relatedProduct.star_average != null" class="stars">
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(relatedProduct.star_average, 4)"
                    class="text-warning pr1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(relatedProduct.star_average, 3)"
                    class="text-warning pr1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(relatedProduct.star_average, 2)"
                    class="text-warning pr-1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(relatedProduct.star_average, 1)"
                    class="text-warning pr-1"
                  />
                  <font-awesome-icon
                    :icon="fontAwesomeComputed(relatedProduct.star_average, 0)"
                    class="text-warning pr-1"
                  />
                </div>

                <div v-else class="empty-starts">
                  <img src="../../assets/images/icons/empty-star.svg" alt="" />
                  <p>محصول جدید</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- all comments in mobile -->
    <div v-show="showAllComments" class="show-all-comments-in-mobile">
      <div class="show-all-comments-page-title">
        <h4>نظرات کاربران</h4>
        <img
          @click="showAllComments = false"
          src="../../assets/images/icons/back-arrow-mobile.svg"
          alt=""
        />
      </div>

      <div class="show-all-comments-page-content">
        <div
          v-for="(commentMobile, index) in allProductComments"
          :key="index"
          :class="['single-comments-mobile', { marginTop: index > 0 }]"
        >
          <h4>
            {{
              commentMobile.full_name == ""
                ? commentMobile.username != null
                  ? commentMobile.username
                  : "کاربر فروشگاه"
                : commentMobile.full_name
            }}
          </h4>
          <p>{{ commentMobile.created_at }}</p>

          <span
            v-if="commentMobile.buy_suggestion == 1"
            :class="[
              'suggestion-box-comments-mobile',
              {
                suggestionClass: commentMobile.buy_suggestion == '1',
              },
            ]"
          >
            خرید این محصول را پیشنهاد میکنم .
          </span>

          <span
            v-if="commentMobile.buy_suggestion == 0"
            :class="[
              'suggestion-box-comments-mobile',
              {
                notSuggestionClass: commentMobile.buy_suggestion == '0',
              },
            ]"
          >
            خرید این محصول را پیشنهاد نمیکنم.
          </span>

          <span
            v-if="commentMobile.buy_suggestion == 2"
            :class="[
              'suggestion-box-comments-mobile',
              {
                notSureClass: commentMobile.buy_suggestion == '2',
              },
            ]"
          >
            مطمئن نیستم .
          </span>

          <!-- <div class="size-and-color-mobile">
            <div class="size-mobile">
              <span>سایز :</span>
              <span>{{ commentMobile.size }}</span>
            </div>
            <div class="color-mobile">
              <span>رنگ :</span>
              <div
                :style="{ backgroundColor: commentMobile.color }"
                class="color-box-mobile"
              ></div>
            </div>
          </div> -->

          <p class="comment-content-mobile">
            {{ commentMobile.comment_text }}
          </p>
        </div>
      </div>
    </div>

    <app-footer class="mt-4"></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Brand from "../store/Brand";
import DiscountSlider from "../store/DiscountSlider";
import ProductDetails from "./ProductDetails";
import GeneralSpecifications from "./GeneralSpecifications";
import Review from "./Review";
import PointsAndCommentsUsers from "./PointsAndCommentsUsers";
import Footer from "../footer/Footer";
import { mapGetters } from "vuex";
import { numberFormat } from "../../assets/helper/helper";

import Sellers from "./Sellers.vue";

export default {
  name: "Product",

  components: {
    "app-header": Header,
    "app-brand": Brand,
    "product-details": ProductDetails,
    "discount-slider": DiscountSlider,
    "general-specifications": GeneralSpecifications,
    "app-review": Review,
    "points-and-comments-users": PointsAndCommentsUsers,
    "app-footer": Footer,
    Sellers,
  },

  data() {
    return {
      productSpecificationsMobile: false,

      /* product considered data */
      consideredShowContent: 0,
      showConsideredContentBox: false,

      /* user comments data */
      loginedOrRegister: true,

      commentValue: {
        qualityMobile: null,
        worthBuyingMobile: null,
        innovationMobile: null,
        commentContent: "",
        buySuggestion: "",
      },

      ShowFormAddNewComment: true,
      showProductUsersCommentsContent: false,

      /* all comments mobile page */
      showAllComments: false,

      // new comment data
      productComment: {
        comment_text: "",
        buy_suggestion: "",
        product_id: "",
        product_rates: [],
      },

      validationError: {
        comment_text: [],
        buy_suggestion: [],
      },
    };
  },

  methods: {
    numberFormat,

    //  method for post productComment
    productCommentSubmit() {
      this.validationError.comment_text = [];
      this.validationError.buy_suggestion = [];

      // add token
      this.productComment.token = this.$store.getters.getToken;

      // add product_id
      this.productComment.product_id = this.$route.params.id;

      this.$store
        .dispatch("postCommentDispatch", this.productComment)
        .then((res) => {
          if (res.status) {
            // empty productComment states
            this.productComment.product_id = "";
            this.productComment.comment_text = "";
            this.productComment.buy_suggestion = "";
            if (this.productComment.product_rates.length > 0) {
              this.productComment.product_rates.map((item) => {
                item.rate = 0;
              });
            }

            // alert of success
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });
          } else {
            if (typeof res.message == "string") {
              if ("comment_text" in res.message) {
                this.validationError.comment_text.push(res.message);
              } else if ("buy_suggestion" in res.message) {
                this.validationError.buy_suggestion.push(res.message);
              }
            } else {
              this.validationError.comment_text = res.message.comment_text;
              this.validationError.buy_suggestion = res.message.buy_suggestion;
            }
          }
        });
    },
    // method for remove validation of comment text
    removeValidationErrorComment() {
      this.validationError.comment_text = [];
    },
    // method for remove validation of comment text
    removeValidationErrorSuggestion() {
      this.validationError.buy_suggestion = [];
    },

    //  method for go to login page
    goToLoginPage() {
      let resolved = this.$router.resolve({
        name: "login",
        query: {},
      });
      //  redirect
      location.href = resolved.href;
    },
    showAllCommentsMethod() {
      this.showAllComments = true;
      console.log(window.scrollTo(0, 0));
    },

    //      font awesome computed for product stars
    fontAwesomeComputed(starAverage, index) {
      if (index < starAverage && starAverage < index + 1) {
        return "fa-solid fa-star-half-stroke";
      } else if (index < starAverage) {
        return "fa fa-star";
      } else {
        return "fa-regular fa-star";
      }
    },

    // method fo open new tab (product have discount)
    openPrductHaveDiscount(product) {
      console.log(product.url);
      let routeData = this.$router.resolve({
        name: "product",
        params: {
          id: product.product_id,
          url: product.url,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },

  computed: {
    // method for get access_token
    token() {
      return this.$store.getters.getToken;
    },

    //  method for get category rating types of specific product
    categoryRatingTypes() {
      if (
        this.$store.getters.getRatingTypes != null &&
        this.$store.getters.getRatingTypes.length > 0
      ) {
        this.$store.getters.getRatingTypes.map((ratingType, index) => {
          let item = {
            title: ratingType.title,
            category_rating_type_id: ratingType.id,
            rate: "",
          };
          this.productComment.product_rates.push(item);
        });

        return this.productComment.product_rates;
      }
    },

    // method for get productCommentsRates
    productRates() {
      if (this.$store.getters.getProductCommentsRates != null) {
        return this.$store.getters.getProductCommentsRates;
      }
    },
    // method for get is not key properties
    getGeneralSpecification() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.isNotKey_properties.length > 0
      ) {
        return this.$store.getters.getShowProductInfo.isNotKey_properties;
      }
    },

    // method for get product review
    getProductReviews() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getShowProductInfo.product_reviews.length > 0
      ) {
        return this.$store.getters.getShowProductInfo.product_reviews;
      }
    },

    // method for show demo of comments
    demoProductComments() {
      if (
        this.$store.getters.getAllProductComments != null &&
        this.$store.getters.getAllProductComments.length > 0
      ) {
        console.log(this.$store.getters.getAllProductComments.slice(0, 3));
        return this.$store.getters.getAllProductComments.slice(0, 3);
      }
    },
    // method for get data of comments
    allProductComments() {
      if (
        this.$store.getters.getAllProductComments != null &&
        this.$store.getters.getAllProductComments.length > 0
      ) {
        return this.$store.getters.getAllProductComments;
      }
    },

    // get products for discount slider
    getProducts() {
      if (this.$route.path == "/") {
        if (this.$store.getters.getDiscountSlider != null) {
          return this.$store.getters.getDiscountSlider;
        }
      } else if (this.$route.path.includes("/product")) {
        if (this.$store.getters.getRelatedSlider != null) {
          return this.$store.getters.getRelatedSlider;
        }
      }
    },
  },

  created() {
    this.$store.dispatch("showProductInfoDispatch", {
      token: this.$store.getters.getToken,
      id: this.$route.params.id,
    });

    this.$store.dispatch("allProductCommentsDispatch", {
      product_id: this.$route.params.id,
      page: 1,
    });

    //  show loader
    this.$store.dispatch("showLoaderDispatch", true);
  },
};
</script>

<style src="../../assets/css/product/product.css" scoped></style>
