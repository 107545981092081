const getters = {
  // getters for show filter mobile
  getShowFilterMobile(state) {
    return state.showFilterMobile;
  },
  getToken(state) {
    return state.token;
  },

  getShowAppSuggestion(state) {
    return state.showAppSuggestion;
  },

  getShowDemoModal(state) {
    return state.showDemoModal;
  },

  getProfileImage(state) {
    return state.profileImage;
  },

  getShowBackground(state) {
    return state.showBackground;
  },

  getAlert(state) {
    return state.alert;
  },

  getWarningAlert(state) {
    return state.warningAlert;
  },

  getErrorAlert(state) {
    return state.errorAlert;
  },
  getHeaderCategories(state) {
    return state.headerCategories;
  },

  getStoreInfo(state) {
    return state.storeInfo;
  },

  getStoreAboutUs(state) {
    return state.storeAboutUs;
  },

  getStoreContactUs(state) {
    return state.storeContactUs;
  },

  getShowProductInfo(state) {
    return state.showProductInfo;
  },

  getActiveSelectedVariable_id(state) {
    return state.activeSelectedVariable_id;
  },

  getRatingTypes(state) {
    return state.ratingTypes;
  },

  getProductSellers(state) {
    return state.productSellers;
  },

  getProductComments(state) {
    return state.productComments;
  },

  getAllProductComments(state) {
    return state.allProductComments;
  },

  getProductCommentsPagination(state) {
    return state.productCommentsPagination;
  },

  getProductCommentsRates(state) {
    return state.productCommentsRates;
  },

  getAllProductCommentsRates(state) {
    return state.allProductCommentsRates;
  },

  getAllProducts(state) {
    return state.allProducts;
  },

  getAllProductsPagination(state) {
    return state.allProductsPagination;
  },
  getColorProp(state) {
    return state.colorProp;
  },

  getDiscountSlider(state) {
    return state.discountSlider;
  },

  getRelatedSlider(state) {
    return state.relatedSlider;
  },

  getFqQuestions(state) {
    return state.fqQuestions;
  },

  getAllQuestions(state) {
    return state.allQuestions;
  },

  getShowCatgories(state) {
    return state.showCategories;
  },
  getUserInfo(state) {
    return state.userInfo;
  },

  getCartItems(state) {
    return state.cartItems;
  },

  getUserAddresses(state) {
    return state.userAddresses;
  },

  getProvinces(state) {
    return state.provinces;
  },

  getCities(state) {
    return state.cities;
  },

  getStoreDeliveryTypes(state) {
    return state.storeDeliveryTypes;
  },

  getUserMessages(state) {
    return state.userMessages;
  },

  getUserComments(state) {
    return state.userComments;
  },

  getUserOrdes(state) {
    return state.userOrders;
  },

  getUserOrder(state) {
    return state.showOrder;
  },

  getShowLoader(state) {
    return state.showLoader;
  },

  getReturnedOrders(state) {
    return state.returnedOrders;
  },

  getShowReturnOrder(state) {
    return state.showReturnOrder;
  },
};

export default getters;
