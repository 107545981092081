// method for calculating price like number_format
export const numberFormat = number => {
  if (number != undefined || number != null) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

// method for check json is valid or not
// regex for check json is valid or not
/* 
/^[\],:{}\s]*$/.test(
  text
    .replace(/\\["\\\/bfnrtu]/g, "@")
    .replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      "]"
    )
    .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
) 
*/

export const isJsonValid = text => {
  if (typeof text !== "string") {
    return false;
  }
  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};
