<template>
  <user-account>
    <div class="create-return-order-content">
      <div class="create-return-order-box">
        <!-- top container -->
        <div class="create-return-top">
          <div class="create-return-top-right">
            <img src="../../assets/images/pictures/product-return.svg" alt="" />
            <h3>درخواست مرجوع کالا</h3>
          </div>
          <div @click="goToReturnPolicy" class="create-return-top-left">
            <img
              src="../../assets/images/icons/danger-circle-blue.svg"
              alt=""
            />
            <p>قوانین بازگشت کالا</p>
          </div>
        </div>

        <!-- center container -->
        <div class="create-return-center">
          <p>
            سفارش
            {{ showReturnOrder != null ? showReturnOrder.order_barcode : "" }}
          </p>
          <div class="product-conatiner">
            <div class="img-box">
              <img
                :src="showReturnOrder != null ? showReturnOrder.image : ''"
                alt=""
              />
            </div>

            <div class="product-info">
              <h3>
                {{
                  showReturnOrder != null ? showReturnOrder.product_name : ""
                }}
              </h3>
              <p>
                {{ showReturnOrder != null ? showReturnOrder.latin_title : "" }}
              </p>
              <div class="returned-count-price">
                <div class="return-count">
                  <p>تعداد مرجوعی :</p>
                  <div class="return-cout-input">
                    <img
                      @click.prevent="incrementReturnCout"
                      src="../../assets/images/icons/plus-img-counter.svg"
                      alt=""
                    />
                    <p>
                      {{ returnOrder.returned_count }}
                    </p>
                    <img
                      @click.prevent="decrementReturnCount"
                      src="../../assets/images/icons/minus-img-counter.svg"
                      alt=""
                    />
                  </div>
                </div>
                <div class="product-prop">
                  <span class="key"
                    >{{
                      showReturnOrder != null ? showReturnOrder.key : ""
                    }}
                    :</span
                  >
                  <span class="value">{{
                    showReturnOrder != null ? showReturnOrder.main_value : ""
                  }}</span>
                </div>
                <div class="return-price">
                  <span class="title">مبلغ مرجوعی :</span>
                  <p>{{ numberFormat(returnPriceComputed) }}</p>
                  <span class="type">تومان</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- bottom container -->
        <div class="create-return-bottom">
          <div class="return-cause-container">
            <!-- return cause box -->
            <div class="selected-address-state col-md-5 p-0">
              <label for="return-order-cause">علت مرجوعی</label>

              <div
                :class="[
                  'selected-address-state-div',
                  { activeBorderFocus: selectedAddressStateFocusActive },
                  {
                    'error-border': returnOrderError.returned_cause.length > 0,
                  },
                ]"
              >
                <select
                  @input="returnedCauseInputEvent"
                  @focus="selectedAddressStateFocusActive = true"
                  @blur="selectedAddressStateFocusActive = false"
                  id="return-order-cause"
                >
                  <option selected disabled>انتخاب کنید.</option>
                  <option
                    v-for="(returnCause, pi) in returnCauses"
                    :key="pi"
                    :selected="returnOrder.returned_cause == returnCause"
                    :value="returnCause"
                  >
                    {{ returnCause }}
                  </option>
                </select>
              </div>
            </div>

            <!-- user description box -->
            <div class="national-code col-md-6 p-0">
              <label for="national-code">توضیحات</label>
              <input
                @input="returnOrderError.returned_userDescription = []"
                :class="[
                  {
                    'error-border':
                      returnOrderError.returned_userDescription.length > 0,
                  },
                ]"
                v-model="returnOrder.returned_userDescription"
                type="text"
                id="national-code"
              />
              <p>
                نتیجه درخواست شما پس از بررسی، توسط پشتیبانی فروشگاه به شما
                اعلام می شود.
              </p>
            </div>
          </div>

          <div class="return-buttons-container">
            <button @click.prevent="backToOrders" class="back-btn">
              بازگشت
            </button>
            <button @click.prevent="submitCreatereturnOrder" class="submit-btn">
              ثبت درخواست مرجوعی
            </button>
          </div>
        </div>
      </div>
    </div>
  </user-account>
</template>

<script>
import UserAccount from "./UserAccount.vue";
import { numberFormat } from "../../assets/helper/helper";

export default {
  components: { UserAccount },
  data() {
    return {
      selectedAddressStateFocusActive: false,

      returnOrder: {
        returned_cause: "",
        returned_userDescription: "",
        returned_count: 1,
        orderItem_id: "",
      },

      returnCauses: [
        "محصول خراب است",
        "محصول شبیه عکس هایش نبود",
        "کیفیت پایین محصول",
      ],

      //  edit address errors
      returnOrderError: {
        returned_cause: [],
        returned_userDescription: [],
      },

      screenWidth: null,
    };
  },

  computed: {
    showReturnOrder() {
      if (this.$store.getters.getShowReturnOrder != null) {
        return this.$store.getters.getShowReturnOrder;
      }
    },

    returnPriceComputed() {
      if (this.showReturnOrder != null) {
        return (
          this.showReturnOrder.price * this.returnOrder.returned_count -
          this.showReturnOrder.discount * this.returnOrder.returned_count
        );
      } else {
        return 0;
      }
    },

    token() {
      return this.$store.getters.getToken;
    },
  },

  methods: {
    numberFormat,

    // method for v-model of returned_cause select box
    returnedCauseInputEvent(e) {
      this.returnOrder.returned_cause = e.target.value;

      this.returnOrderError.returned_cause = [];
    },

    // method for go to return policy page
    goToReturnPolicy() {
      let resolved = this.$router.resolve({
        name: "returnPolicy",
      });

      location.href = resolved.href;
    },
    // method for incerement return count
    incrementReturnCout() {
      if (
        this.returnOrder.returned_count < this.showReturnOrder.product_count
      ) {
        this.returnOrder.returned_count += 1;
      }
    },
    // method for decrement return count
    decrementReturnCount() {
      if (this.returnOrder.returned_count > 1) {
        this.returnOrder.returned_count -= 1;
      }
    },
    // method for back orders page
    backToOrders() {
      //  redirect user
      let resolved = this.$router.resolve({
        name: "orders",
      });

      location.href = resolved.href;
    },

    submitCreatereturnOrder() {
      console.log(this.returnOrder);
      this.returnOrder.token = this.token;
      this.$store
        .dispatch("createReturnOrderDispatch", this.returnOrder)
        .then((res) => {
          if (res.status) {
            this.returnOrderError = {
              returned_cause: [],
              returned_userDescription: [],
            };

            this.returnOrder = {
              returned_cause: "",
              returned_userDescription: "",
              returned_count: 1,
              orderItem_id: "",
            };

            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });

            setTimeout(() => {
              let resolved = this.$router.resolve({
                name: "orders",
              });

              location.href = resolved.href;
            }, 4000);
          } else {
            if (typeof res.message == "string") {
              if ("returned_cause" in res.message) {
                this.returnOrderError.returned_cause.push(res.message);
              } else if ("returned_userDescription" in res.message) {
                this.returnOrderError.returned_userDescription.push(
                  res.message
                );
              }
            } else if (typeof res.message != "string") {
              this.returnOrderError.returned_cause = res.message.returned_cause;
              this.returnOrderError.returned_userDescription =
                res.message.returned_userDescription;
            } else {
              this.$store.dispatch("warningAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          }

          console.log(this.returnOrderError);
        });
    },
  },

  created() {
    this.$store.dispatch("showReturnOrderDispatch", {
      token: this.$store.getters.getToken,
      orderItem_id: this.$route.params.id,
    });

    this.returnOrder.orderItem_id = this.$route.params.id;
  },

  mounted() {
    console.log(this.returnOrder);
    // get size of client mobile for sidebar
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }

    /* get user account slider mobile */
    const userAccountSliderMobile = document.getElementById(
      "user-account-slider-mobile"
    );

    if (this.screenWidth < 426) {
      userAccountSliderMobile.style.display = "none";
    }
  },
};
</script>

<style
  src="../../assets/css/userAccount/createReturnedOrder.css"
  scoped
></style>
