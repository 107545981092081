<template>
  <div class="register-operation">
    <div v-show="mobileNumberPage" class="app-register">
      <!-- mobile size section -->
      <div class="back-page-in-mobile">
        <img
          @click="goToStorePage"
          src="../../assets/images/icons/register-back-in-mobile.svg"
          alt=""
        />
      </div>

      <div class="register-right">
        <!-- TODO v-if="screenWidth > 426" -->
        <img
          class="back-desktop"
          @click="goToStorePage"
          src="../../assets/images/icons/backArrow.svg"
          alt=""
        />

        <div class="description-register">
          <h4>
            ورود &nbsp;/&nbsp; ثبت نام &nbsp; &nbsp;{{ storeInfo?.store_name }}
          </h4>
          <p>لطفا برای ادامه، شماره موبایل خود را وارد کنید .</p>
          <hr />
        </div>

        <!-- mobile size section -->
        <!-- <label v-if="screenWidth < 426" class="label-for-mobile" for="phone-Number-mobile">شماره موبایل</label> -->

        <div
          :class="[
            'phone-Number-input',
            { activeBorder: phoneNumberFocusActive },
            { errorInput: errors.phoneNumberError.length != 0 },
          ]"
        >
          <input
            @keyup.enter="setPhoneNumber"
            id="phone-Number-mobile"
            @focus="phoneNumberFocusActive = true"
            @blur="phoneNumberFocusActive = false"
            @input="checkErrors"
            :class="[
              { fillInput: disableButton },
              { errorBackground: errors.phoneNumberError.length != 0 },
            ]"
            v-model="phoneNumber"
            placeholder="(0912******* : مثال) شماره موبایل خود را وارد کنید"
            type="number"
          />

          <!-- <span
                        :class="[{ fillInput: disableButton }, { errorBackground: errors.phoneNumberError.length != 0 }]">
                        98+
                    </span> -->
        </div>
        <span
          :class="['showErrors', {}]"
          v-for="(error, index) in errors.phoneNumberError"
          v-bind:key="index"
          v-show="errors.phoneNumberError.length != 0"
          >{{ error }}</span
        >

        <div class="next-page-div">
          <button
            id="next-page"
            @click="setPhoneNumber"
            :disabled="!disableButton"
            :class="['next-page', { enableButton: disableButton }]"
          >
            ادامه
          </button>
        </div>

        <div
          v-show="this.$route.path == '/users/login'"
          class="law-and-regulation"
        >
          <div class="w-auto d-flex justify-content-start">
            <span> با ثبت نام در فروشگاه {{ storeInfo?.store_name }}</span>
            <p @click.prevent="goToTermsOfUs">قوانین و مقررات</p>
            <span> را می پذیرم</span>
          </div>
        </div>
      </div>

      <div class="register-left">
        <img
          class="img-fluid"
          src="../../assets/images/pictures/register-user-img.svg"
          alt=""
        />
      </div>
    </div>

    <div v-show="verificationCodePage" class="verification-code">
      <!-- mobile size section -->
      <div class="back-page-in-mobile">
        <img
          v-if="this.$route.path == '/register'"
          @click="
            verificationCodePage = false;
            mobileNumberPage = true;
          "
          src="../../assets/images/icons/register-back-in-mobile.svg"
          alt=""
        />
        <img
          v-else
          @click="
            verificationCodePage = false;
            mobileNumberPage = true;
          "
          src="../../assets/images/icons/register-back-in-mobile.svg"
          alt=""
        />
      </div>

      <div class="verification-code-right">
        <!-- v-show="screenWidth > 425" -->
        <img
          class="back-desktop"
          v-if="this.$route.path == '/users/login'"
          @click="
            verificationCodePage = false;
            mobileNumberPage = true;
          "
          src="../../assets/images/icons/backArrow.svg"
          alt=""
        />

        <!-- v-show="screenWidth > 425" -->
        <img
          class="back-desktop"
          v-else
          @click="
            verificationCodePage = false;
            gotoPassPage = true;
          "
          src="../../assets/images/icons/backArrow.svg"
          alt=""
        />

        <div class="description-register">
          <h4>ورود / ثبت نام {{ storeInfo?.store_name }}</h4>
          <p>لطفا برای ورود ، کد تایید پیامک شده را وارد کنید .</p>
          <hr />

          <div class="mobile-number-verification-code">
            <span>{{ phoneNumber }}</span>
            <img
              class="mb-1"
              src="../../assets/images/icons/mobile-verification-code-icon.svg"
              alt=""
            />
          </div>

          <!--                    <div class="verification-code-inputs">-->

          <form action="#" class="verification-code-form">
            <div class="verification-code-inputs">
              <input
                @keyup.enter="logInWithVerificationCode"
                :class="{
                  activeClassInputs:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                  errorVerificationInputs:
                    errors.verificationCodeError.length > 0,
                }"
                dir="ltr"
                id="fifth-char"
                type="text"
                v-model="verificationCode.fifthChar"
                maxlength="1"
                @keyup="checkFifthInput('fifth-char')"
              />

              <input
                @keyup.enter="logInWithVerificationCode"
                :class="{
                  activeClassInputs:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                  errorVerificationInputs:
                    errors.verificationCodeError.length > 0,
                }"
                dir="ltr"
                id="fourth-char"
                type="text"
                v-model="verificationCode.fourthChar"
                maxlength="1"
                @keyup="checkFourthInput('fourth-char')"
              />

              <input
                @keyup.enter="logInWithVerificationCode"
                :class="{
                  activeClassInputs:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                  errorVerificationInputs:
                    errors.verificationCodeError.length > 0,
                }"
                dir="ltr"
                id="third-char"
                type="text"
                v-model="verificationCode.thirdChar"
                maxlength="1"
                @keyup="checkThirdInput('third-char')"
              />

              <input
                @keyup.enter="logInWithVerificationCode"
                :class="{
                  activeClassInputs:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                  errorVerificationInputs:
                    errors.verificationCodeError.length > 0,
                }"
                dir="ltr"
                id="second-char"
                type="text"
                v-model="verificationCode.secondChar"
                maxlength="1"
                @keyup="checkSecondInput('second-char')"
              />

              <input
                @keyup.enter="logInWithVerificationCode"
                :class="{
                  activeClassInputs:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                  errorVerificationInputs:
                    errors.verificationCodeError.length > 0,
                }"
                dir="ltr"
                id="first-char"
                type="text"
                v-model="verificationCode.firstChar"
                maxlength="1"
                @keyup="checkFirstInput"
              />
            </div>

            <span
              :class="['showVerificationErrors', {}]"
              v-for="(error, index) in errors.verificationCodeError"
              v-bind:key="index"
              v-show="errors.verificationCodeError.length != 0"
            >
              {{ error }}
            </span>

            <!-- phone number errors validation -->
            <!-- <span :class="['showVerificationErrors', {}]" v-for="(error, index) in errors.phoneNumberError"
                            v-bind:key="index" v-show="errors.phoneNumberError.length != 0">
                            {{ error }}
                        </span> -->

            <!-- <span class="message-for-send-code">امکان ارسال مجدد کد بعد از 100 ثانیه</span> -->

            <button
              v-if="this.$route.path == '/users/login'"
              @click.prevent="logInWithVerificationCode"
              :disabled="
                !firstChar ||
                !secondChar ||
                !thirdChar ||
                !fourthChar ||
                !fifthChar
              "
              :class="[
                'verification-code-submit',
                {
                  activeButton:
                    firstChar &&
                    secondChar &&
                    thirdChar &&
                    fourthChar &&
                    fifthChar,
                },
              ]"
            >
              ورود
            </button>

            <!-- <button v-else @click.prevent="verificationCodePage = false; completeTheAccount = true;"
                            :disabled="!firstChar && !secondChar && !thirdChar
                            && !fourthChar && !fifthChar" :class="['verification-code-submit',
    { activeButton: firstChar && secondChar && thirdChar && fourthChar && fifthChar }]">
                            ادامه
                        </button> -->

            <div class="login-link">
              <span @click="goToLoginWithPass">
                ورود با رمز عبور

                <font-awesome-icon icon="fa-chevron-left" />
              </span>
            </div>
          </form>

          <!--                    </div>-->
        </div>
      </div>

      <div class="verification-code-left">
        <img
          class="img-fluid"
          src="../../assets/images/pictures/verification-code-img.svg"
          alt=""
        />
      </div>
    </div>

    <div v-show="gotoPassPage" class="app-password">
      <!-- mobile size section -->

      <div class="back-page-in-mobile">
        <img
          v-if="this.$route.path == '/register'"
          class="img-fluid"
          @click="
            gotoPassPage = false;
            verificationCodePage = true;
          "
          src="../../assets/images/icons/register-back-in-mobile.svg"
          alt=""
        />
        <img
          v-else
          class="img-fluid"
          @click="
            gotoPassPage = false;
            mobileNumberPage = true;
          "
          src="../../assets/images/icons/register-back-in-mobile.svg"
          alt=""
        />
      </div>

      <div class="app-password-right">
        <!-- TODO v-show="screenWidth > 425" -->
        <img
          class="back-desktop img-fluid"
          v-if="this.$route.path == '/users/login'"
          @click="
            gotoPassPage = false;
            mobileNumberPage = true;
          "
          src="../../assets/images/icons/backArrow.svg"
          alt=""
        />

        <!-- TODO v-show="screenWidth > 425" -->
        <!-- <img v-else class="img-fluid" @click="gotoPassPage = false; mobileNumberPage = true;"
                    src="../../assets/images/icons/backArrow.svg" alt=""> -->

        <div class="app-password-description">
          <h4>ورود / ثبت نام {{ storeInfo?.store_name }}</h4>
          <p>لطفا برای ورود ، رمز عبور خود را وارد کنید .</p>
          <hr />
        </div>

        <form class="password-form">
          <div class="password-input">
            <!-- old code -->
            <!-- <span>رمز عبور</span> -->

            <div
              :class="[
                'password',
                {
                  fillInputClass: passwordInputFocusActive,
                  errorInput: errors.passwordError.length != 0,
                },
              ]"
            >
              <input
                @keyup.enter="loginWithPass"
                @input="checkPassError"
                @focus="passwordInputFocusActive = true"
                placeholder="رمز عبور"
                @blur="passwordInputFocusActive = false"
                v-model="passwordValue"
                :type="typeInput"
              />

              <button disabled v-if="!passwordInputFocusActive">
                <img
                  ref="showPassword"
                  @click="showPass"
                  @touchstart="showPass"
                  @touchend="hidePass"
                  src="../../assets/images/icons/hideAndShowInput.svg"
                  alt=""
                />
              </button>

              <button v-else disabled>
                <img
                  @click="showPass"
                  ref="showPassword"
                  src="../../assets/images/icons/active-eyes-for-password.svg"
                  alt=""
                />
              </button>
            </div>

            <span
              :class="['showErrorPassword', {}]"
              v-for="(error, index) in errors.passwordError"
              v-bind:key="index"
              v-show="errors.passwordError.length != 0"
            >
              {{ error }}
            </span>

            <!-- <button v-if="this.$route.path != '/users/login'"
                            @click.prevent="gotoPassPage = false; completeTheAccount = true;" :disabled="!passwordValue"
                            :class="['register-password-button', { activeButtonClass: passwordValue }]">
                            ثبت نام
                        </button> -->

            <!-- TODO login with password -->
            <button
              style="margin-top: 40px"
              @click.prevent="loginWithPass"
              :disabled="!passwordValue"
              :class="[
                'register-password-button',
                { activeButtonClass: passwordValue },
              ]"
            >
              ورود
            </button>

            <div class="loginWithOncePass">
              <span
                v-if="this.$route.path == '/users/login'"
                @click="loginWithOncePass"
              >
                ورود با رمز یکبار مصرف

                <font-awesome-icon icon="fa-chevron-left" />
              </span>
            </div>
          </div>
        </form>
      </div>

      <div class="app-password-left">
        <img
          class="img-fluid"
          src="../../assets/images/pictures/register-password-photo.svg"
          alt=""
        />
      </div>
    </div>

    <!-- finish registration modal -->

    <!--  <div v-show="completeTheAccount" class="finish-register-page">
      
      <img
        @click="
          completeTheAccount = false;
          mobileNumberPage = true;
        "
        src="../../assets/images/icons/backArrow.svg"
        alt=""
      />

      <div class="finish-register-page-content">
        <h4>فروشگاه {{ storeInfo.store_name }}</h4>
        <p>
          ورود با موفقیت انجام شد، لطفا برای تکمیل حساب کاربری خود ادامه دهید.
        </p>
        <hr />

        <img
          class="img-fluid"
          src="../../assets/images/pictures/finish-register-img.svg"
          alt=""
        />

        <button @click="goToProfilePage" class="completeAccountBtn">
          تکمیل حساب کاربری
        </button>
        
        <span @click="goToStorePage">بعدا تکمیل میکنم</span>
      </div>
    </div> -->

    <!--  HTML Tags For Mobile  -->
  </div>
</template>

<script>
export default {
  name: "Register",

  components: {},

  data() {
    return {
      phoneNumber: "",
      disableButton: false,
      getVerificationCode: false,
      passwordInputFocusActive: false,
      errors: {
        phoneNumberError: [],
        verificationCodeError: [],
        passwordError: [],
      },

      verificationCode: {
        firstChar: "",
        secondChar: "",
        thirdChar: "",
        fourthChar: "",
        fifthChar: "",
      },

      mobileNumberPage: true,
      verificationCodePage: false,
      gotoPassPage: false,
      completeTheAccount: false,
      typeInput: "password",

      passwordValue: "",
      phoneNumberFocusActive: false,
    };
  },

  computed: {
    firstChar() {
      return this.verificationCode.firstChar;
    },

    secondChar() {
      return this.verificationCode.secondChar;
    },

    thirdChar() {
      return this.verificationCode.thirdChar;
    },

    fourthChar() {
      return this.verificationCode.fourthChar;
    },

    fifthChar() {
      return this.verificationCode.fifthChar;
    },

    /* TODO  mobile size (get screen width) */

    // screenWidth() {

    //     if (typeof window != undefined) {
    //         return window.screen.width;
    //     }
    // }

    storeInfo() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo;
      }
    },
  },

  watch: {
    phoneNumber() {
      if (this.phoneNumber != "") {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
  },

  methods: {
    loginWithOncePass() {
      this.gotoPassPage = false;
      this.verificationCodePage = true;
    },

    //*** method for go to terms of us page */
    goToTermsOfUs() {
      // redirect to  termsOfUs page
      let resolved = this.$router.resolve({
        name: "termsOfUs",
      });

      // redirect
      location.href = resolved.href;
    },

    //  method for set phone number for send verification code
    setPhoneNumber() {
      this.errors.phoneNumberError = [];

      this.$store
        .dispatch("phoneNumberDispatch", this.phoneNumber)
        .then((res) => {
          if (res.status) {
            this.mobileNumberPage = false;
            this.verificationCodePage = true;
          } else {
            this.errors.phoneNumberError = res.message.phone_number;
            this.$forceUpdate();
          }
        });

      // old code
      //if (this.phoneNumber.length != 10) {
      //     this.errors.phoneNumberError.push('شماره موبایل معتبر نیست .');

      // } else if (this.$route.path === '/login') {

      //     // console.log(this.$route);

      //     this.mobileNumberPage = false;
      //     this.gotoPassPage = true;

      // } else {
      //     console.log('goodbye');

      //     this.mobileNumberPage = false;
      //     this.verificationCodePage = true;

      // }
    },

    //  method for send verification code and login
    logInWithVerificationCode() {
      this.errors.phoneNumberError = [];
      this.errors.verificationCodeError = [];

      this.$store
        .dispatch("verificationCodeDispatch", {
          phone_number: this.phoneNumber,
          verify_code:
            this.verificationCode.firstChar +
            this.verificationCode.secondChar +
            this.verificationCode.thirdChar +
            this.verificationCode.fourthChar +
            this.verificationCode.fifthChar,
        })
        .then((res) => {
          if (res.status) {
            // redirect
            this.verificationCodePage = false;
            this.completeTheAccount = false;

            // set token with vuex persist
            this.$store.dispatch("setTokenDispatch", res.access_token);

            // redirect to profile
            let resolved = this.$router.resolve({
              name: "userAccount",
              query: {},
            });

            // redirect
            location.href = resolved.href;
          } else {
            // check type of message
            if (typeof res.message == "string") {
              this.errors.verificationCodeError.push(res.message);
              this.$forceUpdate();
            } else {
              if ("verify_code" in res.message) {
                this.errors.verificationCodeError = res.message.verify_code;
              } else if ("phone_number" in res.message) {
                this.errors.phoneNumberError = res.message.phone_number;
              }
            }
          }
        });
    },

    // method for login with password
    loginWithPass() {
      this.errors.phoneNumberError = [];
      this.errors.passwordError = [];

      this.$store
        .dispatch("loginPassDispatch", {
          phone_number: this.phoneNumber,
          password: this.passwordValue,
        })
        .then((res) => {
          if (res.status) {
            // redirect
            this.gotoPassPage = false;
            this.completeTheAccount = false;

            // set token with vuex persist
            this.$store.dispatch("setTokenDispatch", res.access_token);

            // redirect to profile
            let resolved = this.$router.resolve({
              name: "userAccount",
              query: {},
            });

            // redirect
            location.href = resolved.href;
          } else {
            // check type of message
            if (typeof res.message == "string") {
              this.errors.passwordError.push(res.message);
              this.$forceUpdate();
            } else {
              if ("password" in res.message) {
                this.errors.verificationCodeError = res.message.password;
              } else if ("phone_number" in res.message) {
                this.errors.phoneNumberError = res.message.phone_number;
              }
            }
          }
        });
    },

    checkErrors() {
      this.errors.phoneNumberError = [];
    },

    // method for check password errror
    checkPassError() {
      this.errors.passwordError = [];
    },

    checkFirstInput() {
      const firstInput = document.getElementById("first-char");
      const secondInput = document.getElementById("second-char");
      firstInput.value = firstInput.value.replace(/\D+/g, "");

      if (firstInput.value.length > 0) {
        this.verificationCode.firstChar = firstInput.value;

        console.log(this.verificationCode.firstChar);
        secondInput.focus();
      }
      this.errors.verificationCodeError = [];
    },

    checkSecondInput() {
      const secondInput = document.getElementById("second-char");
      const thirdInput = document.getElementById("third-char");
      const firstInput = document.getElementById("first-char");

      secondInput.value = secondInput.value.replace(/\D+/g, "");

      if (secondInput.value.length > 0) {
        this.verificationCode.secondChar = secondInput.value;

        thirdInput.focus();
      } else if (secondInput.value.length == 0) {
        firstInput.focus();
      }
      this.errors.verificationCodeError = [];
    },

    checkThirdInput() {
      const thirdInput = document.getElementById("third-char");
      const fourthInput = document.getElementById("fourth-char");
      const secondInput = document.getElementById("second-char");

      thirdInput.value = thirdInput.value.replace(/\D+/g, "");
      if (thirdInput.value.length > 0) {
        this.verificationCode.thirdChar = thirdInput.value;

        fourthInput.focus();
      } else if (thirdInput.value.length == 0) {
        secondInput.focus();
      }

      this.errors.verificationCodeError = [];
    },

    checkFourthInput() {
      const fourthInput = document.getElementById("fourth-char");
      const fifthInput = document.getElementById("fifth-char");
      const thirdInput = document.getElementById("third-char");

      fourthInput.value = fourthInput.value.replace(/\D+/g, "");
      if (fourthInput.value.length > 0) {
        this.verificationCode.fourthChar = fourthInput.value;
        fifthInput.focus();
      } else if (fourthInput.value.length == 0) {
        thirdInput.focus();
      }
      this.errors.verificationCodeError = [];
    },

    checkFifthInput() {
      const fifthInput = document.getElementById("fifth-char");
      const fourthInput = document.getElementById("fourth-char");

      fifthInput.value = fifthInput.value.replace(/\D+/g, "");

      this.verificationCode.fifthChar = fifthInput.value;

      if (fifthInput.value.length == 0) {
        fourthInput.focus();
      }

      this.errors.verificationCodeError = [];
    },

    /*  Password methods */

    showPass() {
      // e.preventDefault();

      if (this.typeInput == "text") {
        // e.preventDefault()
        this.typeInput = "password";
      } else {
        this.typeInput = "text";
        this.passwordInputFocusActive = true;
      }
    },

    hidePass() {
      this.typeInput = "password";
    },

    // method for go to store main page
    goToStorePage() {
      let resolved = this.$router.resolve({
        name: "store",
        query: {},
      });

      // redirect
      location.href = resolved.href;
    },

    //  method for go to profile page
    goToProfilePage() {
      let resolved = this.$router.resolve({
        name: "userAccount",
        query: {},
      });

      // redirect
      location.href = resolved.href;
    },

    // method for for go to login with password page
    goToLoginWithPass() {
      this.verificationCodePage = false;
      this.gotoPassPage = true;
    },

    //  method for get ssr data
    asyncData({ route, store }) {
      // dispatch for get store info
      store.dispatch("storeInfoDispatch");
    },
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    this.$store.dispatch("storeInfoDispatch");
  },
};
</script>

<style src="../../assets/css/register/register.css" scoped></style>
