// main.js
import Vue from "vue";
import App from "./App.vue";
import routes from "./router/router.js";
import { createStore } from "./store/store";
import Router from "vue-router";

// import styles
import "./assets/css/main/index.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// mapbox usage
import Mapbox from "mapbox-gl";
Vue.use({ mapboxgl: Mapbox });

// fontAwesome Vue usage
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-regular-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas, faStar, faComment);

Vue.component("font-awesome-icon", FontAwesomeIcon);

// usage vue bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

// vue slick imports
import "../node_modules/slick-carousel/slick/slick.css";

// vue slider cmponent imports

// import for csr
// import VueSlider from "vue-slider-component";

// import for ssr
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

Vue.component("VueSlider", VueSlider);

// vue circle slider imports
import VueCircleSlider from "vue-circle-slider";

// global variables
Vue.prototype.$mainColor = "#4990e2";
Vue.prototype.$mainColorS = "#e9f3ff";
Vue.prototype.$secondColorS = "#f0f0f0";
Vue.prototype.$secondColorP = "#ff4d16";

Vue.use(VueCircleSlider);
const store = createStore();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// export a factory function for creating fresh app, router and store
// instances
// export function createApp() {
//   // create router instance
//   const router = createRouter();
//   // create store instance
//   const store = createStore();

//   // sync so that route state is available as part of the store
//   sync(store, router);

//   // create the app instance, injecting both the router and the store
//   const app = new Vue({
//     router,
//     store,
//     // the root instance simply renders the App component.
//     render: h => h(App)
//   });

//   return { app, router, store };
// }
