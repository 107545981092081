<template>
  <div class="point-users">
    <div class="point-users-title">
      <p>
        نظرات کاربران در مورد :

        <span>{{
          getProductName.title + " (" + getProductName.latin_title + ")"
        }}</span>
      </p>

      <!-- <i  class="fas fa-chevron-down"></i> -->
      <font-awesome-icon
        id="add-comment-arrow"
        @click="toggleAddNewPoint"
        icon="fa-chevron-down"
      />
    </div>

    <div class="add-new-points-content">
      <div class="add-new-points-content-details">
        <div
          v-for="(type, index) in categoryRatingTypes"
          :key="index"
          class="slider-circle-div"
        >
          <div class="show-worthBuying-value">
            <h6>{{ productComment.product_rates[index].rate }}%</h6>
          </div>

          <circle-slider
            v-model="type.rate"
            circle-color="#EEEEEE"
            knob-color="#4990E2"
            progress-color="#4990E2"
            :step-size="25"
            :circle-width="8"
          ></circle-slider>

          <span>{{ type.title }}</span>
        </div>
      </div>

      <div class="add-new-points-comment">
        <div
          :class="[
            'add-new-points-comment-input',
            {
              validErrorBorder:
                (validationError.comment_text &&
                  validationError.comment_text.length > 0) ||
                (validationError.buy_suggestion &&
                  validationError.buy_suggestion.length > 0),
            },
          ]"
        >
          <textarea
            @input="removeValidationErrorComment"
            :class="{ activeTextArea: productComment.comment_text != '' }"
            v-model="productComment.comment_text"
            placeholder="لطفا نظر خود را در مورد محصول بنویسید..."
          >
          </textarea>

          <div class="add-new-points-comment-radio">
            <span>آیا خرید این محصول را به دیگران پیشنهاد میکنید؟</span>

            <label for="yes" class="radio-success">
              <input
                @input="removeValidationErrorSuggestion"
                v-model="productComment.buy_suggestion"
                value="1"
                id="yes"
                type="radio"
              />
              بله
            </label>

            <label for="no" class="radio-danger">
              <input
                @input="removeValidationErrorSuggestion"
                v-model="productComment.buy_suggestion"
                value="0"
                id="no"
                type="radio"
              />
              خیر
            </label>

            <label for="notSure" class="radio-warning">
              <input
                @input="removeValidationErrorSuggestion"
                v-model="productComment.buy_suggestion"
                value="2"
                id="notSure"
                type="radio"
              />
              مطمئن نیستم
            </label>
          </div>
        </div>

        <span
          class="comment-validation-error"
          v-for="(error, index) in validationError.comment_text"
          :key="index"
          v-show="
            validationError.comment_text &&
            validationError.comment_text.length > 0
          "
        >
          {{ error }}
        </span>

        <span
          class="comment-validation-error"
          v-for="(error, index) in validationError.buy_suggestion"
          :key="index"
          v-show="
            validationError.buy_suggestion &&
            validationError.buy_suggestion.length > 0
          "
        >
          {{ error }}
        </span>

        <!-- 

                    {
                            activeSubmitComment: (productComment.comment_text != ''
                                && productComment.buy_suggestion != '')
                    }

                    :disabled="(productComment.comment_text == '' && productComment.buy_suggestion == '')"
                 -->

        <button
          @click="productCommentSubmit"
          :class="['submit-comment activeSubmitComment']"
        >
          ثبت نظر
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddNewPoint",

  data() {
    return {
      showAndHideCommentsForAdd: false,

      productComment: {
        comment_text: "",
        buy_suggestion: "",
        product_id: "",
        product_rates: [],
      },

      validationError: {
        comment_text: [],
        buy_suggestion: [],
      },
    };
  },

  computed: {
    //  method for get product name from vuex
    getProductName() {
      if (this.$store.getters.getShowProductInfo != null) {
        return {
          title: this.$store.getters.getShowProductInfo.title,
          latin_title: this.$store.getters.getShowProductInfo.latin_title,
        };
      } else {
        return {
          title: "",
          latin_title: "",
        };
      }
    },

    //  method for get category rating types of specific product
    categoryRatingTypes() {
      if (
        this.$store.getters.getRatingTypes != null &&
        this.$store.getters.getRatingTypes.length > 0
      ) {
        this.$store.getters.getRatingTypes.map((ratingType, index) => {
          let item = {
            title: ratingType.title,
            category_rating_type_id: ratingType.id,
            rate: "",
          };
          this.productComment.product_rates.push(item);
        });

        return this.productComment.product_rates;
      }
    },
  },

  methods: {
    toggleAddNewPoint() {
      const commentArrow = document.getElementById("add-comment-arrow");

      if (this.showAndHideCommentsForAdd === false) {
        this.showAndHideCommentsForAdd = true;
        this.$emit(
          "toggleCommentShowForAddNew",
          this.showAndHideCommentsForAdd
        );
        commentArrow.style.transform = "rotate(0deg)";
      } else {
        this.showAndHideCommentsForAdd = false;
        this.$emit(
          "toggleCommentShowForAddNew",
          this.showAndHideCommentsForAdd
        );
        commentArrow.style.transform = "rotate(180deg)";
      }
    },

    //  method for post productComment
    productCommentSubmit() {
      this.validationError.comment_text = [];
      this.validationError.buy_suggestion = [];

      // add token
      this.productComment.token = this.$store.getters.getToken;

      // add product_id
      this.productComment.product_id = this.$route.params.id;

      this.$store
        .dispatch("postCommentDispatch", this.productComment)
        .then((res) => {
          if (res.status) {
            // empty productComment states
            this.productComment.product_id = "";
            this.productComment.comment_text = "";
            this.productComment.buy_suggestion = "";
            if (this.productComment.product_rates.length > 0) {
              this.productComment.product_rates.map((item) => {
                item.rate = 0;
              });
            }

            // alert of success
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message,
            });
          } else {
            if (typeof res.message == "string") {
              if ("comment_text" in res.message) {
                this.validationError.comment_text.push(res.message);
              } else if ("buy_suggestion" in res.message) {
                this.validationError.buy_suggestion.push(res.message);
              }
            } else {
              this.validationError.comment_text = res.message.comment_text;
              this.validationError.buy_suggestion = res.message.buy_suggestion;
            }
          }
        });
    },

    // method for remove validation of comment text
    removeValidationErrorComment() {
      this.validationError.comment_text = [];
    },
    // method for remove validation of comment text
    removeValidationErrorSuggestion() {
      this.validationError.buy_suggestion = [];
    },
  },
};
</script>

<style src="../../assets/css/product/addNewPoint.css" scoped></style>
