<template>
  <div class="frequentlyQuestions">
    <!--  frequently question for web  -->

    <div class="frequentlyQuestions-content">
      <div class="common-your-questions-content">
        <div class="common-your-question-content-title">
          <h5>سوالات متداول شما</h5>

          <p @click="allFqQuestions">مشاهده همه</p>
        </div>

        <div class="common-your-questions-content-details">
          <div
            v-for="(commonQ, x) in fqQuestions"
            :key="x"
            :class="[
              'common-your-questions-content-single',
              { borderActive: commonQuestionsShow[x] },
            ]"
          >
            <div
              @click="showCommonQuestions(x)"
              class="common-your-questions-content-single-title"
            >
              <div class="common-single-title-right">
                <img
                  src="../../assets/images/icons/common-questions-store-sign.svg"
                  alt=""
                />
                <span>{{ commonQ.title_question }}</span>
                <!-- <span>?</span> -->
              </div>

              <font-awesome-icon
                icon="fa-chevron-down"
                :id="'commonQuestion' + x"
              />
            </div>

            <div
              :class="[
                'common-your-questions-content-single-content',
                { CommonQuestionShowContent: commonQuestionsShow[x] },
              ]"
            >
              <hr />
              <span>
                {{ commonQ.answer }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  frequently question for mobile  -->

    <div class="frequentlyQuestions-content-mobile">
      <div class="frequentlyQuestions-mobile-title">
        <h4>سوالات متداول شما</h4>
        <p @click="allFqQuestions">مشاهده همه</p>
      </div>

      <div class="frequentlyQuestionsMobile">
        <div
          v-for="(commonQ, x) in fqQuestions"
          :key="x"
          :class="[
            'single-question-mobile',
            { openQuestionMobileBox: commonQuestionsShow[x] },
          ]"
        >
          <div class="single-question-mobile-title">
            <img
              src="../../assets/images/icons/common-questions-store-sign.svg"
              alt=""
            />
            <p>{{ commonQ.title_question }}</p>
          </div>

          <div
            :class="[
              'single-question-mobile-answer',
              { singleQuestionShowAnswerMobile: commonQuestionsShow[x] },
            ]"
          >
            <p>
              {{ commonQ.answer }}
            </p>
          </div>

          <hr class="single-question-mobile-hr" />

          <div class="question-mobile-show-answer">
            <button
              v-if="!commonQuestionsShow[x]"
              @click="showCommonQuestions(x)"
              class="single-question-mobile-show-answer"
            >
              <span>پاسخ</span>
              <i class="fa fa-chevron-down"></i>
            </button>

            <button
              v-else
              @click="showCommonQuestions(x)"
              class="single-question-mobile-show-answer"
            >
              <span :style="{ color: $mainColor }">بستن</span>
              <i style="color: #4990e2" class="fa fa-chevron-up"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FrequentlyAskedQuestions",

  data() {
    return {
      commonQuestionsContentStore: [
        {
          commonQuestion:
            "تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ",
          questionAlign: "؟",
          commonAnswer:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n" +
            ".بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده",
        },

        {
          commonQuestion:
            "تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ",
          questionAlign: "؟",
          commonAnswer:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n" +
            ".بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده",
        },

        {
          commonQuestion:
            "تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می طلبد، تا با نرم ",
          questionAlign: "؟",
          commonAnswer:
            "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف \n" +
            ".بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده",
        },
      ],

      commonQuestionsShow: [],

      // showQuestionAnswerMobile: false,
    };
  },

  computed: {
    fqQuestions() {
      if (this.$store.getters.getFqQuestions != null) {
        return this.$store.getters.getFqQuestions;
      }
    },
  },

  mounted() {
    for (let i = 0; i < this.commonQuestionsContentStore.length; i++) {
      this.commonQuestionsShow.push(false);

      this.$forceUpdate();
    }
  },

  methods: {
    showCommonQuestions(x) {
      const commonQuestion = document.getElementById("commonQuestion" + x);

      if (this.commonQuestionsShow[x] === false) {
        commonQuestion.style.transform = "rotate(180deg)";
        commonQuestion.style.transition = "all .3s";

        commonQuestion.style.color = this.$mainColor;

        this.commonQuestionsShow[x] = true;
        this.$forceUpdate();
      } else {
        commonQuestion.style.transform = "rotate(0deg)";
        commonQuestion.style.transition = "all .3s";

        commonQuestion.style.color = "#A8A8A8";

        this.commonQuestionsShow[x] = false;
        this.$forceUpdate();
      }
    },

    asyncData({ route, store }) {
      store.dispatch("fqQuestionsDispatch");
    },

    allFqQuestions() {
      window.location.href = "/questions";
    },
  },

  created() {
    this.$store.dispatch("fqQuestionsDispatch");
  },
};
</script>

<style src="../../assets/css/store/frequentlyQuestions.css" scoped></style>
