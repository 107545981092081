<template>
  <div class="point-users">
    <div class="point-users-title">
      <p>
        نظرات کاربران در مورد :

        <span>{{
          productName.title + " (" + productName.latin_title + ")"
        }}</span>
      </p>

      <font-awesome-icon
        id="comment_arrow"
        @click="togglePointAndComment"
        icon="fa-chevron-down"
      />
    </div>

    <div class="point-users-content">
      <div class="point-users-circles">
        <div
          v-for="(rate, index) in productRates"
          :key="index"
          class="point-property"
        >
          <div class="point-main-circle">
            <div
              v-show="0 < rate.average_rate && rate.average_rate <= 100"
              class="point-quarter-background"
            ></div>

            <div
              v-show="25 < rate.average_rate && rate.average_rate <= 100"
              class="point-half-background"
            ></div>

            <div
              v-show="50 < rate.average_rate && rate.average_rate <= 100"
              class="point-three-quarter-background"
            ></div>

            <div
              v-show="75 < rate.average_rate && rate.average_rate <= 100"
              class="point-four-quarter-background"
            ></div>

            <div class="point-into-circle">
              <p>{{ Math.ceil(rate.average_rate) }} %</p>
            </div>
          </div>

          <p class="point-property-title">{{ rate.category_rating_type }}</p>
        </div>
      </div>

      <div class="point-btn">
        <p>برای ثبت نظر، لازم است ابتدا وارد حساب کاربری خود شوید</p>
        <button @click="goToLogin">ورود / ثبت نام</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PointShow",

  data() {
    return {
      showAndHideCommentsForShow: false,
    };
  },

  methods: {
    togglePointAndComment() {
      const commentArrow = document.getElementById("comment_arrow");

      if (this.showAndHideCommentsForShow == false) {
        this.showAndHideCommentsForShow = true;
        this.$emit("toggleCommentShow", this.showAndHideCommentsForShow);
        commentArrow.style.transform = "rotate(0deg)";
      } else {
        this.showAndHideCommentsForShow = false;
        this.$emit("toggleCommentShow", this.showAndHideCommentsForShow);
        commentArrow.style.transform = "rotate(180deg)";
      }
    },

    goToLogin() {
      let resolved = this.$router.resolve({
        name: "login",
        query: {},
      });

      location.href = resolved.href;
    },
  },

  computed: {
    // method for get productName
    productName() {
      if (this.$store.getters.getShowProductInfo != null) {
        return {
          title: this.$store.getters.getShowProductInfo.title,
          latin_title: this.$store.getters.getShowProductInfo.latin_title,
        };
      } else {
        return {
          title: "",
          latin_title: "",
        };
      }
    },
    // method for get productCommentsRates
    productRates() {
      if (this.$store.getters.getProductCommentsRates != null) {
        return this.$store.getters.getProductCommentsRates;
      }
    },
  },
};
</script>

<style src="../../assets/css/product/pointsShow.css" scoped></style>
