<template>
  <div v-show="getActiveSelectedVariable.length > 1" class="sellers-container">
    <div class="sellers-content">
      <h3>فروشندگان دیگر این کالا</h3>

      <!-- sellers row -->
      <!-- v-show="getActiveSelectedVariable_id == item.productPrice_id" -->

      <div class="sellers-row-container">
        <div
          v-for="(item, index) in getActiveSelectedVariable"
          :key="index"
          :style="
            index == 0
              ? { backgroundColor: '#F9FAFC' }
              : { backgroundColor: '#fff' }
          "
          class="sellers-row"
        >
          <!-- title section -->
          <div class="row-sections">
            <img src="../../assets/images/icons/market-place.svg" alt="" />
            <p>
              {{
                item.seller != null
                  ? item?.seller?.seller_storename
                  : getStoreInfo.store_name
              }}
            </p>
          </div>

          <div class="row-sections">
            <img src="../../assets/images/icons/seller-point.png" alt="" />
            <p>فروشنده منتخب {{ getStoreInfo.store_name }}</p>
          </div>

          <!-- price && add to cart section -->
          <div class="row-sections mobile-row-section justify-content-end">
            <!-- final price -->
            <div class="final-price">
              <span>{{
                numberFormat(item.seller_price - item.seller_discount)
              }}</span
              ><span>تومان</span>
            </div>

            <!-- price with discount -->
            <div
              v-if="
                discountComputed(item.seller_price, item.seller_discount) != 0
              "
              class="price-discount p-0"
            >
              <span>{{ numberFormat(item.seller_price) }}</span>
              <div class="price-discount-percent p-0">
                <span
                  >%
                  {{
                    discountComputed(item.seller_price, item.seller_discount)
                  }}
                </span>
              </div>
            </div>
            <!-- add to cart btn -->
            <button
              @click="addToCart(item, index)"
              v-if="!item.isIn_cart"
              class="add-to-cart"
            >
              افزودن به سبد خرید
            </button>
            <button
              v-if="item.isIn_cart"
              @click="removeFromCart(item, index)"
              class="remove-from-cart"
            >
              حذف از سبد خرید
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormat } from "../../assets/helper/helper";

export default {
  name: "Sellers",
  computed: {
    getStoreInfo() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo;
      }
    },

    getSellersInfo() {
      if (
        this.$store.getters.getShowProductInfo != null &&
        this.$store.getters.getProductSellers != null
      ) {
        return this.$store.getters.getProductSellers;
      }
    },

    // get active selected variable id
    getActiveSelectedVariable() {
      if (this.$store.getters.getActiveSelectedVariable_id != null) {
        return this.$store.getters.getActiveSelectedVariable_id;
      }
    },
  },
  methods: {
    numberFormat,

    //      method for get product (price && discount) from vuex
    discountComputed(priceAmount, discountAmount) {
      let discount = 0;
      // check discount have value or not

      discount = Math.ceil((discountAmount / priceAmount) * 100);

      return discount;
    },

    // (seller) method for add to cart product
    addToCart(seller, index) {
      console.log(seller);
      if (this.$store.state.token == "") {
        // alert for login
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "ابتدا وارد حساب کاربری خود شوید .",
        });
      } else {
        //  add to cart
        this.$store
          .dispatch("addToCartDispatch", {
            token: this.$store.getters.getToken,
            productPrice_id: seller.productPrice_id,
            product_count: 1,
            seller_id: seller.seller_id,
          })
          .then((res) => {
            if (res.status) {
              this.getActiveSelectedVariable[index].isIn_cart = true;

              this.$forceUpdate();

              // success alert
              this.$store.dispatch("alertDispatch", {
                show: true,
                text: res.message,
              });
            } else {
              // error alert
              this.$store.dispatch("errorAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          });
      }
    },

    //  method for remove product from cart
    // (seller)  delete order
    removeFromCart(item, index) {
      if (this.$store.state.token == "") {
        // alert for login
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "ابتدا وارد حساب کاربری خود شوید .",
        });
      } else {
        //  add to cart
        this.$store
          .dispatch("removeProductFromCart", {
            token: this.$store.getters.getToken,
            productPrice_id: item.productPrice_id,
            seller_id: item.seller_id == null ? "" : item.seller_id,
          })
          .then((res) => {
            if (res.status) {
              // success alert
              // this.selectedVariable.isIn_cart = false;
              this.getActiveSelectedVariable[index].isIn_cart = false;
              this.$forceUpdate();
              this.$store.dispatch("alertDispatch", {
                show: true,
                text: res.message,
              });
            } else {
              // error alert
              this.$store.dispatch("errorAlertDispatch", {
                show: true,
                text: res.message,
              });
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="css" src="../../assets/css/product/sellers.css"></style>
