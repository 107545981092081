<template>
  <div id="contact-to-us-parent">
    <!-- for mobile size section -->
    <!-- <div class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage"
                 src="../../assets/images/icons/back-arrow-mobile3.svg"
                 alt="">
            <p>تماس با ما</p>
        </div> -->

    <div class="contact-to-us">
      <app-header id="app-header-mobile"></app-header>

      <div class="contact-to-us-content">
        <h5 class="contact-to-us-content-title">تماس با ما</h5>

        <span class="contact-to-us-content-description">
          {{ desComputed }}
        </span>

        <!--<hr>-->

        <div class="contact-to-us-bottom">
          <div class="contact-to-us-info">
            <div class="contact-to-us-info-title">
              <div class="support-telephone">
                <img
                  src="../../assets/images/icons/contact-to-us-support-telephone.svg"
                  alt=""
                />
                <span>تلفن پشتیبانی </span>
              </div>

              <div class="company-email">
                <img src="../../assets/images/icons/company-email.svg" alt="" />
                <span>پست الکترونیکی </span>
              </div>

              <div class="company-address">
                <img
                  src="../../assets/images/icons/company-address.svg"
                  alt=""
                />
                <span>آدرس شرکت </span>
              </div>
            </div>

            <div class="contact-to-us-info-content">
              <span>{{ phoneComputed }}</span>
              <span>{{ emailComputed }}</span>
              <span>{{ addressComputed }}</span>
            </div>
          </div>

          <!-- mobile size section -->
          <!-- <div class="contact-to-us-info-mobile">
                        <div class="contact-to-us-info-mobile-telephone">
                            <p>تلفن پشتیبانی</p>
                            <p>{{ phoneComputed }}</p>
                        </div>
                        <div class="contact-to-us-info-mobile-mail">
                            <p>پست الکترونیکی</p>
                            <p>{{ emailComputed }}</p>
                        </div>
                        <div class="contact-to-us-info-mobile-address">
                            <p>آدرس شرکت</p>
                            <p>{{ addressComputed }}</p>
                        </div>
                    </div> -->

          <div class="contact-to-us-map">
            <div class="contact-to-us-map-content">
              <div id="mapContainer"></div>

              <!-- <MglMap :accessToken="accessToken" :zoom="14" :center="[51.3890, 35.6892]"
                                :mapStyle="mapStyle">
                                <MglMarker :coordinates="coordinates" color="blue" />
                            </MglMap> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</template>

<script>
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { mapGetters } from "vuex";
import mapboxgl from "mapbox-gl";

export default {
  name: "ContactToUs",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiYWxpb3N0YWQiLCJhIjoiY2w1amR3aXluMGg2ZjNkcGZzY3Z3bmVxdiJ9.2kqA9AO3AxHULjSKOEqSEg", // your access token. Needed if you using Mapbox maps
      mapStyle: "mapbox://styles/hossein92/ckd18td6n1rf91imqyn6kx9ne",
      coordinates: [51.389, 35.6892],

      /* contact to us mobile data */
      // screenWidth: window.screen.width,
    };
  },

  computed: {
    ...mapGetters({
      contactUs: "getStoreContactUs",
    }),

    desComputed() {
      if (this.contactUs.length != 0) {
        return this.contactUs.find((item) => item.key === "description").value;
      }
    },

    emailComputed() {
      if (this.contactUs.length != 0) {
        return this.contactUs.find((item) => item.key === "email").value;
      }
    },
    phoneComputed() {
      if (this.contactUs.length != 0) {
        return this.contactUs.find((item) => item.key === "phone-number").value;
      }
    },

    addressComputed() {
      if (this.contactUs.length != 0) {
        return this.contactUs.find((item) => item.key === "Address").value;
      }
    },

    latlngComputed() {
      if (this.contactUs.length != 0) {
        return this.contactUs.find((item) => item.key === "latlng").value;
      }
    },
  },

  created() {
    //  get contact us info
    this.$store.dispatch("storeContactUsDispatch");

    //  show loader
    this.$store.dispatch("showLoaderDispatch", true);

    /* setTimeout(() => {
      if (typeof window !== "undefined") {
        mapboxgl.accessToken = this.accessToken;

        // check latlang have value
        let latlng = [];
        latlng.push(
          Number(
            this.latlngComputed.replace("[", "").replace("]", "").split(",")[0]
          )
        );
        latlng.push(
          Number(
            this.latlngComputed.replace("[", "").replace("]", "").split(",")[1]
          )
        );

        let map = new mapboxgl.Map({
          container: "mapContainer",
          style: this.mapStyle,
          center: latlng,
          zoom: 14,
        });

        mapboxgl.setRTLTextPlugin(
          "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
          null,
          true // Lazy load the plugin
        );

        const nav = new mapboxgl.NavigationControl();
        map.addControl(nav, "top-right");
        new mapboxgl.Marker().setLngLat(latlng).addTo(map);
      }
    }, 1000); */
  },

  mounted() {
    // if (this.screenWidth < 426 && this.$route.path == '/contact-to-us' || this.$route.path == '/contact-to-us/') {
    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');
    //     header.style.display = 'none';
    // }

    setTimeout(() => {
      if (typeof window !== "undefined") {
        mapboxgl.accessToken = this.accessToken;

        // check latlang have value
        let latlng = [];
        if (
          this.latlngComputed.length > 0 ||
          this.latlngComputed.length != ""
        ) {
          latlng.push(
            Number(
              this.latlngComputed
                .replace("[", "")
                .replace("]", "")
                .split(",")[0]
            )
          );
          latlng.push(
            Number(
              this.latlngComputed
                .replace("[", "")
                .replace("]", "")
                .split(",")[1]
            )
          );
        }

        let map = new mapboxgl.Map({
          container: "mapContainer",
          style: this.mapStyle,
          center: latlng,
          zoom: 14,
        });

        mapboxgl.setRTLTextPlugin(
          "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
          null,
          true // Lazy load the plugin
        );

        const nav = new mapboxgl.NavigationControl();
        map.addControl(nav, "top-right");
        new mapboxgl.Marker().setLngLat(latlng).addTo(map);
      }
    }, 3000);
  },

  methods: {
    // backToSidebarMobileFromAddressesPage() {
    //     /* get about us parent */
    //     const contactToUsParent = document.getElementById('contact-to-us-parent');

    //     /* get header mobile */
    //     const header = document.getElementById('app-header-mobile');

    //     if (this.screenWidth < 426) {
    //         contactToUsParent.style.display = 'none';
    //         header.style.display = 'flex';
    //         // console.log(this.prevRouterEnter)
    //         // this.$router.push(this.prevRouterEnter);
    //         this.$router.go(-1);
    //     }

    // }

    // dispatch for server fetching
    asyncData({ store, route }) {
      store.dispatch("storeContactUsDispatch");
    },
  },
};
</script>

<style src="../../assets/css/others/contactToUs.css" scoped></style>
