import createPersistedState from "vuex-persistedstate";

let PersistedState;

PersistedState = createPersistedState({
  key: "access_token",
  paths: ["token", "profileImage", "showDemoModal"],
  // overwrite: true
  storage: {
    getItem: (key) => {
      // with local storage
      return localStorage.getItem(key);
    },

    setItem: (key, value) => {
      // with local storage
      localStorage.setItem(key, value);
    },

    removeItem: (key) => {
      // with local storage
      localStorage.removeItem(key);
    },
  },
});

export default PersistedState;
