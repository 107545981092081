<template>
  <div class="w-100">
    <div
      id="header"
      :class="[
        'header',
        { borderRadiusBottom: showCategoryContent },
        { shadowBottom: !showCategoryContent },
      ]"
    >
      <div class="header-bottom">
        <div class="bottom-right">
          <div class="bottom-right-list">
            <div
              @click="goToHomePage"
              @mouseenter="hoverOnHome(true)"
              @mouseleave="hoverOnHome(false)"
              class="home-box"
            >
              <img
                v-if="showHoverHome"
                src="../../assets/images/icons/HomeActive.svg"
                alt=""
              />
              <img v-else src="../../assets/images/icons/Home.svg" alt="" />

              <span :class="{ activeHeaderTitle: showHoverHome }">خانه</span>
            </div>

            <div
              @mouseenter="showCategoryProductContent(true)"
              @mouseleave="showCategoryProductContent(false)"
              class="product-category"
            >
              <img
                v-if="!showCategoryContent"
                src="../../assets/images/icons/headerCategory.png"
                alt=""
              />
              <img
                v-else
                src="../../assets/images/icons/active-header-category.svg"
                alt=""
              />
              <span :class="{ activeHeaderTitle: showCategoryContent }"
                >دسته بندی محصولات</span
              >
            </div>

            <div
              @click="goToQuestions"
              @mouseenter="hoverOnQuestions(true)"
              @mouseleave="hoverOnQuestions(false)"
              class="question-sign"
            >
              <img
                v-if="!showHoverQuestions"
                src="../../assets/images/icons/questionSign.png"
                alt=""
              />
              <img
                v-else
                src="../../assets/images/icons/active-header-question.svg"
                alt=""
              />

              <span
                @click:only="goToQuestions"
                :class="{ activeHeaderTitle: showHoverQuestions }"
                >سوال بپرسید</span
              >
            </div>

            <div
              @click="goToContactUs"
              @mouseenter="hoverOnContactTOUs(true)"
              @mouseleave="hoverOnContactTOUs(false)"
              class="free-consultation"
            >
              <img
                v-if="!showHoverContactToUs"
                src="../../assets/images/icons/freeConsultation.png"
                alt=""
              />
              <img
                v-else
                src="../../assets/images/icons/active-header-contact-to-us.svg"
                alt=""
              />

              <span
                @click="goToContactUs"
                :class="{ activeHeaderTitle: showHoverContactToUs }"
                >تماس با ما</span
              >
            </div>
          </div>
        </div>

        <div class="bottom-center">
          <div class="bottom-left">
            <input
              v-model="searchValue"
              @keyup.enter="productSearchHandler"
              type="text"
              placeholder="محصول مورد نظر خود را جستجو کنید ...."
            />
            <img
              @click.prevent="productSearchHandler"
              src="../../assets/images/icons/searchIconHeader.png"
              alt=""
            />
          </div>
        </div>

        <div class="top-left">
          <!-- V-IF USER LOGED IN -->
          <a
            v-if="token == ''"
            @click="goToLoginPage"
            class="register-login ml-3"
          >
            <img
              src="../../assets/images/icons/login-register-user-icon.svg"
              alt=""
            />
            <a>ورود</a>
            <span>/</span>
            <a>ثبت نام</a>
          </a>

          <div
            v-else
            @click="dropDownHandler"
            class="register-login-active ml-3"
          >
            <img
              src="../../assets/images/icons/login-register-user-icon-active.svg"
              alt=""
            />
            <font-awesome-icon icon="fa-chevron-down" />
          </div>

          <div class="vertical-line"></div>

          <img
            @click="goToCart"
            src="../../assets/images/icons/cart.png"
            alt=""
          />
        </div>
      </div>

      <!-- new category content -->
      <div
        @mouseenter="showCategoryProductContent(true)"
        @mouseleave="showCategoryProductContent(false)"
        v-show="showCategoryContent"
        class="category-of-products-content"
      >
        <span ref="categoriesBaner" />
        <div class="main-categories">
          <span
            :style="
              lastCoverShowComputed ? { height: '31px' } : { height: '0px' }
            "
            class="cover-last-category"
          />
          <div
            v-for="(mainCategory, mainIndex) in categories"
            :key="mainIndex"
            @mouseenter="activeMainCategoryEvent(mainCategory)"
            @mouseleave="inActiveMainCategoryEvent(mainCategory)"
            @click="goToSearchPage(mainCategory.id)"
            :style="[
              activeMainCategory.id == mainCategory.id
                ? { backgroundColor: $mainColorS }
                : {},
            ]"
            class="main-category-single"
          >
            <img :src="mainCategory.media_link" alt="" />
            <div
              :style="[
                activeMainCategory.id != mainCategory.id
                  ? { zIndex: '10' }
                  : { zIndex: '0' },
              ]"
              class="main-category-name"
            >
              <p
                :style="[
                  activeMainCategory.id == mainCategory.id
                    ? { marginBottom: '15px' }
                    : { marginBottom: '0px' },
                ]"
              >
                {{ mainCategory.title }}
              </p>
              <!-- todo -->
              <div
                :style="[
                  activeMainCategory.id == mainCategory.id
                    ? {
                        transform: 'translate(0, 0)',
                        height: 'auto',
                      }
                    : {
                        transform: 'translate(0px, 50px)',
                        height: '0px',
                      },
                ]"
                class="show-all-products"
              >
                <p>مشاهده همه کالاهای این دسته</p>
                <img
                  src="../../assets/images/icons/arrow-of-dropdown.svg"
                  alt=""
                />
              </div>
            </div>
            <span />
          </div>
        </div>

        <div
          @scroll="secondLayerScrollEvent"
          class="second-layer-categories"
          ref="secondLayer"
        >
          <span
            :style="{ top: secondLineTop + 'px' }"
            class="second-layer-line"
          ></span>

          <template v-if="activeMainCategory != null">
            <div
              v-for="(
                secondLayer, slIndex
              ) in activeMainCategory.all_product_childes_categories_store"
              :key="slIndex"
              @mouseenter="activeSecondLayerEvent(secondLayer)"
              @mouseleave="inActiveSecondLayerEvent(secondLayer)"
              class="row-layer"
              :style="[
                activeSecondLayer.id == secondLayer.id
                  ? { backgroundColor: $mainColorS }
                  : {},
              ]"
            >
              <p
                :style="[
                  activeSecondLayer.id == secondLayer.id
                    ? {
                        color: $mainColor,
                        display: 'flex',
                        alignItems: 'center',
                      }
                    : { display: 'flex', alignItems: 'center' },
                ]"
                @click="goToSearchPage(secondLayer.id)"
              >
                <img
                  class="ml-1"
                  v-if="activeSecondLayer.id != secondLayer.id"
                  src="../../assets/images/icons/arrow-of-dropdown.svg"
                  alt=""
                />
                <img
                  class="ml-1"
                  v-else
                  src="../../assets/images/icons/arrow-of-dropdown-active.svg"
                  alt=""
                />
                {{ secondLayer.title }}
              </p>
            </div>
          </template>
        </div>

        <div ref="thirdLayer" class="third-layer-categoreis">
          <template v-if="activeSecondLayer != null">
            <p
              v-for="(
                thirdLayer, thirdIndex
              ) in activeSecondLayer.all_product_childes_categories_store"
              :key="thirdIndex"
              @click="goToSearchPage(thirdLayer.id)"
            >
              {{ thirdLayer.title }}
            </p>
          </template>
        </div>
      </div>

      <!-- Header Of Mobile  -->
      <div v-show="!showAllComments" class="header-mobile">
        <!-- header mobile top section -->
        <div class="header-top-mobile">
          <button @click="mobileSlider()">
            <img src="../../assets/images/icons/hamburgerIcon.png" alt="" />
          </button>

          <p @click="goToHomePage">
            بازارچه آنلاین

            <span>{{ getStoreName }}</span>
          </p>
          <div v-if="this.token != ''" class="user-cart-img">
            <img
              @click="gotoProfile()"
              src="../../assets/images/icons/header-user-icon.svg"
              alt=""
            />

            <img
              @click="goToCart()"
              src="../../assets/images/icons/header-cart.svg"
              alt=""
            />
          </div>

          <div
            v-else
            @click="goToLogin()"
            :style="{ display: 'flex', alignItems: 'center' }"
            class="user-cart-img"
          >
            <font-awesome-icon
              :style="{ marginLeft: '10px' }"
              icon="fa-sign-in"
            />
            <p>ورود</p>
          </div>
        </div>

        <!-- header mobile bottom section -->
        <div class="header-bottom-mobile">
          <div class="header-bottom-mobile-input">
            <input
              type="text"
              placeholder="محصول مورد نظر خود را جستجو کنید ..."
            />

            <img src="../../assets/images/icons/searchIconHeader.png" alt="" />
          </div>
        </div>

        <!-- header categories in mobile -->
        <div
          id="sliderContent"
          :class="['slider-content', 'p-0', { showSlider: showSliderMobile }]"
        >
          <div class="slider-content-title">
            <img src="../../assets/images/pictures/storeIcone.svg" alt="" />
            <span>فروشگاه اینترنتی {{ getStoreName }}</span>
          </div>
          <hr class="slider-hr" />

          <!--  one item  -->
          <header-categories :categories="categories" />
        </div>
      </div>
    </div>

    <!-- dropdown show profile when user logged in -->
    <div class="profile-dropdown-box" v-show="showDropdownBackground">
      <div class="profile-dropdown-box-top">
        <img
          v-if="profileImg == ''"
          src="../../assets/images/icons/dropdown-profile.svg"
          alt=""
        />
        <img v-else :src="profileImg" alt="" />

        <div class="profile-dropdown-box-top-left">
          <p class="mb-3">{{ userInfo.full_name }}</p>

          <p @click="gotoActiveOrders">
            سفارشات فعال
            <font-awesome-icon icon="fa-chevron-left" />
          </p>
        </div>
      </div>

      <hr class="dash-line" />

      <div class="profile-dropdown-box-center">
        <div class="center-single-section">
          <img
            @click="gotoProfile"
            src="../../assets/images/icons/slider-user-account.svg"
            alt=""
          />
          <p @click="gotoProfile">حساب کاربری</p>
        </div>
        <div class="center-single-section">
          <img
            @click="gotoOrder"
            src="../../assets/images/icons/slider-follow-orders-icon2.svg"
            alt=""
          />
          <p @click="gotoOrder">تاریخچه سفارشات</p>
        </div>
        <div class="center-single-section">
          <img
            @click="gotoAddress"
            src="../../assets/images/icons/slider-my-address2.svg"
            alt=""
          />
          <p @click="gotoAddress">آدرس ها</p>
        </div>
        <div class="center-single-section">
          <img
            @click="gotoComment"
            src="../../assets/images/icons/slider-comments-icon2.svg"
            alt=""
          />
          <p @click="gotoComment">نظرات</p>
        </div>
        <div class="center-single-section">
          <img
            @click="gotoMessage"
            src="../../assets/images/icons/slider-messages-icon2.svg"
            alt=""
          />
          <p @click="gotoMessage">پیام ها</p>
        </div>
        <!-- <div class="center-single-section">
                    <img src="../../assets/images/icons/slider-product-returned-icon2.svg" alt="">
                    <p>محصولات مرجوعی</p>
                </div> -->
      </div>

      <hr class="dash-line" />

      <div class="profile-dropdown-box-bottom">
        <div class="exit-box">
          <img
            @click="logOut"
            src="../../assets/images/icons/slider-user-accoount-exit-icon.svg"
            alt=""
          />

          <p @click="logOut">خروج</p>
        </div>
      </div>
    </div>

    <!-- blur background for mobile section -->
    <div
      id="blur-background"
      :class="{ blurBackground: showSliderMobile }"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeaderCategories from "../mobileSize/HeaderCategories.vue";

const productIcon1 = require("../../assets/images/icons/category-title-icon.svg");
const productIcon2 = require("../../assets/images/icons/category-icon3.svg");
const productIcon3 = require("../../assets/images/icons/categoryIcon2.svg");
const productIcon4 = require("../../assets/images/icons/categoryIcon4.svg");
const productIcon5 = require("../../assets/images/icons/category-icon-5.svg");
const productIcon6 = require("../../assets/images/icons/category-icon-6.svg");
const productIcon7 = require("../../assets/images/icons/category-icon-7.svg");

export default {
  name: "Header",

  components: { HeaderCategories },

  props: ["showAllComments"],

  data() {
    return {
      // variable for active category
      activeMainCategory: null,
      activeSecondLayer: null,

      // variable for top of second layer top
      secondLineTop: 30,
      settings: {
        suppressScrollY: false,
        suppressScrollX: true,
        wheelPropagation: false,
      },
      showSliderMobile: false,
      showCollapse: false,
      showCollapseChild: false,

      // todo show categories
      showCategoryContent: false,
      showHoverQuestions: false,
      showHoverContactToUs: false,
      showHoverHome: false,

      categoryProductsContent: [
        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon1,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "بهداشت و زیبایی ناخن",
            "تجهیزات جانبی آرایشی",
            "لوازم بهداشتی",
            "کرم و مراقبت پوست",
            "شامپو و مراقبت مو",
          ],
        },

        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon2,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "بهداشت و زیبایی ناخن",
            "تجهیزات جانبی آرایشی",
          ],
        },

        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon3,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "بهداشت و زیبایی ناخن",
            "تجهیزات جانبی آرایشی",
          ],
        },
        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon4,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "بهداشت و زیبایی ناخن",
            "تجهیزات جانبی آرایشی",
            "لوازم بهداشتی",
            "کرم و مراقبت پوست",
            "شامپو و مراقبت مو",
          ],
        },

        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon5,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "لوازم بهداشتی",
            "کرم و مراقبت پوست",
            "شامپو و مراقبت مو",
          ],
        },

        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon6,
          categoryContent: [
            "آرایش لب",
            "آرایش صورت",
            "مواد آرایش مو",
            "بهداشت و زیبایی ناخن",
            "تجهیزات جانبی آرایشی",
            "لوازم بهداشتی",
            "کرم و مراقبت پوست",
            "شامپو و مراقبت مو",
          ],
        },

        {
          categoryTitle: "آرایشی و گریم",
          categoryIcon: productIcon7,
          categoryContent: ["آرایش لب", "آرایش صورت"],
        },
      ],

      searchValue: "",
      // state for show dropdown of profile
      // showProfileDropDown: false
    };
  },

  methods: {
    // method active main category (mouse enter)
    activeMainCategoryEvent(category) {
      this.activeMainCategory = category;
      this.activeSecondLayer = category.all_product_childes_categories_store[0];
    },

    // method for inActive main category (mouse leave)
    inActiveMainCategoryEvent(category) {
      console.log(category);
    },

    activeSecondLayerEvent(category) {
      if (category.productCategory_id == this.activeMainCategory.id) {
        this.activeSecondLayer = category;
      } else {
        this.activeSecondLayer =
          this.activeMainCategory.all_product_childes_categories_store[0];
      }
    },

    inActiveSecondLayerEvent(category) {
      console.log(category);
    },
    // method for change second layer line event
    secondLayerScrollEvent(event) {
      this.secondLineTop = event.target.scrollTop + 30;
    },
    mobileSlider() {
      const blurBackground = document.getElementById("blur-background");

      this.showSliderMobile = true;

      blurBackground.addEventListener("click", () => {
        this.showSliderMobile = false;
      });
    },

    showCategoryProductContent(change) {
      this.showCategoryContent = change;
      this.showSliderMobile = change;
    },

    hoverOnHome(active) {
      this.showHoverHome = active;
    },

    hoverOnQuestions(active) {
      this.showHoverQuestions = active;
    },

    hoverOnContactTOUs(active) {
      this.showHoverContactToUs = active;
    },

    goToCart() {
      if (this.token != "") {
        let resolved = this.$router.resolve({
          name: "CartContent",
        });

        location.href = resolved.href;
      } else {
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "ابتدا وارد حساب کاربری خود شوید",
        });
      }
    },

    // method for go to home page
    goToHomePage() {
      let resolved = this.$router.resolve({
        name: "store",
      });
      location.href = resolved.href;
    },
    // method for go to questions page
    goToQuestions() {
      location.pathname = "/questions";
    },

    // method for go to contact us page
    goToContactUs() {
      location.pathname = "/contact-us";
    },
    // method for go to search page
    goToSearchPage(category_id) {
      let resolved = this.$router.resolve({
        name: "search",
        query: { productCategories: JSON.stringify([category_id]) },
      });

      location.href = resolved.href;
    },

    //  method for go to login page
    goToLoginPage() {
      let resolved = this.$router.resolve({
        name: "login",
        query: {},
      });
      //  redirect
      location.href = resolved.href;
    },

    //  method for show and hide dropdown
    dropDownHandler(e) {
      e.preventDefault();

      // dispatch for show background
      this.$store.dispatch(
        "showBackgroundDispatch",
        !this.showDropdownBackground
      );
    },

    //  method for goto order page n profile
    gotoOrder() {
      let resolved = this.$router.resolve({
        name: "orders",
      });

      location.href = resolved.href;
    },

    // method for go to profile
    gotoProfile() {
      let resolved = this.$router.resolve({
        name: "userAccount",
      });

      location.href = resolved.href;
    },

    // method for go to login page
    goToLogin() {
      let resolved = this.$router.resolve({
        name: "login",
      });

      location.href = resolved.href;
    },

    //  method for got addresses page in profile
    gotoAddress() {
      let resolved = this.$router.resolve({
        name: "addresses",
      });

      location.href = resolved.href;
    },

    //  method for goto comment page n profile
    gotoComment() {
      let resolved = this.$router.resolve({
        name: "comments",
      });

      location.href = resolved.href;
    },

    //  method for goto message page in profile
    gotoMessage() {
      let resolved = this.$router.resolve({
        name: "messages",
      });

      location.href = resolved.href;
    },

    //  method for logout user
    logOut() {
      this.$store
        .dispatch("logOutDispatch", {
          token: this.token,
        })
        .then((res) => {
          if (res.status) {
            //  remove token
            this.$store.dispatch("setTokenDispatch", "");

            //  redirect user
            let resolved = this.$router.resolve({
              name: "store",
            });

            location.href = resolved.href;
          }
        });
    },

    //  method for handler search in header
    productSearchHandler() {
      //  redirect user
      let resolved = this.$router.resolve({
        name: "search",
        query: { q: this.searchValue },
      });

      location.href = resolved.href;
    },

    //  method for got active orders
    gotoActiveOrders() {
      //  redirect user
      let resolved = this.$router.resolve({
        name: "orders",
        query: { activeOrder: true },
      });

      location.href = resolved.href;
    },

    //      get header categries ssr
    asyncData({ store, route }) {
      store.dispatch("headerCategoriesDispatch");

      if (this.token != "") {
        store.dispatch("userDispatch", { token: this.token });
      }
    },
  },

  created() {
    //  get categories of header
    this.$store.dispatch("headerCategoriesDispatch");

    //  get user logged info
    if (this.token != "") {
      this.$store.dispatch("userDispatch", { token: this.token });
    }
  },

  watch: {
    activeMainCategory(newVal, prevVal) {
      const secondLayer = this.$refs.secondLayer;
      const banner = this.$refs.categoriesBaner;
      if (
        newVal != prevVal &&
        secondLayer != undefined &&
        banner != undefined
      ) {
        secondLayer.style.transform = "translateX(400px)";
        secondLayer.style.transition = "all .5s ease-in-out";
        // secondLayer.style.width = "0";
        // secondLayer.style.padding = "0";
        banner.style.zIndex = "11";

        setTimeout(() => {
          secondLayer.style.transform = "translateX(0px)";
          secondLayer.style.transition = "all .5s ease-in-out";
          // secondLayer.style.width = "300px";
          // secondLayer.style.padding = "20px";
          banner.style.zIndex = "0";
        }, 500);
      }
    },

    activeSecondLayer(newVal, prevVal) {
      const thirdLayer = this.$refs.thirdLayer;
      const banner = this.$refs.categoriesBaner;
      if (newVal != prevVal && thirdLayer != undefined && banner != undefined) {
        thirdLayer.style.transform = "translateX(650px)";
        thirdLayer.style.transition = "all .5s ease-in-out";
        // thirdLayer.style.width = "0";
        // thirdLayer.style.padding = "0";
        banner.style.zIndex = "10";

        setTimeout(() => {
          thirdLayer.style.transform = "translateX(0px)";
          thirdLayer.style.transition = "all .5s ease-in-out";
          // thirdLayer.style.width = "calc(100% - 760px)";
          // thirdLayer.style.padding = "30px";
          banner.style.zIndex = "0";
        }, 500);
      }
    },
  },

  computed: {
    // method for show cover for last main categories
    lastCoverShowComputed() {
      if (this.activeMainCategory == null) {
        return true;
      } else {
        if (
          this.activeMainCategory.id !=
          this.categories[this.categories.length - 1].id
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    categories() {
      if (this.$store.getters.getHeaderCategories.length != 0) {
        this.activeMainCategory = this.$store.getters.getHeaderCategories[0];
        this.activeSecondLayer =
          this.$store.getters.getHeaderCategories[0].all_product_childes_categories_store[0];
        return this.$store.getters.getHeaderCategories;
      }
    },
    /* ...mapGetters({
      categories: "getHeaderCategories",
    }), */

    token() {
      return this.$store.getters.getToken;
    },

    userInfo() {
      if (this.$store.getters.getUserInfo != null) {
        return this.$store.getters.getUserInfo;
      } else {
        return {
          full_name: "",
        };
      }
    },

    showDropdownBackground() {
      return this.$store.getters.getShowBackground;
    },

    //  method for get profile image
    profileImg() {
      return this.$store.getters.getProfileImage;
    },

    //  computed for get store name
    getStoreName() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.store_name;
      }
    },
  },
};
</script>

<style src="../../assets/css/Header/Header.css" scoped></style>
