<template>
  <div
    :style="[
      getShowDemoModal().show && getShowDemoModal().count > 0
        ? { opacity: '1', zIndex: '100' }
        : { opacity: '0', zIndex: '-1' }
    ]"
    class="demo-background"
  >
    <div
      :style="[
        getShowDemoModal
          ? { opacity: '1', zIndex: '101' }
          : { opacity: '0', zIndex: '-1' }
      ]"
      class="demo-box"
    >
      <div class="demo-box-header">
        <img src="../../assets/images/icons/goodbye-icon.svg" alt="" />
        <h3>به ویتسل خوش آمدید</h3>
      </div>
      <p>برای مشاهده فروشگاه لطفا شماره تلفن خود را تایید کنید</p>

      <div class="phoneNumber-box">
        <h3>شماره موبایل</h3>
        <input
          :class="{
            'error-border': submitDemoUserError.demoPhoneNumber.length > 0
          }"
          @input="submitDemoUserError.demoPhoneNumber = []"
          v-model="demoPhoneNumber"
          placeholder="*۰۹۱۲۳۴۵۶۷۸۹"
          type="number"
          name=""
          id=""
        />

        <span
          v-if="submitDemoUserError.demoPhoneNumber.length > 0"
          class="error-text"
          v-for="(err, index) in submitDemoUserError.demoPhoneNumber"
          :key="index"
          >{{ err }}</span
        >
      </div>

      <button @click="submitDemoUser()" class="demo-submit">
        ورود به فروشگاه
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      demoPhoneNumber: "",
      submitDemoUserError: {
        demoPhoneNumber: []
      }
    };
  },

  methods: {
    scrollListener(e) {
      if (window.scrollY > 1000) {
        this.$store.dispatch("setShowDemoModalDispatch", {
          show: true,
          count: this.getShowDemoModal().count + 1
        });
      }
    },

    // method for submit demo user
    submitDemoUser() {
      let demoUserInfo = {
        toke: this.getAccessToken,
        demoPhoneNumber: this.demoPhoneNumber,
        phone_number: "09128756547",
        password: "Demo1234*"
      };

      //   http request
      this.$store.dispatch("submitDemoUserDispatch", demoUserInfo).then(res => {
        if (res.status) {
          // remove modal
          this.$store.dispatch("setShowDemoModalDispatch", {
            show: false,
            count: -1000000000000000000000000
          });

          // set demo token
          this.$store.dispatch("setTokenDispatch", res.access_token);

          // empty input
          this.demoPhoneNumber = "";
        } else {
          if ("demoPhoneNumber" in res.message) {
            typeof res.message != String
              ? (this.submitDemoUserError.demoPhoneNumber =
                  res.message.demoPhoneNumber)
              : this.submitDemoUserError.demoPhoneNumber.push(res.message);
          }
        }
      });
    },

    // get persist state show demo modal
    getShowDemoModal() {
      return this.$store.getters.getShowDemoModal;
    }
  },

  computed: {
    getAccessToken() {
      return this.$store.getters.getToken;
    }
  },

  mounted() {
    // Only execute this code on the client side, server sticks to [0, 0]
    if (!this.$isServer) {
      // When scrolling, update the position
      window.addEventListener("scroll", this.scrollListener);
    }
  },

  created() {
    // Only execute this code on the client side, server sticks to [0, 0]
    // TODO Error in server
    /* if (!this.$isServer) {
      // When scrolling, update the position
      window.addEventListener("scroll", this.scrollListener);
    } */
  },

  beforeDestroy() {
    // Detach the listener when the component is gone
    window.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style src="../../assets/css/others/demoModal.css" scoped></style>
