<template>
    <div id="about-us-parent">

        <!-- for mobile size section -->

        <!-- <div class="profile-addresses-mobile-title">
            <img @click="backToSidebarMobileFromAddressesPage" src="../../assets/images/icons/back-arrow-mobile3.svg"
                alt="">
            <p>درباره ما</p>
        </div> -->

        <div class="about-us">

            <app-header id="app-header-mobile"></app-header>


            <div class="about-us-content">

                <div class="about-us-content-title">
                    <h5>{{ store != null ? 'درباره' + ' ' + store.store_name : 'درباره ما' }}</h5>
                    <span>
                        {{aboutUs.length > 0 ?  aboutUs[0].description : ''}}
                    </span>
                </div>


                <div class="about-us-properties">

                    <div v-for="(aboutUsProperty, index) in aboutUs" :key="index"
                        v-show="aboutUsProperty.title != 'about-us'" class="about-us-property-single">

                        <div class="single-property-img">

                            <hr>

                            <img :src="aboutUsProperty.media_link" alt="">

                            <hr>


                        </div>
                        <span>{{ aboutUsProperty.title }}</span>


                        <div class="single-property-description">

                            <span>
                                {{ aboutUsProperty.description }}
                            </span>

                        </div>

                    </div>

                </div>


            </div>


        </div>

        <app-footer></app-footer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

/* about us properties images */

import Footer from "../footer/Footer";

const fastSend = require('../../assets/images/pictures/fast-send-img.svg');
const simpleBuying = require('../../assets/images/pictures/simple-buying-img.svg');
const ProductReturnGuarantee = require('../../assets/images/pictures/Product-return-guarantee.svg');
const onlineSupport = require('../../assets/images/pictures/online-support.svg');
const simplePaying = require('../../assets/images/pictures/simple-paying.svg');

import Header from "../header/Header";

export default {
    name: "AboutUs",
    components: {
        'app-header': Header,
        'app-footer': Footer
    },


    data() {
        return {

            /* mobile's data */
            // screenWidth: window.screen.width,
            // prevRouterEnter: '',
        }
    },




    methods: {

        //     backToSidebarMobileFromAddressesPage() {
        //         /* get about us parent */
        //         const aboutUsParent = document.getElementById('about-us-parent');

        //         /* get header mobile */
        //         const header = document.getElementById('app-header-mobile');


        //         if (this.screenWidth < 426) {
        //             aboutUsParent.style.display = 'none';
        //             header.style.display = 'flex';
        //             // console.log(this.prevRouterEnter)
        //             // this.$router.push(this.prevRouterEnter);
        //             this.$router.go(-1);
        //         }

        //     }

        asyncData({ store, route }) {
            store.dispatch('storeInfoDispatch')
            store.dispatch('storeAboutUsDispatch')
        },
    },

    /*beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRouterEnter = from.path;
        })

    },*/


    // mounted() {
    //     if (this.screenWidth < 426 && this.$route.path == '/about-us' || this.$route.path == '/about-us/') {

    //         const header = document.getElementById('app-header-mobile');

    //         header.style.display = 'none';
    //     }
    // },

    created() {
        this.$store.dispatch('storeInfoDispatch');
        this.$store.dispatch('storeAboutUsDispatch');

        //  show loader
        this.$store.dispatch("showLoaderDispatch", true)
    },

    computed: {
        ...mapGetters({
            store: "getStoreInfo",
            // aboutUs: "getStoreAboutUs"
        }),


        aboutUs() {
            this.$store.getters.getStoreAboutUs.map((item) => {
                switch (item.title) {
                    case "send-fast":
                        item.title = "ارسال سریع"
                        item.media_link = fastSend
                        break;
                    case "easy-buy":
                        item.title = "خرید آسان"
                        item.media_link = simpleBuying
                        break;
                    case "product-return":
                        item.title = "ضمانت برگشت کالا"
                        item.media_link = ProductReturnGuarantee
                        break;
                    case "online-support":
                        item.title = "پشتیبانی آنلاین"
                        item.media_link = onlineSupport
                        break;
                    case "pay-Method":
                        item.title = "روش های پرداخت"
                        item.media_link = simplePaying
                        break;
                }
            })
            return this.$store.getters.getStoreAboutUs;
        },

        // mainAboutUsDes() {
        //     let obj = this.$store.getters.getStoreAboutUs.find((item) => {
        //         return item.title == "about-us"
        //     })

        //     console.log(obj)

        //     return obj.description;
        // }
    }
}
</script>

<style src="../../assets/css/others/aboutUs.css" scoped>
</style>