<template>
  <UserAccount>
    <div class="profile-comments-parent">
      <!--  profile comments desktop  -->

      <div class="profile-comments">
        <div
          v-for="(comment, index) in comments"
          :key="index"
          class="profile-comments-details-parent2"
        >
          <div class="profile-comments-details">
            <img class="img-fluid" :src="comment.product_img" alt="" />

            <div class="profile-comments-details-content p-0">
              <div class="profile-comments-details-content-top p-0">
                <span>{{ comment.created_at }}</span>

                <div class="profile-comment-top-left">
                  <div class="suggestion-comments-profile">
                    <img
                      src="../../assets/images/icons/suggestion-comments-icon.svg"
                      alt=""
                    />
                    <span v-if="comment.buy_suggestion == 1"
                      >خرید این محصول را پیشنهاد می کنم</span
                    >
                    <span v-if="comment.buy_suggestion == 0"
                      >خرید این محصول را پیشنهاد نمی کنم</span
                    >
                    <span v-if="comment.buy_suggestion == 2"
                      >در مورد خرید این محصول مطمئن نیستم</span
                    >
                  </div>

                  <div class="button-announcements">
                    <span
                      :class="{
                        'button-announcements-success':
                          comment.comment_status == 'تایید شده',
                        'button-announcements-error':
                          comment.comment_status == 'رد شده',
                        'button-announcements-warning':
                          comment.comment_status == 'در انتظار بررسی'
                      }"
                      >{{ comment.comment_status }}</span
                    >

                    <img
                      @click.prevent="deleteHandler(comment)"
                      src="../../assets/images/icons/delete-profile-comments.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div class="comments-text p-0">
                <span>
                  {{ comment.comment_text }}
                </span>
              </div>

              <div class="valueBuy-quality-Innovation">
                <div
                  v-if="comment.product_rates.length > 0"
                  v-for="(rate, index) in comment.product_rates"
                  :key="index"
                  class="profile-comment-valueBuy"
                >
                  <span>%{{ rate.rate }}</span>

                  <span class="background-rangeBar">
                    <div
                      :style="{ width: `${rate.rate}%` }"
                      class="fillAble-rangBar"
                    ></div>
                  </span>

                  <span class="valueBuy-title">{{
                    rate.categoryRatingType_title
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <hr class="profile-comments-hr" />
        </div>

        <div class="products-pagination">
          <div class="numberBox">
            <div
              v-if="currentPage != 1"
              @click="firstPageHandler"
              class="firstPage"
            >
              <a>
                <img src="../../assets/images/icons/firstPage.png" alt="" />
              </a>
            </div>

            <!-- <div v-if="showFirstThreeDots" class="threeDot">
                        <img src="../../assets/images/icons/threeDot.png" alt="">
                    </div> -->

            <div
              v-for="(page, index) in paginationLinks"
              :key="index"
              v-show="index != 0 && index != paginationLinks.length - 1"
              :class="{ isActive: currentPage == page.label }"
              @click="addNumbers(page.label)"
            >
              {{ page.label }}
            </div>

            <!-- <div v-if="showLastThreeDots" class="threeDot">
                                <img src="../../assets/images/icons/threeDot.png" alt="">
                        </div> -->

            <div
              v-if="currentPage != lastPage"
              @click="lastPageHandler"
              id="lastPage"
              class="lastPage"
            >
              <a>
                <img src="../../assets/images/icons/lastPage.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <!--  profile comments mobile  -->

      <div id="profile-comments-mobile" class="profile-comments-mobile">
        <div class="profile-addresses-mobile-title">
          <img
            @click="backToSidebarMobileFromCommentsPage"
            src="../../assets/images/icons/back-arrow-mobile3.svg"
            alt=""
          />
          <p>نظر های من</p>
        </div>

        <div class="profile-comments-content-mobile">
          <div
            v-for="(comment, i) in comments"
            :key="i"
            class="profile-comment-content-mobile-single"
          >
            <div class="comment-content-date-and-single-mobile">
              <p>{{ comment.created_at }}</p>

              <!-- todo -->
              <span
                :class="{
                  'button-announcements-success':
                    comment.comment_status == 'تایید شده',
                  'button-announcements-error':
                    comment.comment_status == 'رد شده',
                  'button-announcements-warning':
                    comment.comment_status == 'در انتظار بررسی'
                }"
                >{{ comment.comment_status }}</span
              >
            </div>

            <div class="comment-content-suggestion-text-and-icon">
              <img
                src="../../assets/images/icons/suggestion-comments-icon.svg"
                alt=""
              />

              <p v-if="comment.buy_suggestion == 1">
                خرید این محصول را پیشنهاد می کنم
              </p>
              <p v-if="comment.buy_suggestion == 0">
                خرید این محصول را پیشنهاد نمی کنم
              </p>
              <p v-if="comment.buy_suggestion == 2">
                در مورد خرید این محصول مطمئن نیستم
              </p>
            </div>

            <div
              :style="
                comment.product_rates.length > 0
                  ? { justifyContent: 'space-between' }
                  : { justifyContent: 'center' }
              "
              class="comment-content-progress-points-and-product-image"
            >
              <!-- product_rates mobile -->
              <div
                v-if="comment.product_rates.length > 0"
                class="comment-content-progress-points"
              >
                <div
                  v-for="(rate, index) in comment.product_rates"
                  :key="index"
                  class="comment-content-quality-mobile"
                >
                  <div class="comment-content-quality-text">
                    <p>{{ rate.categoryRatingType_title }}</p>
                    <span>{{ rate.rate }}%</span>
                  </div>

                  <div class="background-progress-comment-content">
                    <div
                      :style="{ width: `${rate.rate}%` }"
                      class="into-progress-comment-content"
                    ></div>
                  </div>
                </div>
              </div>

              <!-- product img -->
              <div
                :style="
                  comment.product_rates.length > 0
                    ? { maxWidth: '110px', maxHeight: '110px', height: '110px' }
                    : { maxWidth: '130px', maxHeight: '130px', height: '130px' }
                "
                class="comment-content-product-img"
              >
                <img :src="comment.product_img" alt="" />
              </div>
            </div>

            <div class="comment-content-text">
              <p>
                {{ comment.comment_text }}
              </p>
            </div>

            <button
              @click.prevent="deleteHandler(comment)"
              class="delete-message-mobile"
            >
              <img
                src="../../assets/images/icons/delete-address-mobile-icon.svg"
                alt=""
              />
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  </UserAccount>
</template>

<script>
import UserAccount from "./UserAccount.vue";
export default {
  name: "Comments",
  components: { UserAccount },
  methods: {
    /*  Pagination Methods  */
    addNumbers(number) {
      //  action for get data and paginaion
      this.$store.dispatch("userCommentsDispatch", {
        token: this.token,
        page: number
      });
    },

    firstPageHandler() {
      // firstPage Clicked
      this.$store.dispatch("userCommentsDispatch", {
        token: this.token,
        page: 1
      });
    },

    lastPageHandler() {
      //  lastPage Clicked
      this.$store.dispatch("userCommentsDispatch", {
        token: this.token,
        page: this.lastPage
      });
    },

    //  method for delete comment
    deleteHandler(comment) {
      console.log(comment);
      this.$store
        .dispatch("destroyCommentDispatch", {
          comment_id: comment.id,
          token: this.token
        })
        .then(res => {
          if (res.status) {
            //  success alert
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.message
            });
            //  get all comments
            this.$store.dispatch("userCommentsDispatch", {
              token: this.token,
              page: this.currentPage
            });
          } else {
            //  warning alert
            this.$store.dispatch("warningAlertDispatch", {
              show: true,
              text: res.message
            });
          }
        });
    },

    /* mobile methods */
    backToSidebarMobileFromCommentsPage() {
      const comments = document.getElementById("profile-comments-mobile");
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      if (this.screenWidth < 426) {
        comments.style.display = "none";
        header.style.display = "flex";
        userAccountSliderMobile.style.display = "flex";
        let resolved = this.$router.resolve({
          name: "userAccount"
        });

        location.href = resolved.href;
      }
    }
  },
  data() {
    return {
      countPage: [
        { number: 1, to: "#" },
        { number: 2, to: "#" },
        { number: 3, to: "#" },
        { number: 4, to: "#" },
        { number: 5, to: "#" },
        { number: 6, to: "#" },
        { number: 7, to: "#" },
        { number: 8, to: "#" },
        { number: 9, to: "#" },
        { number: 10, to: "#" },
        { number: 11, to: "#" },
        { number: 12, to: "#" },
        { number: 13, to: "#" },
        { number: 14, to: "#" },
        { number: 15, to: "#" }
      ],
      showFirstThreeDots: false,
      showLastThreeDots: true,
      showPages: [
        { number: 1, done: true },
        { number: 2, done: false },
        { number: 3, done: false },
        { number: 4, done: false },
        { number: 5, done: false }
      ],
      /* mobile data */
      screenWidth: null
    };
  },
  mounted() {
    // get size of client mobile for sidebar
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
    if (
      (this.screenWidth < 426 && this.$route.path == "/profile/comment") ||
      this.$route.path == "/profile/comment/"
    ) {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      header.style.display = "none";
      userAccountSliderMobile.style.display = "none";
    }
  },

  computed: {
    //  method for get token
    token() {
      return this.$store.getters.getToken;
    },
    //  method for get data comments of user
    comments() {
      if (this.$store.getters.getUserComments != null) {
        return this.$store.getters.getUserComments.data;
      }
    },

    // method for get pagination links
    paginationLinks() {
      if (this.$store.getters.getUserComments != null) {
        return this.$store.getters.getUserComments.meta.links;
      }
    },

    // method for get current page
    currentPage() {
      if (this.$store.getters.getUserComments != null) {
        return this.$store.getters.getUserComments.meta.current_page;
      }
    },

    // method for get current page
    lastPage() {
      if (this.$store.getters.getUserComments != null) {
        return this.$store.getters.getUserComments.meta.last_page;
      }
    }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get comments
    this.$store.dispatch("userCommentsDispatch", {
      token: this.token,
      page: 1
    });
  }
};
</script>

<style src="../../assets/css/userAccount/comments.css" scoped></style>
