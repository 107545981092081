var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"review",attrs:{"id":"review"}},[_c('div',{class:[
      'review-route-title',
      { reviewScrollClass: _vm.reviewTitleScrolled },
    ],attrs:{"id":"review-route-title"}},[_c('div',{staticClass:"review-title"},[_c('span',[_vm._v("نقد و بررسی")]),_c('font-awesome-icon',{attrs:{"id":"chevron-arrow","icon":"fa-chevron-down"},on:{"click":_vm.showMoreAndLessReview}})],1),_c('div',{staticClass:"review-route"},_vm._l((_vm.getProductReviews),function(circle,cIndex){return _c('div',{key:cIndex,staticClass:"review-route-circle",style:({
          width:
            cIndex != _vm.getProductReviews.length - 1
              ? 100 / (_vm.getProductReviews.length - 1) - 1 + '%'
              : '5%',
        })},[_c('div',{staticClass:"route-circle",on:{"click":function($event){return _vm.showProductReviewEvent(cIndex)}}},[_c('img',{attrs:{"src":require("../../assets/images/icons/preview-route-img-tike.svg"),"alt":""}})])])}),0),_c('div',{staticClass:"route-item-title"},_vm._l((_vm.getProductReviews),function({ title },tIndex){return _c('a',{key:tIndex,on:{"click":function($event){$event.preventDefault();return _vm.showProductReviewEvent(tIndex)}}},[_vm._v(" "+_vm._s(title.length > 15 ? title.slice(0, 40) + "..." : title)+" ")])}),0)]),_c('div',{staticClass:"review-content",attrs:{"id":"review-content"}},_vm._l((_vm.getProductReviews),function(review,index){return _c('div',{key:index,staticClass:"review-content"},[_c('p',{staticClass:"review-content-title",attrs:{"id":`review${index}`}},[_vm._v(" "+_vm._s(review.title)+" ")]),_c('div',{staticClass:"review-content-description-img"},[_c('span',[_vm._v(" "+_vm._s(review.description)+" ")]),_c('img',{attrs:{"src":review.media.media_link,"alt":""}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }