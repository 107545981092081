<template>
  <user-account>
    <!--  div parent for mobile and desktop  -->
    <div class="follow-orders-parent">
      <!--  follow orders for desktop  -->
      <div id="follow-orders-desktop" class="follow-orders2">
        <div class="follow-orders-report">
          <div class="my-order">
            <div class="my-order-top">
              <h4>{{ countResult != null ? countResult.all : 0 }}</h4>
              <span>سفارش</span>
            </div>

            <div class="my-order-bottom">
              <p>سفارشات من</p>
            </div>
          </div>

          <div class="receive-orders">
            <div class="receive-orders-top">
              <h4>{{ countResult != null ? countResult.completed : 0 }}</h4>
              <span>سفارش</span>
            </div>
            <div class="receive-orders-bottom">
              <p>تحویل گرفتم</p>
            </div>
          </div>

          <div class="do-not-receive-orders-yet">
            <div class="do-not-receive-orders-yet-top">
              <h4>{{ countResult != null ? countResult.processing : 0 }}</h4>
              <span>سفارش</span>
            </div>
            <div class="do-not-receive-orders-yet-bottom">
              <p>هنوز تحویل نگرفتم</p>
            </div>
          </div>

          <div class="cancel-orders">
            <div class="cancel-orders-top">
              <h4>{{ countResult != null ? countResult.canceled : 0 }}</h4>
              <span>سفارش</span>
            </div>
            <div class="cancel-orders-bottom">
              <p>لغو کردم</p>
            </div>
          </div>
        </div>

        <div class="order-follow-filters">
          <button
            @click="allOrdersFilter"
            :class="['all-orders', { activeFilter: allOrder }]"
          >
            همه سفارشات
          </button>

          <button
            @click="currentlyProcessingFilter"
            :class="[
              'currently-processing',
              { activeFilter: currentlyProcessing },
            ]"
          >
            در حال انجام
          </button>

          <button
            @click="receivedFilter"
            :class="['received', { activeFilter: received }]"
          >
            تکمیل شده
          </button>
          <button
            @click="canceledFilter"
            :class="['canceled', { activeFilter: canceled }]"
          >
            لغو شده
          </button>
        </div>

        <div class="orders-follow-details-table">
          <div class="orders-follow-details-table-title">
            <p>شناسه سفارش</p>
            <p>تاریخ</p>
            <p>جمع کل</p>
            <p>هزینه ارسال</p>
            <!-- <p>کد پیگیری</p> -->
            <p>وضعیت</p>
            <!-- <p>فاکتور</p> -->
            <p>جزئیات</p>
          </div>

          <div class="order-content-info">
            <div
              v-show="orders.length > 0"
              :class="{ activeBackgroundColor: allOrderCountIndex[index] }"
              v-for="(followOrders, index) in orders"
              v-bind:key="index"
              class="order-title-and-content-group"
            >
              <div
                :class="[
                  {
                    activeBorderBottom:
                      !allOrderCountIndex[index] && index != orders.length - 1,
                  },
                  'orders-follow-details-table-content',
                ]"
              >
                <div class="order-code">
                  <p>{{ followOrders.order_barcode }}</p>
                </div>

                <div class="followOrders-date">
                  <p style="direction: ltr">{{ followOrders.submit_date }}</p>
                </div>

                <div class="followOrders-totalPrice">
                  <p>{{ numberFormat(followOrders.order_price) }}</p>
                </div>

                <div class="followOrders-sendPrice">
                  <p>{{ numberFormat(followOrders.delivery_price) }}</p>
                </div>

                <!-- <div class="followOrders-level">
                                    <p>{{ followOrders.level }}</p>
                                </div> -->

                <div class="followOrders-status">
                  <span
                    :class="{
                      'followOrders-status-proccessing-span':
                        followOrders.order_status == 'در حال انجام',
                      'followOrders-status-completed-span':
                        followOrders.order_status == 'تکمیل شده',
                      'followOrders-status-canceled-span':
                        followOrders.order_status == 'لغو شده',
                    }"
                  >
                    {{ followOrders.order_status }}
                  </span>
                </div>

                <!-- <div class="followOrders-downloadFactor">
                                    <button class="download-factor">
                                        <img src="../../assets/images/icons/factor-icon-download.svg" alt="">
                                    </button>
                                </div> -->

                <div class="followOrders-allOrderContent">
                  <img
                    v-show="!allOrderCountIndex[index]"
                    @click="showMoreOrder(followOrders, index)"
                    src="../../assets/images/icons/order-details-icon.svg"
                    alt=""
                  />

                  <img
                    v-show="allOrderCountIndex[index]"
                    @click="showMoreOrder(followOrders, index)"
                    src="../../assets/images/icons/openCollapsOrderIcon.svg"
                    alt=""
                  />
                </div>
              </div>

              <div
                :class="[
                  'order-content-info-all',
                  { orderContentInfoShowAll: allOrderCountIndex[index] },
                ]"
              >
                <div class="order-content-info-top">
                  <div class="recipient-delivery-information">
                    <div class="recipient-delivery-information-title">
                      <img
                        src="../../assets/images/icons/recipient-delivery-information-icon.svg"
                        alt=""
                      />
                      <h4>اطلاعات تحویل گیرنده</h4>
                    </div>

                    <div
                      v-if="showOrder != null"
                      class="recipient-delivery-information-content"
                    >
                      <div class="recipient-delivery-information-content-right">
                        <p>نام و نام خانوادگی</p>
                        <p>شماره تماس</p>
                        <p>شماره تلفن ثابت</p>
                        <!-- <p>کد پیگیری</p>  -->
                        <p>کد پستی</p>
                        <p>آدرس</p>
                      </div>

                      <div class="recipient-delivery-information-content-left">
                        <p>
                          {{
                            showOrder.delivery_address != null
                              ? showOrder.delivery_address.full_name
                              : ""
                          }}
                        </p>
                        <p>
                          {{
                            showOrder.delivery_address != null
                              ? showOrder.delivery_address.mobile_number
                              : ""
                          }}
                        </p>
                        <p>
                          {{
                            showOrder.delivery_address != null
                              ? showOrder.delivery_address.phone_number
                              : ""
                          }}
                        </p>
                        <!-- <p>{{ followOrders.RecipienDeliveryInformation.trackingCode }}</p>  -->
                        <p>
                          {{
                            showOrder.delivery_address != null
                              ? showOrder.delivery_address.post_code
                              : ""
                          }}
                        </p>
                        <p>
                          {{
                            showOrder.delivery_address != null
                              ? showOrder.delivery_address.province +
                                "-" +
                                showOrder.delivery_address.city.province +
                                "-" +
                                showOrder.delivery_address.address_details
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="showOrder != null"
                    class="package-information-submitted"
                  >
                    <div class="recipient-delivery-information-title">
                      <img
                        src="../../assets/images/icons/package-information-submitted-icon.svg"
                        alt=""
                      />
                      <h4>اطلاعات بسته ارسالی</h4>
                    </div>

                    <div class="recipient-delivery-information-content">
                      <div class="recipient-delivery-information-content-right">
                        <p>نوع ارسال</p>
                        <p>هزینه ارسال</p>
                        <!-- <p>تاریخ ثبت سفارش</p> -->
                        <p>مبلغ کل</p>
                        <p>تخفیف</p>
                        <p>مبلغ قابل پرداخت</p>
                      </div>
                      <div class="recipient-delivery-information-content-left">
                        <p>
                          {{
                            showOrder.store_delivery_type != null
                              ? showOrder.store_delivery_type.deliveryType_title
                              : ""
                          }}
                          <img
                            src="../../assets/images/icons/car-recieved-icon-normaly.svg"
                            alt=""
                          />
                        </p>

                        <p>
                          {{
                            showOrder.store_delivery_type != null
                              ? showOrder.store_delivery_type.price
                              : ""
                          }}
                          تومان
                        </p>
                        <!-- <p>{{ followOrders.PackageInformationSubmitted.orderTime }}
                                                    <span class="between-history-and-time">|</span>{{
                                                            followOrders.PackageInformationSubmitted.orderDate
                                                    }}
                                                </p> -->
                        <p>
                          {{ showOrder.total_price }}
                          تومان
                        </p>
                        <p class="discount-content">
                          {{ showOrder.total_discount }} تومان
                        </p>
                        <p class="result-payment">
                          {{ showOrder.final_amount }} تومان
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-if="showOrder != null" class="payment-information">
                    <div class="recipient-delivery-information-title">
                      <img
                        src="../../assets/images/icons/payment-information-icon.svg"
                        alt=""
                      />
                      <h4>اطلاعات پرداخت</h4>
                    </div>

                    <div class="recipient-delivery-information-content">
                      <div class="recipient-delivery-information-content-right">
                        <p>نوع پرداخت</p>
                        <p>کد پیگیری پرداخت</p>
                        <p>تاریخ زمان پرداخت</p>
                      </div>

                      <div class="recipient-delivery-information-content-left">
                        <p>
                          {{
                            showOrder.orderPayments != null
                              ? showOrder.orderPayments.payment_type
                              : ""
                          }}
                        </p>
                        <p>
                          {{
                            showOrder.orderPayments != null
                              ? showOrder.orderPayments.transaction_id
                              : ""
                          }}
                        </p>
                        <p>
                          {{
                            showOrder.orderPayments != null
                              ? showOrder.orderPayments.payment_date
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="showOrder != null" class="order-content-info-bottom">
                  <div class="order-content-info-bottom-title">
                    <h4>محصول خریداری شده</h4>
                  </div>

                  <hr class="order-content-info-bottom-title-hr" />

                  <div
                    v-for="(product, ip) in showOrder.order_items"
                    :key="ip"
                    class="order-content-info-bottom-products-buyed"
                  >
                    <div class="product-name">
                      <span>نام محصول :</span>
                      <p class="mr-1">
                        {{
                          product.product_name != null
                            ? product.product_name.slice(0, 20) + "..."
                            : ""
                        }}
                      </p>
                    </div>

                    <div class="product-count">
                      <span>تعداد :</span>
                      <p class="mr-1">
                        {{
                          product.product_count != null
                            ? product.product_count
                            : ""
                        }}
                      </p>
                    </div>

                    <div class="product-color">
                      <span
                        >{{
                          product.prop_key != null ? product.prop_key : ""
                        }}
                        :</span
                      >
                      <span
                        v-if="
                          product.prop_key == 'رنگ' ||
                          product.prop_key == 'color'
                        "
                        :style="{
                          backgroundColor: product.prop_alternative_value,
                        }"
                        class="product-color-show mr-2"
                      ></span>

                      <span class="mr-1" v-else>
                        {{
                          product.prop_main_value != null
                            ? product.prop_main_value
                            : ""
                        }}
                      </span>
                    </div>

                    <div class="product-discount">
                      <span>تخفیف :</span>
                      <p class="mr-1">
                        {{ product.discount != null ? product.discount : 0 }}
                        تومان
                      </p>
                    </div>

                    <div class="product-price">
                      <span>قیمت :</span>
                      <p class="mr-1">
                        {{ product.price != null ? product.price : 0 }} تومان
                      </p>
                    </div>

                    <div class="product-price">
                      <span>قیمت کل :</span>
                      <p class="mr-1">
                        {{
                          product.total_price != null ? product.total_price : 0
                        }}
                        تومان
                      </p>
                    </div>

                    <button
                      @click="goToCreateReturnOrder(product)"
                      v-if="product.is_returned == false"
                      class="back-product-button"
                    >
                      <i class="fas fa-undo"></i>
                      <span>مرجوع کالا</span>
                    </button>

                    <button v-else class="backed-product-button">
                      <i class="fas fa-undo"></i>
                      <span>مرجوع شده</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TODO  follow orders for Mobile  -->
      <div id="follow-orders-mobile" class="follow-orders-mobile">
        <div class="follow-orders-mobile-title">
          <img
            @click="backToMainPage"
            src="../../assets/images/icons/back-arrow-mobile3.svg"
            alt=""
          />

          <p>پیگیری سفارشات</p>
        </div>

        <div class="follow-orders-mobile-content">
          <div class="follow-orders-mobile-content-details">
            <div class="follow-orders-mobile-show-info">
              <div class="orders-info-mobile">
                <p>سفارشات</p>
                <p>{{ countResult != null ? countResult.all : 0 }}</p>
              </div>
              <div class="follow-orders-mobile-show-info-line"></div>
              <div class="delivered-info-mobile">
                <p>تحویل شده</p>
                <p>{{ countResult != null ? countResult.completed : 0 }}</p>
              </div>
              <div class="follow-orders-mobile-show-info-line"></div>
              <div class="not-delivered-info-mobile">
                <p>تحویل نشده</p>
                <p>{{ countResult != null ? countResult.processing : 0 }}</p>
              </div>
              <div class="follow-orders-mobile-show-info-line"></div>
              <div class="canceled-info-mobile">
                <p>لغو شده</p>
                <p>{{ countResult != null ? countResult.canceled : 0 }}</p>
              </div>
            </div>

            <div class="follow-orders-mobile-filters">
              <button
                @click="allOrdersFilter"
                :class="['all-orders-filter', { activeFilter: allOrder }]"
              >
                همه سفارشات
              </button>

              <button
                @click="receivedFilter"
                :class="['delivered-filter', { activeFilter: received }]"
              >
                تکمیل شده
              </button>

              <button
                @click="currentlyProcessingFilter"
                :class="[
                  'not-delivered-filter',
                  { activeFilter: currentlyProcessing },
                ]"
              >
                در حال انجام
              </button>

              <button
                @click="canceledFilter"
                :class="['canceled-filter', { activeFilter: canceled }]"
              >
                لغو شده
              </button>
            </div>

            <div class="orders-follow-details-mobile">
              <div
                v-for="(followOrders, index) in orders"
                :key="index"
                class="orders-follow-details-mobile-single"
              >
                <div class="orders-follow-code-and-price">
                  <p>{{ followOrders.order_barcode }}</p>
                  <p>{{ numberFormat(followOrders.order_price) }} تومان</p>
                </div>

                <div class="orders-follow-dateTime-and-details-link">
                  <p>{{ followOrders.submit_date }}</p>

                  <p @click="seeDetailsOrder(followOrders, index)">
                    جزعیات

                    <font-awesome-icon icon="fa-chevron-left" />
                  </p>
                </div>

                <hr class="orders-follow-mobile-hr" />

                <div class="orders-follow-mobile-images">
                  <img
                    v-for="(item, i) in followOrders.product_galleries"
                    :key="i"
                    :src="item.image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--  details pages for  orders -->
      <div
        v-for="(orderDetails, id) in orders"
        :key="id"
        v-show="allOrderCountIndex[id]"
        class="details-orders-page-mobile"
      >
        <div class="details-orders-page-mobile-title">
          <img
            @click="backToOrdersPage(id)"
            src="../../assets/images/icons/back-arrow-mobile3.svg"
            alt=""
          />
          <p>جزعیات سفارش</p>
        </div>

        <div class="details-orders-mobile-content-parent">
          <div class="details-orders-mobile-content">
            <div class="details-orders-mobile-content-top">
              <div class="details-orders-mobile-content-top-right">
                <div class="order-code-review">
                  <p>شناسه سفارش</p>
                  <hr />
                  <p>{{ showOrder?.order_barcode }}</p>
                </div>
                <div class="order-total-price-review">
                  <p>جمع کل</p>
                  <hr />
                  <p>{{ numberFormat(showOrder?.final_amount) }}</p>
                </div>
                <div class="order-tracking-code-review">
                  <p>نوع پرداخت</p>
                  <hr />
                  <p>
                    {{
                      showOrder?.orderPayments != null
                        ? showOrder.orderPayments.payment_type
                        : ""
                    }}
                  </p>
                </div>
                <!-- <div class="order-tracking-code-review">
                  <p>کد پیگیری</p>
                  <hr />
                  <p>{{ orderDetails.orderCode }}</p>
                </div> -->
                <!-- <button class="order-get-factor">دریافت فاکتور</button> -->
              </div>
              <div class="details-orders-mobile-content-top-left">
                <div class="order-code-review">
                  <p>تاریخ</p>
                  <hr />
                  <p>{{ showOrder?.submit_date }}</p>
                </div>
                <div class="order-total-price-review">
                  <p>هزینه ارسال</p>
                  <hr />
                  <p>{{ numberFormat(showOrder?.delivery_price) }}</p>
                </div>
              </div>
            </div>
            <div
              :class="{
                'followOrders-status-proccessing-span':
                  showOrder?.order_status == 'در حال انجام',
                'followOrders-status-completed-span':
                  showOrder?.order_status == 'تکمیل شده',
                'followOrders-status-canceled-span':
                  showOrder?.order_status == 'لغو شده',
              }"
            >
              {{ showOrder?.order_status }}
            </div>

            <div class="Transferee-info-mobile">
              <div class="Transferee-info-mobile-title">
                <img
                  src="../../assets/images/icons/recipient-delivery-information-icon.svg"
                  alt=""
                />
                <p>اطلاعات تحویل گیرنده</p>
              </div>

              <div class="Transferee-info-mobile-content">
                <div class="Transferee-info-mobile-content-right">
                  <p>نام و نام ‌خانوادگی</p>
                  <p>شماره تماس</p>
                  <p>شماره تلفن ثابت</p>
                  <!-- <p>کد پیگیری</p> -->
                  <p>کد پستی</p>
                  <p>آدرس</p>
                </div>
                <div class="Transferee-info-mobile-content-left">
                  <p>
                    {{
                      showOrder?.delivery_address != null
                        ? showOrder?.delivery_address?.full_name
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      showOrder?.delivery_address != null
                        ? showOrder?.delivery_address?.mobile_number
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      showOrder?.delivery_address != null
                        ? showOrder?.delivery_address?.phone_number
                        : ""
                    }}
                  </p>
                  <!-- <p>
                    {{ orderDetails.RecipienDeliveryInformation.trackingCode }}
                  </p> -->
                  <p>
                    {{
                      showOrder?.delivery_address != null
                        ? showOrder?.delivery_address?.post_code
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      showOrder?.delivery_address != null
                        ? showOrder?.delivery_address?.province +
                          "-" +
                          showOrder?.delivery_address?.city?.province +
                          "-" +
                          showOrder?.delivery_address?.address_details
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="package-sent-info-mobile">
              <div class="package-sent-info-mobile-title">
                <img
                  src="../../assets/images/icons/package-information-submitted-icon.svg"
                  alt=""
                />
                <p>اطلاعات بسته ارسالی</p>
              </div>

              <div class="package-sent-info-mobile-content">
                <div class="package-sent-info-mobile-content-right">
                  <p>نوع ارسال</p>
                  <p>هزینه ارسال</p>
                  <!-- <p>تاریخ ثبت سفارش</p> -->
                  <p>مبلغ کل</p>
                  <p>تخفیف</p>
                  <p>مبلغ قابل پرداخت</p>
                </div>
                <div class="package-sent-info-mobile-content-left">
                  <p>
                    {{
                      showOrder?.store_delivery_type != null
                        ? showOrder?.store_delivery_type?.deliveryType_title
                        : ""
                    }}
                    <img
                      src="../../assets/images/icons/car-recieved-icon-normaly.svg"
                      alt=""
                    />
                  </p>
                  <p>
                    {{
                      showOrder?.store_delivery_type != null
                        ? numberFormat(showOrder?.store_delivery_type?.price) +
                          " " +
                          "تومان"
                        : ""
                    }}
                  </p>
                  <!-- <p>
                    {{ orderDetails.PackageInformationSubmitted.orderDate }}
                  </p> -->
                  <p>{{ numberFormat(showOrder?.total_price) }} تومان</p>
                  <p class="discount-mobile">
                    {{ numberFormat(showOrder?.total_discount) }} تومان
                  </p>
                  <p class="amount-payable-mobile">
                    {{ numberFormat(showOrder?.final_amount) }} تومان
                  </p>
                </div>
              </div>
            </div>

            <div class="payment-info-mobile">
              <div class="payment-info-mobile-title">
                <img
                  src="../../assets/images/icons/payment-information-icon.svg"
                  alt=""
                />
                <p>اطلاعات پرداخت</p>
              </div>

              <div class="payment-info-mobile-content">
                <div class="payment-info-mobile-content-right">
                  <p>نوع پرداخت</p>
                  <p>کد پیگیری پرداخت</p>
                  <p>تاریخ زمان پرداخت</p>
                </div>

                <div class="payment-info-mobile-content-left">
                  <p>
                    {{
                      showOrder?.orderPayments != null
                        ? showOrder?.orderPayments?.payment_type
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      showOrder?.orderPayments != null
                        ? showOrder?.orderPayments?.transaction_id
                        : ""
                    }}
                  </p>
                  <p>
                    {{
                      showOrder?.orderPayments != null
                        ? showOrder?.orderPayments?.payment_date
                        : ""
                    }}
                  </p>
                </div>
              </div>
            </div>

            <div class="products-Bought-mobile">
              <div class="products-Bought-mobile-title">
                <img
                  src="../../assets/images/icons/products-bought-mobile-icon.svg"
                  alt=""
                />
                <p>محصولات خریداری شده</p>
              </div>

              <div class="products-bought-mobile-content">
                <div
                  v-for="(productMobile, im) in showOrder?.order_items"
                  :key="im"
                  class="products-bought-mobile-content-single"
                >
                  <div class="products-bought-mobile-content-single-title">
                    <p>
                      {{
                        productMobile?.product_name != null
                          ? productMobile?.product_name.slice(0, 20) + "..."
                          : ""
                      }}
                    </p>

                    <!-- returned btn -->
                    <button
                      @click="goToCreateReturnOrder(productMobile)"
                      v-if="!productMobile.is_returned"
                      class="products-bought-mobile-single-title-btn"
                    >
                      <img
                        src="../../assets/images/icons/marjoe-kala-mobile-icon.svg"
                        alt=""
                      />
                      مرجوع کالا
                    </button>
                    <button
                      v-else
                      class="products-bought-mobile-single-title-btn-backed"
                    >
                      <img
                        src="../../assets/images/icons/marjoe-kala-mobile-icon.svg"
                        alt=""
                      />
                      مرجوع شده
                    </button>
                  </div>

                  <div class="products-bought-mobile-content-single-content">
                    <div class="product-bought-single-right">
                      <div
                        v-show="productMobile?.prop_key != null"
                        v-if="
                          productMobile?.prop_key == 'color' ||
                          productMobile?.prop_key == 'رنگ'
                        "
                        class="product-bought-color-box"
                        :style="{
                          backgroundColor:
                            productMobile?.prop_alternative_value,
                        }"
                      ></div>
                      <p v-show="productMobile?.prop_key != null" v-else>
                        {{ productMobile?.prop_key }} :
                        {{ productMobile?.prop_main_value }}
                      </p>
                      <p>تعداد : {{ productMobile?.product_count }}</p>
                      <p>تخفیف : {{ numberFormat(productMobile?.discount) }}</p>
                      <p>قیمت : {{ numberFormat(productMobile?.price) }}</p>
                    </div>

                    <div class="product-bought-single-left">
                      <img :src="productMobile?.product_image" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </user-account>
</template>

<script>
import UserAccount from "./UserAccount.vue";
import { numberFormat } from "../../assets/helper/helper";

export default {
  components: { "user-account": UserAccount },
  name: "OrdersFollow",

  data() {
    return {
      allOrder: true,

      currentlyProcessing: false,

      received: false,

      canceled: false,

      downloadFactorOrder1: false,
      downloadFactorOrder2: false,
      downloadFactorOrder3: false,

      showContent: false,
      showOrderContent: false,

      showContent2: false,
      showOrderContent2: false,

      allOrderCountIndex: [],

      orderContent: [
        {
          orderCode: "BARt451555",
          date: "99 / 5 / 20",
          totalPrice: "22,000 تومان",
          sendPrice: "رایگان",
          level: "تکمیل شده",
          status: "تحویل شده",
          imgProduct: [
            require("../../assets/images/pictures/orders-product-img-mobile.png"),
            require("../../assets/images/pictures/orders-product-img-mobile1.png"),
            require("../../assets/images/pictures/orders-product-img-mobile2.png"),
            require("../../assets/images/pictures/orders-product-img-mobile3.png"),
            require("../../assets/images/pictures/orders-product-img-mobile4.png"),
          ],
          RecipienDeliveryInformation: {
            fullName: "مهدی طاهری",
            phoneNumber: "09122707626",
            constNumber: "0215864556",
            trackingCode: "BI1456852",
            postalCode: "2356485958",
            address: "خونه مادرم",
          },

          PackageInformationSubmitted: {
            postType: "عادی",
            shippingCost: "رایگان",
            orderTime: "19:20",
            orderDate: "99 / 5 / 20",
            totalPrice: "12,000 تومان",
            discount: "2,000  تومان",
            amountPayable: "12,000 تومان",
          },

          paymentInformation: {
            PaymentGateway: "درگاه بانک ملت",
            PaymentTrackingCode: "BI14532165",
            PaymentTime: "19:20",
            PaymentDate: "99 / 5 / 20",
          },

          ProductsPurchased: [
            {
              productName: "رکسونا برای اقایان",
              productCount: "2 عدد",
              color: "#FA378B",
              discount: "0 تومان",
              price: "5,200,000 تومان",
              returnTheProduct: false,
              productImg: require("../../assets/images/pictures/product-img-mobile.svg"),
            },

            {
              productName: "رکسونا برای اقایان",
              productCount: "2 عدد",
              color: "#FA378B",
              discount: "0 تومان",
              price: "5,200,000 تومان",
              returnTheProduct: false,
              productImg: require("../../assets/images/pictures/product-img-mobile.svg"),
            },
          ],
        },

        {
          orderCode: "BARt451555",
          date: "99 / 5 / 22",
          totalPrice: "29,000 تومان",
          sendPrice: "رایگان",
          level: "تکمیل شده",
          status: "تحویل شده",
          imgProduct: [
            require("../../assets/images/pictures/orders-product-img-mobile.png"),
            require("../../assets/images/pictures/orders-product-img-mobile1.png"),
            require("../../assets/images/pictures/orders-product-img-mobile2.png"),
            require("../../assets/images/pictures/orders-product-img-mobile3.png"),
            require("../../assets/images/pictures/orders-product-img-mobile4.png"),
            require("../../assets/images/pictures/orders-product-img-mobile3.png"),
            require("../../assets/images/pictures/orders-product-img-mobile4.png"),
          ],

          RecipienDeliveryInformation: {
            fullName: "مهدی طاهری",
            phoneNumber: "09122707626",
            constNumber: "0215864556",
            trackingCode: "BI1456852",
            postalCode: "2356485958",
            address: "خونه مادرم",
          },

          PackageInformationSubmitted: {
            postType: "عادی",
            shippingCost: "رایگان",
            orderTime: "19:20",
            orderDate: "99 / 5 / 20",
            totalPrice: "12,000 تومان",
            discount: "2,000  تومان",
            amountPayable: "12,000 تومان",
          },

          paymentInformation: {
            PaymentGateway: "درگاه بانک ملت",
            PaymentTrackingCode: "BI14532165",
            PaymentTime: "19:20",
            PaymentDate: "99 / 5 / 20",
          },

          ProductsPurchased: [
            {
              productName: "رکسونا برای اقایان",
              productCount: "2 عدد",
              color: "#FA378B",
              discount: "0 تومان",
              price: "5,200,000 تومان",
              returnTheProduct: true,
              productImg: require("../../assets/images/pictures/product-img-mobile.svg"),
            },

            {
              productName: "رکسونا برای اقایان",
              productCount: "2 عدد",
              color: "#FA378B",
              discount: "0 تومان",
              price: "5,200,000 تومان",
              returnTheProduct: true,
              productImg: require("../../assets/images/pictures/product-img-mobile.svg"),
            },
          ],
        },
      ],

      /* mobile data */

      screenWidth: null,
    };
  },

  mounted() {
    // get size of client mobile for sidebar
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }

    if (
      (this.screenWidth < 426 && this.$route.path == "/profile/order") ||
      this.$route.path == "/profile/order/"
    ) {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      header.style.display = "none";
      userAccountSliderMobile.style.display = "none";
    }
  },

  methods: {
    numberFormat,
    //  method for go to return order page
    goToCreateReturnOrder(orderItem) {
      if (this.showOrder.order_status == "تکمیل شده") {
        //  redirect user
        let resolved = this.$router.resolve({
          name: "createReturnOrder",
          params: { id: orderItem.id },
        });

        location.href = resolved.href;
      } else {
        this.$store.dispatch("warningAlertDispatch", {
          show: true,
          text: "سفارش تکمیل نشده است ، امکان مرجوع کالا نمی باشد .",
        });
      }
    },
    allOrdersFilter() {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "",
      });

      // close all content of orders
      this.orders.map((item, i) => {
        this.allOrderCountIndex[i] = false;
        this.$forceUpdate();
      });

      this.allOrder = true;
      this.currentlyProcessing = false;
      this.received = false;
      this.canceled = false;
    },
    currentlyProcessingFilter() {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "در حال انجام",
      });

      // close all content of orders
      this.orders.map((item, i) => {
        this.allOrderCountIndex[i] = false;
        this.$forceUpdate();
      });

      this.allOrder = false;
      this.currentlyProcessing = true;
      this.received = false;
      this.canceled = false;
    },

    receivedFilter() {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "تکمیل شده",
      });

      // close all content of orders
      this.orders.map((item, i) => {
        this.allOrderCountIndex[i] = false;
        this.$forceUpdate();
      });
      this.allOrder = false;
      this.currentlyProcessing = false;
      this.received = true;
      this.canceled = false;
    },

    canceledFilter() {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "لغو شده",
      });

      // close all content of orders
      this.orders.map((item, i) => {
        this.allOrderCountIndex[i] = false;
        this.$forceUpdate();
      });

      this.allOrder = false;
      this.currentlyProcessing = false;
      this.received = false;
      this.canceled = true;
    },

    /* show More Orders methods */
    showMoreOrder(order, index) {
      this.$store.dispatch("showOrderDispatch", {
        token: this.token,
        order_id: order.id,
      });

      if (this.allOrderCountIndex[index] == false) {
        this.allOrderCountIndex[index] = true;

        this.orders.map((item, i) => {
          if (index != i) {
            this.allOrderCountIndex[i] = false;
          }
        });

        this.$forceUpdate();
      } else {
        this.allOrderCountIndex[index] = false;
        this.$forceUpdate();
      }
    },

    /* mobile methods */
    backToMainPage() {
      let resolved = this.$router.resolve({
        name: "userAccount",
      });

      location.href = resolved.href;

      /* get Header Mobile */
      const header = document.getElementById("app-header-mobile");
      header.style.display = "flex";

      /* get sideBar Mobile */
      const sidebarMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      sidebarMobile.style.display = "flex";
    },

    seeDetailsOrder(order, index) {
      // dispatch show order api
      this.$store.dispatch("showOrderDispatch", {
        token: this.token,
        order_id: order.id,
      });

      this.allOrderCountIndex[index] = true;

      this.$forceUpdate();

      // get follow orders mobile

      const followOrdersMobile = document.getElementById(
        "follow-orders-mobile"
      );

      followOrdersMobile.style.display = "none";
    },

    backToOrdersPage(id) {
      this.allOrderCountIndex[id] = false;
      this.$forceUpdate();

      /* get follow orders mobile */
      const followOrdersMobile = document.getElementById(
        "follow-orders-mobile"
      );
      followOrdersMobile.style.display = "flex";
    },
  },

  computed: {
    token() {
      return this.$store.getters.getToken;
    },

    orders() {
      if (
        this.$store.getters.getUserOrdes != null &&
        "data" in this.$store.getters.getUserOrdes
      ) {
        this.$store.getters.getUserOrdes.data.map((order) => {
          this.allOrderCountIndex.push(false);
          this.$forceUpdate();
        });
        return this.$store.getters.getUserOrdes.data;
      } else {
        return [];
      }
    },

    countResult() {
      if (this.$store.getters.getUserOrdes != null) {
        return this.$store.getters.getUserOrdes.count_result;
      } else {
        return null;
      }
    },

    showOrder() {
      if (this.$store.getters.getUserOrder != null) {
        return this.$store.getters.getUserOrder;
      } else {
        return null;
      }
    },
  },

  created() {
    // dispatch for show loader
    // this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get orders of user

    if (this.$route.query.activeOrder != undefined) {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "در حال انجام",
      });
      this.allOrder = false;
      this.currentlyProcessing = true;
      this.received = false;
      this.canceled = false;
    } else {
      this.$store.dispatch("userOrdersDispatch", {
        token: this.token,
        order_status: "",
      });
    }
  },
};
</script>

<style src="../../assets/css/userAccount/OrdersFollow.css" scoped></style>
