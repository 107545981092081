<template>
  <user-account>
    <div class="orders-returned-parent">
      <!--  orders returned for desktop  -->
      <div class="orders-returned">
        <div class="orders-returned-title">
          <p>نام محصول</p>
          <p>شناسه سفارش</p>
          <!-- <p>تخفیف</p> -->
          <p>مبلغ مرجوعی</p>
          <p>وضعیت محصول مرجوعی</p>
          <p>علت مرجوعی</p>
          <p>تعداد</p>
        </div>

        <div class="orders-returned-content">
          <div
            v-show="returnedOrders.length > 0"
            v-for="(orderReturned, index) in returnedOrders"
            v-bind:key="index"
            class="orders-returned-content-row"
          >
            <span>
              {{
                orderReturned.product_name.length > 50
                  ? orderReturned.product_name.slice(0, 50) + "..."
                  : orderReturned.product_name
              }}
            </span>
            <span :style="{ width: '10%' }">
              {{ orderReturned.order_barcode }}
            </span>
            <!-- <span>{{ orderReturned.discount }}</span> -->
            <span>{{ orderReturned.returned_price }} تومان</span>

            <span
              v-show="orderReturned.returned_status == 'در انتظار بررسی'"
              class="waiting"
            >
              {{ orderReturned.returned_status }}</span
            >

            <span
              v-show="orderReturned.returned_status == 'تایید شده'"
              class="accepts"
              >{{ orderReturned.returned_status }}</span
            >

            <span
              v-show="orderReturned.returned_status == 'ردشده'"
              class="canceled"
            >
              {{ orderReturned.returned_status }}</span
            >

            <span :style="{ minWidth: '171px', paddingRight: '15px' }">{{
              orderReturned.returned_cause
            }}</span>
            <span :style="{ paddingRight: '25px' }">{{
              orderReturned.returned_count
            }}</span>
          </div>
        </div>
      </div>

      <!--  orders returned for mobile  (mobile section) -->
      <div id="orders-returned-mobile" class="orders-returned-mobile">
        <div class="details-orders-page-mobile-title">
          <img
            @click="backToSideBarProfileFromOrdersReturned"
            src="../../assets/images/icons/back-arrow-mobile3.svg"
            alt=""
          />
          <p>محصولات مرجوعی</p>
        </div>

        <div class="details-orders-mobile-content-parent">
          <div
            v-for="(orderReturned, or) in returnedOrders"
            :key="or"
            class="details-orders-mobile-content"
          >
            <div class="details-orders-mobile-content-top">
              <div class="details-orders-mobile-content-top-right">
                <div class="order-code-review">
                  <p>نام محصول</p>
                  <hr />
                  <p>
                    {{
                      orderReturned.product_name.length > 50
                        ? orderReturned.product_name.slice(0, 50) + "..."
                        : orderReturned.product_name
                    }}
                  </p>
                </div>
                <div class="order-total-price-review">
                  <p>تخفیف</p>
                  <hr />
                  <p>{{ numberFormat(orderReturned.discount) }} تومان</p>
                </div>
                <div class="order-tracking-code-review">
                  <p>تعداد</p>
                  <hr />
                  <p>{{ orderReturned.returned_count }}</p>
                </div>
              </div>
              <div class="details-orders-mobile-content-top-left">
                <div class="order-code-review">
                  <p>شناسه سفارش</p>
                  <hr />
                  <p>{{ orderReturned.order_barcode }}</p>
                </div>
                <div class="order-total-price-review">
                  <p>مبلغ مرجوعی</p>
                  <hr />
                  <p>{{ numberFormat(orderReturned.returned_price) }} تومان</p>
                </div>
                <div
                  v-if="orderReturned.prop_key != null"
                  class="order-tracking-code-review"
                >
                  <p>{{ orderReturned.prop_key }}</p>
                  <hr />
                  <p>{{ orderReturned.prop_mainValue }}</p>
                </div>
              </div>
            </div>
            <div
              v-show="orderReturned.returned_status == 'در انتظار بررسی'"
              class="order-status waiting-mobile"
            >
              {{ orderReturned.returned_status }}
            </div>
            <div
              v-show="orderReturned.returned_status == 'تایید شده'"
              class="order-status accepts-mobile"
            >
              {{ orderReturned.returned_status }}
            </div>
            <div
              v-show="orderReturned.returned_status == 'ردشده'"
              class="order-status canceled-mobile"
            >
              {{ orderReturned.returned_status }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </user-account>
</template>

<script>
import UserAccount from "./UserAccount.vue";
import { numberFormat } from "../../assets/helper/helper";

export default {
  components: { UserAccount },
  name: "OrdersReturned",

  data() {
    return {
      ordersReturned: [
        {
          productName: "رکسونا",
          orderCode: "BARt451555",
          discount: "22,000 تومان",
          total: "22,000 تومان",
          statusReturnedProductWaiting: "منتظر تماس اپراتور",
          count: "2 عدد",
          size: "L",
        },

        {
          productName: "رکسونا",
          orderCode: "BARt451555",
          discount: "22,000 تومان",
          total: "22,000 تومان",
          statusReturnedProductAccepts: "پذیرفته شده",
          count: "2 عدد",
          size: "L",
        },
        {
          productName: "رکسونا",
          orderCode: "BARt451555",
          discount: "22,000 تومان",
          total: "22,000 تومان",
          statusReturnedProductCanceled: "رد شده",
          count: "2 عدد",
          size: "L",
        },
      ],

      screenWidth: null,
    };
  },

  mounted() {
    // get size of client mobile for sidebar
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }
    if (
      this.screenWidth < 426 &&
      (this.$route.path == "/profile/return-order" ||
        this.$route.path == "/profile/return-order/")
    ) {
      console.log(this.$route.path);
      console.log("return order", this.screenWidth);
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");

      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );

      header.style.display = "none";
      userAccountSliderMobile.style.display = "none";
    }
  },

  methods: {
    numberFormat,
    backToSideBarProfileFromOrdersReturned() {
      /* get orders returned mobile */
      const ordersReturnedMobile = document.getElementById(
        "orders-returned-mobile"
      );

      /* get header mobile */
      const header = document.getElementById("app-header-mobile");

      /* get sideBar mobile */
      const sideBar = document.getElementById("user-account-slider-mobile");

      if (this.screenWidth < 426) {
        ordersReturnedMobile.style.display = "none";

        let resolved = this.$router.resolve({
          name: "userAccount",
        });

        location.href = resolved.href;

        header.style.display = "flex";

        sideBar.style.display = "flex";
      }
    },
  },

  computed: {
    // method for get user token
    token() {
      return this.$store.getters.getToken;
    },

    // get all returned orders
    returnedOrders() {
      if (this.$store.getters.getReturnedOrders != null) {
        return this.$store.getters.getReturnedOrders;
      } else {
        return [];
      }
    },
  },

  created() {
    // action for get all returned orders
    this.$store.dispatch("getAllReturnedOrdersDispatch", { token: this.token });
  },
};
</script>

<style src="../../assets/css/userAccount/ordersReturned.css" scoped></style>
