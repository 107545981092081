<template>
  <!--  -->
  <div
    :class="[
      'alert-box',
      {
        activeAlert: alertInfo.show == true,
        deActiveAlert: alertInfo.show == false
      }
    ]"
  >
    <img src="../../assets/images/icons/Success-alert.png" alt="" />

    <p>
      {{ alertInfo.text }}
    </p>

    <!-- <p>{{ alertInfo.text }}</p> -->
  </div>
</template>
<script>
export default {
  name: "Success",
  data() {
    return {};
  },
  computed: {
    alertInfo() {
      if (this.$store.getters.getAlert != null) {
        return this.$store.getters.getAlert;
      }
    }
  },

  watch: {
    alertInfo() {
      if (this.alertInfo.show) {
        setTimeout(() => {
          this.$store.dispatch("alertDispatch", { show: false, text: "" });
        }, 3000);
      }
    }
  }
};
</script>
<style src="../../assets/css/alert/success.css" scoped></style>
