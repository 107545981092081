<template>
  <div class="payment-verify-container">
    <!-- success box -->
    <div v-if="verifyInfo.statusPayment" class="success-box">
      <p class="success-text">از خرید شما متشکریم</p>
      <img src="../../assets/images/pictures/success-payment.png" alt="" />
      <h3>پرداخت موفق</h3>
      <p>سفارش ثبت و به فروشگاه ارسال شد</p>
      <span> شناسه تراکنش: {{ this.verifyInfo.transaction_id }}</span>
      <span> شناسه پیگیری: {{ this.verifyInfo.reference_id }}</span>
      <button @click="goToOrdes">پیگیری سفارش</button>
    </div>
    <!-- error box -->
    <div v-if="!verifyInfo.statusPayment" class="success-box">
      <img src="../../assets/images/pictures/error-payment.png" alt="" />
      <h3>پرداخت ناموفق</h3>
      <span> شناسه تراکنش: {{ this.verifyInfo.transaction_id }}</span>
      <button @click="tryAgain">تلاش مجدد</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentVerify",
  data() {
    return {
      verifyInfo: {
        statusPayment: "",
        token: null,
        transaction_id: null,
        reference_id: "",
      },
    };
  },
  methods: {
    //** method for go to follow ordersa page */
    goToOrdes() {
      let resolved = this.$router.resolve({
        name: "orders",
      });

      location.href = resolved.href;
    },

    //** method for go to cart checkout */
    tryAgain() {
      let resolved = this.$router.resolve({
        name: "CartContent",
      });

      location.href = resolved.href;
    },
  },
  computed: {
    token() {
      return this.$store.getters.getToken;
    },
  },
  created() {
    if ("Status" in this.$route.query && "Authority" in this.$route.query) {
      this.verifyInfo.transaction_id = this.$route.query.Authority;
      this.verifyInfo.token = this.token;

      this.$store
        .dispatch("checkoutVerifyDispatch", this.verifyInfo)

        .then((res) => {
          console.log(res);
          if (res.data.status) {
            this.verifyInfo.statusPayment = res.data.status;

            this.verifyInfo.reference_id = res.data.reference_id;
            this.$store.dispatch("alertDispatch", {
              show: true,
              text: res.data.message,
            });
          } else {
            this.verifyInfo.statusPayment = res.data.status;

            if (typeof res.data.message == "string") {
              this.$store.dispatch("errorAlertDispatch", {
                show: true,
                text: res.data.message,
              });
            }
          }
        });
    } else {
      let resolved = this.$router.resolve({
        name: "CartContent",
      });

      location.href = resolved.href;
    }
  },
};
</script>

<style scoped lang="css" src="../../assets/css/cart/paymentVerify.css"></style>
