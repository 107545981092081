<template>
  <div
    id="content"
    :class="[
      'mt-4',
      'mb-3',
      'products',
      {
        marginTopForCategory:
          this.$route.path == '/search' || this.$route.path == '/search/',
      },
    ]"
  >
    <div class="products-content">
      <div class="filters-list">
        <!-- product category component -->
        <product-category :categories="getCategories" />

        <div v-if="showFilterComputed" class="applied-filters">
          <div class="applied-filters-title">
            <p class="applied-filters-title-right">فیلتر های اعمال شده</p>

            <p @click="cancelAllFilters" class="applied-filters-title-left">
              لغو همه
            </p>
          </div>

          <div class="applied-filters-items-parent">
            <div v-if="'q' in $route.query" class="applied-filters-items">
              <p class="py-2">{{ this.$route.query.q }}</p>
              <img
                @click="removeSearchFilter(true)"
                src="../../assets/images/icons/multiplied.png"
                alt=""
              />
            </div>

            <div
              v-if="'availableStatus' in $route.query"
              class="applied-filters-items mt-3"
            >
              <p class="py-2">نمایش محصولات موجود</p>
              <img
                @click="removeAvailableStatusFilter(true)"
                src="../../assets/images/icons/multiplied.png"
                alt=""
              />
            </div>

            <div
              v-if="'minPrice' in $route.query && 'maxPrice' in $route.query"
              class="applied-filters-items mt-3"
            >
              <p class="py-2">
                از {{ minPrice }} تومان تا {{ maxPrice }} تومان
              </p>
              <img
                @click="removePriceFilter(true)"
                src="../../assets/images/icons/multiplied.png"
                alt=""
              />
            </div>

            <div
              v-if="'propertyKey' in $route.query"
              class="applied-filters-items mt-3"
            >
              <p class="py-2">نمایش محصولات با ویژگی رنگ</p>
              <img
                @click="removeColorPropFilter(true)"
                src="../../assets/images/icons/multiplied.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="advance-search">
          <div
            @click="showAdvanceSearch = !showAdvanceSearch"
            class="advance-search-title"
          >
            <p>جست و جوی پیشرفته</p>

            <font-awesome-icon
              v-if="!showAdvanceSearch"
              icon="fa-chevron-down"
            />
            <font-awesome-icon v-else icon="fa-chevron-up" />
          </div>

          <div
            :class="[
              'w-100',
              'p-0',
              'advanceSearch',
              { showCollapseResultCategoryContent: showAdvanceSearch },
            ]"
            id="advanceSearch"
          >
            <div class="paddingTop w-100 p-0">
              <div class="search-input">
                <input
                  id="search-input"
                  @keyup.enter="submitSearch"
                  type="text"
                  @input="searchChangeFilter($event)"
                  placeholder="لطفا نام محصول مورد نظر را بنویسید"
                />

                <img
                  @click="submitSearch"
                  src="../../assets/images/icons/searchIcon.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div class="price-range">
          <div
            @click="showPriceRange = !showPriceRange"
            class="price-range-title"
          >
            <p>محدوده قیمت</p>
            <font-awesome-icon v-if="!showPriceRange" icon="fa-chevron-down" />
            <font-awesome-icon v-else icon="fa-chevron-up" />
          </div>

          <div
            :class="[
              'w-100',
              'p-0',
              'price-range-content',
              { showCollapseResultCategoryContent: showPriceRange },
            ]"
            id="price-range"
          >
            <div class="card card-body paddingTop p-0 w-100">
              <div class="price-range-div">
                <p>قیمت به تومان است</p>

                <!--                 vue slider for price filter -->
                <!--                v-if="typeof window != null"-->
                <vue-slider
                  :tooltip="'none'"
                  :process-style="{ backgroundColor: $mainColor }"
                  v-bind="{ max, direction }"
                  :enable-cross="false"
                  :hide-label="true"
                  v-model="Price"
                >
                  <template v-slot:dot>
                    <div class="bigCircle">
                      <div class="smallCircle"></div>
                    </div>
                  </template>
                </vue-slider>

                <div class="price-details">
                  <div class="price-details-min">
                    <span>از</span>
                    <span>{{ numberFormat(Price[0]) }}</span>
                    <span>تومان</span>
                  </div>
                  <div class="price-details-max mt-3">
                    <span>تا</span>
                    <span>{{ numberFormat(Price[1]) }}</span>
                    <span>تومان</span>
                  </div>
                </div>

                <button
                  @click="setPriceFilter"
                  class="apply-changes-btn mt-5 mb-2"
                >
                  اعمال تغییرات
                </button>
              </div>
            </div>
          </div>

          <input type="range" class="progress mt-5" style="display: none" />
        </div>

        <div class="available-products">
          <div class="available-products-title px-2 py-1">
            <p>فقط محصولات موجود</p>

            <div
              @click="checkBoxProducts()"
              class="checkBox"
              :style="[
                availableStatus
                  ? { backgroundColor: $mainColor }
                  : { backgroundColor: $secondColorP },
              ]"
              id="checkBoxAvailableProducts"
            >
              <div
                class="circle"
                :style="[
                  availableStatus
                    ? { left: '24px', transition: 'all .3s ease-in-out' }
                    : { left: '2px', transition: 'all .3s ease-in-out' },
                ]"
                id="circleAvailableProducts"
              ></div>
            </div>

            <input
              v-model="availableStatus"
              :checked="availableStatus"
              type="checkbox"
              id="checkboxAvailableProductsInput"
              style="display: none"
            />
          </div>

          <!-- <hr class="available-products-hr"> -->

          <!-- <div class="available-products-title px-2 py-1">

              <p>فقط محصولات ارسال سریع</p>

              <div @click="checkBoxProducts('checkBoxFastSending', 'circleFastSending', 'checkBoxFastSendingInput')"
                  class="checkBox" id="checkBoxFastSending">

                  <div class="circle" id="circleFastSending"></div>
              </div>

              <input type="checkbox" id="checkBoxFastSendingInput" style="display: none">
          </div> -->
        </div>

        <!-- TODO for color prop filters -->
        <!--    <div v-if="colorPropValues.length > 0" class="color-determination">

          <div @click="showColorDeterminate = !showColorDeterminate" class="color-determination-title">

            <p class="mr-2">تعیین رنگ</p>
            <font-awesome-icon v-if="!showColorDeterminate" icon="fa-chevron-down" />
            <font-awesome-icon v-else icon="fa-chevron-up" />
          </div>

          <div :class="[
              'w-100', 'p-0', 'color-determination-content',
              { showCollapseResultCategoryContent: showColorDeterminate }
          ]" id="color-determination">
            <div class="card card-body paddingTop w-100 p-0">


              <div v-for="(item, vIndex) in colorPropValues" :key="vIndex" class="color-determination-detail">

                <div class="color-determination-detail-right">
                  <div :style="{ backgroundColor: item.value }" class="preview-color-black"></div>
                  <p>{{ item.value }}</p>
                </div>

                <label class="ml-1"
                  @click.prevent="radioChecked('color-radio-black', 'circle-radio-checked-black', item, vIndex)"
                  :for="'color-radio-black' + vIndex">

                  <input type="checkbox" :id="'color-radio-black' + vIndex" style="display: none">

                  <span :id="'circle-radio-checked-black' + vIndex"></span>

                </label>
              </div>

            </div>
          </div>

        </div>
 -->
      </div>

      <!--    Search Filter For Mobile Size-->

      <div class="mobileFilterSearchResult">
        <!-- show filter page -->
        <button
          @click="showFilterPageMobileMethod"
          class="mobileFilterSearchBtn"
        >
          <img src="../../assets/images/icons/filterIcon.png" alt="" />
          <span>فیلتر ها</span>
        </button>

        <!-- search filter -->
        <button
          @click="removeSearchFilter(true)"
          v-if="'q' in $route.query"
          class="ButtonFilterSearchResult mr-2"
        >
          <img
            src="../../assets/images/icons/delete-icon-for-filter-mob.svg"
            alt=""
          />
          <span>{{ this.$route.query.q }}</span>
        </button>

        <!-- available products filters -->
        <button
          @click="removeAvailableStatusFilter(true)"
          v-if="'availableStatus' in $route.query"
          class="ButtonFilterSearchResult mr-2"
        >
          <img
            src="../../assets/images/icons/delete-icon-for-filter-mob.svg"
            alt=""
          />
          <span>نمایش محصولات موجود</span>
        </button>

        <!-- price filter -->
        <button
          @click="removePriceFilter(true)"
          v-if="'minPrice' in $route.query && 'maxPrice' in $route.query"
          class="ButtonFilterSearchResult mr-2"
        >
          <img
            src="../../assets/images/icons/delete-icon-for-filter-mob.svg"
            alt=""
          />
          <span>فیلتر محدوده قیمت</span>
        </button>

        <!-- category filter -->
        <button
          @click.prevent="removeCategoryFilter"
          v-if="'productCategories' in $route.query"
          class="ButtonFilterSearchResult mr-2"
        >
          <img
            src="../../assets/images/icons/delete-icon-for-filter-mob.svg"
            alt=""
          />
          <span>دسته بندی {{ parentTitle }}</span>
        </button>
      </div>

      <div class="products-list mt-5 p-0">
        <!-- categories route for store main page -->
        <!-- <div class="chevron-left p-0">
            <p>بازارچه آنلاین ویتسل</p>
            <img src="../../assets/images/icons/chevron-left.png" alt="">
            <p>آرایشی و بهداشتی</p>
            <img src="../../assets/images/icons/chevron-left.png" alt="">
            <p>فروشگاه اینلای</p>
            <img src="../../assets/images/icons/chevron-left.png" alt="">
            <p>همه محصولات</p>
        </div> -->

        <div class="products-filter-buttons p-0">
          <div class="buttons">
            <button
              @click="filterButtons('allProducts')"
              id="allProducts"
              :class="[
                'products-filter-buttons-all',
                { filterActiveStyle: filterButtonActive == 'allProducts' },
                { filterActiveBorder: filterButtonActive == 'allProducts' },
              ]"
            >
              <img
                v-if="filterButtonActive == 'allProducts'"
                src="../../assets/images/icons/active-all-products.svg"
                alt=""
              />
              <img v-else src="../../assets/images/icons/category.png" alt="" />

              همه محصولات
            </button>

            <button
              @click="filterButtons('mostSaleProducts')"
              id="bestSelling"
              :class="[
                'products-filter-buttons-most-sell',
                { filterActiveStyle: filterButtonActive == 'mostSaleProducts' },
                {
                  filterActiveBorder: filterButtonActive == 'mostSaleProducts',
                },
              ]"
            >
              <img
                v-if="filterButtonActive == 'mostSaleProducts'"
                src="../../assets/images/icons/activeBestSelling.svg"
                alt=""
              />
              <img v-else src="../../assets/images/icons/mostSell.png" alt="" />
              پرفروش ترین
            </button>

            <button
              @click="filterButtons('newestProducts')"
              id="newest"
              :class="[
                'products-filter-buttons-newest',
                { filterActiveStyle: filterButtonActive == 'newestProducts' },
                { filterActiveBorder: filterButtonActive == 'newestProducts' },
              ]"
            >
              <img
                v-if="filterButtonActive == 'newestProducts'"
                src="../../assets/images/icons/activeNewest.svg"
                alt=""
              />
              <img v-else src="../../assets/images/icons/new.png" alt="" />
              جدیدترین
            </button>

            <button
              @click="filterButtons('mostExpensiveProducts')"
              id="mostRelevant"
              :class="[
                'products-filter-buttons-relevant',
                {
                  filterActiveStyle:
                    filterButtonActive == 'mostExpensiveProducts',
                },
                {
                  filterActiveBorder:
                    filterButtonActive == 'mostExpensiveProducts',
                },
              ]"
            >
              <img
                v-if="filterButtonActive == 'mostExpensiveProducts'"
                src="../../assets/images/icons/mostExpensiveActive.svg"
                alt=""
              />
              <img
                v-else
                src="../../assets/images/icons/mostExpensive.svg"
                alt=""
              />
              گران ترین
            </button>

            <button
              @click="filterButtons('cheapestProducts')"
              id="cheapest"
              :class="[
                'products-filter-buttons-cheapest',
                { filterActiveStyle: filterButtonActive == 'cheapestProducts' },
                {
                  filterActiveBorder: filterButtonActive == 'cheapestProducts',
                },
              ]"
            >
              <img
                v-if="filterButtonActive == 'cheapestProducts'"
                src="../../assets/images/icons/cheapestActive.svg"
                alt=""
              />
              <img v-else src="../../assets/images/icons/cheapest.svg" alt="" />
              ارزان ترین
            </button>
          </div>

          <div class="count-products">
            <p>{{ productsCount }} محصول</p>
          </div>
        </div>

        <!--  Products Filter Buttons fot mobile  -->
        <div class="products-filter-btn-mobile">
          <button
            @click="filterButtons('allProducts')"
            :class="[
              'mobile-filter-btn',
              { activeFilterMobileBtn: filterButtonActive === 'allProducts' },
            ]"
          >
            همه محصولات
          </button>

          <button
            @click="filterButtons('mostSaleProducts')"
            :class="[
              'mobile-filter-btn',
              {
                activeFilterMobileBtn:
                  filterButtonActive === 'mostSaleProducts',
              },
            ]"
          >
            پرفروش ترین
          </button>

          <button
            @click="filterButtons('newestProducts')"
            :class="[
              'mobile-filter-btn',
              {
                activeFilterMobileBtn: filterButtonActive === 'newestProducts',
              },
            ]"
          >
            جدیدترین
          </button>

          <button
            @click="filterButtons('mostExpensiveProducts')"
            :class="[
              'mobile-filter-btn',
              {
                activeFilterMobileBtn:
                  filterButtonActive === 'mostExpensiveProducts',
              },
            ]"
          >
            گران ترین
          </button>

          <button
            @click="filterButtons('cheapestProducts')"
            :class="[
              'mobile-filter-btn',
              {
                activeFilterMobileBtn:
                  filterButtonActive === 'cheapestProducts',
              },
            ]"
          >
            ارزان ترین
          </button>
        </div>

        <!-- <div class="row"> -->

        <div ref="productsDiv" class="col-12 products-div">
          <div
            :style="{ width: calcWidthBox + 'px !important' }"
            v-for="(product, index) in getAllProducts"
            :key="index"
            @click="navigateProduct(product)"
            class="product-div"
          >
            <div v-if="product.discount != 0" class="price-discount-percent">
              <span>
                %{{
                  Math.ceil((product.discount / product.product_price) * 100)
                }}
              </span>
            </div>

            <div class="product-div-top">
              <div :class="{ 'hr-right-mobile': index % 2 != 0 }"></div>
              <div class="hr-right"></div>
              <div class="hr-bottom"></div>
              <img :src="product.productGallery" alt="" />
            </div>

            <div class="product-div-bottom">
              <div class="product-about">
                <!-- redirect section -->
                <h3>
                  {{
                    product.product_name.length > 28
                      ? product.product_name.slice(0, 28) + "..."
                      : product.product_name
                  }}
                </h3>
                <span>
                  {{
                    product.product_introduction.length > 25
                      ? product.product_introduction.slice(0, 25) + "..."
                      : product.product_introduction
                  }}
                </span>
              </div>
              <div class="product-price">
                <!-- TODO prev version of discount -->
                <!--  <div v-if="product.discount != 0" class="price-discount p-0">
                  <span>{{ product.product_price }}</span>
                  <div class="line-discount"></div> 

                  <div class="price-discount-percent p-0">
                    <span
                      >%
                      {{
                        Math.ceil(
                          (product.discount / product.product_price) * 100
                        )
                      }}</span
                    >
                  </div>
                </div> -->

                <span class="initial-price">
                  {{numberFormat(product.product_price)}} تومان
                
                </span>
                <div class="price">
                  <p>
                    {{ numberFormat(product.product_price - product.discount) }}
                  </p>
                  <span>تومان</span>
                </div>
              </div>

              <!-- <div class="news">
              <img class="img-fluid" src="../../assets/images/icons/report.svg" alt="">
                  <p>7 عدد در انبار باقیست</p>
              </div> -->

              <div class="star-and-comment">
                <div class="comments">
                  <img
                    src="../../assets/images/icons/new-product-comment.svg"
                    alt=""
                  />

                  <p>{{ product.productComment_count }} +</p>
                  <span>نظر</span>
                </div>

                <div v-if="product.star_average != null" class="stars">
                  <font-awesome-icon
                    class="text-warning"
                    :icon="fontAwesomeComputed(product.star_average, 4)"
                  />

                  <font-awesome-icon
                    class="text-warning"
                    :icon="fontAwesomeComputed(product.star_average, 3)"
                  />

                  <font-awesome-icon
                    class="text-warning"
                    :icon="fontAwesomeComputed(product.star_average, 2)"
                  />

                  <font-awesome-icon
                    class="text-warning"
                    :icon="fontAwesomeComputed(product.star_average, 1)"
                  />

                  <font-awesome-icon
                    class="text-warning"
                    :icon="fontAwesomeComputed(product.star_average, 0)"
                  />
                </div>

                <div v-else class="empty-starts">
                  <img src="../../assets/images/icons/empty-star.svg" alt="" />
                  <p>محصول جدید</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- product pagination box -->
        <div class="products-pagination">
          <div class="numberBox">
            <!-- first page handler -->
            <div
              v-if="currentPage != 1"
              @click="firstPageHandler"
              class="firstPage"
            >
              <a>
                <img src="../../assets/images/icons/firstPage.png" alt="" />
              </a>
            </div>

            <!-- <div v-if="showFirstThreeDots" class="threeDot">
                <img src="../../assets/images/icons/threeDot.png" alt="">
            </div> -->

            <!-- pages -->
            <div
              v-for="(page, index) in paginationLinks"
              :key="index"
              v-show="index != 0 && index != paginationLinks.length - 1"
              :class="{ isActive: currentPage == page.label }"
              @click="addNumbers(page.label)"
            >
              {{ page.label }}
            </div>

            <!-- <div v-if="showLastThreeDots" class="threeDot">
                <img src="../../assets/images/icons/threeDot.png" alt="">
            </div> -->

            <!-- last page handler -->
            <div
              v-if="currentPage != lastPage"
              @click="lastPageHandler"
              id="lastPage"
              class="lastPage"
            >
              <a>
                <img src="../../assets/images/icons/lastPage.png" alt="" />
              </a>
            </div>
          </div>
          <!-- next page for mobile version -->
          <div class="nextPage">
            <button @click="nextPageForMobile(currentPage, lastPage)">
              صفحه بعد
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCategory from "./ProductCategory.vue";
import { numberFormat } from "../../assets/helper/helper";
const productImage = require("../../assets/images/pictures/shampo.png");
import { isJsonValid } from "../../assets/helper/helper";

export default {
  name: "ProductsList",
  props: ["filterMobile"],
  components: {
    "product-category": ProductCategory,
  },

  data() {
    return {
      filterButtonActive: "allProducts",
      isDown: false,
      radioCheck: false,
      showPriceRange: false,
      showColorDeterminate: false,
      showAdvanceSearch: false,

      showComponent: "allProducts",
      isActive: "is-active",
      active: false,

      countPage: [
        { number: 1, to: "#" },
        { number: 2, to: "#" },
        { number: 3, to: "#" },
        { number: 4, to: "#" },
        { number: 5, to: "#" },
        { number: 6, to: "#" },
        { number: 7, to: "#" },
        { number: 8, to: "#" },
        { number: 9, to: "#" },
        { number: 10, to: "#" },
        { number: 11, to: "#" },
        { number: 12, to: "#" },
        { number: 13, to: "#" },
        { number: 14, to: "#" },
        { number: 15, to: "#" },
      ],

      showFirstThreeDots: false,
      showLastThreeDots: true,

      showPages: [
        { number: 1, done: true },
        { number: 2, done: false },
        { number: 3, done: false },
        { number: 4, done: false },
        { number: 5, done: false },
      ],

      /*  Vue Slider Data  */
      Price: [0, 60000000],
      max: 60000000,
      direction: "rtl",

      /* single product content */

      // productContent: [
      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },

      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },

      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },
      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },

      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },
      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },
      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     },

      //     {
      //         productImg: productImage,
      //         productDes: ['پاک کننده ارایش چشم تک فاز پریم', 'مدل نیلوفری'],
      //         productDiscount: '%10',
      //         mainPrice: '52,500',
      //         priceAfterCalculatingDiscount: '39,000',

      //     }
      // ],

      //    mobile active style

      filterMobileBtn: "",

      /*  data for show filters mobile */

      showFilterMobile: false,

      // filters for all products
      mostSaleProducts:
        "mostSaleProducts" in this.$route.query
          ? this.$route.query.mostSaleProducts
          : 0,

      newestProducts:
        "newestProducts" in this.$route.query
          ? this.$route.query.newestProducts
          : 0,
      cheapestProducts:
        "cheapestProducts" in this.$route.query
          ? this.$route.query.cheapestProducts
          : 0,
      mostExpensiveProducts:
        "mostExpensiveProducts" in this.$route.query
          ? this.$route.query.mostExpensiveProducts
          : 0,

      search: "q" in this.$route.query ? this.$route.query.q : "",

      availableStatus:
        "availableStatus" in this.$route.query
          ? this.$route.query.availableStatus
          : 0,

      minPrice:
        "minPrice" in this.$route.query ? this.$route.query.minPrice : "",
      maxPrice:
        "maxPrice" in this.$route.query ? this.$route.query.maxPrice : "",

      productCategories:
        "productCategories" in this.$route.query
          ? JSON.parse(this.$route.query.productCategories)
          : [],

      propertyKey:
        "propertyKey" in this.$route.query ? this.$route.query.propertyKey : "",

      propertyValues:
        "propertyValues" in this.$route.query
          ? JSON.parse(this.$route.query.propertyValues)
          : [],
    };
  },

  created() {
    console.log(this.search);
    // TODO Here
    // check route have avalaible staus query params
    // if (this.$route.path.includes('/search') && ('availableStatus' in this.$route.query)
    //     && (typeof window != undefined)) {

    //     setTimeout(() => {
    //         const checkboxAvailableProductsInput = document.getElementById('checkboxAvailableProductsInput')
    //         const circleAvailableProducts = document.getElementById('circleAvailableProducts')
    //         const checkBoxAvailableProducts = document.getElementById('checkBoxAvailableProducts')

    //         checkBoxAvailableProducts.style.backgroundColor = '#4990E2';
    //         circleAvailableProducts.style.left = '24px';
    //         circleAvailableProducts.style.transition = '0.3s ease-in-out';
    //         checkboxAvailableProductsInput.checked = true;
    //         this.availableStatus = 1;
    //     }, 1000)

    // }

    // check query params for active filters
    if ("mostSaleProducts" in this.$route.query) {
      this.filterButtonActive = "mostSaleProducts";
    } else if ("newestProducts" in this.$route.query) {
      this.filterButtonActive = "newestProducts";
    } else if ("cheapestProducts" in this.$route.query) {
      this.filterButtonActive = "cheapestProducts";
    } else if ("mostExpensiveProducts" in this.$route.query) {
      this.filterButtonActive = "mostExpensiveProducts";
    } else {
      this.filterButtonActive = "allProducts";
    }

    // action for get all products

    this.$store.dispatch("allProductsDispatch", {
      page: 1,
      mostSaleProducts: this.mostSaleProducts,
      search: this.search,
      minPrice: this.minPrice,
      maxPrice: this.maxPrice,
      availableStatus: this.availableStatus,
      newestProducts: this.newestProducts,
      cheapestProducts: this.cheapestProducts,
      mostExpensiveProducts: this.mostExpensiveProducts,
      propertyKey: this.propertyKey,
      propertyValues: JSON.stringify(this.propertyValues),
      productCategories: JSON.stringify(this.productCategories),
    });

    //  show loader
    this.$store.dispatch("showLoaderDispatch", true);

    // action for color prop and all values
    this.$store.dispatch("colorPropDispatch");

    // check query have product categories or not
    if ("productCategories" in this.$route.query) {
      this.$store.dispatch("showCategoriesDispatch", {
        category_id: this.productCategories[0],
      });
    }
  },

  computed: {
    // get current category filter title for mobile
    parentTitle() {
      if (this.$store.getters.getShowCatgories) {
        return this.$store.getters.getShowCatgories.title;
      } else {
        return "";
      }
    },
    // method for calculate div box
    calcWidthBox() {
      const divBoxWidth = this.$refs.productsDiv;
      if (divBoxWidth != undefined) {
        if (divBoxWidth.clientWidth >= 1073) {
          return divBoxWidth.clientWidth / 4;
        } else if (
          800 <= divBoxWidth.clientWidth &&
          divBoxWidth.clientWidth < 1073
        ) {
          return divBoxWidth.clientWidth / 3;
        } else if (
          divBoxWidth.clientWidth < 800 &&
          divBoxWidth.clientWidth >= 500
        ) {
          return divBoxWidth.clientWidth / 2;
        } else if (divBoxWidth.clientWidth < 500) {
          return divBoxWidth.clientWidth / 1;
        }
      }
    },
    // get all products for specific page
    getAllProducts() {
      if (
        this.$store.getters.getAllProducts != null &&
        this.$store.getters.getAllProducts.length > 0
      ) {
        return this.$store.getters.getAllProducts;
      }
    },

    // method for get pagination links
    paginationLinks() {
      if (this.$store.getters.getAllProductsPagination != null) {
        return this.$store.getters.getAllProductsPagination.meta.links;
      } else {
        return [];
      }
    },

    // method for get current page
    currentPage() {
      if (this.$store.getters.getAllProductsPagination != null) {
        return this.$store.getters.getAllProductsPagination.meta.current_page;
      }
    },

    // method for get current page
    lastPage() {
      if (this.$store.getters.getAllProductsPagination != null) {
        return this.$store.getters.getAllProductsPagination.meta.last_page;
      }
    },

    productsCount() {
      if (this.$store.getters.getAllProductsPagination != null) {
        return this.$store.getters.getAllProductsPagination.meta.total;
      }
    },

    // method for get color property
    colorProp() {
      if (this.$store.getters.getColorProp != null) {
        return this.$store.getters.getColorProp;
      }
    },

    // method for color property values
    colorPropValues() {
      if (
        this.$store.getters.getColorProp != null &&
        this.$store.getters.getColorProp.property_values.length > 0
      ) {
        return this.$store.getters.getColorProp.property_values;
      } else {
        return [];
      }
    },

    // method for show filter box
    showFilterComputed() {
      if (
        "q" in this.$route.query ||
        "propertyKey" in this.$route.query ||
        ("minPrice" in this.$route.query && "maxPrice" in this.$route.query) ||
        "availableStatus" in this.$route.query
      ) {
        return true;
      } else {
        return false;
      }
    },

    // method for get all categories
    getCategories() {
      if ("productCategories" in this.$route.query) {
        // return specific categoreis with childes

        if (
          this.$store.getters.getShowCatgories != null &&
          "title" in this.$store.getters.getShowCatgories
        ) {
          return this.$store.getters.getShowCatgories
            .all_product_childes_categories_store;
        }
      } else {
        // return all categories
        if (
          this.$store.getters.getHeaderCategories != null &&
          this.$store.getters.getHeaderCategories.length > 0
        ) {
          return this.$store.getters.getHeaderCategories;
        }
      }
    },
  },

  methods: {
    // is json valid
    isJsonValid,
    // number format
    numberFormat,
    /* show filter Page Method */

    // method for show filter mobile
    showFilterPageMobileMethod() {
      this.showFilterMobile = true;

      this.$store.dispatch(
        "setShowFilterMobileDispatch",
        this.showFilterMobile
      );
    },

    /* next page handler */
    nextPageForMobile(currentPage, lastPage) {
      console.log("not worked");
      if (currentPage != lastPage) {
        console.log("worked");
        //  lastPage Clicked
        this.$store.dispatch("allProductsDispatch", {
          page: this.currentPage + 1,
          mostSaleProducts: this.mostSaleProducts,
          search: this.search,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          availableStatus: this.availableStatus,
          productCategories: JSON.stringify(this.productCategories),
          newestProducts: this.newestProducts,
          cheapestProducts: this.cheapestProducts,
          mostExpensiveProducts: this.mostExpensiveProducts,
          propertyKey: this.propertyKey,
          propertyValues: JSON.stringify(this.propertyValues),
        });
      }
    },

    /*  Pagination Methods  */
    addNumbers(number) {
      //  action for get data and paginaion
      this.$store.dispatch("allProductsDispatch", {
        page: number,
        mostSaleProducts: this.mostSaleProducts,
        search: this.search,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        availableStatus: this.availableStatus,
        productCategories: JSON.stringify(this.productCategories),
        newestProducts: this.newestProducts,
        cheapestProducts: this.cheapestProducts,
        mostExpensiveProducts: this.mostExpensiveProducts,
        propertyKey: this.propertyKey,
        propertyValues: JSON.stringify(this.propertyValues),
      });
    },

    firstPageHandler() {
      // firstPage Clicked
      this.$store.dispatch("allProductsDispatch", {
        page: 1,
        mostSaleProducts: this.mostSaleProducts,
        search: this.search,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        availableStatus: this.availableStatus,
        productCategories: JSON.stringify(this.productCategories),
        newestProducts: this.newestProducts,
        cheapestProducts: this.cheapestProducts,
        mostExpensiveProducts: this.mostExpensiveProducts,
        propertyKey: this.propertyKey,
        propertyValues: JSON.stringify(this.propertyValues),
      });
    },

    lastPageHandler() {
      //  lastPage Clicked
      this.$store.dispatch("allProductsDispatch", {
        page: this.lastPage,
        mostSaleProducts: this.mostSaleProducts,
        search: this.search,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        availableStatus: this.availableStatus,
        productCategories: JSON.stringify(this.productCategories),
        newestProducts: this.newestProducts,
        cheapestProducts: this.cheapestProducts,
        mostExpensiveProducts: this.mostExpensiveProducts,
        propertyKey: this.propertyKey,
        propertyValues: JSON.stringify(this.propertyValues),
      });
    },

    // navigate to specific product
    navigateProduct(product) {
      //  redirect user
      let resolved = this.$router.resolve({
        name: "product",
        params: { id: product.product_id, url: product.url },
      });
      location.href = resolved.href;
    },

    /*  products list Buttons  */
    filterButtons(typeOfFilter) {
      this.filterButtonActive = "";

      this.filterButtonActive = typeOfFilter;

      switch (this.filterButtonActive) {
        case "mostSaleProducts":
          if (this.$route.path == "/") {
            location.href = `search?mostSaleProducts=1`;
          } else if (location.href.includes("/search")) {
            this.$router.push({
              query: Object.assign({}, this.$route.query, {
                mostSaleProducts: 1,
              }),
            });

            let query = Object.assign({}, this.$route.query);
            delete query.cheapestProducts;
            delete query.newestProducts;
            delete query.mostExpensiveProducts;
            this.$router.replace({ query });

            this.mostSaleProducts = 1;
            this.newestProducts = 0;
            this.cheapestProducts = 0;
            this.mostExpensiveProducts = 0;

            console.log(this.search);

            this.$store.dispatch("allProductsDispatch", {
              page: 1,
              mostSaleProducts: this.mostSaleProducts,
              search: this.search,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
              availableStatus: this.availableStatus,
              productCategories: JSON.stringify(this.productCategories),
              newestProducts: this.newestProducts,
              cheapestProducts: this.cheapestProducts,
              mostExpensiveProducts: this.mostExpensiveProducts,
              propertyKey: this.propertyKey,
              propertyValues: JSON.stringify(this.propertyValues),
            });
          }

          break;

        case "mostExpensiveProducts":
          if (this.$route.path == "/") {
            location.href = `search?mostExpensiveProducts=1`;
          } else if (location.href.includes("/search")) {
            this.$router.push({
              query: Object.assign({}, this.$route.query, {
                mostExpensiveProducts: 1,
              }),
            });

            let query = Object.assign({}, this.$route.query);
            delete query.cheapestProducts;
            delete query.newestProducts;
            delete query.mostSaleProducts;
            this.$router.replace({ query });

            this.mostSaleProducts = 0;
            this.newestProducts = 0;
            this.cheapestProducts = 0;
            this.mostExpensiveProducts = 1;

            this.$store.dispatch("allProductsDispatch", {
              page: 1,
              mostSaleProducts: this.mostSaleProducts,
              search: this.search,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
              availableStatus: this.availableStatus,
              productCategories: JSON.stringify(this.productCategories),
              newestProducts: this.newestProducts,
              cheapestProducts: this.cheapestProducts,
              mostExpensiveProducts: this.mostExpensiveProducts,
              propertyKey: this.propertyKey,
              propertyValues: JSON.stringify(this.propertyValues),
            });
          }

          break;

        case "newestProducts":
          if (this.$route.path == "/") {
            location.href = `search?newestProducts=1`;
          } else if (location.href.includes("/search")) {
            this.$router.push({
              query: Object.assign({}, this.$route.query, {
                newestProducts: 1,
              }),
            });

            let query = Object.assign({}, this.$route.query);
            delete query.cheapestProducts;
            delete query.mostExpensiveProducts;
            delete query.mostSaleProducts;
            this.$router.replace({ query });

            this.mostSaleProducts = 0;
            this.newestProducts = 1;
            this.cheapestProducts = 0;
            this.mostExpensiveProducts = 0;

            this.$store.dispatch("allProductsDispatch", {
              page: 1,
              mostSaleProducts: this.mostSaleProducts,
              search: this.search,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
              availableStatus: this.availableStatus,
              productCategories: JSON.stringify(this.productCategories),
              newestProducts: this.newestProducts,
              cheapestProducts: this.cheapestProducts,
              mostExpensiveProducts: this.mostExpensiveProducts,
              propertyKey: this.propertyKey,
              propertyValues: JSON.stringify(this.propertyValues),
            });
          }

          break;

        case "cheapestProducts":
          if (this.$route.path == "/") {
            location.href = `search?cheapestProducts=1`;
          } else if (location.href.includes("/search")) {
            this.$router.push({
              query: Object.assign({}, this.$route.query, {
                cheapestProducts: 1,
              }),
            });

            let query = Object.assign({}, this.$route.query);
            delete query.newestProducts;
            delete query.mostExpensiveProducts;
            delete query.mostSaleProducts;
            this.$router.replace({ query });

            this.mostSaleProducts = 0;
            this.newestProducts = 0;
            this.cheapestProducts = 1;
            this.mostExpensiveProducts = 0;

            this.$store.dispatch("allProductsDispatch", {
              page: 1,
              mostSaleProducts: this.mostSaleProducts,
              search: this.search,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
              availableStatus: this.availableStatus,
              productCategories: JSON.stringify(this.productCategories),
              newestProducts: this.newestProducts,
              cheapestProducts: this.cheapestProducts,
              mostExpensiveProducts: this.mostExpensiveProducts,
              propertyKey: this.propertyKey,
              propertyValues: JSON.stringify(this.propertyValues),
            });
          }

          break;

        default:
          if (location.href.includes("/search")) {
            let query = Object.assign({}, this.$route.query);
            delete query.newestProducts;
            delete query.mostExpensiveProducts;
            delete query.mostSaleProducts;
            delete query.cheapestProducts;
            this.$router.replace({ query });

            this.mostSaleProducts = 0;
            this.newestProducts = 0;
            this.cheapestProducts = 0;
            this.mostExpensiveProducts = 0;

            this.$store.dispatch("allProductsDispatch", {
              page: 1,
              mostSaleProducts: this.mostSaleProducts,
              search: this.search,
              minPrice: this.minPrice,
              maxPrice: this.maxPrice,
              availableStatus: this.availableStatus,
              productCategories: JSON.stringify(this.productCategories),
              newestProducts: this.newestProducts,
              cheapestProducts: this.cheapestProducts,
              mostExpensiveProducts: this.mostExpensiveProducts,
              propertyKey: this.propertyKey,
              propertyValues: JSON.stringify(this.propertyValues),
            });
          }

          break;
      }
    },

    /*  products list Buttons end   */
    checkBoxProducts() {
      // check route is main page or search
      if (this.$route.path == "/") {
        location.href = `/search?availableStatus=${1}`;
      } else if (this.$route.path.includes("/search")) {
        if (this.availableStatus == 0) {
          this.availableStatus = 1;
          this.$forceUpdate();

          this.$router.push({
            query: Object.assign({}, this.$route.query, {
              availableStatus: this.availableStatus,
            }),
          });

          //  dispatch get all products for available products
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });
        } else {
          this.availableStatus = 0;
          this.$forceUpdate();

          let query = Object.assign({}, this.$route.query);
          delete query.availableStatus;

          this.$router.replace({ query });

          //   dispatch get all products for available products
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });
        }
      }
    },

    // method for properties flter
    radioChecked(inputId, checkedId, item, index) {
      let colorRadio = document.getElementById(inputId + index);
      let elem = document.getElementById(checkedId + index);

      // check page is store main or search
      if (this.$route.path == "/") {
        this.propertyKey = this.colorProp.key;
        this.propertyValues.push(item.value);

        let resolved = this.$router.resolve({
          name: "search",
          query: {
            propertyValues: JSON.stringify(this.propertyValues),
            propertyKey: this.colorProp.key,
          },
        });

        location.href = resolved.href;
      } else if (this.$route.path.includes("/search")) {
        if (colorRadio.checked === false) {
          elem.style.display = "flex";
          colorRadio.checked = true;

          // set filter
          this.propertyKey = this.colorProp.key;
          this.propertyValues.push(item.value);

          this.$router.push({
            query: Object.assign({}, this.$route.query, {
              propertyValues: JSON.stringify(this.propertyValues),
              propertyKey: this.colorProp.key,
            }),
          });

          // dispatch get all product with color property filter
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });
        } else {
          elem.style.display = "none";
          colorRadio.checked = false;

          if (this.propertyValues.length == 1) {
            this.propertyKey = "";
            this.propertyValues.splice(
              this.propertyValues.indexOf(item.value),
              1
            );

            let query = Object.assign({}, this.$route.query);
            delete query.propertyKey;
            delete query.propertyValues;

            this.$router.replace({ query });
          } else if (this.propertyValues.length > 1) {
            this.propertyValues.splice(
              this.propertyValues.indexOf(item.value),
              1
            );
            this.$router.push({
              query: Object.assign({}, this.$route.query, {
                propertyValues: JSON.stringify(this.propertyValues),
                propertyKey: this.colorProp.key,
              }),
            });
          }

          // dispatch get all product with color property filter
          this.$store.dispatch("allProductsDispatch", {
            page: 1,
            mostSaleProducts: this.mostSaleProducts,
            search: this.search,
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            availableStatus: this.availableStatus,
            productCategories: JSON.stringify(this.productCategories),
            newestProducts: this.newestProducts,
            cheapestProducts: this.cheapestProducts,
            mostExpensiveProducts: this.mostExpensiveProducts,
            propertyKey: this.propertyKey,
            propertyValues: JSON.stringify(this.propertyValues),
          });
        }
      }
    },

    minPriceDown() {
      this.isDown = true;
      console.log(this.isDown);
    },

    minPriceUp() {
      this.isDown = false;
      console.log(this.isDown);
    },

    minPriceMove() {
      // const minPrice = document.querySelector('.progress-btn-min');

      if (this.isDown) {
        // let clientX = minPrice.getBoundingClientRect().left;
        // event.target.clientX += `${10}px`;
        // minPrice.style.right = clientX+'px';
      }
    },

    //      font awesome computed for product stars
    fontAwesomeComputed(starAverage, index) {
      console.log(starAverage);
      if (index < starAverage && starAverage < index + 1) {
        return "fa-solid fa-star-half-stroke";
      } else if (index < starAverage) {
        return "fa fa-star";
      } else {
        return "fa-regular fa-star";
      }
    },

    //      on change for search filter
    searchChangeFilter(e) {
      // set value for search input
      this.search = e.target.value;
    },

    // method for submit search
    submitSearch() {
      // check route is main page or search
      if (this.$route.path == "/") {
        location.href = `/search?q=${this.search}`;
      } else if (this.$route.path.includes("/search")) {
        this.$router.push({
          query: Object.assign({}, this.$route.query, { q: this.search }),
        });

        // dispatch get all products with search filter
        this.$store.dispatch("allProductsDispatch", {
          page: 1,
          mostSaleProducts: this.mostSaleProducts,
          search: this.search,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          availableStatus: this.availableStatus,
          productCategories: JSON.stringify(this.productCategories),
          newestProducts: this.newestProducts,
          cheapestProducts: this.cheapestProducts,
          mostExpensiveProducts: this.mostExpensiveProducts,
          propertyKey: this.propertyKey,
          propertyValues: JSON.stringify(this.propertyValues),
        });
      }
    },

    // set filter price
    setPriceFilter() {
      // check route is store main page or search page
      if (this.$route.path == "/") {
        //  change route to search page and set querty params for min and max price
        location.href = `/search?minPrice=${this.Price[0]}&maxPrice=${this.Price[1]}`;
      } else if (this.$route.path.includes("/search")) {
        // set value price of vue-slider for filter
        this.minPrice = this.Price[0];
        this.maxPrice = this.Price[1];

        this.$router.push({
          query: Object.assign({}, this.$route.query, {
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
          }),
        });

        // dispatch for get all products with price filter
        this.$store.dispatch("allProductsDispatch", {
          page: 1,
          mostSaleProducts: this.mostSaleProducts,
          search: this.search,
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          availableStatus: this.availableStatus,
          productCategories: JSON.stringify(this.productCategories),
          newestProducts: this.newestProducts,
          cheapestProducts: this.cheapestProducts,
          mostExpensiveProducts: this.mostExpensiveProducts,
          propertyKey: this.propertyKey,
          propertyValues: JSON.stringify(this.propertyValues),
        });
      }
    },

    // remove price filter
    removePriceFilter(withDispatch) {
      this.minPrice = "";
      this.maxPrice = "";
      this.Price = [0, 60000000];

      let query = Object.assign({}, this.$route.query);
      delete query.minPrice;
      delete query.maxPrice;

      this.$router.replace({ query });

      if (withDispatch) {
        // dispatch for get all products without price filter
        this.dispatchFilters();
      }
    },

    // remove category filter for mobile
    // method for show all categories
    removeCategoryFilter() {
      let query = Object.assign({}, this.$route.query);
      delete query.productCategories;

      this.$router.replace({ query });

      // reload page
      location.href = this.$router.resolve({
        name: this.$route.path == "/" ? "store" : "search",
        query: this.$router.query,
      }).href;
    },

    //  remove available status filter
    removeAvailableStatusFilter(withDispatch) {
      // get elements
      const checkBoxAvailableProducts = document.getElementById(
        "checkBoxAvailableProducts"
      );
      const circleAvailableProducts = document.getElementById(
        "circleAvailableProducts"
      );
      const checkboxAvailableProductsInput = document.getElementById(
        "checkboxAvailableProductsInput"
      );

      checkBoxAvailableProducts.style.backgroundColor = "#C8C8C8";
      checkboxAvailableProductsInput.checked = false;
      circleAvailableProducts.style.left = "2px";
      circleAvailableProducts.style.transition = "0.3s ease-in-out";

      // remove available products filter
      this.availableStatus = 0;

      let query = Object.assign({}, this.$route.query);
      delete query.availableStatus;

      this.$router.replace({ query });

      if (withDispatch) {
        // dispatch for get all products without availableStatus filter
        this.dispatchFilters();
      }
    },

    //      method for remove color prop dispatch
    removeColorPropFilter(withDispatch) {
      //          remove styles for color props
      this.propertyValues.map((item, i) => {
        let colorRadio = document.getElementById("color-radio-black" + i);
        let elem = document.getElementById("circle-radio-checked-black" + i);

        elem.style.display = "none";
        colorRadio.checked = false;
      });

      this.propertyKey = "";
      this.propertyValues.splice(0, this.propertyValues.length);

      let query = Object.assign({}, this.$route.query);
      delete query.propertyKey;
      delete query.propertyValues;

      this.$router.replace({ query });

      if (withDispatch) {
        // dispatch get all product with color property filter
        this.dispatchFilters();
      }
    },

    //      method for remove search filter
    removeSearchFilter(withDispatch) {
      // get search input
      const input = document.getElementById("search-input");

      input.value = "";
      this.search = "";

      let query = Object.assign({}, this.$route.query);
      delete query.q;
      delete query.propertyValues;

      this.$router.replace({ query });

      if (withDispatch) {
        // dispatch get all product with color property filter
        this.dispatchFilters();
      }
    },

    // cancel all filters
    cancelAllFilters() {
      //  remove search filter without dispatch
      this.removeSearchFilter(false);

      //  remove color prop filter without dispatch
      this.removeColorPropFilter(false);

      //  remove available status filter without dispatch
      this.removeAvailableStatusFilter(false);

      // remove price filter without dispatch
      this.removePriceFilter(false);

      //  dispatch get products without any filters
      this.dispatchFilters();
    },

    //  method for dispatch filters
    dispatchFilters() {
      this.$store.dispatch("allProductsDispatch", {
        page: 1,
        mostSaleProducts: this.mostSaleProducts,
        search: this.search,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        availableStatus: this.availableStatus,
        productCategories: JSON.stringify(this.productCategories),
        newestProducts: this.newestProducts,
        cheapestProducts: this.cheapestProducts,
        mostExpensiveProducts: this.mostExpensiveProducts,
        propertyKey: this.propertyKey,
        propertyValues: JSON.stringify(this.propertyValues),
      });
    },

    // method for dispatch info for backend
    asyncData({ route, store }) {
      // dispatch for get all categories for product category filter
      // store.dispatch('headerCategoriesDispatch')
    },
  },

  // watch show filter mobile
  watch: {
    filterMobile: function (newValue) {
      if (newValue == false) {
        // filters for all products
        this.mostSaleProducts =
          "mostSaleProducts" in this.$route.query
            ? this.$route.query.mostSaleProducts
            : 0;

        this.newestProducts =
          "newestProducts" in this.$route.query
            ? this.$route.query.newestProducts
            : 0;
        this.cheapestProducts =
          "cheapestProducts" in this.$route.query
            ? this.$route.query.cheapestProducts
            : 0;
        this.mostExpensiveProducts =
          "mostExpensiveProducts" in this.$route.query
            ? this.$route.query.mostExpensiveProducts
            : 0;

        this.search = "q" in this.$route.query ? this.$route.query.q : "";

        this.availableStatus =
          "availableStatus" in this.$route.query
            ? this.$route.query.availableStatus
            : 0;

        this.minPrice =
          "minPrice" in this.$route.query ? this.$route.query.minPrice : "";
        this.maxPrice =
          "maxPrice" in this.$route.query ? this.$route.query.maxPrice : "";

        this.productCategories =
          "productCategories" in this.$route.query
            ? JSON.parse(this.$route.query.productCategories)
            : [];

        this.propertyKey =
          "propertyKey" in this.$route.query
            ? this.$route.query.propertyKey
            : "";

        this.propertyValues =
          "propertyValues" in this.$route.query
            ? JSON.parse(this.$route.query.propertyValues)
            : [];
      }
    },
  },
};
</script>

<style src="../../assets/css/store/productsList.css" scoped></style>
