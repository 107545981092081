import { render, staticRenderFns } from "./AddNewPoint.vue?vue&type=template&id=f08d7e56&scoped=true&"
import script from "./AddNewPoint.vue?vue&type=script&lang=js&"
export * from "./AddNewPoint.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/product/addNewPoint.css?vue&type=style&index=0&id=f08d7e56&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f08d7e56",
  null
  
)

export default component.exports