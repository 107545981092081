import { render, staticRenderFns } from "./DeliveryInformation.vue?vue&type=template&id=3791ba4c&scoped=true&"
import script from "./DeliveryInformation.vue?vue&type=script&lang=js&"
export * from "./DeliveryInformation.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/cart/deliveryInformation.css?vue&type=style&index=0&id=3791ba4c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3791ba4c",
  null
  
)

export default component.exports