<template>
  <div id="comment-users" :class="['comment-users', toggleClassObject]">
    <div class="all-comments-product">
      <div
        v-for="(comment, index) in productComments"
        :key="index"
        :class="{
          'comment-users-box1': index % 2 == 0,
          'comment-users-box2': index % 2 == 1,
        }"
      >
        <div class="user-info-btn-suggestion">
          <div class="user-info">
            <div class="user-img">
              <svg
                id="Iconly_Bold_Profile"
                width="80%"
                height="80%"
                data-name="Iconly/Bold/Profile"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <g id="Profile" transform="translate(4 2)">
                  <path
                    id="Profile-2"
                    data-name="Profile"
                    d="M0,16.575c0-2.722,3.686-3.4,8-3.4,4.339,0,8,.7,8,3.424S12.315,20,8,20C3.662,20,0,19.3,0,16.575ZM2.706,5.291A5.294,5.294,0,1,1,8,10.583,5.274,5.274,0,0,1,2.706,5.291Z"
                    transform="translate(0 0)"
                    fill="#fff"
                  />
                </g>
              </svg>
            </div>
            <div class="username-commentDate">
              <p>
                {{
                  comment.full_name == ""
                    ? comment.username != null
                      ? comment.username
                      : "کاربر فروشگاه"
                    : comment.full_name
                }}
              </p>

              <p>
                در تاریخ :
                <span>{{ comment.created_at }}</span>
              </p>
            </div>
          </div>

          <div class="btn-suggestion-danger" v-if="comment.buy_suggestion == 0">
            <img :src="suggestionDanger" alt="" />
            <span>خرید این محصول را پیشنهاد نمیکنم</span>
          </div>

          <div class="btn-suggestion" v-if="comment.buy_suggestion == 1">
            <img :src="suggestionSuccess" alt="" />
            <span>خرید این محصول را پیشنهاد میکنم</span>
          </div>

          <div
            class="btn-suggestion-warning"
            v-if="comment.buy_suggestion == 2"
          >
            <img :src="suggestionWarning" alt="" />
            <span>در مورد خرید این محصول مطمئن نیستم</span>
          </div>
        </div>

        <div class="vertical-comment-line1"></div>

        <div class="vertical-comment-line2"></div>

        <div class="comment-content">
          <img src="../../assets/images/icons/commentsIcon.svg" alt="" />
          <span>
            {{ comment.comment_text }}
          </span>
        </div>
      </div>
    </div>

    <div class="products-pagination">
      <div class="numberBox">
        <div
          v-if="currentPage != 1"
          @click="firstPageHandler"
          class="firstPage"
        >
          <a>
            <img src="../../assets/images/icons/firstPage.png" alt="" />
          </a>
        </div>

        <!-- <div v-if="showFirstThreeDots" class="threeDot">
                    <img src="../../assets/images/icons/threeDot.png" alt="">
                </div> -->

        <div
          v-for="(page, index) in paginationLinks"
          :key="index"
          v-show="index != 0 && index != paginationLinks.length - 1"
          :class="{ isActive: currentPage == page.label }"
          @click="addNumbers(page.label)"
        >
          {{ page.label }}
        </div>

        <!-- <div v-if="showLastThreeDots" class="threeDot">
                    <img src="../../assets/images/icons/threeDot.png" alt="">
                </div> -->

        <div
          v-if="currentPage != lastPage"
          @click="lastPageHandler"
          id="lastPage"
          class="lastPage"
        >
          <a>
            <img src="../../assets/images/icons/lastPage.png" alt="" />
          </a>
        </div>
      </div>

      <div class="nextPage">
        <button>صفحه بعد</button>
      </div>
    </div>
  </div>
</template>

<script>
const suggestionDanger = require("../../assets/images/icons/btn-suggestion-danger.svg");
const suggestionSuccess = require("../../assets/images/icons/btn-suggestion-success-icon.svg");
const suggestionWarning = require("../../assets/images/icons/btn-suggestion-warning.svg");

export default {
  name: "CommentUsers",

  props: ["showAndHideCommentsForPointShow", "showAndHideCommentsForAddNew"],

  data() {
    return {
      countPage: [
        { number: 1, to: "#" },
        { number: 2, to: "#" },
        { number: 3, to: "#" },
        { number: 4, to: "#" },
        { number: 5, to: "#" },
        { number: 6, to: "#" },
        { number: 7, to: "#" },
        { number: 8, to: "#" },
        { number: 9, to: "#" },
        { number: 10, to: "#" },
        { number: 11, to: "#" },
        { number: 12, to: "#" },
        { number: 13, to: "#" },
        { number: 14, to: "#" },
        { number: 15, to: "#" },
      ],

      showFirstThreeDots: false,
      showLastThreeDots: true,

      showPages: [
        { number: 1, done: true },
        { number: 2, done: false },
        { number: 3, done: false },
        { number: 4, done: false },
        { number: 5, done: false },
      ],
    };
  },

  methods: {
    /*  Pagination Methods  */
    addNumbers(number) {
      //  action for get data and paginaion
      this.$store.dispatch("productCommentsDispatch", {
        product_id: this.$route.params.id,
        page: number,
      });
    },

    firstPageHandler() {
      // firstPage Clicked
      this.$store.dispatch("productCommentsDispatch", {
        product_id: this.$route.params.id,
        page: 1,
      });
    },

    lastPageHandler() {
      //  lastPage Clicked
      this.$store.dispatch("productCommentsDispatch", {
        product_id: this.$route.params.id,
        page: this.lastPage,
      });
    },

    // commented method for computed suggestion
    suggestionComputed(comment) {
      console.log(comment);
      switch (comment.buy_suggestion) {
        case 0:
          return {
            class: "btn-suggestion-danger",
            text: "خرید این محصول را پیشنهاد نمیکنم",
            icon: suggestionDanger,
          };
        case 1:
          return {
            class: "btn-suggestion",
            text: "خرید این محصول را پیشنهاد میکنم",
            icon: suggestionSuccess,
          };
        case 2:
          return {
            class: "btn-suggestion-warning",
            text: "در مورد خرید این محصول مطمئن نیستم",
            icon: suggestionWarning,
          };
      }
    },
  },

  computed: {
    toggleClassObject() {
      return {
        toggleComments:
          this.showAndHideCommentsForPointShow ||
          this.showAndHideCommentsForAddNew,
      };
    },

    // method for get data of comments
    productComments() {
      if (
        this.$store.getters.getProductComments != null &&
        this.$store.getters.getProductComments.length > 0
      ) {
        console.log(this.$store.getters.getProductComments);
        return this.$store.getters.getProductComments;
      }
    },

    // method for get pgination info
    ProductCommentsPagination() {
      if (this.$store.getters.getProductCommentsPagination != null) {
        return this.$store.getters.getProductCommentsPagination;
      }
    },

    // method for get pagination links
    paginationLinks() {
      if (this.$store.getters.getProductCommentsPagination != null) {
        return this.$store.getters.getProductCommentsPagination.meta.links;
      }
    },

    // method for get current page
    currentPage() {
      if (this.$store.getters.getProductCommentsPagination != null) {
        return this.$store.getters.getProductCommentsPagination.meta
          .current_page;
      }
    },

    // method for get current page
    lastPage() {
      if (this.$store.getters.getProductCommentsPagination != null) {
        return this.$store.getters.getProductCommentsPagination.meta.last_page;
      }
    },
  },

  created() {
    this.$store.dispatch("productCommentsDispatch", {
      product_id: this.$route.params.id,
      page: 1,
      // isPaginated: 1,
    });
  },
};
</script>

<style src="../../assets/css/product/commentUsers.css" scoped></style>
