import { render, staticRenderFns } from "./DownloadApp.vue?vue&type=template&id=05d4f3fe&scoped=true&"
import script from "./DownloadApp.vue?vue&type=script&lang=js&"
export * from "./DownloadApp.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/mobileSize/downloadApp.css?vue&type=style&index=0&id=05d4f3fe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05d4f3fe",
  null
  
)

export default component.exports