<template>
  <UserAccount>
    <div id="profile-messages-parent" class="profile-messages-parent">
      <div class="profile-addresses-mobile-title">
        <img
          @click="backToSidebarMobileFromMessagePage"
          src="../../assets/images/icons/back-arrow-mobile3.svg"
          alt=""
        />
        <p>پیغام ها</p>
      </div>

      <!--  profile Messages For Desktop  -->
      <div class="profile-messages">
        <div
          v-for="(message, index) in messages"
          :key="index"
          class="profile-messages-content"
        >
          <span>{{ message.created_at }}</span>
          <div class="profile-messages-content-question">
            <div class="profile-messages-content-question-right">
              <img
                src="../../assets/images/icons/message-question.svg"
                alt=""
              />
              <img
                src="../../assets/images/icons/question-mark-mobile.svg"
                alt=""
              />
              <p>{{ message.message_text }}</p>
            </div>
            <span>{{ message.created_at }}</span>
          </div>

          <hr class="profile-messages-hr" />

          <p class="profile-messages-content-answer">
            {{ message.message_answer }}
          </p>

          <button class="delete-message-mobile">
            <img
              src="../../assets/images/icons/delete-address-mobile-icon.svg"
              alt=""
            />
            حذف
          </button>
        </div>
      </div>
    </div>
  </UserAccount>
</template>

<script>
import UserAccount from "./UserAccount.vue";
export default {
  name: "Messages",
  components: { UserAccount },
  data() {
    return {
      screenWidth: null
    };
  },
  mounted() {
    // get size of client mobile for sidebar
    if (window != undefined) {
      this.screenWidth = window.screen.width;
    }

    if (
      (this.screenWidth < 426 && this.$route.path == "/profile/message") ||
      this.$route.path == "/profile/message/"
    ) {
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      header.style.display = "none";
      userAccountSliderMobile.style.display = "none";
    }
  },
  computed: {
    //  method for get token
    token() {
      return this.$store.getters.getToken;
    },

    //  method for get messages
    messages() {
      if (this.$store.getters.getUserMessages != null) {
        console.log(this.$store.getters.getUserMessages);
        return this.$store.getters.getUserMessages;
      }
    }
  },
  methods: {
    backToSidebarMobileFromMessagePage() {
      const messages = document.getElementById("profile-messages-parent");
      /*  get mobile header  */
      const header = document.getElementById("app-header-mobile");
      /* get user account slider mobile */
      const userAccountSliderMobile = document.getElementById(
        "user-account-slider-mobile"
      );
      if (this.screenWidth < 426) {
        messages.style.display = "none";
        header.style.display = "flex";
        userAccountSliderMobile.style.display = "flex";
        let resolved = this.$router.resolve({
          name: "userAccount"
        });

        location.href = resolved.href;
      }
    },

    //  method for get async data in ssr
    asyncData({ store, router }) {
      store.dispatch("userMessagesDispatch", { token: this.token });
    }
  },

  created() {
    // dispatch for show loader
    this.$store.dispatch("showLoaderDispatch", true);

    //  dispatch for get messages of user
    this.$store.dispatch("userMessagesDispatch", { token: this.token });
  }
};
</script>

<style src="../../assets/css/userAccount/messages.css" scoped></style>
