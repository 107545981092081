<template>

    <!--  -->
    <div :class="['alert-box', {
        activeAlert: alertInfo.show == true,
        deActiveAlert: alertInfo.show == false,
    }]">

        <img src="../../assets/images/icons/warning-alert2.svg" alt="">

        <p>
            {{ alertInfo.text }}
        </p>

        <!-- <p>{{ alertInfo.text }}</p> -->
    </div>

</template>
<script>
export default {
    name: "Warning",
    data() {
        return {}
    },
    computed: {
        alertInfo() {
            if (this.$store.getters.getWarningAlert != null) {
                return this.$store.getters.getWarningAlert;
            }
        }
    },



    watch: {
        alertInfo() {
            if (this.alertInfo.show) {
                setTimeout(() => {
                    this.$store.dispatch("warningAlertDispatch", { show: false, text: "" })
                }, 3000)
            }
        }
    }
}
</script>
<style src="../../assets/css/alert/warning.css" scoped>
</style>