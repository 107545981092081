import axios from "axios";

const baseURL = "https://api.vitsell.ir/api";
// const baseURL = "http://192.168.1.128:8000/api";
// const baseURL = "http://127.0.0.1:8000/api";

//*** karfarma server */
// const baseURL = "https://store-api.labniid.com/api/";


const http = (token) => {
  return axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// http.interceptors.request.use(
//   config => {
//     config.headers["Authorization"] = `Bearer ${token}`;

//     return config;
//   },

//   error => {
//     return Promise.reject(error);
//   }
// );

export default http;
