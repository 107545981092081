<template>
  <div class="d-flex flex-column w-100">
    <div
      v-for="(category, index) in categories"
      :key="index"
      :id="'slider-collapse1' + index"
      :class="['slider-collapse', 'p-0', { 'mt-3': index == 0 }]"
    >
      <!-- main layer -->
      <div class="slider-content-collapse-parent">
        <span @click.prevent="showSpecificCategory(category)">
          {{ category.title }}
        </span>
        <font-awesome-icon
          @click="
            showAndLessCollapse(
              `slider-collapse1${index}`,
              `parent-collapse-icon1${index}`,
              `slider-content-collapse-child1${index}`,
              category
            )
          "
          :id="'parent-collapse-icon1' + index"
          icon="fa-chevron-down"
        />
      </div>

      <!-- second and third layer -->
      <div
        :id="'slider-content-collapse-child1' + index"
        class="slider-content-collapse-child"
      >
        <div
          v-show="category.all_product_childes_categories_store.length > 0"
          v-for="(second, i) in category.all_product_childes_categories_store"
          :key="i"
          class="slider-content-collapse-child-details"
        >
          <div class="slider-content-collapse-child-details-title">
            <font-awesome-icon
              @click="
                showMoreAndLessCollapseChild(
                  `child-collapse-icon1${index}${i}`,
                  `slider-content-collapse-child-into1${index}${i}`,
                  second
                )
              "
              :id="'child-collapse-icon1' + index + i"
              icon="fa-chevron-left"
            />
            <span @click.prevent="showSpecificCategory(second)">{{
              second.title
            }}</span>
          </div>

          <div
            :id="'slider-content-collapse-child-into1' + index + i"
            class="slider-content-collapse-child-into"
          >
            <span
              v-show="second.all_product_childes_categories_store.length > 0"
              v-for="(third, i) in second.all_product_childes_categories_store"
              :key="i"
              @click.prevent="showSpecificCategory(third)"
              >{{ third.title }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderCategories",
  props: ["categories"],
  data() {
    return {
      activeSecondLayer: null,
    };
  },

  methods: {
    // method for show specific category
    showSpecificCategory(category) {
      // dispatch show specific categories
      this.$store.dispatch("showCategoriesDispatch", {
        category_id: category.id,
      });

      // reload page
      location.href = this.$router.resolve({
        name: "search",
        query: {
          productCategories: JSON.stringify([`${category.id}`]),
        },
      }).href;
    },
    showAndLessCollapse(
      sliderCollapse,
      ParentIconI,
      collapseChildHeight,
      category
    ) {
      const parentCollapseIcon = document.getElementById(ParentIconI);
      const sliderCollapseConst = document.getElementById(sliderCollapse);
      const sliderCollapseChildHeight =
        document.getElementById(collapseChildHeight);
      if (category.is_active === 1) {
        category.is_active = 0;

        parentCollapseIcon.style.transform = "rotate(180deg)";

        sliderCollapseChildHeight.style.maxHeight = "1000px";
        sliderCollapseChildHeight.style.transition = "max-height .3s linear";

        sliderCollapseConst.style.backgroundColor = this.$mainColorS;
      } else {
        category.is_active = 1;
        parentCollapseIcon.style.transform = "rotate(0deg)";

        sliderCollapseChildHeight.style.maxHeight = "0px";

        sliderCollapseConst.style.backgroundColor = "#fff";
      }

      console.log(this.activeSecondLayer);
    },

    showMoreAndLessCollapseChild(
      childIconI,
      collapseChildIntoHeight,
      category
    ) {
      const childCollapseIcon = document.getElementById(childIconI);
      const sliderCollapseChildIntoHeight = document.getElementById(
        collapseChildIntoHeight
      );

      if (category.is_active === 1) {
        category.is_active = 0;
        childCollapseIcon.style.transform = "rotate(-90deg)";
        sliderCollapseChildIntoHeight.style.maxHeight = "1000px";
        sliderCollapseChildIntoHeight.style.transition = ".3s linear";
      } else {
        category.is_active = 1;
        childCollapseIcon.style.transform = "rotate(0deg)";

        sliderCollapseChildIntoHeight.style.maxHeight = "0px";
        sliderCollapseChildIntoHeight.style.transition = ".3s linear";
      }
    },
  },

  computed: {},

  watch: {},
};
</script>

<style src="../../assets/css/Header/Header.css" scoped></style>
