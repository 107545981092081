import { render, staticRenderFns } from "./TermsOfReturn.vue?vue&type=template&id=76a8d651&scoped=true&"
import script from "./TermsOfReturn.vue?vue&type=script&lang=js&"
export * from "./TermsOfReturn.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/others/termsOfReturn.css?vue&type=style&index=0&id=76a8d651&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76a8d651",
  null
  
)

export default component.exports