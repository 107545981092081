<template>
  <div :style="{ position: 'relative' }" class="category">
    <div v-show="!getShowFilterMobile" class="col-12 p-0 m-0 store">
      <app-header id="app-header-mobile"></app-header>

      <app-products-list
        :filterMobile="getShowFilterMobile"
      ></app-products-list>

      <!-- TODO sliders -->
      <!-- <discount-slider></discount-slider> -->

      <category-description></category-description>

      <app-footer></app-footer>
    </div>

    <filters-mobile v-show="getShowFilterMobile"></filters-mobile>
  </div>
</template>

<script>
import Header from "../header/Header";
// import DiscountSlider from "../store/DiscountSlider";
import ProductsList from "../store/ProductsList";
import Footer from "../footer/Footer";
import CategoryDescription from "./CategoryDescription";
import FiltersMobile from "../store/FiltersMobile";

export default {
  name: "Category",

  components: {
    "app-header": Header,
    "app-products-list": ProductsList,
    // 'discount-slider': DiscountSlider,
    "category-description": CategoryDescription,
    "filters-mobile": FiltersMobile,
    "app-footer": Footer
  },

  data() {
    return {
      showFilterMobile: false
    };
  },

  computed: {
    getShowFilterMobile() {
      return this.$store.getters.getShowFilterMobile;
    }
  },

  methods: {}
};
</script>

<style src="../../assets/css/category/category.css" scoped></style>
