var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"frequentlyQuestions"},[_c('div',{staticClass:"frequentlyQuestions-content"},[_c('div',{staticClass:"common-your-questions-content"},[_c('div',{staticClass:"common-your-question-content-title"},[_c('h5',[_vm._v("سوالات متداول شما")]),_c('p',{on:{"click":_vm.allFqQuestions}},[_vm._v("مشاهده همه")])]),_c('div',{staticClass:"common-your-questions-content-details"},_vm._l((_vm.fqQuestions),function(commonQ,x){return _c('div',{key:x,class:[
            'common-your-questions-content-single',
            { borderActive: _vm.commonQuestionsShow[x] },
          ]},[_c('div',{staticClass:"common-your-questions-content-single-title",on:{"click":function($event){return _vm.showCommonQuestions(x)}}},[_c('div',{staticClass:"common-single-title-right"},[_c('img',{attrs:{"src":require("../../assets/images/icons/common-questions-store-sign.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(commonQ.title_question))])]),_c('font-awesome-icon',{attrs:{"icon":"fa-chevron-down","id":'commonQuestion' + x}})],1),_c('div',{class:[
              'common-your-questions-content-single-content',
              { CommonQuestionShowContent: _vm.commonQuestionsShow[x] },
            ]},[_c('hr'),_c('span',[_vm._v(" "+_vm._s(commonQ.answer)+" ")])])])}),0)])]),_c('div',{staticClass:"frequentlyQuestions-content-mobile"},[_c('div',{staticClass:"frequentlyQuestions-mobile-title"},[_c('h4',[_vm._v("سوالات متداول شما")]),_c('p',{on:{"click":_vm.allFqQuestions}},[_vm._v("مشاهده همه")])]),_c('div',{staticClass:"frequentlyQuestionsMobile"},_vm._l((_vm.fqQuestions),function(commonQ,x){return _c('div',{key:x,class:[
          'single-question-mobile',
          { openQuestionMobileBox: _vm.commonQuestionsShow[x] },
        ]},[_c('div',{staticClass:"single-question-mobile-title"},[_c('img',{attrs:{"src":require("../../assets/images/icons/common-questions-store-sign.svg"),"alt":""}}),_c('p',[_vm._v(_vm._s(commonQ.title_question))])]),_c('div',{class:[
            'single-question-mobile-answer',
            { singleQuestionShowAnswerMobile: _vm.commonQuestionsShow[x] },
          ]},[_c('p',[_vm._v(" "+_vm._s(commonQ.answer)+" ")])]),_c('hr',{staticClass:"single-question-mobile-hr"}),_c('div',{staticClass:"question-mobile-show-answer"},[(!_vm.commonQuestionsShow[x])?_c('button',{staticClass:"single-question-mobile-show-answer",on:{"click":function($event){return _vm.showCommonQuestions(x)}}},[_c('span',[_vm._v("پاسخ")]),_c('i',{staticClass:"fa fa-chevron-down"})]):_c('button',{staticClass:"single-question-mobile-show-answer",on:{"click":function($event){return _vm.showCommonQuestions(x)}}},[_c('span',{style:({ color: _vm.$mainColor })},[_vm._v("بستن")]),_c('i',{staticClass:"fa fa-chevron-up",staticStyle:{"color":"#4990e2"}})])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }