var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'alert-box',
    {
      activeAlert: _vm.alertInfo.show == true,
      deActiveAlert: _vm.alertInfo.show == false
    }
  ]},[_c('img',{attrs:{"src":require("../../assets/images/icons/error-alert2.svg"),"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.alertInfo.text)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }