<template>
  <div
    v-show="!showAllComments"
    id="brand"
    :style="{
      // backgroundImage: `url(${getBrandBanner})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    }"
    class="brand"
  >
    <img class="baner-type-one" :src="getBrandBanner" alt="" />

    <div class="brand-cover p-0"></div>

    <div class="brand-content p-0">
      <div class="icon-div">
        <img class="img-fluid" :src="getStoreIcon" alt="تصویر برند" />
        <div class="brand-description">
          <p class="pb-0">{{ getStoreName }}</p>
          <p class="pt-0 mb-0">
            {{
              window != undefined && window.innerWidth > 500
                ? getStoreDes
                : getStoreDesMobile
            }}
          </p>
        </div>
      </div>

      <a v-if="showContent" class="dropDown-link" href="#content">
        <img src="../../assets/images/pictures/dropDown(main).svg" alt="" />
      </a>

      <div class="contact-us">
        <div class="points">
          <!-- <span class="ml-1 mt-2 points-message-count">(نظر 134)</span> -->

          <font-awesome-icon :icon="fontAwesomeComputed(4)" />
          <font-awesome-icon :icon="fontAwesomeComputed(3)" />
          <font-awesome-icon :icon="fontAwesomeComputed(2)" />
          <font-awesome-icon :icon="fontAwesomeComputed(1)" />
          <font-awesome-icon :icon="fontAwesomeComputed(0)" />

          <span class="points-span mr-2">{{ getStarsAverageCount }}</span>
        </div>

        <div class="phoneNumber">
          <span class="ml-4">
            <img
              src="../../assets/images/icons/location(main).png"
              alt="مکان"
            />
            {{ getStoreProvince }}
          </span>
          <span>
            <img src="../../assets/images/icons/phone(main).png" alt="تلفن" />
            {{ getPhoneStore }}
          </span>
        </div>
      </div>
      <!--<div class="dropDown-btn">
                <img src="../../assets/images/pictures/dropdown.jpg" alt="">
            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "Brand",

  props: ["showAllComments"],

  data() {
    return {
      // currentRoute: this.$router.currentRoute.path,
      showContent: true
    };
  },

  computed: {
    getBrandBanner() {
      if (
        this.$store.getters.getStoreInfo != null &&
        this.$store.getters.getStoreInfo.store_banners.length > 0
      ) {
        return this.$store.getters.getStoreInfo.store_banners.find(
          item => item.banner_type == 1
        ).media_link;
      }
    },

    getStoreIcon() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.storeIcon;
      }
    },

    getStoreName() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.store_name;
      }
    },

    getStoreDes() {
      if (this.$store.getters.getStoreInfo != null) {
        if (this.$store.getters.getStoreInfo.store_short_des.length > 160) {
          return (
            this.$store.getters.getStoreInfo.store_short_des.slice(0, 160) +
            "..."
          );
        } else {
          return this.$store.getters.getStoreInfo.store_short_des;
        }
      }
    },

    getStoreDesMobile() {
      if (this.$store.getters.getStoreInfo != null) {
        if (this.$store.getters.getStoreInfo.store_short_des.length > 30) {
          return (
            this.$store.getters.getStoreInfo.store_short_des.slice(0, 30) +
            "..."
          );
        } else {
          return this.$store.getters.getStoreInfo.store_short_des;
        }
      }
    },
    getStoreProvince() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.province;
      }
    },
    getPhoneStore() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.phone_store;
      }
    },

    getStarsAverageCount() {
      if (this.$store.getters.getStoreInfo != null) {
        return this.$store.getters.getStoreInfo.stars_average;
      }
    }
  },
  created() {
    // if (this.currentRoute === '/product' || this.currentRoute === '/product/point/add'
    //     || this.currentRoute === '/product/' || this.currentRoute === '/product/point/add/') {
    //     this.showContent = false;
    // }

    this.$store.dispatch("storeInfoDispatch");

    //  show loader
    this.$store.dispatch("showLoaderDispatch", true);
  },

  mounted() {
    console.log(window.innerWidth);
  },

  methods: {
    fontAwesomeComputed(index) {
      if (
        index < this.getStarsAverageCount &&
        this.getStarsAverageCount < index + 1
      ) {
        return "fa-solid fa-star-half-stroke";
      } else if (index < this.getStarsAverageCount) {
        return "fa fa-star";
      } else {
        return "fa-regular fa-star";
      }
    }
  }
};
</script>

<style src="../../assets/css/store/brand.css" scoped></style>
