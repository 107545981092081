import { render, staticRenderFns } from "./PointsShow.vue?vue&type=template&id=d85cfe42&scoped=true&"
import script from "./PointsShow.vue?vue&type=script&lang=js&"
export * from "./PointsShow.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/product/pointsShow.css?vue&type=style&index=0&id=d85cfe42&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d85cfe42",
  null
  
)

export default component.exports