<template>
  <div class="col-12 p-0 m-0 not-found-container">
    <!-- header -->
    <app-header id="app-header-mobile" />

    <!-- not found content -->
    <div class="not-found-content">
      <img src="../../assets/images/pictures/404.svg" alt="" />
      <h3>صفحه مورد نظر پیدا نشد</h3>
      <p>آدرس صفحه ای که جستجو کردید تغییر کرده است یا وجود ندارد</p>
      <button @click="gotoHome()">بازگشت به صفحه اصلی</button>
    </div>
  </div>
</template>

<script>
import header from "../header/Header.vue";
export default {
  name: "NotFound",
  components: {
    "app-header": header,
  },

  methods: {
    gotoHome() {
      let resolved = this.$router.resolve({
        name: "store",
      });
      location.href = resolved.href;
    },
  },
};
</script>

<style src="../../assets/css/others/notFound.css"></style>
