import { render, staticRenderFns } from "./OrdersReturned.vue?vue&type=template&id=db279652&scoped=true&"
import script from "./OrdersReturned.vue?vue&type=script&lang=js&"
export * from "./OrdersReturned.vue?vue&type=script&lang=js&"
import style0 from "../../assets/css/userAccount/ordersReturned.css?vue&type=style&index=0&id=db279652&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db279652",
  null
  
)

export default component.exports